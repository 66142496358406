window.serverReference = {
  "abbreviatedDayNames": [
    "So",
    "Mo",
    "Di",
    "Mi",
    "Do",
    "Fr",
    "Sa"
  ],
  "abbreviatedDayLetters": [
    "S",
    "M",
    "D",
    "M",
    "D",
    "F",
    "S"
  ],
  "abbreviatedMonthNames": [
    "Jan",
    "Feb",
    "Mrz",
    "Apr",
    "Mai",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Okt",
    "Nov",
    "Dez"
  ],
  "addressFilterTypes": [
    {
      "key": "region",
      "value": "Nach Region auswählen"
    },
    {
      "key": "country",
      "value": "Nach Land auswählen"
    },
    {
      "key": "state",
      "value": "Nach Bundesstaat/Bundesland auswählen"
    },
    {
      "key": "city",
      "value": "Nach Stadt auswählen"
    },
    {
      "key": "postalCode",
      "value": "Nach Postleitzahl auswählen"
    }
  ],
  "ageRestrictions": [
    {
      "value": "years10AndOver",
      "text": "ab 10 Jahren"
    },
    {
      "value": "years12AndOver",
      "text": "ab 12 Jahren"
    },
    {
      "value": "years14AndOver",
      "text": "ab 14 Jahren"
    },
    {
      "value": "years16AndOver",
      "text": "ab 16 Jahren"
    },
    {
      "value": "years18AndOver",
      "text": "ab 18 Jahren"
    },
    {
      "value": "years19AndOver",
      "text": "ab 19 Jahren"
    },
    {
      "value": "years2AndOver",
      "text": "ab 2 Jahren"
    },
    {
      "value": "years20AndOver",
      "text": "ab 20 Jahren"
    },
    {
      "value": "years21AndOver",
      "text": "ab 21 Jahren"
    },
    {
      "value": "years6AndOver",
      "text": "ab 6 Jahren"
    },
    {
      "value": "years8AndOver",
      "text": "ab 8 Jahren"
    },
    {
      "value": "allAges",
      "text": "Alle Altersgruppen"
    },
    {
      "value": "unknown",
      "text": "Unbekannt"
    }
  ],
  "appointmentPhases": [
    {
      "value": "option",
      "text": "Option",
      "icon": "icon-option",
      "hexColor": "#ffcc02"
    },
    {
      "value": "confirmed",
      "text": "Bestätigt",
      "icon": "icon-confirmed",
      "hexColor": "#23d2a0"
    },
    {
      "value": "cancelled",
      "text": "Abgesagt",
      "icon": "icon-cancelled",
      "hexColor": "#ff005c"
    }
  ],
  "appointmentTypes": [
    {
      "value": "show",
      "text": "Buchung",
      "icon": "icon-show"
    },
    {
      "value": "flight",
      "text": "Flug",
      "icon": "icon-flight"
    },
    {
      "value": "train",
      "text": "Zugreise",
      "icon": "icon-train"
    },
    {
      "value": "groundTransport",
      "text": "Ground transport",
      "icon": "icon-groundtransport"
    },
    {
      "value": "accommodation",
      "text": "Accomodation",
      "icon": "icon-accommodation"
    },
    {
      "value": "externalCalendar",
      "text": "Externer Kalender",
      "icon": "icon-external"
    }
  ],
  "bankAccountFormats": [
    {
      "value": "americanChecking",
      "text": "Amerikanisches Girokonto",
      "displayAccountNumberInput": true,
      "displayIbanNumberInput": false,
      "displayBranchNameInput": false,
      "displayBranchCodeInput": false,
      "displaySwiftCodeInput": true,
      "displaySortCodeInput": false,
      "displayBsbCodeInput": false,
      "displayAbaRoutingNumberInput": true
    },
    {
      "value": "australian",
      "text": "Australisches Konto",
      "displayAccountNumberInput": true,
      "displayIbanNumberInput": false,
      "displayBranchNameInput": false,
      "displayBranchCodeInput": false,
      "displaySwiftCodeInput": true,
      "displaySortCodeInput": false,
      "displayBsbCodeInput": true,
      "displayAbaRoutingNumberInput": false
    },
    {
      "value": "british",
      "text": "Britisches Konto",
      "displayAccountNumberInput": true,
      "displayIbanNumberInput": true,
      "displayBranchNameInput": false,
      "displayBranchCodeInput": false,
      "displaySwiftCodeInput": true,
      "displaySortCodeInput": true,
      "displayBsbCodeInput": false,
      "displayAbaRoutingNumberInput": false
    },
    {
      "value": "genericIban",
      "text": "Generisches IBAN-Konto",
      "displayAccountNumberInput": false,
      "displayIbanNumberInput": true,
      "displayBranchNameInput": false,
      "displayBranchCodeInput": false,
      "displaySwiftCodeInput": true,
      "displaySortCodeInput": false,
      "displayBsbCodeInput": false,
      "displayAbaRoutingNumberInput": false
    },
    {
      "value": "southAfrican",
      "text": "Südafrikanisches Konto",
      "displayAccountNumberInput": true,
      "displayIbanNumberInput": false,
      "displayBranchNameInput": true,
      "displayBranchCodeInput": true,
      "displaySwiftCodeInput": true,
      "displaySortCodeInput": false,
      "displayBsbCodeInput": false,
      "displayAbaRoutingNumberInput": false
    }
  ],
  "bedTypes": [
    {
      "value": "unknown",
      "text": "Unbekannt"
    },
    {
      "value": "kingSizeBed",
      "text": "King-Size-Bett"
    },
    {
      "value": "queenSizeBed",
      "text": "Queen-Size-Bett"
    },
    {
      "value": "doubleBed",
      "text": "Doppelbett"
    }
  ],
  "countries": [
    {
      "key": "afghanistan",
      "name": "Afghanistan",
      "code": "AF",
      "region": "Asien",
      "states": [
        {
          "key": 99,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 259,
          "isCountryDefault": false,
          "name": "Badakhshan"
        },
        {
          "key": 260,
          "isCountryDefault": false,
          "name": "Badghis"
        },
        {
          "key": 261,
          "isCountryDefault": false,
          "name": "Baghlan"
        },
        {
          "key": 262,
          "isCountryDefault": false,
          "name": "Balkh"
        },
        {
          "key": 263,
          "isCountryDefault": false,
          "name": "Bamyan"
        },
        {
          "key": 264,
          "isCountryDefault": false,
          "name": "Daykundi"
        },
        {
          "key": 265,
          "isCountryDefault": false,
          "name": "Farah"
        },
        {
          "key": 266,
          "isCountryDefault": false,
          "name": "Faryab"
        },
        {
          "key": 267,
          "isCountryDefault": false,
          "name": "Ghazni"
        },
        {
          "key": 268,
          "isCountryDefault": false,
          "name": "Ghor"
        },
        {
          "key": 269,
          "isCountryDefault": false,
          "name": "Helmand"
        },
        {
          "key": 270,
          "isCountryDefault": false,
          "name": "Herat"
        },
        {
          "key": 271,
          "isCountryDefault": false,
          "name": "Jowzjan"
        },
        {
          "key": 272,
          "isCountryDefault": false,
          "name": "Kabul"
        },
        {
          "key": 273,
          "isCountryDefault": false,
          "name": "Kandahar"
        },
        {
          "key": 274,
          "isCountryDefault": false,
          "name": "Kapisa"
        },
        {
          "key": 275,
          "isCountryDefault": false,
          "name": "Khost"
        },
        {
          "key": 276,
          "isCountryDefault": false,
          "name": "Konar"
        },
        {
          "key": 277,
          "isCountryDefault": false,
          "name": "Kunduz"
        },
        {
          "key": 278,
          "isCountryDefault": false,
          "name": "Laghman"
        },
        {
          "key": 279,
          "isCountryDefault": false,
          "name": "Logar"
        },
        {
          "key": 280,
          "isCountryDefault": false,
          "name": "Nangarhar"
        },
        {
          "key": 281,
          "isCountryDefault": false,
          "name": "Nimruz"
        },
        {
          "key": 282,
          "isCountryDefault": false,
          "name": "Nurestan"
        },
        {
          "key": 283,
          "isCountryDefault": false,
          "name": "Oruzgan"
        },
        {
          "key": 284,
          "isCountryDefault": false,
          "name": "Paktia"
        },
        {
          "key": 285,
          "isCountryDefault": false,
          "name": "Paktika"
        },
        {
          "key": 286,
          "isCountryDefault": false,
          "name": "Panjshir"
        },
        {
          "key": 287,
          "isCountryDefault": false,
          "name": "Parvan"
        },
        {
          "key": 288,
          "isCountryDefault": false,
          "name": "Samangan"
        },
        {
          "key": 289,
          "isCountryDefault": false,
          "name": "Sare Pol"
        },
        {
          "key": 290,
          "isCountryDefault": false,
          "name": "Takhar"
        },
        {
          "key": 291,
          "isCountryDefault": false,
          "name": "Wardak"
        },
        {
          "key": 292,
          "isCountryDefault": false,
          "name": "Zabol"
        }
      ],
      "isEu": false
    },
    {
      "key": "egypt",
      "name": "Ägypten",
      "code": "EG",
      "region": "Naher und Mittlerer Osten",
      "states": [
        {
          "key": 15,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1171,
          "isCountryDefault": false,
          "name": "Alexandria"
        },
        {
          "key": 1172,
          "isCountryDefault": false,
          "name": "Aswan"
        },
        {
          "key": 1173,
          "isCountryDefault": false,
          "name": "Asyut"
        },
        {
          "key": 1174,
          "isCountryDefault": false,
          "name": "Beheira"
        },
        {
          "key": 1175,
          "isCountryDefault": false,
          "name": "Beni Suef"
        },
        {
          "key": 1176,
          "isCountryDefault": false,
          "name": "Cairo"
        },
        {
          "key": 1177,
          "isCountryDefault": false,
          "name": "Dakahlia"
        },
        {
          "key": 1178,
          "isCountryDefault": false,
          "name": "Damietta"
        },
        {
          "key": 1179,
          "isCountryDefault": false,
          "name": "Faiyum"
        },
        {
          "key": 1180,
          "isCountryDefault": false,
          "name": "Gharbia"
        },
        {
          "key": 1181,
          "isCountryDefault": false,
          "name": "Giza"
        },
        {
          "key": 1182,
          "isCountryDefault": false,
          "name": "Ismailia"
        },
        {
          "key": 1183,
          "isCountryDefault": false,
          "name": "Kafr el-Sheikh"
        },
        {
          "key": 1197,
          "isCountryDefault": false,
          "name": "Luxor"
        },
        {
          "key": 1184,
          "isCountryDefault": false,
          "name": "Matruh"
        },
        {
          "key": 1185,
          "isCountryDefault": false,
          "name": "Minya"
        },
        {
          "key": 1186,
          "isCountryDefault": false,
          "name": "Monufia"
        },
        {
          "key": 1187,
          "isCountryDefault": false,
          "name": "New Valley"
        },
        {
          "key": 1188,
          "isCountryDefault": false,
          "name": "North Sinai"
        },
        {
          "key": 1189,
          "isCountryDefault": false,
          "name": "Port Said"
        },
        {
          "key": 1190,
          "isCountryDefault": false,
          "name": "Qalyubia"
        },
        {
          "key": 1191,
          "isCountryDefault": false,
          "name": "Qena"
        },
        {
          "key": 1192,
          "isCountryDefault": false,
          "name": "Red Sea"
        },
        {
          "key": 1193,
          "isCountryDefault": false,
          "name": "Sharqia"
        },
        {
          "key": 1194,
          "isCountryDefault": false,
          "name": "Sohag"
        },
        {
          "key": 1195,
          "isCountryDefault": false,
          "name": "South Sinai"
        },
        {
          "key": 1196,
          "isCountryDefault": false,
          "name": "Suez"
        }
      ],
      "isEu": false
    },
    {
      "key": "ålandIslands",
      "name": "Åland-Inseln",
      "code": "AX",
      "region": "Europa",
      "states": [
        {
          "key": 4108,
          "isCountryDefault": true,
          "name": null
        }
      ],
      "isEu": false
    },
    {
      "key": "albania",
      "name": "Albanien",
      "code": "AL",
      "region": "Europa",
      "states": [
        {
          "key": 101,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 293,
          "isCountryDefault": false,
          "name": "Berat"
        },
        {
          "key": 294,
          "isCountryDefault": false,
          "name": "Dibër"
        },
        {
          "key": 295,
          "isCountryDefault": false,
          "name": "Durrës"
        },
        {
          "key": 296,
          "isCountryDefault": false,
          "name": "Elbasan"
        },
        {
          "key": 297,
          "isCountryDefault": false,
          "name": "Fier"
        },
        {
          "key": 298,
          "isCountryDefault": false,
          "name": "Gjirokastër"
        },
        {
          "key": 299,
          "isCountryDefault": false,
          "name": "Korçë"
        },
        {
          "key": 300,
          "isCountryDefault": false,
          "name": "Kukës"
        },
        {
          "key": 301,
          "isCountryDefault": false,
          "name": "Lezhë"
        },
        {
          "key": 302,
          "isCountryDefault": false,
          "name": "Shkodër"
        },
        {
          "key": 303,
          "isCountryDefault": false,
          "name": "Tirana"
        },
        {
          "key": 304,
          "isCountryDefault": false,
          "name": "Vlorë"
        }
      ],
      "isEu": false
    },
    {
      "key": "algeria",
      "name": "Algerien",
      "code": "DZ",
      "region": "Afrika",
      "states": [
        {
          "key": 136,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 305,
          "isCountryDefault": false,
          "name": "Adrar"
        },
        {
          "key": 348,
          "isCountryDefault": false,
          "name": "Aïn Defla"
        },
        {
          "key": 351,
          "isCountryDefault": false,
          "name": "Aïn Témouchent"
        },
        {
          "key": 320,
          "isCountryDefault": false,
          "name": "Algiers"
        },
        {
          "key": 327,
          "isCountryDefault": false,
          "name": "Annaba"
        },
        {
          "key": 309,
          "isCountryDefault": false,
          "name": "Batna"
        },
        {
          "key": 312,
          "isCountryDefault": false,
          "name": "Béchar"
        },
        {
          "key": 310,
          "isCountryDefault": false,
          "name": "Béjaïa"
        },
        {
          "key": 311,
          "isCountryDefault": false,
          "name": "Biskra"
        },
        {
          "key": 313,
          "isCountryDefault": false,
          "name": "Blida"
        },
        {
          "key": 338,
          "isCountryDefault": false,
          "name": "Bordj Bou Arréridj"
        },
        {
          "key": 314,
          "isCountryDefault": false,
          "name": "Bouira"
        },
        {
          "key": 339,
          "isCountryDefault": false,
          "name": "Boumerdès"
        },
        {
          "key": 306,
          "isCountryDefault": false,
          "name": "Chlef"
        },
        {
          "key": 329,
          "isCountryDefault": false,
          "name": "Constantine"
        },
        {
          "key": 321,
          "isCountryDefault": false,
          "name": "Djelfa"
        },
        {
          "key": 336,
          "isCountryDefault": false,
          "name": "El Bayadh"
        },
        {
          "key": 343,
          "isCountryDefault": false,
          "name": "El Oued"
        },
        {
          "key": 340,
          "isCountryDefault": false,
          "name": "El Tarf"
        },
        {
          "key": 352,
          "isCountryDefault": false,
          "name": "Ghardaïa"
        },
        {
          "key": 328,
          "isCountryDefault": false,
          "name": "Guelma"
        },
        {
          "key": 337,
          "isCountryDefault": false,
          "name": "Illizi"
        },
        {
          "key": 322,
          "isCountryDefault": false,
          "name": "Jijel"
        },
        {
          "key": 344,
          "isCountryDefault": false,
          "name": "Khenchela"
        },
        {
          "key": 307,
          "isCountryDefault": false,
          "name": "Laghouat"
        },
        {
          "key": 333,
          "isCountryDefault": false,
          "name": "Mascara"
        },
        {
          "key": 330,
          "isCountryDefault": false,
          "name": "Médéa"
        },
        {
          "key": 347,
          "isCountryDefault": false,
          "name": "Mila"
        },
        {
          "key": 331,
          "isCountryDefault": false,
          "name": "Mostaganem"
        },
        {
          "key": 332,
          "isCountryDefault": false,
          "name": "M'Sila"
        },
        {
          "key": 350,
          "isCountryDefault": false,
          "name": "Naama"
        },
        {
          "key": 335,
          "isCountryDefault": false,
          "name": "Oran"
        },
        {
          "key": 334,
          "isCountryDefault": false,
          "name": "Ouargla"
        },
        {
          "key": 308,
          "isCountryDefault": false,
          "name": "Oum el-Bouaghi"
        },
        {
          "key": 353,
          "isCountryDefault": false,
          "name": "Relizane"
        },
        {
          "key": 324,
          "isCountryDefault": false,
          "name": "Saïda"
        },
        {
          "key": 323,
          "isCountryDefault": false,
          "name": "Sétif"
        },
        {
          "key": 326,
          "isCountryDefault": false,
          "name": "Sidi Bel Abbes"
        },
        {
          "key": 325,
          "isCountryDefault": false,
          "name": "Skikda"
        },
        {
          "key": 345,
          "isCountryDefault": false,
          "name": "Souk Ahras"
        },
        {
          "key": 315,
          "isCountryDefault": false,
          "name": "Tamanghasset"
        },
        {
          "key": 316,
          "isCountryDefault": false,
          "name": "Tébessa"
        },
        {
          "key": 318,
          "isCountryDefault": false,
          "name": "Tiaret"
        },
        {
          "key": 341,
          "isCountryDefault": false,
          "name": "Tindouf"
        },
        {
          "key": 346,
          "isCountryDefault": false,
          "name": "Tipasa"
        },
        {
          "key": 342,
          "isCountryDefault": false,
          "name": "Tissemsilt"
        },
        {
          "key": 319,
          "isCountryDefault": false,
          "name": "Tizi Ouzou"
        },
        {
          "key": 317,
          "isCountryDefault": false,
          "name": "Tlemcen"
        }
      ],
      "isEu": false
    },
    {
      "key": "virginIslandsUS",
      "name": "Amerikanische Jungferninseln",
      "code": "VI",
      "region": "Nordamerika",
      "states": [
        {
          "key": 229,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3625,
          "isCountryDefault": false,
          "name": "St. Croix"
        },
        {
          "key": 3627,
          "isCountryDefault": false,
          "name": "St. John"
        },
        {
          "key": 3626,
          "isCountryDefault": false,
          "name": "St. Thomas"
        }
      ],
      "isEu": false
    },
    {
      "key": "unitedStatesMinorOutlyingIslandsIsle",
      "name": "Amerikanische Überseeinseln, Kleinere",
      "code": "UM",
      "region": "Ozeanien",
      "states": [
        {
          "key": 224,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3545,
          "isCountryDefault": false,
          "name": "Bakerinsel"
        },
        {
          "key": 3546,
          "isCountryDefault": false,
          "name": "Howlandinsel"
        },
        {
          "key": 3547,
          "isCountryDefault": false,
          "name": "Jarvisinsel"
        },
        {
          "key": 3548,
          "isCountryDefault": false,
          "name": "Johnstoninsel"
        },
        {
          "key": 3549,
          "isCountryDefault": false,
          "name": "Kingmanriff"
        },
        {
          "key": 3550,
          "isCountryDefault": false,
          "name": "Midwayinseln"
        },
        {
          "key": 3544,
          "isCountryDefault": false,
          "name": "Navassa"
        },
        {
          "key": 3551,
          "isCountryDefault": false,
          "name": "Palmyra"
        },
        {
          "key": 3552,
          "isCountryDefault": false,
          "name": "Wake"
        }
      ],
      "isEu": false
    },
    {
      "key": "americanSamoa",
      "name": "Amerikanisch-Samoa",
      "code": "AS",
      "region": "Ozeanien",
      "states": [
        {
          "key": 106,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2352,
          "isCountryDefault": false,
          "name": "American Samoa"
        }
      ],
      "isEu": false
    },
    {
      "key": "andorra",
      "name": "Andorra",
      "code": "AD",
      "region": "Europa",
      "states": [
        {
          "key": 1,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 488,
          "isCountryDefault": false,
          "name": "Andorra la Vella"
        },
        {
          "key": 484,
          "isCountryDefault": false,
          "name": "Canillo"
        },
        {
          "key": 485,
          "isCountryDefault": false,
          "name": "Encamp"
        },
        {
          "key": 490,
          "isCountryDefault": false,
          "name": "Escaldes-Engordany"
        },
        {
          "key": 487,
          "isCountryDefault": false,
          "name": "La Massana"
        },
        {
          "key": 486,
          "isCountryDefault": false,
          "name": "Ordino"
        },
        {
          "key": 489,
          "isCountryDefault": false,
          "name": "Sant Julià de Lòria"
        }
      ],
      "isEu": false
    },
    {
      "key": "angola",
      "name": "Angola",
      "code": "AO",
      "region": "Afrika",
      "states": [
        {
          "key": 104,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 491,
          "isCountryDefault": false,
          "name": "Bengo"
        },
        {
          "key": 492,
          "isCountryDefault": false,
          "name": "Benguela"
        },
        {
          "key": 493,
          "isCountryDefault": false,
          "name": "Bié"
        },
        {
          "key": 494,
          "isCountryDefault": false,
          "name": "Cabinda"
        },
        {
          "key": 495,
          "isCountryDefault": false,
          "name": "Cuando Cubango"
        },
        {
          "key": 496,
          "isCountryDefault": false,
          "name": "Cuanza Norte"
        },
        {
          "key": 497,
          "isCountryDefault": false,
          "name": "Cuanza Sul"
        },
        {
          "key": 498,
          "isCountryDefault": false,
          "name": "Cunene"
        },
        {
          "key": 499,
          "isCountryDefault": false,
          "name": "Huambo"
        },
        {
          "key": 500,
          "isCountryDefault": false,
          "name": "Huila"
        },
        {
          "key": 501,
          "isCountryDefault": false,
          "name": "Luanda"
        },
        {
          "key": 502,
          "isCountryDefault": false,
          "name": "Lunda Norte"
        },
        {
          "key": 503,
          "isCountryDefault": false,
          "name": "Lunda Sul"
        },
        {
          "key": 504,
          "isCountryDefault": false,
          "name": "Malanje"
        },
        {
          "key": 505,
          "isCountryDefault": false,
          "name": "Moxico"
        },
        {
          "key": 506,
          "isCountryDefault": false,
          "name": "Namibe"
        },
        {
          "key": 507,
          "isCountryDefault": false,
          "name": "Uíge"
        },
        {
          "key": 508,
          "isCountryDefault": false,
          "name": "Zaire"
        }
      ],
      "isEu": false
    },
    {
      "key": "anguilla",
      "name": "Anguilla",
      "code": "AI",
      "region": "Nordamerika",
      "states": [
        {
          "key": 103,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 523,
          "isCountryDefault": false,
          "name": "Island Harbour"
        },
        {
          "key": 521,
          "isCountryDefault": false,
          "name": "The Quater"
        },
        {
          "key": 522,
          "isCountryDefault": false,
          "name": "The Valley"
        },
        {
          "key": 520,
          "isCountryDefault": false,
          "name": "West End"
        }
      ],
      "isEu": false
    },
    {
      "key": "antarctica",
      "name": "Antarktis",
      "code": "AQ",
      "region": "Ozeanien",
      "states": [
        {
          "key": 105,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2353,
          "isCountryDefault": false,
          "name": "Antartica"
        }
      ],
      "isEu": false
    },
    {
      "key": "antiguaandBarbuda",
      "name": "Antigua und Barbuda",
      "code": "AG",
      "region": "Nordamerika",
      "states": [
        {
          "key": 100,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 543,
          "isCountryDefault": false,
          "name": "Saint George"
        },
        {
          "key": 544,
          "isCountryDefault": false,
          "name": "Saint John"
        },
        {
          "key": 545,
          "isCountryDefault": false,
          "name": "Saint Mary"
        },
        {
          "key": 546,
          "isCountryDefault": false,
          "name": "Saint Paul"
        },
        {
          "key": 547,
          "isCountryDefault": false,
          "name": "Saint Peter"
        },
        {
          "key": 548,
          "isCountryDefault": false,
          "name": "Saint Philip"
        }
      ],
      "isEu": false
    },
    {
      "key": "equatorialGuinea",
      "name": "Äquatorialguinea",
      "code": "GQ",
      "region": "Afrika",
      "states": [
        {
          "key": 153,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1283,
          "isCountryDefault": false,
          "name": "Annobón Province"
        },
        {
          "key": 1284,
          "isCountryDefault": false,
          "name": "Bioko Norte Province"
        },
        {
          "key": 1285,
          "isCountryDefault": false,
          "name": "Bioko Sur Province"
        },
        {
          "key": 1286,
          "isCountryDefault": false,
          "name": "Centro Sur Province"
        },
        {
          "key": 1287,
          "isCountryDefault": false,
          "name": "Kié-Ntem Province"
        },
        {
          "key": 1288,
          "isCountryDefault": false,
          "name": "Litoral Province"
        },
        {
          "key": 1289,
          "isCountryDefault": false,
          "name": "Wele-Nzas Province"
        }
      ],
      "isEu": false
    },
    {
      "key": "argentina",
      "name": "Argentinien",
      "code": "AR",
      "region": "Südamerika",
      "states": [
        {
          "key": 2,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 381,
          "isCountryDefault": false,
          "name": "Buenos Aires Province"
        },
        {
          "key": 382,
          "isCountryDefault": false,
          "name": "Catamarca"
        },
        {
          "key": 383,
          "isCountryDefault": false,
          "name": "Chaco"
        },
        {
          "key": 384,
          "isCountryDefault": false,
          "name": "Chubut"
        },
        {
          "key": 385,
          "isCountryDefault": false,
          "name": "Córdoba"
        },
        {
          "key": 386,
          "isCountryDefault": false,
          "name": "Corrientes"
        },
        {
          "key": 387,
          "isCountryDefault": false,
          "name": "Entre Ríos"
        },
        {
          "key": 388,
          "isCountryDefault": false,
          "name": "Formosa"
        },
        {
          "key": 389,
          "isCountryDefault": false,
          "name": "Jujuy"
        },
        {
          "key": 390,
          "isCountryDefault": false,
          "name": "La Pampa"
        },
        {
          "key": 391,
          "isCountryDefault": false,
          "name": "La Rioja"
        },
        {
          "key": 392,
          "isCountryDefault": false,
          "name": "Mendoza"
        },
        {
          "key": 393,
          "isCountryDefault": false,
          "name": "Misiones"
        },
        {
          "key": 394,
          "isCountryDefault": false,
          "name": "Neuquén"
        },
        {
          "key": 395,
          "isCountryDefault": false,
          "name": "Río Negro"
        },
        {
          "key": 396,
          "isCountryDefault": false,
          "name": "Salta"
        },
        {
          "key": 397,
          "isCountryDefault": false,
          "name": "San Juan"
        },
        {
          "key": 398,
          "isCountryDefault": false,
          "name": "San Luis"
        },
        {
          "key": 399,
          "isCountryDefault": false,
          "name": "Santa Cruz"
        },
        {
          "key": 400,
          "isCountryDefault": false,
          "name": "Santa Fe"
        },
        {
          "key": 401,
          "isCountryDefault": false,
          "name": "Santiago del Estero"
        },
        {
          "key": 402,
          "isCountryDefault": false,
          "name": "Tierra del Fuego, Antártida e Islas del Atlántico"
        },
        {
          "key": 403,
          "isCountryDefault": false,
          "name": "Tucumán"
        }
      ],
      "isEu": false
    },
    {
      "key": "armenia",
      "name": "Armenien",
      "code": "AM",
      "region": "Asien",
      "states": [
        {
          "key": 102,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 517,
          "isCountryDefault": false,
          "name": "Aragatsotn"
        },
        {
          "key": 513,
          "isCountryDefault": false,
          "name": "Ararat"
        },
        {
          "key": 511,
          "isCountryDefault": false,
          "name": "Armavir"
        },
        {
          "key": 515,
          "isCountryDefault": false,
          "name": "Gegharkunik"
        },
        {
          "key": 514,
          "isCountryDefault": false,
          "name": "Kotayk"
        },
        {
          "key": 512,
          "isCountryDefault": false,
          "name": "Lori"
        },
        {
          "key": 510,
          "isCountryDefault": false,
          "name": "Shirak"
        },
        {
          "key": 516,
          "isCountryDefault": false,
          "name": "Syunik"
        },
        {
          "key": 518,
          "isCountryDefault": false,
          "name": "Tavush"
        },
        {
          "key": 519,
          "isCountryDefault": false,
          "name": "Vayots Dzor"
        },
        {
          "key": 509,
          "isCountryDefault": false,
          "name": "Yerevan"
        }
      ],
      "isEu": false
    },
    {
      "key": "aruba",
      "name": "Aruba",
      "code": "AW",
      "region": "Nordamerika",
      "states": [
        {
          "key": 79,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2355,
          "isCountryDefault": false,
          "name": "Aruba"
        }
      ],
      "isEu": false
    },
    {
      "key": "azerbaijan",
      "name": "Aserbaidschan",
      "code": "AZ",
      "region": "Asien",
      "states": [
        {
          "key": 107,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 533,
          "isCountryDefault": false,
          "name": "Absheron"
        },
        {
          "key": 534,
          "isCountryDefault": false,
          "name": "Aran"
        },
        {
          "key": 535,
          "isCountryDefault": false,
          "name": "Daghlig Shirvan"
        },
        {
          "key": 536,
          "isCountryDefault": false,
          "name": "Ganja-Gazakh"
        },
        {
          "key": 537,
          "isCountryDefault": false,
          "name": "Guba-Khachmaz"
        },
        {
          "key": 538,
          "isCountryDefault": false,
          "name": "Kalbajar-Lachin"
        },
        {
          "key": 539,
          "isCountryDefault": false,
          "name": "Lankaran"
        },
        {
          "key": 540,
          "isCountryDefault": false,
          "name": "Nakhchivan"
        },
        {
          "key": 541,
          "isCountryDefault": false,
          "name": "Shaki-Zaqatala"
        },
        {
          "key": 542,
          "isCountryDefault": false,
          "name": "Yukhari Garabakh"
        }
      ],
      "isEu": false
    },
    {
      "key": "ethiopia",
      "name": "Äthiopien",
      "code": "ET",
      "region": "Afrika",
      "states": [
        {
          "key": 139,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1311,
          "isCountryDefault": false,
          "name": "Addis Ababa"
        },
        {
          "key": 1312,
          "isCountryDefault": false,
          "name": "Afar"
        },
        {
          "key": 1313,
          "isCountryDefault": false,
          "name": "Amhara"
        },
        {
          "key": 1314,
          "isCountryDefault": false,
          "name": "Benishangul-Gumuz"
        },
        {
          "key": 1315,
          "isCountryDefault": false,
          "name": "Dire Dawa"
        },
        {
          "key": 1316,
          "isCountryDefault": false,
          "name": "Gambela"
        },
        {
          "key": 1317,
          "isCountryDefault": false,
          "name": "Harari"
        },
        {
          "key": 1318,
          "isCountryDefault": false,
          "name": "Oromia"
        },
        {
          "key": 1319,
          "isCountryDefault": false,
          "name": "Somali"
        },
        {
          "key": 1320,
          "isCountryDefault": false,
          "name": "Southern Nations, Nationalities, and People's Regi"
        },
        {
          "key": 1321,
          "isCountryDefault": false,
          "name": "Tigray"
        }
      ],
      "isEu": false
    },
    {
      "key": "australia",
      "name": "Australien",
      "code": "AU",
      "region": "Ozeanien",
      "states": [
        {
          "key": 4,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 404,
          "isCountryDefault": false,
          "name": "Australian Capital Territory"
        },
        {
          "key": 405,
          "isCountryDefault": false,
          "name": "Jervis Bay Territory"
        },
        {
          "key": 406,
          "isCountryDefault": false,
          "name": "New South Wales"
        },
        {
          "key": 407,
          "isCountryDefault": false,
          "name": "Northern Territory"
        },
        {
          "key": 408,
          "isCountryDefault": false,
          "name": "Queensland"
        },
        {
          "key": 409,
          "isCountryDefault": false,
          "name": "South Australia"
        },
        {
          "key": 410,
          "isCountryDefault": false,
          "name": "Tasmania"
        },
        {
          "key": 411,
          "isCountryDefault": false,
          "name": "Victoria"
        },
        {
          "key": 412,
          "isCountryDefault": false,
          "name": "Western Australia"
        }
      ],
      "isEu": false
    },
    {
      "key": "bahamas",
      "name": "Bahamas",
      "code": "BS",
      "region": "Nordamerika",
      "states": [
        {
          "key": 117,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 549,
          "isCountryDefault": false,
          "name": "Acklins"
        },
        {
          "key": 550,
          "isCountryDefault": false,
          "name": "Berry Islands"
        },
        {
          "key": 551,
          "isCountryDefault": false,
          "name": "Bimini"
        },
        {
          "key": 552,
          "isCountryDefault": false,
          "name": "Black Point, Exuma"
        },
        {
          "key": 553,
          "isCountryDefault": false,
          "name": "Cat Island"
        },
        {
          "key": 554,
          "isCountryDefault": false,
          "name": "Central Abaco"
        },
        {
          "key": 555,
          "isCountryDefault": false,
          "name": "Central Andros"
        },
        {
          "key": 556,
          "isCountryDefault": false,
          "name": "Central Eleuthera"
        },
        {
          "key": 557,
          "isCountryDefault": false,
          "name": "City of Freeport, Grand Bahama"
        },
        {
          "key": 558,
          "isCountryDefault": false,
          "name": "Crooked Island"
        },
        {
          "key": 559,
          "isCountryDefault": false,
          "name": "East Grand Bahama"
        },
        {
          "key": 560,
          "isCountryDefault": false,
          "name": "Exuma"
        },
        {
          "key": 561,
          "isCountryDefault": false,
          "name": "Grand Cay, Abaco"
        },
        {
          "key": 580,
          "isCountryDefault": false,
          "name": "Green Turtle Cay"
        },
        {
          "key": 562,
          "isCountryDefault": false,
          "name": "Harbour Island, Eleuthera"
        },
        {
          "key": 563,
          "isCountryDefault": false,
          "name": "Hope Town, Abaco"
        },
        {
          "key": 564,
          "isCountryDefault": false,
          "name": "Inagua"
        },
        {
          "key": 565,
          "isCountryDefault": false,
          "name": "Long Island"
        },
        {
          "key": 566,
          "isCountryDefault": false,
          "name": "Mangrove Cay, Andros"
        },
        {
          "key": 567,
          "isCountryDefault": false,
          "name": "Mayaguana"
        },
        {
          "key": 568,
          "isCountryDefault": false,
          "name": "Moore's Island, Abaco"
        },
        {
          "key": 569,
          "isCountryDefault": false,
          "name": "North Abaco"
        },
        {
          "key": 570,
          "isCountryDefault": false,
          "name": "North Andros"
        },
        {
          "key": 571,
          "isCountryDefault": false,
          "name": "North Eleuthera"
        },
        {
          "key": 572,
          "isCountryDefault": false,
          "name": "Ragged Island"
        },
        {
          "key": 573,
          "isCountryDefault": false,
          "name": "Rum Cay"
        },
        {
          "key": 574,
          "isCountryDefault": false,
          "name": "San Salvador"
        },
        {
          "key": 575,
          "isCountryDefault": false,
          "name": "South Abaco"
        },
        {
          "key": 576,
          "isCountryDefault": false,
          "name": "South Andros"
        },
        {
          "key": 577,
          "isCountryDefault": false,
          "name": "South Eleuthera"
        },
        {
          "key": 578,
          "isCountryDefault": false,
          "name": "Spanish Wells, Eleuthera"
        },
        {
          "key": 579,
          "isCountryDefault": false,
          "name": "West Grand Bahama"
        }
      ],
      "isEu": false
    },
    {
      "key": "bahrain",
      "name": "Bahrein",
      "code": "BH",
      "region": "Naher und Mittlerer Osten",
      "states": [
        {
          "key": 85,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 581,
          "isCountryDefault": false,
          "name": "Capital Governorate"
        },
        {
          "key": 582,
          "isCountryDefault": false,
          "name": "Central Governorate"
        },
        {
          "key": 583,
          "isCountryDefault": false,
          "name": "Muharraq Governorate"
        },
        {
          "key": 584,
          "isCountryDefault": false,
          "name": "Northern Governorate"
        },
        {
          "key": 585,
          "isCountryDefault": false,
          "name": "Southern Governorate"
        }
      ],
      "isEu": false
    },
    {
      "key": "bangladesh",
      "name": "Bangladesch",
      "code": "BD",
      "region": "Asien",
      "states": [
        {
          "key": 110,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 586,
          "isCountryDefault": false,
          "name": "Barisal"
        },
        {
          "key": 587,
          "isCountryDefault": false,
          "name": "Chittagong"
        },
        {
          "key": 588,
          "isCountryDefault": false,
          "name": "Dhaka"
        },
        {
          "key": 589,
          "isCountryDefault": false,
          "name": "Khulna"
        },
        {
          "key": 590,
          "isCountryDefault": false,
          "name": "Rajshahi"
        },
        {
          "key": 591,
          "isCountryDefault": false,
          "name": "Rangpur"
        },
        {
          "key": 592,
          "isCountryDefault": false,
          "name": "Sylhet"
        }
      ],
      "isEu": false
    },
    {
      "key": "barbados",
      "name": "Barbados",
      "code": "BB",
      "region": "Nordamerika",
      "states": [
        {
          "key": 109,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2357,
          "isCountryDefault": false,
          "name": "Barbados"
        }
      ],
      "isEu": false
    },
    {
      "key": "belarus",
      "name": "Belarus (Weißrussland)",
      "code": "BY",
      "region": "Europa",
      "states": [
        {
          "key": 121,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 593,
          "isCountryDefault": false,
          "name": "Brest"
        },
        {
          "key": 594,
          "isCountryDefault": false,
          "name": "Gomel"
        },
        {
          "key": 595,
          "isCountryDefault": false,
          "name": "Grodno"
        },
        {
          "key": 597,
          "isCountryDefault": false,
          "name": "Minsk"
        },
        {
          "key": 596,
          "isCountryDefault": false,
          "name": "Mogilev"
        },
        {
          "key": 598,
          "isCountryDefault": false,
          "name": "Vitebsk"
        }
      ],
      "isEu": false
    },
    {
      "key": "belgium",
      "name": "Belgien",
      "code": "BE",
      "region": "Europa",
      "states": [
        {
          "key": 5,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 429,
          "isCountryDefault": false,
          "name": "Antwerpen"
        },
        {
          "key": 435,
          "isCountryDefault": false,
          "name": "Brabant wallon"
        },
        {
          "key": 4089,
          "isCountryDefault": false,
          "name": "Brussels"
        },
        {
          "key": 434,
          "isCountryDefault": false,
          "name": "Hainaut"
        },
        {
          "key": 437,
          "isCountryDefault": false,
          "name": "Liège"
        },
        {
          "key": 430,
          "isCountryDefault": false,
          "name": "Limburg"
        },
        {
          "key": 438,
          "isCountryDefault": false,
          "name": "Luxembourg"
        },
        {
          "key": 436,
          "isCountryDefault": false,
          "name": "Namur"
        },
        {
          "key": 432,
          "isCountryDefault": false,
          "name": "Oost-Vlaanderen"
        },
        {
          "key": 431,
          "isCountryDefault": false,
          "name": "Vlaams-Brabant"
        },
        {
          "key": 433,
          "isCountryDefault": false,
          "name": "West-Vlaanderen"
        }
      ],
      "isEu": true
    },
    {
      "key": "belize",
      "name": "Belize",
      "code": "BZ",
      "region": "Mittelamerika",
      "states": [
        {
          "key": 122,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 599,
          "isCountryDefault": false,
          "name": "Belize District"
        },
        {
          "key": 600,
          "isCountryDefault": false,
          "name": "Cayo District"
        },
        {
          "key": 601,
          "isCountryDefault": false,
          "name": "Corozal District"
        },
        {
          "key": 602,
          "isCountryDefault": false,
          "name": "Orange Walk District"
        },
        {
          "key": 603,
          "isCountryDefault": false,
          "name": "Stann Creek District"
        },
        {
          "key": 604,
          "isCountryDefault": false,
          "name": "Toledo District"
        }
      ],
      "isEu": false
    },
    {
      "key": "benin",
      "name": "Benin",
      "code": "BJ",
      "region": "Afrika",
      "states": [
        {
          "key": 113,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 605,
          "isCountryDefault": false,
          "name": "Alibori"
        },
        {
          "key": 606,
          "isCountryDefault": false,
          "name": "Atakora"
        },
        {
          "key": 607,
          "isCountryDefault": false,
          "name": "Atlantique"
        },
        {
          "key": 608,
          "isCountryDefault": false,
          "name": "Borgou"
        },
        {
          "key": 609,
          "isCountryDefault": false,
          "name": "Collines"
        },
        {
          "key": 610,
          "isCountryDefault": false,
          "name": "Donga"
        },
        {
          "key": 611,
          "isCountryDefault": false,
          "name": "Kouffo"
        },
        {
          "key": 612,
          "isCountryDefault": false,
          "name": "Littoral"
        },
        {
          "key": 613,
          "isCountryDefault": false,
          "name": "Mono"
        },
        {
          "key": 614,
          "isCountryDefault": false,
          "name": "Ouémé"
        },
        {
          "key": 615,
          "isCountryDefault": false,
          "name": "Plateau"
        },
        {
          "key": 616,
          "isCountryDefault": false,
          "name": "Zou"
        }
      ],
      "isEu": false
    },
    {
      "key": "bermuda",
      "name": "Bermuda",
      "code": "BM",
      "region": "Nordamerika",
      "states": [
        {
          "key": 114,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 617,
          "isCountryDefault": false,
          "name": "Devonshire"
        },
        {
          "key": 618,
          "isCountryDefault": false,
          "name": "Hamilton"
        },
        {
          "key": 619,
          "isCountryDefault": false,
          "name": "Paget"
        },
        {
          "key": 620,
          "isCountryDefault": false,
          "name": "Pembroke"
        },
        {
          "key": 622,
          "isCountryDefault": false,
          "name": "Sandys"
        },
        {
          "key": 623,
          "isCountryDefault": false,
          "name": "Smith's"
        },
        {
          "key": 624,
          "isCountryDefault": false,
          "name": "Southampton"
        },
        {
          "key": 621,
          "isCountryDefault": false,
          "name": "St George's"
        },
        {
          "key": 625,
          "isCountryDefault": false,
          "name": "Warwick"
        }
      ],
      "isEu": false
    },
    {
      "key": "bhutan",
      "name": "Bhutan",
      "code": "BT",
      "region": "Asien",
      "states": [
        {
          "key": 118,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2356,
          "isCountryDefault": false,
          "name": "Bhutan"
        }
      ],
      "isEu": false
    },
    {
      "key": "bolivia",
      "name": "Bolivien",
      "code": "BO",
      "region": "Südamerika",
      "states": [
        {
          "key": 116,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 626,
          "isCountryDefault": false,
          "name": "Beni"
        },
        {
          "key": 627,
          "isCountryDefault": false,
          "name": "Chuquisaca"
        },
        {
          "key": 628,
          "isCountryDefault": false,
          "name": "Cochabamba"
        },
        {
          "key": 629,
          "isCountryDefault": false,
          "name": "La Paz"
        },
        {
          "key": 630,
          "isCountryDefault": false,
          "name": "Oruro"
        },
        {
          "key": 631,
          "isCountryDefault": false,
          "name": "Pando"
        },
        {
          "key": 632,
          "isCountryDefault": false,
          "name": "Potosí"
        },
        {
          "key": 633,
          "isCountryDefault": false,
          "name": "Santa Cruz"
        },
        {
          "key": 634,
          "isCountryDefault": false,
          "name": "Tarija"
        }
      ],
      "isEu": false
    },
    {
      "key": "bonaireSintEustatiusandSaba",
      "name": "Bonaire, St. Eustatius und Saba",
      "code": "BQ",
      "region": "Nordamerika",
      "states": [
        {
          "key": 4105,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2265,
          "isCountryDefault": false,
          "name": "Bonaire"
        },
        {
          "key": 2268,
          "isCountryDefault": false,
          "name": "Saba"
        },
        {
          "key": 2267,
          "isCountryDefault": false,
          "name": "Sint Eustatius"
        }
      ],
      "isEu": false
    },
    {
      "key": "bosniaandHerzegovina",
      "name": "Bosnien und Herzegowina",
      "code": "BA",
      "region": "Europa",
      "states": [
        {
          "key": 108,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 637,
          "isCountryDefault": false,
          "name": "Brčko District"
        },
        {
          "key": 635,
          "isCountryDefault": false,
          "name": "Federation of Bosnia and Herzegovina"
        },
        {
          "key": 636,
          "isCountryDefault": false,
          "name": "Republika Srpska"
        }
      ],
      "isEu": false
    },
    {
      "key": "botswana",
      "name": "Botsuana",
      "code": "BW",
      "region": "Afrika",
      "states": [
        {
          "key": 120,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 638,
          "isCountryDefault": false,
          "name": "Central District"
        },
        {
          "key": 647,
          "isCountryDefault": false,
          "name": "Chobe District"
        },
        {
          "key": 639,
          "isCountryDefault": false,
          "name": "Ghanzi District"
        },
        {
          "key": 640,
          "isCountryDefault": false,
          "name": "Kgalagadi District"
        },
        {
          "key": 641,
          "isCountryDefault": false,
          "name": "Kgatleng District"
        },
        {
          "key": 642,
          "isCountryDefault": false,
          "name": "Kweneng District"
        },
        {
          "key": 644,
          "isCountryDefault": false,
          "name": "Ngamiland District"
        },
        {
          "key": 643,
          "isCountryDefault": false,
          "name": "North-East District"
        },
        {
          "key": 645,
          "isCountryDefault": false,
          "name": "South-East District"
        },
        {
          "key": 646,
          "isCountryDefault": false,
          "name": "Southern District"
        }
      ],
      "isEu": false
    },
    {
      "key": "bouvetIsland",
      "name": "Bouvetinsel",
      "code": "BV",
      "region": "Ozeanien",
      "states": [
        {
          "key": 119,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2358,
          "isCountryDefault": false,
          "name": "Bouvet Island"
        }
      ],
      "isEu": false
    },
    {
      "key": "brazil",
      "name": "Brasilien",
      "code": "BR",
      "region": "Südamerika",
      "states": [
        {
          "key": 6,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 354,
          "isCountryDefault": false,
          "name": "Acre"
        },
        {
          "key": 355,
          "isCountryDefault": false,
          "name": "Alagoas"
        },
        {
          "key": 356,
          "isCountryDefault": false,
          "name": "Amapá"
        },
        {
          "key": 357,
          "isCountryDefault": false,
          "name": "Amazonas"
        },
        {
          "key": 358,
          "isCountryDefault": false,
          "name": "Bahia"
        },
        {
          "key": 359,
          "isCountryDefault": false,
          "name": "Ceará"
        },
        {
          "key": 360,
          "isCountryDefault": false,
          "name": "Distrito Federal"
        },
        {
          "key": 361,
          "isCountryDefault": false,
          "name": "Espírito Santo"
        },
        {
          "key": 362,
          "isCountryDefault": false,
          "name": "Goiás"
        },
        {
          "key": 363,
          "isCountryDefault": false,
          "name": "Maranhão"
        },
        {
          "key": 364,
          "isCountryDefault": false,
          "name": "Mato Grosso"
        },
        {
          "key": 365,
          "isCountryDefault": false,
          "name": "Mato Grosso do Sul"
        },
        {
          "key": 366,
          "isCountryDefault": false,
          "name": "Minas Gerais"
        },
        {
          "key": 367,
          "isCountryDefault": false,
          "name": "Pará"
        },
        {
          "key": 368,
          "isCountryDefault": false,
          "name": "Paraíba"
        },
        {
          "key": 369,
          "isCountryDefault": false,
          "name": "Paraná"
        },
        {
          "key": 370,
          "isCountryDefault": false,
          "name": "Pernambuco"
        },
        {
          "key": 371,
          "isCountryDefault": false,
          "name": "Piauí"
        },
        {
          "key": 372,
          "isCountryDefault": false,
          "name": "Rio de Janeiro"
        },
        {
          "key": 373,
          "isCountryDefault": false,
          "name": "Rio Grande do Norte"
        },
        {
          "key": 374,
          "isCountryDefault": false,
          "name": "Rio Grande do Sul"
        },
        {
          "key": 375,
          "isCountryDefault": false,
          "name": "Rondônia"
        },
        {
          "key": 376,
          "isCountryDefault": false,
          "name": "Roraima"
        },
        {
          "key": 377,
          "isCountryDefault": false,
          "name": "Santa Catarina"
        },
        {
          "key": 378,
          "isCountryDefault": false,
          "name": "São Paulo"
        },
        {
          "key": 379,
          "isCountryDefault": false,
          "name": "Sergipe"
        },
        {
          "key": 380,
          "isCountryDefault": false,
          "name": "Tocantins"
        }
      ],
      "isEu": false
    },
    {
      "key": "britishVirginIslands",
      "name": "Britische Jungferninseln",
      "code": "VG",
      "region": "Nordamerika",
      "states": [
        {
          "key": 228,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 648,
          "isCountryDefault": false,
          "name": "Anegada"
        },
        {
          "key": 649,
          "isCountryDefault": false,
          "name": "Beef Island"
        },
        {
          "key": 650,
          "isCountryDefault": false,
          "name": "Bellamy Cay"
        },
        {
          "key": 651,
          "isCountryDefault": false,
          "name": "Buck Island"
        },
        {
          "key": 652,
          "isCountryDefault": false,
          "name": "Carvel Rock"
        },
        {
          "key": 653,
          "isCountryDefault": false,
          "name": "Cockroach Island"
        },
        {
          "key": 654,
          "isCountryDefault": false,
          "name": "Cooper Island"
        },
        {
          "key": 655,
          "isCountryDefault": false,
          "name": "Dead Chest Island"
        },
        {
          "key": 656,
          "isCountryDefault": false,
          "name": "Diamond Cay"
        },
        {
          "key": 657,
          "isCountryDefault": false,
          "name": "Dog Islands"
        },
        {
          "key": 658,
          "isCountryDefault": false,
          "name": "East Seal Dog Island"
        },
        {
          "key": 659,
          "isCountryDefault": false,
          "name": "Eustatia"
        },
        {
          "key": 660,
          "isCountryDefault": false,
          "name": "Fallen Jerusalem Island"
        },
        {
          "key": 661,
          "isCountryDefault": false,
          "name": "Frenchman's Cay"
        },
        {
          "key": 662,
          "isCountryDefault": false,
          "name": "George Dog Island"
        },
        {
          "key": 663,
          "isCountryDefault": false,
          "name": "Ginger Island"
        },
        {
          "key": 664,
          "isCountryDefault": false,
          "name": "Great Camanoe"
        },
        {
          "key": 665,
          "isCountryDefault": false,
          "name": "Great Dog Island"
        },
        {
          "key": 666,
          "isCountryDefault": false,
          "name": "Great Thatch"
        },
        {
          "key": 667,
          "isCountryDefault": false,
          "name": "Great Tobago Island"
        },
        {
          "key": 668,
          "isCountryDefault": false,
          "name": "Green Cay"
        },
        {
          "key": 669,
          "isCountryDefault": false,
          "name": "Guana Island"
        },
        {
          "key": 671,
          "isCountryDefault": false,
          "name": "Jost Van Dyke"
        },
        {
          "key": 672,
          "isCountryDefault": false,
          "name": "Little Camanoe"
        },
        {
          "key": 673,
          "isCountryDefault": false,
          "name": "Little Jost Van Dyke"
        },
        {
          "key": 674,
          "isCountryDefault": false,
          "name": "Little Thatch"
        },
        {
          "key": 675,
          "isCountryDefault": false,
          "name": "Little Tobago, British Virgin Islands"
        },
        {
          "key": 676,
          "isCountryDefault": false,
          "name": "Little Wickmans Cay"
        },
        {
          "key": 677,
          "isCountryDefault": false,
          "name": "Marina Cay"
        },
        {
          "key": 678,
          "isCountryDefault": false,
          "name": "Mosquito Island"
        },
        {
          "key": 679,
          "isCountryDefault": false,
          "name": "Nanny Cay"
        },
        {
          "key": 680,
          "isCountryDefault": false,
          "name": "Necker Island"
        },
        {
          "key": 681,
          "isCountryDefault": false,
          "name": "Norman Island"
        },
        {
          "key": 682,
          "isCountryDefault": false,
          "name": "Old Jerusalem Island"
        },
        {
          "key": 683,
          "isCountryDefault": false,
          "name": "Pelican Island"
        },
        {
          "key": 684,
          "isCountryDefault": false,
          "name": "Peter Island"
        },
        {
          "key": 685,
          "isCountryDefault": false,
          "name": "Prickly Pear"
        },
        {
          "key": 686,
          "isCountryDefault": false,
          "name": "Round Rock"
        },
        {
          "key": 687,
          "isCountryDefault": false,
          "name": "Saba Rock"
        },
        {
          "key": 688,
          "isCountryDefault": false,
          "name": "Salt Island"
        },
        {
          "key": 689,
          "isCountryDefault": false,
          "name": "Sandy Cay"
        },
        {
          "key": 690,
          "isCountryDefault": false,
          "name": "Sandy Spit"
        },
        {
          "key": 691,
          "isCountryDefault": false,
          "name": "Scrub Island"
        },
        {
          "key": 670,
          "isCountryDefault": false,
          "name": "The Indians"
        },
        {
          "key": 692,
          "isCountryDefault": false,
          "name": "Tortola"
        },
        {
          "key": 693,
          "isCountryDefault": false,
          "name": "Virgin Gorda"
        },
        {
          "key": 694,
          "isCountryDefault": false,
          "name": "West Dog Island"
        }
      ],
      "isEu": false
    },
    {
      "key": "britishIndianOceanTerritory",
      "name": "Britisches Territorium im Indischen Ozean",
      "code": "IO",
      "region": "Asien",
      "states": [
        {
          "key": 4109,
          "isCountryDefault": true,
          "name": null
        }
      ],
      "isEu": false
    },
    {
      "key": "bruneiDarussalam",
      "name": "Brunei Darussalam",
      "code": "BN",
      "region": "Asien",
      "states": [
        {
          "key": 115,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 695,
          "isCountryDefault": false,
          "name": "Belait"
        },
        {
          "key": 696,
          "isCountryDefault": false,
          "name": "Brunei and Muara"
        },
        {
          "key": 697,
          "isCountryDefault": false,
          "name": "Temburong"
        },
        {
          "key": 698,
          "isCountryDefault": false,
          "name": "Tutong"
        }
      ],
      "isEu": false
    },
    {
      "key": "bulgaria",
      "name": "Bulgarien",
      "code": "BG",
      "region": "Europa",
      "states": [
        {
          "key": 88,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 699,
          "isCountryDefault": false,
          "name": "Blagoevgrad"
        },
        {
          "key": 700,
          "isCountryDefault": false,
          "name": "Burgas"
        },
        {
          "key": 701,
          "isCountryDefault": false,
          "name": "Dobrich"
        },
        {
          "key": 702,
          "isCountryDefault": false,
          "name": "Gabrovo"
        },
        {
          "key": 703,
          "isCountryDefault": false,
          "name": "Haskovo"
        },
        {
          "key": 704,
          "isCountryDefault": false,
          "name": "Kardzhali"
        },
        {
          "key": 705,
          "isCountryDefault": false,
          "name": "Kyustendil"
        },
        {
          "key": 706,
          "isCountryDefault": false,
          "name": "Lovech"
        },
        {
          "key": 707,
          "isCountryDefault": false,
          "name": "Montana"
        },
        {
          "key": 708,
          "isCountryDefault": false,
          "name": "Pazardzhik"
        },
        {
          "key": 709,
          "isCountryDefault": false,
          "name": "Pernik"
        },
        {
          "key": 710,
          "isCountryDefault": false,
          "name": "Pleven"
        },
        {
          "key": 711,
          "isCountryDefault": false,
          "name": "Plovdiv"
        },
        {
          "key": 712,
          "isCountryDefault": false,
          "name": "Razgrad"
        },
        {
          "key": 713,
          "isCountryDefault": false,
          "name": "Ruse"
        },
        {
          "key": 714,
          "isCountryDefault": false,
          "name": "Shumen"
        },
        {
          "key": 715,
          "isCountryDefault": false,
          "name": "Silistra"
        },
        {
          "key": 716,
          "isCountryDefault": false,
          "name": "Sliven"
        },
        {
          "key": 717,
          "isCountryDefault": false,
          "name": "Smolyan"
        },
        {
          "key": 719,
          "isCountryDefault": false,
          "name": "Sofia (province)"
        },
        {
          "key": 718,
          "isCountryDefault": false,
          "name": "Sofia-Capital"
        },
        {
          "key": 720,
          "isCountryDefault": false,
          "name": "Stara Zagora"
        },
        {
          "key": 721,
          "isCountryDefault": false,
          "name": "Targovishte"
        },
        {
          "key": 722,
          "isCountryDefault": false,
          "name": "Varna"
        },
        {
          "key": 723,
          "isCountryDefault": false,
          "name": "Veliko Tarnovo"
        },
        {
          "key": 724,
          "isCountryDefault": false,
          "name": "Vidin"
        },
        {
          "key": 725,
          "isCountryDefault": false,
          "name": "Vratsa"
        },
        {
          "key": 726,
          "isCountryDefault": false,
          "name": "Yambol"
        }
      ],
      "isEu": true
    },
    {
      "key": "burkinaFaso",
      "name": "Burkina Faso",
      "code": "BF",
      "region": "Afrika",
      "states": [
        {
          "key": 111,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 727,
          "isCountryDefault": false,
          "name": "Boucle du Mouhoun"
        },
        {
          "key": 728,
          "isCountryDefault": false,
          "name": "Cascades"
        },
        {
          "key": 729,
          "isCountryDefault": false,
          "name": "Centre"
        },
        {
          "key": 730,
          "isCountryDefault": false,
          "name": "Centre-Est"
        },
        {
          "key": 731,
          "isCountryDefault": false,
          "name": "Centre-Nord"
        },
        {
          "key": 732,
          "isCountryDefault": false,
          "name": "Centre-Ouest"
        },
        {
          "key": 733,
          "isCountryDefault": false,
          "name": "Centre-Sud"
        },
        {
          "key": 734,
          "isCountryDefault": false,
          "name": "Est"
        },
        {
          "key": 735,
          "isCountryDefault": false,
          "name": "Hauts-Bassins"
        },
        {
          "key": 736,
          "isCountryDefault": false,
          "name": "Nord"
        },
        {
          "key": 737,
          "isCountryDefault": false,
          "name": "Plateau-Central"
        },
        {
          "key": 738,
          "isCountryDefault": false,
          "name": "Sahel"
        },
        {
          "key": 739,
          "isCountryDefault": false,
          "name": "Sud-Ouest"
        }
      ],
      "isEu": false
    },
    {
      "key": "burundi",
      "name": "Burundi",
      "code": "BI",
      "region": "Afrika",
      "states": [
        {
          "key": 112,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 740,
          "isCountryDefault": false,
          "name": "Bubanza"
        },
        {
          "key": 741,
          "isCountryDefault": false,
          "name": "Bujumbura Mairie"
        },
        {
          "key": 742,
          "isCountryDefault": false,
          "name": "Bujumbura Rural"
        },
        {
          "key": 743,
          "isCountryDefault": false,
          "name": "Bururi"
        },
        {
          "key": 744,
          "isCountryDefault": false,
          "name": "Cankuzo"
        },
        {
          "key": 745,
          "isCountryDefault": false,
          "name": "Cibitoke"
        },
        {
          "key": 746,
          "isCountryDefault": false,
          "name": "Gitega"
        },
        {
          "key": 747,
          "isCountryDefault": false,
          "name": "Karuzi"
        },
        {
          "key": 748,
          "isCountryDefault": false,
          "name": "Kayanza"
        },
        {
          "key": 749,
          "isCountryDefault": false,
          "name": "Kirundo"
        },
        {
          "key": 750,
          "isCountryDefault": false,
          "name": "Makamba"
        },
        {
          "key": 751,
          "isCountryDefault": false,
          "name": "Muramvya"
        },
        {
          "key": 752,
          "isCountryDefault": false,
          "name": "Muyinga"
        },
        {
          "key": 753,
          "isCountryDefault": false,
          "name": "Mwaro"
        },
        {
          "key": 754,
          "isCountryDefault": false,
          "name": "Ngozi"
        },
        {
          "key": 755,
          "isCountryDefault": false,
          "name": "Rutana"
        },
        {
          "key": 756,
          "isCountryDefault": false,
          "name": "Ruyigi"
        }
      ],
      "isEu": false
    },
    {
      "key": "chile",
      "name": "Chile",
      "code": "CL",
      "region": "Südamerika",
      "states": [
        {
          "key": 10,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 903,
          "isCountryDefault": false,
          "name": "Antartica Chilena"
        },
        {
          "key": 860,
          "isCountryDefault": false,
          "name": "Antofagasta"
        },
        {
          "key": 887,
          "isCountryDefault": false,
          "name": "Arauco"
        },
        {
          "key": 856,
          "isCountryDefault": false,
          "name": "Arica"
        },
        {
          "key": 886,
          "isCountryDefault": false,
          "name": "Biobío"
        },
        {
          "key": 877,
          "isCountryDefault": false,
          "name": "Cachapoal"
        },
        {
          "key": 899,
          "isCountryDefault": false,
          "name": "Capitan Prat"
        },
        {
          "key": 879,
          "isCountryDefault": false,
          "name": "Cardenal Caro"
        },
        {
          "key": 883,
          "isCountryDefault": false,
          "name": "Cauquenes"
        },
        {
          "key": 888,
          "isCountryDefault": false,
          "name": "Cautin"
        },
        {
          "key": 909,
          "isCountryDefault": false,
          "name": "Chacabuco"
        },
        {
          "key": 865,
          "isCountryDefault": false,
          "name": "Chañaral"
        },
        {
          "key": 894,
          "isCountryDefault": false,
          "name": "Chiloe"
        },
        {
          "key": 868,
          "isCountryDefault": false,
          "name": "Choapa"
        },
        {
          "key": 896,
          "isCountryDefault": false,
          "name": "Coihaique"
        },
        {
          "key": 878,
          "isCountryDefault": false,
          "name": "Colchagua"
        },
        {
          "key": 884,
          "isCountryDefault": false,
          "name": "Concepción"
        },
        {
          "key": 863,
          "isCountryDefault": false,
          "name": "Copiapó"
        },
        {
          "key": 905,
          "isCountryDefault": false,
          "name": "Cordillera"
        },
        {
          "key": 882,
          "isCountryDefault": false,
          "name": "Curicó"
        },
        {
          "key": 861,
          "isCountryDefault": false,
          "name": "El Loa"
        },
        {
          "key": 866,
          "isCountryDefault": false,
          "name": "Elqui"
        },
        {
          "key": 898,
          "isCountryDefault": false,
          "name": "General Carrera"
        },
        {
          "key": 864,
          "isCountryDefault": false,
          "name": "Huasco"
        },
        {
          "key": 858,
          "isCountryDefault": false,
          "name": "Iquique"
        },
        {
          "key": 869,
          "isCountryDefault": false,
          "name": "Isla de Pascua"
        },
        {
          "key": 867,
          "isCountryDefault": false,
          "name": "Limarí"
        },
        {
          "key": 881,
          "isCountryDefault": false,
          "name": "Linares"
        },
        {
          "key": 892,
          "isCountryDefault": false,
          "name": "Llanquihue"
        },
        {
          "key": 870,
          "isCountryDefault": false,
          "name": "Los Andes"
        },
        {
          "key": 900,
          "isCountryDefault": false,
          "name": "Magallanes"
        },
        {
          "key": 906,
          "isCountryDefault": false,
          "name": "Maipo"
        },
        {
          "key": 889,
          "isCountryDefault": false,
          "name": "Malleco"
        },
        {
          "key": 871,
          "isCountryDefault": false,
          "name": "Marga Marga"
        },
        {
          "key": 908,
          "isCountryDefault": false,
          "name": "Melipilla"
        },
        {
          "key": 885,
          "isCountryDefault": false,
          "name": "Ñuble"
        },
        {
          "key": 893,
          "isCountryDefault": false,
          "name": "Osorno"
        },
        {
          "key": 895,
          "isCountryDefault": false,
          "name": "Palena"
        },
        {
          "key": 857,
          "isCountryDefault": false,
          "name": "Parinacota"
        },
        {
          "key": 872,
          "isCountryDefault": false,
          "name": "Petorca"
        },
        {
          "key": 897,
          "isCountryDefault": false,
          "name": "Puerto Aisén"
        },
        {
          "key": 873,
          "isCountryDefault": false,
          "name": "Quillota"
        },
        {
          "key": 891,
          "isCountryDefault": false,
          "name": "Ranco"
        },
        {
          "key": 874,
          "isCountryDefault": false,
          "name": "San Antonio"
        },
        {
          "key": 875,
          "isCountryDefault": false,
          "name": "San Felipe de Aconcagua"
        },
        {
          "key": 904,
          "isCountryDefault": false,
          "name": "Santiago"
        },
        {
          "key": 907,
          "isCountryDefault": false,
          "name": "Talagante"
        },
        {
          "key": 880,
          "isCountryDefault": false,
          "name": "Talca"
        },
        {
          "key": 859,
          "isCountryDefault": false,
          "name": "Tamarugal"
        },
        {
          "key": 902,
          "isCountryDefault": false,
          "name": "Tierra del Fuego"
        },
        {
          "key": 862,
          "isCountryDefault": false,
          "name": "Tocopilla"
        },
        {
          "key": 901,
          "isCountryDefault": false,
          "name": "Ultima Esperanza"
        },
        {
          "key": 890,
          "isCountryDefault": false,
          "name": "Valdivia"
        },
        {
          "key": 876,
          "isCountryDefault": false,
          "name": "Valparaíso"
        }
      ],
      "isEu": false
    },
    {
      "key": "china",
      "name": "China",
      "code": "CN",
      "region": "Asien",
      "states": [
        {
          "key": 11,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 926,
          "isCountryDefault": false,
          "name": "Anhui"
        },
        {
          "key": 915,
          "isCountryDefault": false,
          "name": "Beijing"
        },
        {
          "key": 936,
          "isCountryDefault": false,
          "name": "Chongqing"
        },
        {
          "key": 927,
          "isCountryDefault": false,
          "name": "Fujian"
        },
        {
          "key": 942,
          "isCountryDefault": false,
          "name": "Gansu"
        },
        {
          "key": 933,
          "isCountryDefault": false,
          "name": "Guangdong"
        },
        {
          "key": 934,
          "isCountryDefault": false,
          "name": "Guangxi"
        },
        {
          "key": 938,
          "isCountryDefault": false,
          "name": "Guizhou"
        },
        {
          "key": 935,
          "isCountryDefault": false,
          "name": "Hainan"
        },
        {
          "key": 917,
          "isCountryDefault": false,
          "name": "Hebei"
        },
        {
          "key": 922,
          "isCountryDefault": false,
          "name": "Heilongjiang"
        },
        {
          "key": 930,
          "isCountryDefault": false,
          "name": "Henan"
        },
        {
          "key": 946,
          "isCountryDefault": false,
          "name": "Hong Kong"
        },
        {
          "key": 931,
          "isCountryDefault": false,
          "name": "Hubei"
        },
        {
          "key": 913,
          "isCountryDefault": false,
          "name": "Hui"
        },
        {
          "key": 932,
          "isCountryDefault": false,
          "name": "Hunan"
        },
        {
          "key": 919,
          "isCountryDefault": false,
          "name": "Inner Mongolia"
        },
        {
          "key": 924,
          "isCountryDefault": false,
          "name": "Jiangsu"
        },
        {
          "key": 928,
          "isCountryDefault": false,
          "name": "Jiangxi"
        },
        {
          "key": 921,
          "isCountryDefault": false,
          "name": "Jilin"
        },
        {
          "key": 920,
          "isCountryDefault": false,
          "name": "Liaoning"
        },
        {
          "key": 947,
          "isCountryDefault": false,
          "name": "Macau"
        },
        {
          "key": 911,
          "isCountryDefault": false,
          "name": "Mongol"
        },
        {
          "key": 944,
          "isCountryDefault": false,
          "name": "Ningxia"
        },
        {
          "key": 943,
          "isCountryDefault": false,
          "name": "Qinghai"
        },
        {
          "key": 941,
          "isCountryDefault": false,
          "name": "Shaanxi"
        },
        {
          "key": 929,
          "isCountryDefault": false,
          "name": "Shandong"
        },
        {
          "key": 923,
          "isCountryDefault": false,
          "name": "Shanghai"
        },
        {
          "key": 918,
          "isCountryDefault": false,
          "name": "Shanxi"
        },
        {
          "key": 937,
          "isCountryDefault": false,
          "name": "Sichuan"
        },
        {
          "key": 916,
          "isCountryDefault": false,
          "name": "Tianjin"
        },
        {
          "key": 940,
          "isCountryDefault": false,
          "name": "Tibet"
        },
        {
          "key": 912,
          "isCountryDefault": false,
          "name": "Tibetan"
        },
        {
          "key": 910,
          "isCountryDefault": false,
          "name": "Uyghur"
        },
        {
          "key": 945,
          "isCountryDefault": false,
          "name": "Xinjiang"
        },
        {
          "key": 939,
          "isCountryDefault": false,
          "name": "Yunnan"
        },
        {
          "key": 925,
          "isCountryDefault": false,
          "name": "Zhejiang"
        },
        {
          "key": 914,
          "isCountryDefault": false,
          "name": "Zhuang"
        }
      ],
      "isEu": false
    },
    {
      "key": "cookIslands",
      "name": "Cookinseln",
      "code": "CK",
      "region": "Ozeanien",
      "states": [
        {
          "key": 128,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1016,
          "isCountryDefault": false,
          "name": "Aitutaki"
        },
        {
          "key": 1017,
          "isCountryDefault": false,
          "name": "Mangaia"
        },
        {
          "key": 1022,
          "isCountryDefault": false,
          "name": "Manihiki"
        },
        {
          "key": 1020,
          "isCountryDefault": false,
          "name": "Manuae"
        },
        {
          "key": 1023,
          "isCountryDefault": false,
          "name": "Nassau"
        },
        {
          "key": 1015,
          "isCountryDefault": false,
          "name": "Nga-pu-Toru"
        },
        {
          "key": 1019,
          "isCountryDefault": false,
          "name": "Palmerston Island"
        },
        {
          "key": 1024,
          "isCountryDefault": false,
          "name": "Penrhyn Island"
        },
        {
          "key": 1025,
          "isCountryDefault": false,
          "name": "Pukapuka"
        },
        {
          "key": 1026,
          "isCountryDefault": false,
          "name": "Rakahanga"
        },
        {
          "key": 1018,
          "isCountryDefault": false,
          "name": "Rarotonga"
        },
        {
          "key": 1027,
          "isCountryDefault": false,
          "name": "Suwarrow"
        },
        {
          "key": 1028,
          "isCountryDefault": false,
          "name": "Tema Reef"
        },
        {
          "key": 1021,
          "isCountryDefault": false,
          "name": "Winslow Reef"
        }
      ],
      "isEu": false
    },
    {
      "key": "costaRica",
      "name": "Costa Rica",
      "code": "CR",
      "region": "Mittelamerika",
      "states": [
        {
          "key": 130,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1029,
          "isCountryDefault": false,
          "name": "Alajuela"
        },
        {
          "key": 1030,
          "isCountryDefault": false,
          "name": "Cartago"
        },
        {
          "key": 1031,
          "isCountryDefault": false,
          "name": "Guanacaste"
        },
        {
          "key": 1032,
          "isCountryDefault": false,
          "name": "Heredia"
        },
        {
          "key": 1033,
          "isCountryDefault": false,
          "name": "Limón"
        },
        {
          "key": 1034,
          "isCountryDefault": false,
          "name": "Puntarenas"
        },
        {
          "key": 1035,
          "isCountryDefault": false,
          "name": "San José"
        }
      ],
      "isEu": false
    },
    {
      "key": "curaçao",
      "name": "Curaçao",
      "code": "CW",
      "region": "Nordamerika",
      "states": [
        {
          "key": 4106,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2264,
          "isCountryDefault": false,
          "name": "Curaçao"
        }
      ],
      "isEu": false
    },
    {
      "key": "denmark",
      "name": "Dänemark",
      "code": "DK",
      "region": "Europa",
      "states": [
        {
          "key": 14,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1093,
          "isCountryDefault": false,
          "name": "Hovedstaden"
        },
        {
          "key": 1094,
          "isCountryDefault": false,
          "name": "Midtjylland"
        },
        {
          "key": 1095,
          "isCountryDefault": false,
          "name": "Nordjylland"
        },
        {
          "key": 1096,
          "isCountryDefault": false,
          "name": "Sjælland"
        },
        {
          "key": 1097,
          "isCountryDefault": false,
          "name": "Syddanmark"
        }
      ],
      "isEu": true
    },
    {
      "key": "germany",
      "name": "Deutschland",
      "code": "DE",
      "region": "Europa",
      "states": [
        {
          "key": 13,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 413,
          "isCountryDefault": false,
          "name": "Baden-Württemberg"
        },
        {
          "key": 414,
          "isCountryDefault": false,
          "name": "Bayern"
        },
        {
          "key": 415,
          "isCountryDefault": false,
          "name": "Berlin"
        },
        {
          "key": 416,
          "isCountryDefault": false,
          "name": "Brandenburg"
        },
        {
          "key": 417,
          "isCountryDefault": false,
          "name": "Bremen"
        },
        {
          "key": 418,
          "isCountryDefault": false,
          "name": "Hamburg"
        },
        {
          "key": 419,
          "isCountryDefault": false,
          "name": "Hessen"
        },
        {
          "key": 420,
          "isCountryDefault": false,
          "name": "Mecklenburg-Vorpommern"
        },
        {
          "key": 421,
          "isCountryDefault": false,
          "name": "Niedersachsen"
        },
        {
          "key": 422,
          "isCountryDefault": false,
          "name": "Nordrhein-Westfalen"
        },
        {
          "key": 423,
          "isCountryDefault": false,
          "name": "Rheinland-Pfalz"
        },
        {
          "key": 424,
          "isCountryDefault": false,
          "name": "Saarland"
        },
        {
          "key": 425,
          "isCountryDefault": false,
          "name": "Sachsen"
        },
        {
          "key": 426,
          "isCountryDefault": false,
          "name": "Sachsen-Anhalt"
        },
        {
          "key": 427,
          "isCountryDefault": false,
          "name": "Schleswig-Holstein"
        },
        {
          "key": 428,
          "isCountryDefault": false,
          "name": "Thüringen"
        }
      ],
      "isEu": true
    },
    {
      "key": "dominica",
      "name": "Dominica",
      "code": "DM",
      "region": "Nordamerika",
      "states": [
        {
          "key": 134,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1104,
          "isCountryDefault": false,
          "name": "Saint Andrew Parish"
        },
        {
          "key": 1105,
          "isCountryDefault": false,
          "name": "Saint David Parish"
        },
        {
          "key": 1106,
          "isCountryDefault": false,
          "name": "Saint George Parish"
        },
        {
          "key": 1107,
          "isCountryDefault": false,
          "name": "Saint John Parish"
        },
        {
          "key": 1108,
          "isCountryDefault": false,
          "name": "Saint Joseph Parish"
        },
        {
          "key": 1110,
          "isCountryDefault": false,
          "name": "Saint Luke Parish"
        },
        {
          "key": 1111,
          "isCountryDefault": false,
          "name": "Saint Mark Parish"
        },
        {
          "key": 1112,
          "isCountryDefault": false,
          "name": "Saint Patrick Parish"
        },
        {
          "key": 1113,
          "isCountryDefault": false,
          "name": "Saint Paul Parish"
        },
        {
          "key": 1114,
          "isCountryDefault": false,
          "name": "Saint Peter Parish"
        }
      ],
      "isEu": false
    },
    {
      "key": "dominicanRepublic",
      "name": "Dominikanische Republik",
      "code": "DO",
      "region": "Nordamerika",
      "states": [
        {
          "key": 135,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1115,
          "isCountryDefault": false,
          "name": "Azua"
        },
        {
          "key": 1116,
          "isCountryDefault": false,
          "name": "Baoruco"
        },
        {
          "key": 1117,
          "isCountryDefault": false,
          "name": "Barahona"
        },
        {
          "key": 1118,
          "isCountryDefault": false,
          "name": "Dajabón"
        },
        {
          "key": 1119,
          "isCountryDefault": false,
          "name": "Distrito Nacional"
        },
        {
          "key": 1120,
          "isCountryDefault": false,
          "name": "Duarte"
        },
        {
          "key": 1122,
          "isCountryDefault": false,
          "name": "El Seibo"
        },
        {
          "key": 1121,
          "isCountryDefault": false,
          "name": "Elías Piña"
        },
        {
          "key": 1123,
          "isCountryDefault": false,
          "name": "Espaillat"
        },
        {
          "key": 1124,
          "isCountryDefault": false,
          "name": "Hato Mayor"
        },
        {
          "key": 1125,
          "isCountryDefault": false,
          "name": "Hermanas Mirabal"
        },
        {
          "key": 1126,
          "isCountryDefault": false,
          "name": "Independencia"
        },
        {
          "key": 1127,
          "isCountryDefault": false,
          "name": "La Altagracia"
        },
        {
          "key": 1128,
          "isCountryDefault": false,
          "name": "La Romana"
        },
        {
          "key": 1129,
          "isCountryDefault": false,
          "name": "La Vega"
        },
        {
          "key": 1130,
          "isCountryDefault": false,
          "name": "María Trinidad Sánchez"
        },
        {
          "key": 1131,
          "isCountryDefault": false,
          "name": "Monseñor Nouel"
        },
        {
          "key": 1132,
          "isCountryDefault": false,
          "name": "Monte Cristi"
        },
        {
          "key": 1133,
          "isCountryDefault": false,
          "name": "Monte Plata"
        },
        {
          "key": 1134,
          "isCountryDefault": false,
          "name": "Pedernales"
        },
        {
          "key": 1135,
          "isCountryDefault": false,
          "name": "Peravia"
        },
        {
          "key": 1136,
          "isCountryDefault": false,
          "name": "Puerto Plata"
        },
        {
          "key": 1137,
          "isCountryDefault": false,
          "name": "Samaná"
        },
        {
          "key": 1139,
          "isCountryDefault": false,
          "name": "San Cristóbal"
        },
        {
          "key": 1140,
          "isCountryDefault": false,
          "name": "San José de Ocoa"
        },
        {
          "key": 1141,
          "isCountryDefault": false,
          "name": "San Juan"
        },
        {
          "key": 1142,
          "isCountryDefault": false,
          "name": "San Pedro de Macorís"
        },
        {
          "key": 1138,
          "isCountryDefault": false,
          "name": "Sánchez Ramírez"
        },
        {
          "key": 1143,
          "isCountryDefault": false,
          "name": "Santiago"
        },
        {
          "key": 1144,
          "isCountryDefault": false,
          "name": "Santiago Rodríguez"
        },
        {
          "key": 1145,
          "isCountryDefault": false,
          "name": "Santo Domingo"
        },
        {
          "key": 1146,
          "isCountryDefault": false,
          "name": "Valverde"
        }
      ],
      "isEu": false
    },
    {
      "key": "djibouti",
      "name": "Dschibuti",
      "code": "DJ",
      "region": "Afrika",
      "states": [
        {
          "key": 133,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1098,
          "isCountryDefault": false,
          "name": "Région d'Ali Sabieh"
        },
        {
          "key": 1099,
          "isCountryDefault": false,
          "name": "Région d'Arta"
        },
        {
          "key": 1100,
          "isCountryDefault": false,
          "name": "Région de Dikhil"
        },
        {
          "key": 1103,
          "isCountryDefault": false,
          "name": "Région de Tadjourah"
        },
        {
          "key": 1102,
          "isCountryDefault": false,
          "name": "Région d'Obock"
        },
        {
          "key": 1101,
          "isCountryDefault": false,
          "name": "Ville de Djibouti"
        }
      ],
      "isEu": false
    },
    {
      "key": "ecuador",
      "name": "Ecuador",
      "code": "EC",
      "region": "Südamerika",
      "states": [
        {
          "key": 75,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1147,
          "isCountryDefault": false,
          "name": "Azuay"
        },
        {
          "key": 1148,
          "isCountryDefault": false,
          "name": "Bolivar"
        },
        {
          "key": 1149,
          "isCountryDefault": false,
          "name": "Cañar"
        },
        {
          "key": 1150,
          "isCountryDefault": false,
          "name": "Carchi"
        },
        {
          "key": 1151,
          "isCountryDefault": false,
          "name": "Chimborazo"
        },
        {
          "key": 1152,
          "isCountryDefault": false,
          "name": "Cotopaxi"
        },
        {
          "key": 1153,
          "isCountryDefault": false,
          "name": "El Oro"
        },
        {
          "key": 1154,
          "isCountryDefault": false,
          "name": "Esmeraldas"
        },
        {
          "key": 1155,
          "isCountryDefault": false,
          "name": "Galapagos"
        },
        {
          "key": 1156,
          "isCountryDefault": false,
          "name": "Guayas"
        },
        {
          "key": 1157,
          "isCountryDefault": false,
          "name": "Imbabura"
        },
        {
          "key": 1158,
          "isCountryDefault": false,
          "name": "Loja"
        },
        {
          "key": 1159,
          "isCountryDefault": false,
          "name": "Los Rios"
        },
        {
          "key": 1160,
          "isCountryDefault": false,
          "name": "Manabi"
        },
        {
          "key": 1161,
          "isCountryDefault": false,
          "name": "Morona Santiago"
        },
        {
          "key": 1162,
          "isCountryDefault": false,
          "name": "Napo"
        },
        {
          "key": 1163,
          "isCountryDefault": false,
          "name": "Orellana"
        },
        {
          "key": 1164,
          "isCountryDefault": false,
          "name": "Pastaza"
        },
        {
          "key": 1165,
          "isCountryDefault": false,
          "name": "Pichincha"
        },
        {
          "key": 1166,
          "isCountryDefault": false,
          "name": "Santa Elena"
        },
        {
          "key": 1167,
          "isCountryDefault": false,
          "name": "Santo Domingo de los Tsachilas"
        },
        {
          "key": 1168,
          "isCountryDefault": false,
          "name": "Sucumbios"
        },
        {
          "key": 1169,
          "isCountryDefault": false,
          "name": "Tungurahua"
        },
        {
          "key": 1170,
          "isCountryDefault": false,
          "name": "Zamora Chinchipe"
        }
      ],
      "isEu": false
    },
    {
      "key": "elSalvador",
      "name": "El Salvador",
      "code": "SV",
      "region": "Mittelamerika",
      "states": [
        {
          "key": 86,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1198,
          "isCountryDefault": false,
          "name": "Ahuachapán"
        },
        {
          "key": 1206,
          "isCountryDefault": false,
          "name": "Cabañas"
        },
        {
          "key": 1202,
          "isCountryDefault": false,
          "name": "Chalatenango"
        },
        {
          "key": 1203,
          "isCountryDefault": false,
          "name": "Cuscatlán"
        },
        {
          "key": 1201,
          "isCountryDefault": false,
          "name": "La Libertad"
        },
        {
          "key": 1205,
          "isCountryDefault": false,
          "name": "La Paz"
        },
        {
          "key": 1211,
          "isCountryDefault": false,
          "name": "La Unión"
        },
        {
          "key": 1210,
          "isCountryDefault": false,
          "name": "Morazán"
        },
        {
          "key": 1209,
          "isCountryDefault": false,
          "name": "San Miguel"
        },
        {
          "key": 1204,
          "isCountryDefault": false,
          "name": "San Salvador"
        },
        {
          "key": 1207,
          "isCountryDefault": false,
          "name": "San Vicente"
        },
        {
          "key": 1199,
          "isCountryDefault": false,
          "name": "Santa Ana"
        },
        {
          "key": 1200,
          "isCountryDefault": false,
          "name": "Sonsonate"
        },
        {
          "key": 1208,
          "isCountryDefault": false,
          "name": "Usulután"
        }
      ],
      "isEu": false
    },
    {
      "key": "ivoryCoast",
      "name": "Elfenbeinküste",
      "code": "CI",
      "region": "Afrika",
      "states": [
        {
          "key": 127,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1714,
          "isCountryDefault": false,
          "name": "Agnéby"
        },
        {
          "key": 1715,
          "isCountryDefault": false,
          "name": "Bafing"
        },
        {
          "key": 1716,
          "isCountryDefault": false,
          "name": "Bas-Sassandra"
        },
        {
          "key": 1717,
          "isCountryDefault": false,
          "name": "Denguélé"
        },
        {
          "key": 1718,
          "isCountryDefault": false,
          "name": "Dix-Huit Montagnes"
        },
        {
          "key": 1719,
          "isCountryDefault": false,
          "name": "Fromager"
        },
        {
          "key": 1720,
          "isCountryDefault": false,
          "name": "Haut-Sassandra"
        },
        {
          "key": 1721,
          "isCountryDefault": false,
          "name": "Lacs"
        },
        {
          "key": 1722,
          "isCountryDefault": false,
          "name": "Lagunes"
        },
        {
          "key": 1723,
          "isCountryDefault": false,
          "name": "Marahoué"
        },
        {
          "key": 1724,
          "isCountryDefault": false,
          "name": "Moyen-Cavally"
        },
        {
          "key": 1725,
          "isCountryDefault": false,
          "name": "Moyen-Comoé"
        },
        {
          "key": 1726,
          "isCountryDefault": false,
          "name": "N'zi-Comoé"
        },
        {
          "key": 1727,
          "isCountryDefault": false,
          "name": "Savanes"
        },
        {
          "key": 1728,
          "isCountryDefault": false,
          "name": "Sud-Bandama"
        },
        {
          "key": 1729,
          "isCountryDefault": false,
          "name": "Sud-Comoé"
        },
        {
          "key": 1730,
          "isCountryDefault": false,
          "name": "Vallée du Bandama"
        },
        {
          "key": 1731,
          "isCountryDefault": false,
          "name": "Worodougou"
        },
        {
          "key": 1732,
          "isCountryDefault": false,
          "name": "Zanzan"
        }
      ],
      "isEu": false
    },
    {
      "key": "england",
      "name": "England",
      "code": "GB",
      "region": "Europa",
      "states": [
        {
          "key": 240,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1212,
          "isCountryDefault": false,
          "name": "Avon"
        },
        {
          "key": 1213,
          "isCountryDefault": false,
          "name": "Bedfordshire"
        },
        {
          "key": 1214,
          "isCountryDefault": false,
          "name": "Berkshire"
        },
        {
          "key": 1215,
          "isCountryDefault": false,
          "name": "Bristol"
        },
        {
          "key": 1216,
          "isCountryDefault": false,
          "name": "Buckinghamshire"
        },
        {
          "key": 1217,
          "isCountryDefault": false,
          "name": "Cambridgeshire"
        },
        {
          "key": 1218,
          "isCountryDefault": false,
          "name": "Cambridgeshire and Isle of Ely"
        },
        {
          "key": 1219,
          "isCountryDefault": false,
          "name": "Cheshire"
        },
        {
          "key": 1220,
          "isCountryDefault": false,
          "name": "Cleveland"
        },
        {
          "key": 1221,
          "isCountryDefault": false,
          "name": "Cornwall"
        },
        {
          "key": 1222,
          "isCountryDefault": false,
          "name": "Cumberland"
        },
        {
          "key": 1223,
          "isCountryDefault": false,
          "name": "Cumbria"
        },
        {
          "key": 1224,
          "isCountryDefault": false,
          "name": "Derbyshire"
        },
        {
          "key": 1225,
          "isCountryDefault": false,
          "name": "Devon"
        },
        {
          "key": 1226,
          "isCountryDefault": false,
          "name": "Dorset"
        },
        {
          "key": 1227,
          "isCountryDefault": false,
          "name": "Durham"
        },
        {
          "key": 1228,
          "isCountryDefault": false,
          "name": "East Suffolk"
        },
        {
          "key": 1229,
          "isCountryDefault": false,
          "name": "East Sussex"
        },
        {
          "key": 1230,
          "isCountryDefault": false,
          "name": "Essex"
        },
        {
          "key": 1231,
          "isCountryDefault": false,
          "name": "Gloucestershire"
        },
        {
          "key": 1232,
          "isCountryDefault": false,
          "name": "Greater London"
        },
        {
          "key": 1233,
          "isCountryDefault": false,
          "name": "Greater Manchester"
        },
        {
          "key": 1234,
          "isCountryDefault": false,
          "name": "Hampshire"
        },
        {
          "key": 1235,
          "isCountryDefault": false,
          "name": "Hereford and Worcester"
        },
        {
          "key": 1236,
          "isCountryDefault": false,
          "name": "Herefordshire"
        },
        {
          "key": 1237,
          "isCountryDefault": false,
          "name": "Hertfordshire"
        },
        {
          "key": 1238,
          "isCountryDefault": false,
          "name": "Humberside"
        },
        {
          "key": 1239,
          "isCountryDefault": false,
          "name": "Huntingdon and Peterborough"
        },
        {
          "key": 1240,
          "isCountryDefault": false,
          "name": "Huntingdonshire"
        },
        {
          "key": 1241,
          "isCountryDefault": false,
          "name": "Isle of Ely"
        },
        {
          "key": 1242,
          "isCountryDefault": false,
          "name": "Isle of Wight"
        },
        {
          "key": 1243,
          "isCountryDefault": false,
          "name": "Kent"
        },
        {
          "key": 1244,
          "isCountryDefault": false,
          "name": "Lancashire"
        },
        {
          "key": 1245,
          "isCountryDefault": false,
          "name": "Leicestershire"
        },
        {
          "key": 1246,
          "isCountryDefault": false,
          "name": "Lincolnshire"
        },
        {
          "key": 1250,
          "isCountryDefault": false,
          "name": "London"
        },
        {
          "key": 1251,
          "isCountryDefault": false,
          "name": "Merseyside"
        },
        {
          "key": 1252,
          "isCountryDefault": false,
          "name": "Middlesex"
        },
        {
          "key": 1253,
          "isCountryDefault": false,
          "name": "Norfolk"
        },
        {
          "key": 1256,
          "isCountryDefault": false,
          "name": "North Humberside"
        },
        {
          "key": 1257,
          "isCountryDefault": false,
          "name": "North Yorkshire"
        },
        {
          "key": 1254,
          "isCountryDefault": false,
          "name": "Northamptonshire"
        },
        {
          "key": 1255,
          "isCountryDefault": false,
          "name": "Northumberland"
        },
        {
          "key": 1258,
          "isCountryDefault": false,
          "name": "Nottinghamshire"
        },
        {
          "key": 1259,
          "isCountryDefault": false,
          "name": "Oxfordshire"
        },
        {
          "key": 1261,
          "isCountryDefault": false,
          "name": "Rutland"
        },
        {
          "key": 1262,
          "isCountryDefault": false,
          "name": "Shropshire (Salop)"
        },
        {
          "key": 1260,
          "isCountryDefault": false,
          "name": "Soke of Peterborough"
        },
        {
          "key": 1263,
          "isCountryDefault": false,
          "name": "Somerset"
        },
        {
          "key": 1264,
          "isCountryDefault": false,
          "name": "South Humberside"
        },
        {
          "key": 1265,
          "isCountryDefault": false,
          "name": "South Yorkshire"
        },
        {
          "key": 1266,
          "isCountryDefault": false,
          "name": "Staffordshire"
        },
        {
          "key": 1267,
          "isCountryDefault": false,
          "name": "Suffolk"
        },
        {
          "key": 1268,
          "isCountryDefault": false,
          "name": "Surrey"
        },
        {
          "key": 1269,
          "isCountryDefault": false,
          "name": "Sussex"
        },
        {
          "key": 1270,
          "isCountryDefault": false,
          "name": "Tyne and Wear"
        },
        {
          "key": 1271,
          "isCountryDefault": false,
          "name": "Warwickshire"
        },
        {
          "key": 1272,
          "isCountryDefault": false,
          "name": "West Midlands"
        },
        {
          "key": 1274,
          "isCountryDefault": false,
          "name": "West Suffolk"
        },
        {
          "key": 1275,
          "isCountryDefault": false,
          "name": "West Sussex"
        },
        {
          "key": 1276,
          "isCountryDefault": false,
          "name": "West Yorkshire"
        },
        {
          "key": 1273,
          "isCountryDefault": false,
          "name": "Westmorland"
        },
        {
          "key": 1277,
          "isCountryDefault": false,
          "name": "Wiltshire"
        },
        {
          "key": 1278,
          "isCountryDefault": false,
          "name": "Worcestershire"
        },
        {
          "key": 1279,
          "isCountryDefault": false,
          "name": "Yorkshire"
        },
        {
          "key": 1280,
          "isCountryDefault": false,
          "name": "Yorkshire, East Riding"
        },
        {
          "key": 1282,
          "isCountryDefault": false,
          "name": "Yorkshire, West Riding"
        }
      ],
      "isEu": false
    },
    {
      "key": "eritrea",
      "name": "Eritrea",
      "code": "ER",
      "region": "Afrika",
      "states": [
        {
          "key": 138,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1293,
          "isCountryDefault": false,
          "name": "Anseba"
        },
        {
          "key": 1290,
          "isCountryDefault": false,
          "name": "Central"
        },
        {
          "key": 1292,
          "isCountryDefault": false,
          "name": "Gash-Barka"
        },
        {
          "key": 1294,
          "isCountryDefault": false,
          "name": "Northern Red Sea"
        },
        {
          "key": 1291,
          "isCountryDefault": false,
          "name": "Southern"
        },
        {
          "key": 1295,
          "isCountryDefault": false,
          "name": "Southern Red Sea"
        }
      ],
      "isEu": false
    },
    {
      "key": "estonia",
      "name": "Estland",
      "code": "EE",
      "region": "Europa",
      "states": [
        {
          "key": 80,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1296,
          "isCountryDefault": false,
          "name": "Harjumaa"
        },
        {
          "key": 1297,
          "isCountryDefault": false,
          "name": "Hiiumaa"
        },
        {
          "key": 1298,
          "isCountryDefault": false,
          "name": "Ida-Virumaa"
        },
        {
          "key": 1300,
          "isCountryDefault": false,
          "name": "Järvamaa"
        },
        {
          "key": 1299,
          "isCountryDefault": false,
          "name": "Jõgevamaa"
        },
        {
          "key": 1301,
          "isCountryDefault": false,
          "name": "Läänemaa"
        },
        {
          "key": 1302,
          "isCountryDefault": false,
          "name": "Lääne-Virumaa"
        },
        {
          "key": 1304,
          "isCountryDefault": false,
          "name": "Pärnumaa"
        },
        {
          "key": 1303,
          "isCountryDefault": false,
          "name": "Põlvamaa"
        },
        {
          "key": 1305,
          "isCountryDefault": false,
          "name": "Raplamaa"
        },
        {
          "key": 1306,
          "isCountryDefault": false,
          "name": "Saaremaa"
        },
        {
          "key": 1307,
          "isCountryDefault": false,
          "name": "Tartumaa"
        },
        {
          "key": 1308,
          "isCountryDefault": false,
          "name": "Valgamaa"
        },
        {
          "key": 1309,
          "isCountryDefault": false,
          "name": "Viljandimaa"
        },
        {
          "key": 1310,
          "isCountryDefault": false,
          "name": "Võrumaa"
        }
      ],
      "isEu": true
    },
    {
      "key": "falklandIslandsMalvinas",
      "name": "Falklandinseln (Malvinen)",
      "code": "FK",
      "region": "Südamerika",
      "states": [
        {
          "key": 141,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1322,
          "isCountryDefault": false,
          "name": "East Falkland"
        },
        {
          "key": 1323,
          "isCountryDefault": false,
          "name": "West Falkland"
        }
      ],
      "isEu": false
    },
    {
      "key": "faroeIslands",
      "name": "Färöer",
      "code": "FO",
      "region": "Europa",
      "states": [
        {
          "key": 143,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2362,
          "isCountryDefault": false,
          "name": "Faroe Islands"
        }
      ],
      "isEu": false
    },
    {
      "key": "fiji",
      "name": "Fidschi",
      "code": "FJ",
      "region": "Ozeanien",
      "states": [
        {
          "key": 140,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1326,
          "isCountryDefault": false,
          "name": "Ba"
        },
        {
          "key": 1325,
          "isCountryDefault": false,
          "name": "Bua"
        },
        {
          "key": 1324,
          "isCountryDefault": false,
          "name": "Cakaudrove"
        },
        {
          "key": 4047,
          "isCountryDefault": false,
          "name": "Kadavu"
        },
        {
          "key": 4048,
          "isCountryDefault": false,
          "name": "Lau"
        },
        {
          "key": 4049,
          "isCountryDefault": false,
          "name": "Lomaiviti"
        },
        {
          "key": 4050,
          "isCountryDefault": false,
          "name": "Macuata"
        },
        {
          "key": 4051,
          "isCountryDefault": false,
          "name": "Nadroga-Navosa"
        },
        {
          "key": 4052,
          "isCountryDefault": false,
          "name": "Naitasiri"
        },
        {
          "key": 4053,
          "isCountryDefault": false,
          "name": "Namosi"
        },
        {
          "key": 4054,
          "isCountryDefault": false,
          "name": "Ra"
        },
        {
          "key": 4055,
          "isCountryDefault": false,
          "name": "Rewa"
        },
        {
          "key": 4056,
          "isCountryDefault": false,
          "name": "Serua"
        },
        {
          "key": 4057,
          "isCountryDefault": false,
          "name": "Tailevu"
        }
      ],
      "isEu": false
    },
    {
      "key": "finland",
      "name": "Finnland",
      "code": "FI",
      "region": "Europa",
      "states": [
        {
          "key": 17,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1345,
          "isCountryDefault": false,
          "name": "Ahvenanmaa (Aland Islands)"
        },
        {
          "key": 1340,
          "isCountryDefault": false,
          "name": "Etelä-Karjala (South Karelia)"
        },
        {
          "key": 1333,
          "isCountryDefault": false,
          "name": "Etelä-Pohjanmaa (Southern Ostrobothnia)"
        },
        {
          "key": 1332,
          "isCountryDefault": false,
          "name": "Etelä-Savo (Southern Savonia)"
        },
        {
          "key": 1329,
          "isCountryDefault": false,
          "name": "Kainuu (Kainuu)"
        },
        {
          "key": 1342,
          "isCountryDefault": false,
          "name": "Kanta-Häme (Tavastia Proper)"
        },
        {
          "key": 1334,
          "isCountryDefault": false,
          "name": "Keski-Pohjanmaa (Central Ostrobothnia)"
        },
        {
          "key": 1338,
          "isCountryDefault": false,
          "name": "Keski-Suomi (Central Finland)"
        },
        {
          "key": 1344,
          "isCountryDefault": false,
          "name": "Kymenlaakso (Kymenlaakso)"
        },
        {
          "key": 1327,
          "isCountryDefault": false,
          "name": "Lappi (Lapland)"
        },
        {
          "key": 1341,
          "isCountryDefault": false,
          "name": "Päijät-Häme (Päijänne Tavastia)"
        },
        {
          "key": 1336,
          "isCountryDefault": false,
          "name": "Pirkanmaa (Pirkanmaa)"
        },
        {
          "key": 1335,
          "isCountryDefault": false,
          "name": "Pohjanmaa (Ostrobothnia)"
        },
        {
          "key": 1330,
          "isCountryDefault": false,
          "name": "Pohjois-Karjala (North Karelia)"
        },
        {
          "key": 1328,
          "isCountryDefault": false,
          "name": "Pohjois-Pohjanmaa (Northern Ostrobothnia)"
        },
        {
          "key": 1331,
          "isCountryDefault": false,
          "name": "Pohjois-Savo (Northern Savonia)"
        },
        {
          "key": 1337,
          "isCountryDefault": false,
          "name": "Satakunta (Satakunta)"
        },
        {
          "key": 1343,
          "isCountryDefault": false,
          "name": "Uusimaa (Uusimaa)"
        },
        {
          "key": 1339,
          "isCountryDefault": false,
          "name": "Varsinais-Suomi (Finland Proper)"
        }
      ],
      "isEu": true
    },
    {
      "key": "france",
      "name": "Frankreich",
      "code": "FR",
      "region": "Europa",
      "states": [
        {
          "key": 18,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1348,
          "isCountryDefault": false,
          "name": "Auvergne-Rhône-Alpes"
        },
        {
          "key": 1350,
          "isCountryDefault": false,
          "name": "Bourgogne-Franche-Comté"
        },
        {
          "key": 1349,
          "isCountryDefault": false,
          "name": "Bretagne"
        },
        {
          "key": 1351,
          "isCountryDefault": false,
          "name": "Centre-Val-de-Loire"
        },
        {
          "key": 1367,
          "isCountryDefault": false,
          "name": "Corse"
        },
        {
          "key": 1346,
          "isCountryDefault": false,
          "name": "Grand Est"
        },
        {
          "key": 1360,
          "isCountryDefault": false,
          "name": "Hauts-de-France"
        },
        {
          "key": 1354,
          "isCountryDefault": false,
          "name": "Île-de-France"
        },
        {
          "key": 1358,
          "isCountryDefault": false,
          "name": "Normandy"
        },
        {
          "key": 1347,
          "isCountryDefault": false,
          "name": "Nouvelle-Aquitaine"
        },
        {
          "key": 1355,
          "isCountryDefault": false,
          "name": "Occitanie"
        },
        {
          "key": 1361,
          "isCountryDefault": false,
          "name": "Pays de la Loire"
        },
        {
          "key": 1364,
          "isCountryDefault": false,
          "name": "Provence-Alpes-Côte d'Azur"
        }
      ],
      "isEu": true
    },
    {
      "key": "frenchSouthernTerritories",
      "name": "Französische Süd- und Antarktisgebiete",
      "code": "TF",
      "region": "Ozeanien",
      "states": [
        {
          "key": 213,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2359,
          "isCountryDefault": false,
          "name": "French Southern Territories"
        }
      ],
      "isEu": false
    },
    {
      "key": "frenchGuiana",
      "name": "Französisch-Guayana",
      "code": "GF",
      "region": "Südamerika",
      "states": [
        {
          "key": 147,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1368,
          "isCountryDefault": false,
          "name": "Approuague-Kaw"
        },
        {
          "key": 1369,
          "isCountryDefault": false,
          "name": "Cayenne 1st Canton Nord-Ouest"
        },
        {
          "key": 1370,
          "isCountryDefault": false,
          "name": "Cayenne 2nd Canton Nord-Est"
        },
        {
          "key": 1371,
          "isCountryDefault": false,
          "name": "Cayenne 3rd Canton Sud-Ouest"
        },
        {
          "key": 1372,
          "isCountryDefault": false,
          "name": "Cayenne 4th Canton Centre"
        },
        {
          "key": 1373,
          "isCountryDefault": false,
          "name": "Cayenne 5th Canton Sud"
        },
        {
          "key": 1374,
          "isCountryDefault": false,
          "name": "Cayenne 6th Canton Sud-Est"
        },
        {
          "key": 1375,
          "isCountryDefault": false,
          "name": "Iracoubo"
        },
        {
          "key": 1376,
          "isCountryDefault": false,
          "name": "Macouria"
        },
        {
          "key": 1383,
          "isCountryDefault": false,
          "name": "Mana"
        },
        {
          "key": 1384,
          "isCountryDefault": false,
          "name": "Maripasoula"
        },
        {
          "key": 1377,
          "isCountryDefault": false,
          "name": "Matoury"
        },
        {
          "key": 1378,
          "isCountryDefault": false,
          "name": "Montsinéry-Tonnegrande"
        },
        {
          "key": 1379,
          "isCountryDefault": false,
          "name": "Rémiré-Montjoly"
        },
        {
          "key": 1380,
          "isCountryDefault": false,
          "name": "Roura"
        },
        {
          "key": 1381,
          "isCountryDefault": false,
          "name": "Saint-Georges-de-l'Oyapock"
        },
        {
          "key": 1385,
          "isCountryDefault": false,
          "name": "Saint-Laurent-du-Maroni"
        },
        {
          "key": 1382,
          "isCountryDefault": false,
          "name": "Sinnamary"
        }
      ],
      "isEu": false
    },
    {
      "key": "frenchPolynesia",
      "name": "Französisch-Polynesien",
      "code": "PF",
      "region": "Ozeanien",
      "states": [
        {
          "key": 194,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1390,
          "isCountryDefault": false,
          "name": "Austral Islands"
        },
        {
          "key": 1391,
          "isCountryDefault": false,
          "name": "Bass Islands"
        },
        {
          "key": 1389,
          "isCountryDefault": false,
          "name": "Gambier Islands"
        },
        {
          "key": 1386,
          "isCountryDefault": false,
          "name": "Marquesas Islands"
        },
        {
          "key": 1387,
          "isCountryDefault": false,
          "name": "Society Islands"
        },
        {
          "key": 1388,
          "isCountryDefault": false,
          "name": "Tuamotu Archipelago"
        }
      ],
      "isEu": false
    },
    {
      "key": "gabon",
      "name": "Gabun",
      "code": "GA",
      "region": "Afrika",
      "states": [
        {
          "key": 144,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1392,
          "isCountryDefault": false,
          "name": "Estuaire"
        },
        {
          "key": 1393,
          "isCountryDefault": false,
          "name": "Haut-Ogooué"
        },
        {
          "key": 1394,
          "isCountryDefault": false,
          "name": "Moyen-Ogooué"
        },
        {
          "key": 1395,
          "isCountryDefault": false,
          "name": "Ngounié"
        },
        {
          "key": 1396,
          "isCountryDefault": false,
          "name": "Nyanga"
        },
        {
          "key": 1397,
          "isCountryDefault": false,
          "name": "Ogooué-Ivindo"
        },
        {
          "key": 1398,
          "isCountryDefault": false,
          "name": "Ogooué-Lolo"
        },
        {
          "key": 1399,
          "isCountryDefault": false,
          "name": "Ogooué-Maritime"
        },
        {
          "key": 1400,
          "isCountryDefault": false,
          "name": "Woleu-Ntem"
        }
      ],
      "isEu": false
    },
    {
      "key": "gambia",
      "name": "Gambia",
      "code": "GM",
      "region": "Afrika",
      "states": [
        {
          "key": 150,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1406,
          "isCountryDefault": false,
          "name": "Banjul"
        },
        {
          "key": 1402,
          "isCountryDefault": false,
          "name": "Central River"
        },
        {
          "key": 1401,
          "isCountryDefault": false,
          "name": "Lower River"
        },
        {
          "key": 1403,
          "isCountryDefault": false,
          "name": "North Bank"
        },
        {
          "key": 1404,
          "isCountryDefault": false,
          "name": "Upper River"
        },
        {
          "key": 1405,
          "isCountryDefault": false,
          "name": "Western"
        }
      ],
      "isEu": false
    },
    {
      "key": "georgia",
      "name": "Georgien",
      "code": "GE",
      "region": "Asien",
      "states": [
        {
          "key": 146,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1407,
          "isCountryDefault": false,
          "name": "Abkhazia"
        },
        {
          "key": 1410,
          "isCountryDefault": false,
          "name": "Adjara"
        },
        {
          "key": 1409,
          "isCountryDefault": false,
          "name": "Guria"
        },
        {
          "key": 1412,
          "isCountryDefault": false,
          "name": "Imereti"
        },
        {
          "key": 1417,
          "isCountryDefault": false,
          "name": "Kakheti"
        },
        {
          "key": 1416,
          "isCountryDefault": false,
          "name": "Kvemo Kartli"
        },
        {
          "key": 1415,
          "isCountryDefault": false,
          "name": "Mtskheta-Mtianeti"
        },
        {
          "key": 1411,
          "isCountryDefault": false,
          "name": "Racha-Lechkhumi"
        },
        {
          "key": 1408,
          "isCountryDefault": false,
          "name": "Samegrelo-Zemo Svaneti"
        },
        {
          "key": 1413,
          "isCountryDefault": false,
          "name": "Samtskhe-Javakheti"
        },
        {
          "key": 1414,
          "isCountryDefault": false,
          "name": "Shida Kartli"
        },
        {
          "key": 1418,
          "isCountryDefault": false,
          "name": "Tbilisi"
        }
      ],
      "isEu": false
    },
    {
      "key": "ghana",
      "name": "Ghana",
      "code": "GH",
      "region": "Afrika",
      "states": [
        {
          "key": 148,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1419,
          "isCountryDefault": false,
          "name": "Ashanti"
        },
        {
          "key": 1420,
          "isCountryDefault": false,
          "name": "Brong Ahafo"
        },
        {
          "key": 1421,
          "isCountryDefault": false,
          "name": "Central"
        },
        {
          "key": 1422,
          "isCountryDefault": false,
          "name": "Eastern"
        },
        {
          "key": 1423,
          "isCountryDefault": false,
          "name": "Greater Accra"
        },
        {
          "key": 1424,
          "isCountryDefault": false,
          "name": "Northern"
        },
        {
          "key": 1425,
          "isCountryDefault": false,
          "name": "Upper East"
        },
        {
          "key": 1426,
          "isCountryDefault": false,
          "name": "Upper West"
        },
        {
          "key": 1427,
          "isCountryDefault": false,
          "name": "Volta"
        },
        {
          "key": 1428,
          "isCountryDefault": false,
          "name": "Western"
        }
      ],
      "isEu": false
    },
    {
      "key": "gibraltar",
      "name": "Gibraltar",
      "code": "GI",
      "region": "Europa",
      "states": [
        {
          "key": 149,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1429,
          "isCountryDefault": false,
          "name": "East Side"
        },
        {
          "key": 1430,
          "isCountryDefault": false,
          "name": "North District"
        },
        {
          "key": 1431,
          "isCountryDefault": false,
          "name": "Reclamation Areas"
        },
        {
          "key": 1432,
          "isCountryDefault": false,
          "name": "Sandpits Area"
        },
        {
          "key": 1433,
          "isCountryDefault": false,
          "name": "South District"
        },
        {
          "key": 1434,
          "isCountryDefault": false,
          "name": "Town Area"
        },
        {
          "key": 1435,
          "isCountryDefault": false,
          "name": "Upper Town"
        }
      ],
      "isEu": false
    },
    {
      "key": "grenada",
      "name": "Grenada",
      "code": "GD",
      "region": "Nordamerika",
      "states": [
        {
          "key": 145,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1440,
          "isCountryDefault": false,
          "name": "Saint Andrew"
        },
        {
          "key": 1441,
          "isCountryDefault": false,
          "name": "Saint David"
        },
        {
          "key": 1442,
          "isCountryDefault": false,
          "name": "Saint George"
        },
        {
          "key": 1443,
          "isCountryDefault": false,
          "name": "Saint John"
        },
        {
          "key": 1444,
          "isCountryDefault": false,
          "name": "Saint Mark"
        },
        {
          "key": 1445,
          "isCountryDefault": false,
          "name": "Saint Patrick"
        }
      ],
      "isEu": false
    },
    {
      "key": "greece",
      "name": "Griechenland",
      "code": "GR",
      "region": "Europa",
      "states": [
        {
          "key": 21,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 439,
          "isCountryDefault": false,
          "name": "Attica"
        },
        {
          "key": 440,
          "isCountryDefault": false,
          "name": "Central Greece"
        },
        {
          "key": 441,
          "isCountryDefault": false,
          "name": "Central Macedonia"
        },
        {
          "key": 442,
          "isCountryDefault": false,
          "name": "Crete"
        },
        {
          "key": 443,
          "isCountryDefault": false,
          "name": "East Macedonia and Thrace"
        },
        {
          "key": 444,
          "isCountryDefault": false,
          "name": "Epirus"
        },
        {
          "key": 445,
          "isCountryDefault": false,
          "name": "Ionian Islands"
        },
        {
          "key": 452,
          "isCountryDefault": false,
          "name": "Mount Athos"
        },
        {
          "key": 446,
          "isCountryDefault": false,
          "name": "North Aegean"
        },
        {
          "key": 447,
          "isCountryDefault": false,
          "name": "Peloponnese"
        },
        {
          "key": 448,
          "isCountryDefault": false,
          "name": "South Aegean"
        },
        {
          "key": 449,
          "isCountryDefault": false,
          "name": "Thessaly"
        },
        {
          "key": 450,
          "isCountryDefault": false,
          "name": "West Greece"
        },
        {
          "key": 451,
          "isCountryDefault": false,
          "name": "West Macedonia"
        }
      ],
      "isEu": true
    },
    {
      "key": "greenland",
      "name": "Grönland",
      "code": "GL",
      "region": "Nordamerika",
      "states": [
        {
          "key": 20,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1436,
          "isCountryDefault": false,
          "name": "Kujalleq"
        },
        {
          "key": 1437,
          "isCountryDefault": false,
          "name": "Qaasuitsup"
        },
        {
          "key": 1438,
          "isCountryDefault": false,
          "name": "Qeqqata"
        },
        {
          "key": 1439,
          "isCountryDefault": false,
          "name": "Sermersooq"
        }
      ],
      "isEu": false
    },
    {
      "key": "guadeloupe",
      "name": "Guadeloupe",
      "code": "GP",
      "region": "Nordamerika",
      "states": [
        {
          "key": 152,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1447,
          "isCountryDefault": false,
          "name": "Basse-Terre"
        },
        {
          "key": 1450,
          "isCountryDefault": false,
          "name": "Le Moule"
        },
        {
          "key": 1449,
          "isCountryDefault": false,
          "name": "Petit-Bourg"
        },
        {
          "key": 1446,
          "isCountryDefault": false,
          "name": "Pointe-à-Pitre"
        },
        {
          "key": 1448,
          "isCountryDefault": false,
          "name": "Sainte-Anne"
        }
      ],
      "isEu": false
    },
    {
      "key": "guam",
      "name": "Guam",
      "code": "GU",
      "region": "Ozeanien",
      "states": [
        {
          "key": 155,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2360,
          "isCountryDefault": false,
          "name": "Guam"
        }
      ],
      "isEu": false
    },
    {
      "key": "guatemala",
      "name": "Guatemala",
      "code": "GT",
      "region": "Mittelamerika",
      "states": [
        {
          "key": 81,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1451,
          "isCountryDefault": false,
          "name": "Alta Verapaz"
        },
        {
          "key": 1452,
          "isCountryDefault": false,
          "name": "Baja Verapaz"
        },
        {
          "key": 1453,
          "isCountryDefault": false,
          "name": "Chimaltenango"
        },
        {
          "key": 1454,
          "isCountryDefault": false,
          "name": "Chiquimula"
        },
        {
          "key": 1456,
          "isCountryDefault": false,
          "name": "El Progreso"
        },
        {
          "key": 1457,
          "isCountryDefault": false,
          "name": "El Quiché"
        },
        {
          "key": 1458,
          "isCountryDefault": false,
          "name": "Escuintla"
        },
        {
          "key": 1459,
          "isCountryDefault": false,
          "name": "Guatemala"
        },
        {
          "key": 1460,
          "isCountryDefault": false,
          "name": "Huehuetenango"
        },
        {
          "key": 1461,
          "isCountryDefault": false,
          "name": "Izabal"
        },
        {
          "key": 1462,
          "isCountryDefault": false,
          "name": "Jalapa"
        },
        {
          "key": 1463,
          "isCountryDefault": false,
          "name": "Jutiapa"
        },
        {
          "key": 1455,
          "isCountryDefault": false,
          "name": "Petén"
        },
        {
          "key": 1464,
          "isCountryDefault": false,
          "name": "Quetzaltenango"
        },
        {
          "key": 1465,
          "isCountryDefault": false,
          "name": "Retalhuleu"
        },
        {
          "key": 1466,
          "isCountryDefault": false,
          "name": "Sacatepéquez"
        },
        {
          "key": 1467,
          "isCountryDefault": false,
          "name": "San Marcos"
        },
        {
          "key": 1468,
          "isCountryDefault": false,
          "name": "Santa Rosa"
        },
        {
          "key": 1469,
          "isCountryDefault": false,
          "name": "Sololá"
        },
        {
          "key": 1470,
          "isCountryDefault": false,
          "name": "Suchitepéquez"
        },
        {
          "key": 1471,
          "isCountryDefault": false,
          "name": "Totonicapán"
        },
        {
          "key": 1472,
          "isCountryDefault": false,
          "name": "Zacapa"
        }
      ],
      "isEu": false
    },
    {
      "key": "guernsey",
      "name": "Guernsey",
      "code": "GG",
      "region": "Europa",
      "states": [
        {
          "key": 244,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1474,
          "isCountryDefault": false,
          "name": "Castel"
        },
        {
          "key": 1473,
          "isCountryDefault": false,
          "name": "Forest"
        },
        {
          "key": 3931,
          "isCountryDefault": false,
          "name": "St Andrew"
        },
        {
          "key": 3932,
          "isCountryDefault": false,
          "name": "St Martin"
        },
        {
          "key": 3933,
          "isCountryDefault": false,
          "name": "St Peter Port"
        },
        {
          "key": 3934,
          "isCountryDefault": false,
          "name": "St Pierre du Bois"
        },
        {
          "key": 3935,
          "isCountryDefault": false,
          "name": "St Sampson"
        },
        {
          "key": 3936,
          "isCountryDefault": false,
          "name": "St. Saviour"
        },
        {
          "key": 3937,
          "isCountryDefault": false,
          "name": "Torteval"
        },
        {
          "key": 3938,
          "isCountryDefault": false,
          "name": "Vale"
        }
      ],
      "isEu": false
    },
    {
      "key": "guinea",
      "name": "Guinea",
      "code": "GN",
      "region": "Afrika",
      "states": [
        {
          "key": 151,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1475,
          "isCountryDefault": false,
          "name": "Boké Region"
        },
        {
          "key": 1476,
          "isCountryDefault": false,
          "name": "Conakry Region"
        },
        {
          "key": 1477,
          "isCountryDefault": false,
          "name": "Faranah Region"
        },
        {
          "key": 1478,
          "isCountryDefault": false,
          "name": "Kankan Region"
        },
        {
          "key": 1479,
          "isCountryDefault": false,
          "name": "Kindia Region"
        },
        {
          "key": 1480,
          "isCountryDefault": false,
          "name": "Labé Region"
        },
        {
          "key": 1481,
          "isCountryDefault": false,
          "name": "Mamou Region"
        },
        {
          "key": 1482,
          "isCountryDefault": false,
          "name": "Nzérékoré Region"
        }
      ],
      "isEu": false
    },
    {
      "key": "guineaBissau",
      "name": "Guinea-Bissau",
      "code": "GW",
      "region": "Afrika",
      "states": [
        {
          "key": 156,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1483,
          "isCountryDefault": false,
          "name": "Bafatá"
        },
        {
          "key": 1484,
          "isCountryDefault": false,
          "name": "Biombo"
        },
        {
          "key": 1485,
          "isCountryDefault": false,
          "name": "Bissau"
        },
        {
          "key": 1486,
          "isCountryDefault": false,
          "name": "Bolama"
        },
        {
          "key": 1488,
          "isCountryDefault": false,
          "name": "Cacheu"
        },
        {
          "key": 1489,
          "isCountryDefault": false,
          "name": "Gabu"
        },
        {
          "key": 1490,
          "isCountryDefault": false,
          "name": "Oio"
        },
        {
          "key": 1491,
          "isCountryDefault": false,
          "name": "Quinara"
        },
        {
          "key": 1492,
          "isCountryDefault": false,
          "name": "Tombali"
        }
      ],
      "isEu": false
    },
    {
      "key": "guyana",
      "name": "Guyana",
      "code": "GY",
      "region": "Südamerika",
      "states": [
        {
          "key": 157,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1493,
          "isCountryDefault": false,
          "name": "Barima-Waini"
        },
        {
          "key": 1499,
          "isCountryDefault": false,
          "name": "Cuyuni-Mazaruni"
        },
        {
          "key": 1496,
          "isCountryDefault": false,
          "name": "Demerara-Mahaica"
        },
        {
          "key": 1498,
          "isCountryDefault": false,
          "name": "East Berbice-Corentyne"
        },
        {
          "key": 1495,
          "isCountryDefault": false,
          "name": "Essequibo Islands-West Demerara"
        },
        {
          "key": 1497,
          "isCountryDefault": false,
          "name": "Mahaica-Berbice"
        },
        {
          "key": 1494,
          "isCountryDefault": false,
          "name": "Pomeroon-Supenaam"
        },
        {
          "key": 1500,
          "isCountryDefault": false,
          "name": "Potaro-Siparuni"
        },
        {
          "key": 1502,
          "isCountryDefault": false,
          "name": "Upper Demerara-Berbice"
        },
        {
          "key": 1501,
          "isCountryDefault": false,
          "name": "Upper Takutu-Upper Essequibo"
        }
      ],
      "isEu": false
    },
    {
      "key": "haiti",
      "name": "Haiti",
      "code": "HT",
      "region": "Nordamerika",
      "states": [
        {
          "key": 159,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1503,
          "isCountryDefault": false,
          "name": "Artibonite"
        },
        {
          "key": 1504,
          "isCountryDefault": false,
          "name": "Centre"
        },
        {
          "key": 1505,
          "isCountryDefault": false,
          "name": "Grand'Anse"
        },
        {
          "key": 1506,
          "isCountryDefault": false,
          "name": "Nippes"
        },
        {
          "key": 1507,
          "isCountryDefault": false,
          "name": "Nord"
        },
        {
          "key": 1508,
          "isCountryDefault": false,
          "name": "Nord-Est"
        },
        {
          "key": 1510,
          "isCountryDefault": false,
          "name": "Nord-Ouest"
        },
        {
          "key": 1511,
          "isCountryDefault": false,
          "name": "Ouest"
        },
        {
          "key": 1513,
          "isCountryDefault": false,
          "name": "Sud"
        },
        {
          "key": 1512,
          "isCountryDefault": false,
          "name": "Sud-Est"
        }
      ],
      "isEu": false
    },
    {
      "key": "heardandMcDonaldIslands",
      "name": "Heard und die Mcdonaldinseln",
      "code": "HM",
      "region": "Ozeanien",
      "states": [
        {
          "key": 158,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2361,
          "isCountryDefault": false,
          "name": "Heard and McDonald Islands"
        }
      ],
      "isEu": false
    },
    {
      "key": "honduras",
      "name": "Honduras",
      "code": "HN",
      "region": "Mittelamerika",
      "states": [
        {
          "key": 23,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1514,
          "isCountryDefault": false,
          "name": "Atlántida"
        },
        {
          "key": 1515,
          "isCountryDefault": false,
          "name": "Choluteca"
        },
        {
          "key": 1516,
          "isCountryDefault": false,
          "name": "Colón"
        },
        {
          "key": 1517,
          "isCountryDefault": false,
          "name": "Comayagua"
        },
        {
          "key": 1518,
          "isCountryDefault": false,
          "name": "Copán"
        },
        {
          "key": 1519,
          "isCountryDefault": false,
          "name": "Cortés"
        },
        {
          "key": 1520,
          "isCountryDefault": false,
          "name": "El Paraíso"
        },
        {
          "key": 1521,
          "isCountryDefault": false,
          "name": "Francisco Morazán"
        },
        {
          "key": 1522,
          "isCountryDefault": false,
          "name": "Gracias a Dios"
        },
        {
          "key": 1523,
          "isCountryDefault": false,
          "name": "Intibucá"
        },
        {
          "key": 1524,
          "isCountryDefault": false,
          "name": "Islas de la Bahía"
        },
        {
          "key": 1525,
          "isCountryDefault": false,
          "name": "La Paz"
        },
        {
          "key": 1526,
          "isCountryDefault": false,
          "name": "Lempira"
        },
        {
          "key": 1527,
          "isCountryDefault": false,
          "name": "Ocotepeque"
        },
        {
          "key": 1528,
          "isCountryDefault": false,
          "name": "Olancho"
        },
        {
          "key": 1529,
          "isCountryDefault": false,
          "name": "Santa Bárbara"
        },
        {
          "key": 1530,
          "isCountryDefault": false,
          "name": "Valle"
        },
        {
          "key": 1531,
          "isCountryDefault": false,
          "name": "Yoro"
        }
      ],
      "isEu": false
    },
    {
      "key": "hongKong",
      "name": "Hongkong",
      "code": "HK",
      "region": "Asien",
      "states": [
        {
          "key": 22,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2393,
          "isCountryDefault": false,
          "name": "Hong Kong"
        }
      ],
      "isEu": false
    },
    {
      "key": "india",
      "name": "Indien",
      "code": "IN",
      "region": "Asien",
      "states": [
        {
          "key": 28,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1575,
          "isCountryDefault": false,
          "name": "Andhra Pradesh"
        },
        {
          "key": 1576,
          "isCountryDefault": false,
          "name": "Arunachal Pradesh"
        },
        {
          "key": 1577,
          "isCountryDefault": false,
          "name": "Assam"
        },
        {
          "key": 1578,
          "isCountryDefault": false,
          "name": "Bihar"
        },
        {
          "key": 1579,
          "isCountryDefault": false,
          "name": "Chhattisgarh"
        },
        {
          "key": 1580,
          "isCountryDefault": false,
          "name": "Goa"
        },
        {
          "key": 1581,
          "isCountryDefault": false,
          "name": "Gujarat"
        },
        {
          "key": 1582,
          "isCountryDefault": false,
          "name": "Haryana"
        },
        {
          "key": 1583,
          "isCountryDefault": false,
          "name": "Himachal Pradesh"
        },
        {
          "key": 1584,
          "isCountryDefault": false,
          "name": "Jammu and Kashmir"
        },
        {
          "key": 1585,
          "isCountryDefault": false,
          "name": "Jharkhand"
        },
        {
          "key": 1586,
          "isCountryDefault": false,
          "name": "Karnataka"
        },
        {
          "key": 1587,
          "isCountryDefault": false,
          "name": "Kerala"
        },
        {
          "key": 1588,
          "isCountryDefault": false,
          "name": "Madhya Pradesh"
        },
        {
          "key": 1589,
          "isCountryDefault": false,
          "name": "Maharashtra"
        },
        {
          "key": 1590,
          "isCountryDefault": false,
          "name": "Manipur"
        },
        {
          "key": 1591,
          "isCountryDefault": false,
          "name": "Meghalaya"
        },
        {
          "key": 1592,
          "isCountryDefault": false,
          "name": "Mizoram"
        },
        {
          "key": 1593,
          "isCountryDefault": false,
          "name": "Nagaland"
        },
        {
          "key": 1594,
          "isCountryDefault": false,
          "name": "Orissa"
        },
        {
          "key": 1595,
          "isCountryDefault": false,
          "name": "Punjab"
        },
        {
          "key": 1596,
          "isCountryDefault": false,
          "name": "Rajasthan"
        },
        {
          "key": 1597,
          "isCountryDefault": false,
          "name": "Sikkim"
        },
        {
          "key": 1598,
          "isCountryDefault": false,
          "name": "Tamil Nadu"
        },
        {
          "key": 1599,
          "isCountryDefault": false,
          "name": "Tripura"
        },
        {
          "key": 1600,
          "isCountryDefault": false,
          "name": "Uttar Pradesh"
        },
        {
          "key": 1601,
          "isCountryDefault": false,
          "name": "Uttarakhand"
        },
        {
          "key": 1602,
          "isCountryDefault": false,
          "name": "West Bengal"
        }
      ],
      "isEu": false
    },
    {
      "key": "indonesia",
      "name": "Indonesien",
      "code": "ID",
      "region": "Asien",
      "states": [
        {
          "key": 25,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1603,
          "isCountryDefault": false,
          "name": "Aceh"
        },
        {
          "key": 1619,
          "isCountryDefault": false,
          "name": "Bali"
        },
        {
          "key": 1611,
          "isCountryDefault": false,
          "name": "Bangka–Belitung Islands"
        },
        {
          "key": 1613,
          "isCountryDefault": false,
          "name": "Banten"
        },
        {
          "key": 1609,
          "isCountryDefault": false,
          "name": "Bengkulu"
        },
        {
          "key": 1616,
          "isCountryDefault": false,
          "name": "Central Java"
        },
        {
          "key": 1623,
          "isCountryDefault": false,
          "name": "Central Kalimantan"
        },
        {
          "key": 1627,
          "isCountryDefault": false,
          "name": "Central Sulawesi"
        },
        {
          "key": 1618,
          "isCountryDefault": false,
          "name": "East Java"
        },
        {
          "key": 4058,
          "isCountryDefault": false,
          "name": "East Kalimantan"
        },
        {
          "key": 1621,
          "isCountryDefault": false,
          "name": "East Nusa Tenggara"
        },
        {
          "key": 1629,
          "isCountryDefault": false,
          "name": "Gorontalo"
        },
        {
          "key": 1614,
          "isCountryDefault": false,
          "name": "Jakarta"
        },
        {
          "key": 1608,
          "isCountryDefault": false,
          "name": "Jambi"
        },
        {
          "key": 1612,
          "isCountryDefault": false,
          "name": "Lampung"
        },
        {
          "key": 1632,
          "isCountryDefault": false,
          "name": "Maluku"
        },
        {
          "key": 1631,
          "isCountryDefault": false,
          "name": "North Maluku"
        },
        {
          "key": 1630,
          "isCountryDefault": false,
          "name": "North Sulawesi"
        },
        {
          "key": 1604,
          "isCountryDefault": false,
          "name": "North Sumatra"
        },
        {
          "key": 3941,
          "isCountryDefault": false,
          "name": "Papua"
        },
        {
          "key": 1606,
          "isCountryDefault": false,
          "name": "Riau"
        },
        {
          "key": 1607,
          "isCountryDefault": false,
          "name": "Riau Islands Province"
        },
        {
          "key": 1628,
          "isCountryDefault": false,
          "name": "South East Sulawesi"
        },
        {
          "key": 1624,
          "isCountryDefault": false,
          "name": "South Kalimantan"
        },
        {
          "key": 1626,
          "isCountryDefault": false,
          "name": "South Sulawesi"
        },
        {
          "key": 1610,
          "isCountryDefault": false,
          "name": "South Sumatra"
        },
        {
          "key": 1615,
          "isCountryDefault": false,
          "name": "West Java"
        },
        {
          "key": 1622,
          "isCountryDefault": false,
          "name": "West Kalimantan"
        },
        {
          "key": 1620,
          "isCountryDefault": false,
          "name": "West Nusa Tenggara"
        },
        {
          "key": 3940,
          "isCountryDefault": false,
          "name": "West Papua"
        },
        {
          "key": 1625,
          "isCountryDefault": false,
          "name": "West Sulawesi"
        },
        {
          "key": 1605,
          "isCountryDefault": false,
          "name": "West Sumatra"
        },
        {
          "key": 1617,
          "isCountryDefault": false,
          "name": "Yogyakarta"
        }
      ],
      "isEu": false
    },
    {
      "key": "iraq",
      "name": "Irak",
      "code": "IQ",
      "region": "Naher und Mittlerer Osten",
      "states": [
        {
          "key": 160,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1677,
          "isCountryDefault": false,
          "name": "Al-Anbar"
        },
        {
          "key": 1670,
          "isCountryDefault": false,
          "name": "Al-Basrah"
        },
        {
          "key": 1672,
          "isCountryDefault": false,
          "name": "Al-Muthannā"
        },
        {
          "key": 1673,
          "isCountryDefault": false,
          "name": "Al-Qādisiyyah"
        },
        {
          "key": 1676,
          "isCountryDefault": false,
          "name": "An-Najaf"
        },
        {
          "key": 1680,
          "isCountryDefault": false,
          "name": "Arbīl"
        },
        {
          "key": 1682,
          "isCountryDefault": false,
          "name": "As-Sulaymāniyyah"
        },
        {
          "key": 1674,
          "isCountryDefault": false,
          "name": "Bābil (Babylon)"
        },
        {
          "key": 1665,
          "isCountryDefault": false,
          "name": "Baghdād"
        },
        {
          "key": 1671,
          "isCountryDefault": false,
          "name": "Dhī Qār"
        },
        {
          "key": 1667,
          "isCountryDefault": false,
          "name": "Diyālā"
        },
        {
          "key": 1679,
          "isCountryDefault": false,
          "name": "Duhok"
        },
        {
          "key": 1675,
          "isCountryDefault": false,
          "name": "Karbalā'"
        },
        {
          "key": 1681,
          "isCountryDefault": false,
          "name": "Kirkuk (or At-Ta'mim)"
        },
        {
          "key": 1669,
          "isCountryDefault": false,
          "name": "Maysān"
        },
        {
          "key": 1678,
          "isCountryDefault": false,
          "name": "Nīnawā (Nineveh)"
        },
        {
          "key": 1666,
          "isCountryDefault": false,
          "name": "Salāh ad-Dīn"
        },
        {
          "key": 1668,
          "isCountryDefault": false,
          "name": "Wāsit"
        }
      ],
      "isEu": false
    },
    {
      "key": "iran",
      "name": "Iran",
      "code": "IR",
      "region": "Naher und Mittlerer Osten",
      "states": [
        {
          "key": 161,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1633,
          "isCountryDefault": false,
          "name": "Alborz"
        },
        {
          "key": 1634,
          "isCountryDefault": false,
          "name": "Ardabil"
        },
        {
          "key": 1635,
          "isCountryDefault": false,
          "name": "Bushehr"
        },
        {
          "key": 1636,
          "isCountryDefault": false,
          "name": "Chaharmahal and Bakhtiari"
        },
        {
          "key": 1637,
          "isCountryDefault": false,
          "name": "East Azerbaijan"
        },
        {
          "key": 1638,
          "isCountryDefault": false,
          "name": "Esfahan"
        },
        {
          "key": 1639,
          "isCountryDefault": false,
          "name": "Fars"
        },
        {
          "key": 1640,
          "isCountryDefault": false,
          "name": "Gilan"
        },
        {
          "key": 1641,
          "isCountryDefault": false,
          "name": "Golestan"
        },
        {
          "key": 1642,
          "isCountryDefault": false,
          "name": "Hamadan"
        },
        {
          "key": 1643,
          "isCountryDefault": false,
          "name": "Hormozgan"
        },
        {
          "key": 1644,
          "isCountryDefault": false,
          "name": "Ilam"
        },
        {
          "key": 1645,
          "isCountryDefault": false,
          "name": "Kerman"
        },
        {
          "key": 1646,
          "isCountryDefault": false,
          "name": "Kermanshah"
        },
        {
          "key": 1647,
          "isCountryDefault": false,
          "name": "Khuzestan"
        },
        {
          "key": 1648,
          "isCountryDefault": false,
          "name": "Kohgeluyeh and Boyer-Ahmad"
        },
        {
          "key": 1649,
          "isCountryDefault": false,
          "name": "Kordestan"
        },
        {
          "key": 1650,
          "isCountryDefault": false,
          "name": "Lorestan"
        },
        {
          "key": 1651,
          "isCountryDefault": false,
          "name": "Markazi"
        },
        {
          "key": 1652,
          "isCountryDefault": false,
          "name": "Mazandaran"
        },
        {
          "key": 1653,
          "isCountryDefault": false,
          "name": "North Khorasan"
        },
        {
          "key": 1654,
          "isCountryDefault": false,
          "name": "Qazvin"
        },
        {
          "key": 1655,
          "isCountryDefault": false,
          "name": "Qom"
        },
        {
          "key": 1656,
          "isCountryDefault": false,
          "name": "Razavi Khorasan"
        },
        {
          "key": 1657,
          "isCountryDefault": false,
          "name": "Semnan"
        },
        {
          "key": 1658,
          "isCountryDefault": false,
          "name": "Sistan and Baluchestan"
        },
        {
          "key": 1659,
          "isCountryDefault": false,
          "name": "South Khorasan"
        },
        {
          "key": 1660,
          "isCountryDefault": false,
          "name": "Tehran"
        },
        {
          "key": 1662,
          "isCountryDefault": false,
          "name": "West Azerbaijan"
        },
        {
          "key": 1663,
          "isCountryDefault": false,
          "name": "Yazd"
        },
        {
          "key": 1664,
          "isCountryDefault": false,
          "name": "Zanjan"
        }
      ],
      "isEu": false
    },
    {
      "key": "ireland",
      "name": "Irland",
      "code": "IE",
      "region": "Europa",
      "states": [
        {
          "key": 26,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3741,
          "isCountryDefault": false,
          "name": "Antrim"
        },
        {
          "key": 3742,
          "isCountryDefault": false,
          "name": "Armagh"
        },
        {
          "key": 3713,
          "isCountryDefault": false,
          "name": "Carlow"
        },
        {
          "key": 3718,
          "isCountryDefault": false,
          "name": "Cavan"
        },
        {
          "key": 3733,
          "isCountryDefault": false,
          "name": "Clare"
        },
        {
          "key": 3727,
          "isCountryDefault": false,
          "name": "Cork"
        },
        {
          "key": 3740,
          "isCountryDefault": false,
          "name": "Donegal"
        },
        {
          "key": 3743,
          "isCountryDefault": false,
          "name": "Down"
        },
        {
          "key": 1683,
          "isCountryDefault": false,
          "name": "Dublin"
        },
        {
          "key": 1685,
          "isCountryDefault": false,
          "name": "Dún Laoghaire-Rathdown"
        },
        {
          "key": 3744,
          "isCountryDefault": false,
          "name": "Fermanagh"
        },
        {
          "key": 1686,
          "isCountryDefault": false,
          "name": "Fingal"
        },
        {
          "key": 3734,
          "isCountryDefault": false,
          "name": "Galway"
        },
        {
          "key": 3728,
          "isCountryDefault": false,
          "name": "Kerry"
        },
        {
          "key": 3714,
          "isCountryDefault": false,
          "name": "Kildare"
        },
        {
          "key": 3723,
          "isCountryDefault": false,
          "name": "Kilkenny"
        },
        {
          "key": 3722,
          "isCountryDefault": false,
          "name": "Laois"
        },
        {
          "key": 3739,
          "isCountryDefault": false,
          "name": "Leitrim"
        },
        {
          "key": 3729,
          "isCountryDefault": false,
          "name": "Limerick"
        },
        {
          "key": 3745,
          "isCountryDefault": false,
          "name": "Londonderry"
        },
        {
          "key": 3719,
          "isCountryDefault": false,
          "name": "Longford"
        },
        {
          "key": 3716,
          "isCountryDefault": false,
          "name": "Louth"
        },
        {
          "key": 3736,
          "isCountryDefault": false,
          "name": "Mayo"
        },
        {
          "key": 3715,
          "isCountryDefault": false,
          "name": "Meath"
        },
        {
          "key": 3717,
          "isCountryDefault": false,
          "name": "Monaghan"
        },
        {
          "key": 3721,
          "isCountryDefault": false,
          "name": "Offaly"
        },
        {
          "key": 3737,
          "isCountryDefault": false,
          "name": "Roscommon"
        },
        {
          "key": 3738,
          "isCountryDefault": false,
          "name": "Sligo"
        },
        {
          "key": 1684,
          "isCountryDefault": false,
          "name": "South Dublin"
        },
        {
          "key": 3731,
          "isCountryDefault": false,
          "name": "Tipperary"
        },
        {
          "key": 3746,
          "isCountryDefault": false,
          "name": "Tyrone"
        },
        {
          "key": 3725,
          "isCountryDefault": false,
          "name": "Waterford"
        },
        {
          "key": 3720,
          "isCountryDefault": false,
          "name": "Westmeath"
        },
        {
          "key": 3712,
          "isCountryDefault": false,
          "name": "Wexford"
        },
        {
          "key": 3711,
          "isCountryDefault": false,
          "name": "Wicklow"
        }
      ],
      "isEu": true
    },
    {
      "key": "iceland",
      "name": "Island",
      "code": "IS",
      "region": "Europa",
      "states": [
        {
          "key": 29,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1552,
          "isCountryDefault": false,
          "name": "Árnessýsla"
        },
        {
          "key": 1553,
          "isCountryDefault": false,
          "name": "Austur-Barðastrandarsýsla"
        },
        {
          "key": 1554,
          "isCountryDefault": false,
          "name": "Austur-Húnavatnssýsla"
        },
        {
          "key": 1555,
          "isCountryDefault": false,
          "name": "Austur-Skaftafellssýsla"
        },
        {
          "key": 1556,
          "isCountryDefault": false,
          "name": "Borgarfjarðarsýsla"
        },
        {
          "key": 1557,
          "isCountryDefault": false,
          "name": "Dalasýsla"
        },
        {
          "key": 1558,
          "isCountryDefault": false,
          "name": "Eyjafjarðarsýsla"
        },
        {
          "key": 1559,
          "isCountryDefault": false,
          "name": "Gullbringusýsla"
        },
        {
          "key": 1560,
          "isCountryDefault": false,
          "name": "Kjósarsýsla"
        },
        {
          "key": 1561,
          "isCountryDefault": false,
          "name": "Mýrasýsla"
        },
        {
          "key": 1562,
          "isCountryDefault": false,
          "name": "Norður-Ísafjarðarsýsla"
        },
        {
          "key": 1563,
          "isCountryDefault": false,
          "name": "Norður-Múlasýsla"
        },
        {
          "key": 1564,
          "isCountryDefault": false,
          "name": "Norður-Þingeyjarsýsla"
        },
        {
          "key": 1565,
          "isCountryDefault": false,
          "name": "Rangárvallasýsla"
        },
        {
          "key": 1566,
          "isCountryDefault": false,
          "name": "Skagafjarðarsýsla"
        },
        {
          "key": 1567,
          "isCountryDefault": false,
          "name": "Snæfellsnes-og Hnappadalssýsla"
        },
        {
          "key": 1568,
          "isCountryDefault": false,
          "name": "Strandasýsla"
        },
        {
          "key": 1569,
          "isCountryDefault": false,
          "name": "Suður-Múlasýsla"
        },
        {
          "key": 1570,
          "isCountryDefault": false,
          "name": "Suður-Þingeyjarsýsla"
        },
        {
          "key": 1571,
          "isCountryDefault": false,
          "name": "Vestur-Barðastrandarsýsla"
        },
        {
          "key": 1572,
          "isCountryDefault": false,
          "name": "Vestur-Húnavatnssýsla"
        },
        {
          "key": 1573,
          "isCountryDefault": false,
          "name": "Vestur-Ísafjarðarsýsla"
        },
        {
          "key": 1574,
          "isCountryDefault": false,
          "name": "Vestur-Skaftafellssýsla"
        }
      ],
      "isEu": false
    },
    {
      "key": "isleOfMan",
      "name": "Isle Of Man",
      "code": "IM",
      "region": "Europa",
      "states": [
        {
          "key": 245,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2423,
          "isCountryDefault": false,
          "name": "Isle Of Man"
        }
      ],
      "isEu": false
    },
    {
      "key": "israel",
      "name": "Israel",
      "code": "IL",
      "region": "Naher und Mittlerer Osten",
      "states": [
        {
          "key": 27,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1690,
          "isCountryDefault": false,
          "name": "Central District (Mehoz HaMerkaz)"
        },
        {
          "key": 1689,
          "isCountryDefault": false,
          "name": "Haifa District (Mehoz Heifa)"
        },
        {
          "key": 1687,
          "isCountryDefault": false,
          "name": "Jerusalem District (Mehoz Yerushalayim)"
        },
        {
          "key": 1693,
          "isCountryDefault": false,
          "name": "Judea and Samaria Area (Ezor Yehuda VeShomron)"
        },
        {
          "key": 1688,
          "isCountryDefault": false,
          "name": "Northern District (Mehoz HaTzafon)"
        },
        {
          "key": 1692,
          "isCountryDefault": false,
          "name": "Southern District (Mehoz HaDarom)"
        },
        {
          "key": 1691,
          "isCountryDefault": false,
          "name": "Tel Aviv District (Mehoz Tel Aviv)"
        }
      ],
      "isEu": false
    },
    {
      "key": "italy",
      "name": "Italien",
      "code": "IT",
      "region": "Europa",
      "states": [
        {
          "key": 30,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1699,
          "isCountryDefault": false,
          "name": "Agrigento"
        },
        {
          "key": 1700,
          "isCountryDefault": false,
          "name": "Alessandria"
        },
        {
          "key": 1701,
          "isCountryDefault": false,
          "name": "Ancona"
        },
        {
          "key": 1702,
          "isCountryDefault": false,
          "name": "Aosta"
        },
        {
          "key": 1703,
          "isCountryDefault": false,
          "name": "Arezzo"
        },
        {
          "key": 1704,
          "isCountryDefault": false,
          "name": "Ascoli Piceno"
        },
        {
          "key": 1705,
          "isCountryDefault": false,
          "name": "Asti"
        },
        {
          "key": 1706,
          "isCountryDefault": false,
          "name": "Avellino"
        },
        {
          "key": 1707,
          "isCountryDefault": false,
          "name": "Bari"
        },
        {
          "key": 1708,
          "isCountryDefault": false,
          "name": "Barletta-Andria-Trani"
        },
        {
          "key": 1709,
          "isCountryDefault": false,
          "name": "Belluno"
        },
        {
          "key": 1711,
          "isCountryDefault": false,
          "name": "Benevento"
        },
        {
          "key": 1710,
          "isCountryDefault": false,
          "name": "Bergamo"
        },
        {
          "key": 1712,
          "isCountryDefault": false,
          "name": "Biella"
        },
        {
          "key": 1713,
          "isCountryDefault": false,
          "name": "Bologna"
        },
        {
          "key": 3747,
          "isCountryDefault": false,
          "name": "Bolzano"
        },
        {
          "key": 3748,
          "isCountryDefault": false,
          "name": "Brescia"
        },
        {
          "key": 3749,
          "isCountryDefault": false,
          "name": "Brindisi"
        },
        {
          "key": 3750,
          "isCountryDefault": false,
          "name": "Cagliari"
        },
        {
          "key": 3751,
          "isCountryDefault": false,
          "name": "Caltanissetta"
        },
        {
          "key": 3752,
          "isCountryDefault": false,
          "name": "Campobasso"
        },
        {
          "key": 3753,
          "isCountryDefault": false,
          "name": "Carbonia-Iglesias"
        },
        {
          "key": 3754,
          "isCountryDefault": false,
          "name": "Caserta"
        },
        {
          "key": 3755,
          "isCountryDefault": false,
          "name": "Catania"
        },
        {
          "key": 3756,
          "isCountryDefault": false,
          "name": "Catanzaro"
        },
        {
          "key": 3757,
          "isCountryDefault": false,
          "name": "Chieti"
        },
        {
          "key": 3758,
          "isCountryDefault": false,
          "name": "Como"
        },
        {
          "key": 3759,
          "isCountryDefault": false,
          "name": "Cosenza"
        },
        {
          "key": 3760,
          "isCountryDefault": false,
          "name": "Cremona"
        },
        {
          "key": 3761,
          "isCountryDefault": false,
          "name": "Crotone"
        },
        {
          "key": 3763,
          "isCountryDefault": false,
          "name": "Cuneo"
        },
        {
          "key": 3764,
          "isCountryDefault": false,
          "name": "Enna"
        },
        {
          "key": 3765,
          "isCountryDefault": false,
          "name": "Fermo"
        },
        {
          "key": 3766,
          "isCountryDefault": false,
          "name": "Ferrara"
        },
        {
          "key": 3767,
          "isCountryDefault": false,
          "name": "Florence"
        },
        {
          "key": 3768,
          "isCountryDefault": false,
          "name": "Foggia"
        },
        {
          "key": 3769,
          "isCountryDefault": false,
          "name": "Forlì-Cesena"
        },
        {
          "key": 3770,
          "isCountryDefault": false,
          "name": "Frosinone"
        },
        {
          "key": 3771,
          "isCountryDefault": false,
          "name": "Genoa"
        },
        {
          "key": 3772,
          "isCountryDefault": false,
          "name": "Gorizia"
        },
        {
          "key": 3773,
          "isCountryDefault": false,
          "name": "Grosseto"
        },
        {
          "key": 3774,
          "isCountryDefault": false,
          "name": "Imperia"
        },
        {
          "key": 3775,
          "isCountryDefault": false,
          "name": "Isernia"
        },
        {
          "key": 3776,
          "isCountryDefault": false,
          "name": "La Spezia"
        },
        {
          "key": 3777,
          "isCountryDefault": false,
          "name": "L'Aquila"
        },
        {
          "key": 3778,
          "isCountryDefault": false,
          "name": "Latina"
        },
        {
          "key": 3779,
          "isCountryDefault": false,
          "name": "Lecce"
        },
        {
          "key": 3780,
          "isCountryDefault": false,
          "name": "Lecco"
        },
        {
          "key": 3781,
          "isCountryDefault": false,
          "name": "Livorno"
        },
        {
          "key": 3782,
          "isCountryDefault": false,
          "name": "Lodi"
        },
        {
          "key": 3783,
          "isCountryDefault": false,
          "name": "Lucca"
        },
        {
          "key": 3784,
          "isCountryDefault": false,
          "name": "Macerata"
        },
        {
          "key": 3785,
          "isCountryDefault": false,
          "name": "Mantua"
        },
        {
          "key": 3786,
          "isCountryDefault": false,
          "name": "Massa and Carrara"
        },
        {
          "key": 3787,
          "isCountryDefault": false,
          "name": "Matera"
        },
        {
          "key": 3788,
          "isCountryDefault": false,
          "name": "Medio Campidano"
        },
        {
          "key": 3789,
          "isCountryDefault": false,
          "name": "Messina"
        },
        {
          "key": 3790,
          "isCountryDefault": false,
          "name": "Milan"
        },
        {
          "key": 3791,
          "isCountryDefault": false,
          "name": "Modena"
        },
        {
          "key": 3792,
          "isCountryDefault": false,
          "name": "Monza and Brianza"
        },
        {
          "key": 3793,
          "isCountryDefault": false,
          "name": "Naples"
        },
        {
          "key": 3794,
          "isCountryDefault": false,
          "name": "Novara"
        },
        {
          "key": 3795,
          "isCountryDefault": false,
          "name": "Nuoro"
        },
        {
          "key": 3796,
          "isCountryDefault": false,
          "name": "Ogliastra"
        },
        {
          "key": 3797,
          "isCountryDefault": false,
          "name": "Olbia-Tempio"
        },
        {
          "key": 3798,
          "isCountryDefault": false,
          "name": "Oristano"
        },
        {
          "key": 3799,
          "isCountryDefault": false,
          "name": "Padua"
        },
        {
          "key": 3800,
          "isCountryDefault": false,
          "name": "Palermo"
        },
        {
          "key": 3801,
          "isCountryDefault": false,
          "name": "Parma"
        },
        {
          "key": 3802,
          "isCountryDefault": false,
          "name": "Pavia"
        },
        {
          "key": 3803,
          "isCountryDefault": false,
          "name": "Perugia"
        },
        {
          "key": 3804,
          "isCountryDefault": false,
          "name": "Pesaro and Urbino"
        },
        {
          "key": 3805,
          "isCountryDefault": false,
          "name": "Pescara"
        },
        {
          "key": 3806,
          "isCountryDefault": false,
          "name": "Piacenza"
        },
        {
          "key": 3807,
          "isCountryDefault": false,
          "name": "Pisa"
        },
        {
          "key": 3808,
          "isCountryDefault": false,
          "name": "Pistoia"
        },
        {
          "key": 3809,
          "isCountryDefault": false,
          "name": "Pordenone"
        },
        {
          "key": 3810,
          "isCountryDefault": false,
          "name": "Potenza"
        },
        {
          "key": 3811,
          "isCountryDefault": false,
          "name": "Prato"
        },
        {
          "key": 3812,
          "isCountryDefault": false,
          "name": "Ragusa"
        },
        {
          "key": 3813,
          "isCountryDefault": false,
          "name": "Ravenna"
        },
        {
          "key": 3814,
          "isCountryDefault": false,
          "name": "Reggio Calabria"
        },
        {
          "key": 3815,
          "isCountryDefault": false,
          "name": "Reggio Emilia"
        },
        {
          "key": 3816,
          "isCountryDefault": false,
          "name": "Rieti"
        },
        {
          "key": 3817,
          "isCountryDefault": false,
          "name": "Rimini"
        },
        {
          "key": 3818,
          "isCountryDefault": false,
          "name": "Rome"
        },
        {
          "key": 3819,
          "isCountryDefault": false,
          "name": "Rovigo"
        },
        {
          "key": 3820,
          "isCountryDefault": false,
          "name": "Salerno"
        },
        {
          "key": 3821,
          "isCountryDefault": false,
          "name": "Sassari"
        },
        {
          "key": 3822,
          "isCountryDefault": false,
          "name": "Savona"
        },
        {
          "key": 3823,
          "isCountryDefault": false,
          "name": "Siena"
        },
        {
          "key": 3824,
          "isCountryDefault": false,
          "name": "Sondrio"
        },
        {
          "key": 3825,
          "isCountryDefault": false,
          "name": "Syracuse"
        },
        {
          "key": 3826,
          "isCountryDefault": false,
          "name": "Taranto"
        },
        {
          "key": 3827,
          "isCountryDefault": false,
          "name": "Teramo"
        },
        {
          "key": 3828,
          "isCountryDefault": false,
          "name": "Terni"
        },
        {
          "key": 3829,
          "isCountryDefault": false,
          "name": "Trapani"
        },
        {
          "key": 3830,
          "isCountryDefault": false,
          "name": "Trento"
        },
        {
          "key": 3831,
          "isCountryDefault": false,
          "name": "Treviso"
        },
        {
          "key": 3832,
          "isCountryDefault": false,
          "name": "Trieste"
        },
        {
          "key": 3834,
          "isCountryDefault": false,
          "name": "Turin"
        },
        {
          "key": 3833,
          "isCountryDefault": false,
          "name": "Udine"
        },
        {
          "key": 3835,
          "isCountryDefault": false,
          "name": "Varese"
        },
        {
          "key": 3836,
          "isCountryDefault": false,
          "name": "Venice"
        },
        {
          "key": 3837,
          "isCountryDefault": false,
          "name": "Verbano-Cusio-Ossola"
        },
        {
          "key": 3838,
          "isCountryDefault": false,
          "name": "Vercelli"
        },
        {
          "key": 3839,
          "isCountryDefault": false,
          "name": "Verona"
        },
        {
          "key": 3840,
          "isCountryDefault": false,
          "name": "Vibo Valentia"
        },
        {
          "key": 3841,
          "isCountryDefault": false,
          "name": "Vicenza"
        },
        {
          "key": 3842,
          "isCountryDefault": false,
          "name": "Viterbo"
        }
      ],
      "isEu": true
    },
    {
      "key": "jamaica",
      "name": "Jamaika",
      "code": "JM",
      "region": "Nordamerika",
      "states": [
        {
          "key": 31,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1738,
          "isCountryDefault": false,
          "name": "Clarendon"
        },
        {
          "key": 1733,
          "isCountryDefault": false,
          "name": "Hanover"
        },
        {
          "key": 1743,
          "isCountryDefault": false,
          "name": "Kingston (parish)"
        },
        {
          "key": 1739,
          "isCountryDefault": false,
          "name": "Manchester"
        },
        {
          "key": 1744,
          "isCountryDefault": false,
          "name": "Portland"
        },
        {
          "key": 1745,
          "isCountryDefault": false,
          "name": "Saint Andrew"
        },
        {
          "key": 1740,
          "isCountryDefault": false,
          "name": "Saint Ann"
        },
        {
          "key": 1741,
          "isCountryDefault": false,
          "name": "Saint Catherine"
        },
        {
          "key": 1734,
          "isCountryDefault": false,
          "name": "Saint Elizabeth"
        },
        {
          "key": 1735,
          "isCountryDefault": false,
          "name": "Saint James"
        },
        {
          "key": 1742,
          "isCountryDefault": false,
          "name": "Saint Mary"
        },
        {
          "key": 1746,
          "isCountryDefault": false,
          "name": "Saint Thomas"
        },
        {
          "key": 1736,
          "isCountryDefault": false,
          "name": "Trelawny"
        },
        {
          "key": 1737,
          "isCountryDefault": false,
          "name": "Westmoreland"
        }
      ],
      "isEu": false
    },
    {
      "key": "japan",
      "name": "Japan",
      "code": "JP",
      "region": "Asien",
      "states": [
        {
          "key": 33,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1769,
          "isCountryDefault": false,
          "name": "Aichi"
        },
        {
          "key": 1751,
          "isCountryDefault": false,
          "name": "Akita"
        },
        {
          "key": 1748,
          "isCountryDefault": false,
          "name": "Aomori"
        },
        {
          "key": 1758,
          "isCountryDefault": false,
          "name": "Chiba"
        },
        {
          "key": 1784,
          "isCountryDefault": false,
          "name": "Ehime"
        },
        {
          "key": 1764,
          "isCountryDefault": false,
          "name": "Fukui"
        },
        {
          "key": 1786,
          "isCountryDefault": false,
          "name": "Fukuoka"
        },
        {
          "key": 1753,
          "isCountryDefault": false,
          "name": "Fukushima"
        },
        {
          "key": 1767,
          "isCountryDefault": false,
          "name": "Gifu"
        },
        {
          "key": 1756,
          "isCountryDefault": false,
          "name": "Gunma"
        },
        {
          "key": 1780,
          "isCountryDefault": false,
          "name": "Hiroshima"
        },
        {
          "key": 1747,
          "isCountryDefault": false,
          "name": "Hokkaidō"
        },
        {
          "key": 1774,
          "isCountryDefault": false,
          "name": "Hyōgo"
        },
        {
          "key": 1754,
          "isCountryDefault": false,
          "name": "Ibaraki"
        },
        {
          "key": 1763,
          "isCountryDefault": false,
          "name": "Ishikawa"
        },
        {
          "key": 1749,
          "isCountryDefault": false,
          "name": "Iwate"
        },
        {
          "key": 1783,
          "isCountryDefault": false,
          "name": "Kagawa"
        },
        {
          "key": 1792,
          "isCountryDefault": false,
          "name": "Kagoshima"
        },
        {
          "key": 1760,
          "isCountryDefault": false,
          "name": "Kanagawa"
        },
        {
          "key": 1785,
          "isCountryDefault": false,
          "name": "Kōchi"
        },
        {
          "key": 1789,
          "isCountryDefault": false,
          "name": "Kumamoto"
        },
        {
          "key": 1772,
          "isCountryDefault": false,
          "name": "Kyōto"
        },
        {
          "key": 1770,
          "isCountryDefault": false,
          "name": "Mie"
        },
        {
          "key": 1750,
          "isCountryDefault": false,
          "name": "Miyagi"
        },
        {
          "key": 1791,
          "isCountryDefault": false,
          "name": "Miyazaki"
        },
        {
          "key": 1766,
          "isCountryDefault": false,
          "name": "Nagano"
        },
        {
          "key": 1788,
          "isCountryDefault": false,
          "name": "Nagasaki"
        },
        {
          "key": 1775,
          "isCountryDefault": false,
          "name": "Nara"
        },
        {
          "key": 1761,
          "isCountryDefault": false,
          "name": "Niigata"
        },
        {
          "key": 1790,
          "isCountryDefault": false,
          "name": "Ōita"
        },
        {
          "key": 1779,
          "isCountryDefault": false,
          "name": "Okayama"
        },
        {
          "key": 1793,
          "isCountryDefault": false,
          "name": "Okinawa"
        },
        {
          "key": 1773,
          "isCountryDefault": false,
          "name": "Ōsaka"
        },
        {
          "key": 1787,
          "isCountryDefault": false,
          "name": "Saga"
        },
        {
          "key": 1757,
          "isCountryDefault": false,
          "name": "Saitama"
        },
        {
          "key": 1771,
          "isCountryDefault": false,
          "name": "Shiga"
        },
        {
          "key": 1778,
          "isCountryDefault": false,
          "name": "Shimane"
        },
        {
          "key": 1768,
          "isCountryDefault": false,
          "name": "Shizuoka"
        },
        {
          "key": 1755,
          "isCountryDefault": false,
          "name": "Tochigi"
        },
        {
          "key": 1782,
          "isCountryDefault": false,
          "name": "Tokushima"
        },
        {
          "key": 1759,
          "isCountryDefault": false,
          "name": "Tōkyō"
        },
        {
          "key": 1777,
          "isCountryDefault": false,
          "name": "Tottori"
        },
        {
          "key": 1762,
          "isCountryDefault": false,
          "name": "Toyama"
        },
        {
          "key": 1776,
          "isCountryDefault": false,
          "name": "Wakayama"
        },
        {
          "key": 1752,
          "isCountryDefault": false,
          "name": "Yamagata"
        },
        {
          "key": 1781,
          "isCountryDefault": false,
          "name": "Yamaguchi"
        },
        {
          "key": 1765,
          "isCountryDefault": false,
          "name": "Yamanashi"
        }
      ],
      "isEu": false
    },
    {
      "key": "yemen",
      "name": "Jemen",
      "code": "YE",
      "region": "Naher und Mittlerer Osten",
      "states": [
        {
          "key": 234,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3673,
          "isCountryDefault": false,
          "name": "Abyan"
        },
        {
          "key": 3674,
          "isCountryDefault": false,
          "name": "Ad Dali'"
        },
        {
          "key": 3671,
          "isCountryDefault": false,
          "name": "'Adan"
        },
        {
          "key": 3675,
          "isCountryDefault": false,
          "name": "Al Bayda'"
        },
        {
          "key": 3676,
          "isCountryDefault": false,
          "name": "Al Hudaydah"
        },
        {
          "key": 3677,
          "isCountryDefault": false,
          "name": "Al Jawf"
        },
        {
          "key": 3678,
          "isCountryDefault": false,
          "name": "Al Mahrah"
        },
        {
          "key": 3679,
          "isCountryDefault": false,
          "name": "Al Mahwit"
        },
        {
          "key": 3689,
          "isCountryDefault": false,
          "name": "Amanat Al Asimah"
        },
        {
          "key": 3672,
          "isCountryDefault": false,
          "name": "'Amran"
        },
        {
          "key": 3681,
          "isCountryDefault": false,
          "name": "Dhamar"
        },
        {
          "key": 3682,
          "isCountryDefault": false,
          "name": "Hadhramaut"
        },
        {
          "key": 3683,
          "isCountryDefault": false,
          "name": "Hajjah"
        },
        {
          "key": 3684,
          "isCountryDefault": false,
          "name": "Ibb"
        },
        {
          "key": 3685,
          "isCountryDefault": false,
          "name": "Lahij"
        },
        {
          "key": 3686,
          "isCountryDefault": false,
          "name": "Ma'rib"
        },
        {
          "key": 3687,
          "isCountryDefault": false,
          "name": "Raymah"
        },
        {
          "key": 3688,
          "isCountryDefault": false,
          "name": "Saada"
        },
        {
          "key": 3680,
          "isCountryDefault": false,
          "name": "Sana'a"
        },
        {
          "key": 3690,
          "isCountryDefault": false,
          "name": "Shabwah"
        },
        {
          "key": 3691,
          "isCountryDefault": false,
          "name": "Ta'izz"
        }
      ],
      "isEu": false
    },
    {
      "key": "jersey",
      "name": "Jersey",
      "code": "JE",
      "region": "Europa",
      "states": [
        {
          "key": 243,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1794,
          "isCountryDefault": false,
          "name": "Grouville"
        },
        {
          "key": 1795,
          "isCountryDefault": false,
          "name": "Saint Brélade"
        },
        {
          "key": 1796,
          "isCountryDefault": false,
          "name": "Saint Clément"
        },
        {
          "key": 1797,
          "isCountryDefault": false,
          "name": "Saint Helier"
        },
        {
          "key": 1798,
          "isCountryDefault": false,
          "name": "Saint John"
        },
        {
          "key": 1799,
          "isCountryDefault": false,
          "name": "Saint Lawrence"
        },
        {
          "key": 1800,
          "isCountryDefault": false,
          "name": "Saint Martin"
        },
        {
          "key": 1801,
          "isCountryDefault": false,
          "name": "Saint Mary"
        },
        {
          "key": 1802,
          "isCountryDefault": false,
          "name": "Saint Ouen"
        },
        {
          "key": 1803,
          "isCountryDefault": false,
          "name": "Saint Peter"
        },
        {
          "key": 1804,
          "isCountryDefault": false,
          "name": "Saint Saviour"
        },
        {
          "key": 1805,
          "isCountryDefault": false,
          "name": "Trinity"
        }
      ],
      "isEu": false
    },
    {
      "key": "jordan",
      "name": "Jordanien",
      "code": "JO",
      "region": "Naher und Mittlerer Osten",
      "states": [
        {
          "key": 32,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1806,
          "isCountryDefault": false,
          "name": "Ajlun"
        },
        {
          "key": 1807,
          "isCountryDefault": false,
          "name": "Aqaba"
        },
        {
          "key": 1808,
          "isCountryDefault": false,
          "name": "Balqa"
        },
        {
          "key": 1810,
          "isCountryDefault": false,
          "name": "Irbed"
        },
        {
          "key": 1811,
          "isCountryDefault": false,
          "name": "Jerash"
        },
        {
          "key": 1812,
          "isCountryDefault": false,
          "name": "Karak"
        },
        {
          "key": 1813,
          "isCountryDefault": false,
          "name": "Ma'an"
        },
        {
          "key": 1814,
          "isCountryDefault": false,
          "name": "Madaba"
        },
        {
          "key": 1815,
          "isCountryDefault": false,
          "name": "Mafraq"
        },
        {
          "key": 1809,
          "isCountryDefault": false,
          "name": "Muhafazat al-Asima"
        },
        {
          "key": 1816,
          "isCountryDefault": false,
          "name": "Tafilah"
        },
        {
          "key": 1817,
          "isCountryDefault": false,
          "name": "Zarqa"
        }
      ],
      "isEu": false
    },
    {
      "key": "caymanIslands",
      "name": "Kaimaninseln",
      "code": "KY",
      "region": "Nordamerika",
      "states": [
        {
          "key": 170,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 815,
          "isCountryDefault": false,
          "name": "Cayman Brac"
        },
        {
          "key": 814,
          "isCountryDefault": false,
          "name": "Grand Cayman"
        },
        {
          "key": 816,
          "isCountryDefault": false,
          "name": "Little Cayman"
        }
      ],
      "isEu": false
    },
    {
      "key": "cambodia",
      "name": "Kambodscha",
      "code": "KH",
      "region": "Asien",
      "states": [
        {
          "key": 164,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 757,
          "isCountryDefault": false,
          "name": "Banteay Meanchey"
        },
        {
          "key": 758,
          "isCountryDefault": false,
          "name": "Battambang"
        },
        {
          "key": 759,
          "isCountryDefault": false,
          "name": "Kampong Cham"
        },
        {
          "key": 760,
          "isCountryDefault": false,
          "name": "Kampong Chhnang"
        },
        {
          "key": 761,
          "isCountryDefault": false,
          "name": "Kampong Speu"
        },
        {
          "key": 762,
          "isCountryDefault": false,
          "name": "Kampong Thom"
        },
        {
          "key": 763,
          "isCountryDefault": false,
          "name": "Kampot"
        },
        {
          "key": 764,
          "isCountryDefault": false,
          "name": "Kandal"
        },
        {
          "key": 766,
          "isCountryDefault": false,
          "name": "Kep"
        },
        {
          "key": 765,
          "isCountryDefault": false,
          "name": "Koh Kong"
        },
        {
          "key": 767,
          "isCountryDefault": false,
          "name": "Kratie"
        },
        {
          "key": 768,
          "isCountryDefault": false,
          "name": "Mondulkiri"
        },
        {
          "key": 769,
          "isCountryDefault": false,
          "name": "Oddar Meanchey"
        },
        {
          "key": 770,
          "isCountryDefault": false,
          "name": "Pailin"
        },
        {
          "key": 771,
          "isCountryDefault": false,
          "name": "Phnom Penh"
        },
        {
          "key": 772,
          "isCountryDefault": false,
          "name": "Preah Sihanouk"
        },
        {
          "key": 773,
          "isCountryDefault": false,
          "name": "Preah Vihear"
        },
        {
          "key": 775,
          "isCountryDefault": false,
          "name": "Prey Veng"
        },
        {
          "key": 774,
          "isCountryDefault": false,
          "name": "Pursat"
        },
        {
          "key": 776,
          "isCountryDefault": false,
          "name": "Ratanakiri"
        },
        {
          "key": 777,
          "isCountryDefault": false,
          "name": "Siem Reap"
        },
        {
          "key": 778,
          "isCountryDefault": false,
          "name": "Stung Treng"
        },
        {
          "key": 779,
          "isCountryDefault": false,
          "name": "Svay Rieng"
        },
        {
          "key": 780,
          "isCountryDefault": false,
          "name": "Takeo"
        }
      ],
      "isEu": false
    },
    {
      "key": "cameroon",
      "name": "Kamerun",
      "code": "CM",
      "region": "Afrika",
      "states": [
        {
          "key": 129,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 781,
          "isCountryDefault": false,
          "name": "Adamaoua"
        },
        {
          "key": 782,
          "isCountryDefault": false,
          "name": "Centre"
        },
        {
          "key": 783,
          "isCountryDefault": false,
          "name": "Est"
        },
        {
          "key": 784,
          "isCountryDefault": false,
          "name": "Extrême-Nord"
        },
        {
          "key": 785,
          "isCountryDefault": false,
          "name": "Littoral"
        },
        {
          "key": 786,
          "isCountryDefault": false,
          "name": "Nord"
        },
        {
          "key": 787,
          "isCountryDefault": false,
          "name": "Nord-Ouest"
        },
        {
          "key": 790,
          "isCountryDefault": false,
          "name": "Ouest"
        },
        {
          "key": 788,
          "isCountryDefault": false,
          "name": "Sud"
        },
        {
          "key": 789,
          "isCountryDefault": false,
          "name": "Sud-Ouest"
        }
      ],
      "isEu": false
    },
    {
      "key": "canada",
      "name": "Kanada",
      "code": "CA",
      "region": "Nordamerika",
      "states": [
        {
          "key": 7,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 791,
          "isCountryDefault": false,
          "name": "Alberta"
        },
        {
          "key": 792,
          "isCountryDefault": false,
          "name": "British Columbia"
        },
        {
          "key": 793,
          "isCountryDefault": false,
          "name": "Manitoba"
        },
        {
          "key": 794,
          "isCountryDefault": false,
          "name": "New Brunswick"
        },
        {
          "key": 795,
          "isCountryDefault": false,
          "name": "Newfoundland and Labrador"
        },
        {
          "key": 801,
          "isCountryDefault": false,
          "name": "Northwest Territories"
        },
        {
          "key": 796,
          "isCountryDefault": false,
          "name": "Nova Scotia"
        },
        {
          "key": 802,
          "isCountryDefault": false,
          "name": "Nunavut"
        },
        {
          "key": 797,
          "isCountryDefault": false,
          "name": "Ontario"
        },
        {
          "key": 798,
          "isCountryDefault": false,
          "name": "Prince Edward Island"
        },
        {
          "key": 799,
          "isCountryDefault": false,
          "name": "Quebec"
        },
        {
          "key": 800,
          "isCountryDefault": false,
          "name": "Saskatchewan"
        },
        {
          "key": 803,
          "isCountryDefault": false,
          "name": "Yukon"
        }
      ],
      "isEu": false
    },
    {
      "key": "capeVerde",
      "name": "Kap Verde",
      "code": "CV",
      "region": "Afrika",
      "states": [
        {
          "key": 131,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 808,
          "isCountryDefault": false,
          "name": "Boa Vista"
        },
        {
          "key": 804,
          "isCountryDefault": false,
          "name": "Brava"
        },
        {
          "key": 806,
          "isCountryDefault": false,
          "name": "Fogo"
        },
        {
          "key": 807,
          "isCountryDefault": false,
          "name": "Maio"
        },
        {
          "key": 809,
          "isCountryDefault": false,
          "name": "Sal"
        },
        {
          "key": 813,
          "isCountryDefault": false,
          "name": "Santa Luzia"
        },
        {
          "key": 805,
          "isCountryDefault": false,
          "name": "Santiago"
        },
        {
          "key": 812,
          "isCountryDefault": false,
          "name": "Santo Antão"
        },
        {
          "key": 810,
          "isCountryDefault": false,
          "name": "São Nicolau"
        },
        {
          "key": 811,
          "isCountryDefault": false,
          "name": "São Vicente"
        }
      ],
      "isEu": false
    },
    {
      "key": "kazakhstan",
      "name": "Kasachstan",
      "code": "KZ",
      "region": "Asien",
      "states": [
        {
          "key": 95,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1818,
          "isCountryDefault": false,
          "name": "Akmola"
        },
        {
          "key": 1819,
          "isCountryDefault": false,
          "name": "Aktobe"
        },
        {
          "key": 1820,
          "isCountryDefault": false,
          "name": "Almaty"
        },
        {
          "key": 1821,
          "isCountryDefault": false,
          "name": "Almaty Province"
        },
        {
          "key": 1822,
          "isCountryDefault": false,
          "name": "Astana"
        },
        {
          "key": 1823,
          "isCountryDefault": false,
          "name": "Atyrau"
        },
        {
          "key": 1824,
          "isCountryDefault": false,
          "name": "Baikonur"
        },
        {
          "key": 1825,
          "isCountryDefault": false,
          "name": "East Kazakhstan"
        },
        {
          "key": 3939,
          "isCountryDefault": false,
          "name": "Karagandy Province"
        },
        {
          "key": 1826,
          "isCountryDefault": false,
          "name": "Kyzylorda"
        },
        {
          "key": 1827,
          "isCountryDefault": false,
          "name": "Mangystau"
        },
        {
          "key": 1828,
          "isCountryDefault": false,
          "name": "North Kazakhstan"
        },
        {
          "key": 1829,
          "isCountryDefault": false,
          "name": "Pavlodar"
        },
        {
          "key": 1830,
          "isCountryDefault": false,
          "name": "South Kazakhstan"
        },
        {
          "key": 1831,
          "isCountryDefault": false,
          "name": "West Kazakhstan"
        },
        {
          "key": 1832,
          "isCountryDefault": false,
          "name": "Zhambyl"
        }
      ],
      "isEu": false
    },
    {
      "key": "qatar",
      "name": "Katar",
      "code": "QA",
      "region": "Naher und Mittlerer Osten",
      "states": [
        {
          "key": 90,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2646,
          "isCountryDefault": false,
          "name": "Ad Doha"
        },
        {
          "key": 2647,
          "isCountryDefault": false,
          "name": "Al Ghuwariyah"
        },
        {
          "key": 2648,
          "isCountryDefault": false,
          "name": "Al Jumayliyah"
        },
        {
          "key": 2649,
          "isCountryDefault": false,
          "name": "Al Khawr"
        },
        {
          "key": 2650,
          "isCountryDefault": false,
          "name": "Al Wakrah"
        },
        {
          "key": 2651,
          "isCountryDefault": false,
          "name": "Ar Rayyan"
        },
        {
          "key": 2653,
          "isCountryDefault": false,
          "name": "Ash Shamal"
        },
        {
          "key": 2652,
          "isCountryDefault": false,
          "name": "Jariyan al Batnah"
        },
        {
          "key": 2655,
          "isCountryDefault": false,
          "name": "Mesaieed"
        },
        {
          "key": 2654,
          "isCountryDefault": false,
          "name": "Umm Salal"
        }
      ],
      "isEu": false
    },
    {
      "key": "kenya",
      "name": "Kenya",
      "code": "KE",
      "region": "Afrika",
      "states": [
        {
          "key": 162,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1833,
          "isCountryDefault": false,
          "name": "Central"
        },
        {
          "key": 1834,
          "isCountryDefault": false,
          "name": "Coast"
        },
        {
          "key": 1835,
          "isCountryDefault": false,
          "name": "Eastern"
        },
        {
          "key": 1836,
          "isCountryDefault": false,
          "name": "Nairobi"
        },
        {
          "key": 1837,
          "isCountryDefault": false,
          "name": "North Eastern"
        },
        {
          "key": 1839,
          "isCountryDefault": false,
          "name": "Nyanza"
        },
        {
          "key": 1840,
          "isCountryDefault": false,
          "name": "Rift Valley"
        },
        {
          "key": 1841,
          "isCountryDefault": false,
          "name": "Western"
        }
      ],
      "isEu": false
    },
    {
      "key": "kyrgyzstan",
      "name": "Kirgisistan",
      "code": "KG",
      "region": "Asien",
      "states": [
        {
          "key": 163,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1859,
          "isCountryDefault": false,
          "name": "Batken Province"
        },
        {
          "key": 1858,
          "isCountryDefault": false,
          "name": "Bishkek"
        },
        {
          "key": 1860,
          "isCountryDefault": false,
          "name": "Chuy Province"
        },
        {
          "key": 1866,
          "isCountryDefault": false,
          "name": "Issyk Kul Province"
        },
        {
          "key": 1861,
          "isCountryDefault": false,
          "name": "Jalalabat"
        },
        {
          "key": 1862,
          "isCountryDefault": false,
          "name": "Naryn Province"
        },
        {
          "key": 1863,
          "isCountryDefault": false,
          "name": "Osh Province"
        },
        {
          "key": 1864,
          "isCountryDefault": false,
          "name": "Talas Province"
        }
      ],
      "isEu": false
    },
    {
      "key": "kiribati",
      "name": "Kiribati",
      "code": "KI",
      "region": "Ozeanien",
      "states": [
        {
          "key": 165,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1842,
          "isCountryDefault": false,
          "name": "Gilbert Islands"
        },
        {
          "key": 1843,
          "isCountryDefault": false,
          "name": "Line Islands"
        },
        {
          "key": 1844,
          "isCountryDefault": false,
          "name": "Phoenix Islands"
        }
      ],
      "isEu": false
    },
    {
      "key": "cocosKeelingIslands",
      "name": "Kokosinseln (Keeling)",
      "code": "CC",
      "region": "Asien",
      "states": [
        {
          "key": 123,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 954,
          "isCountryDefault": false,
          "name": "Home Island - Pulu Selma"
        },
        {
          "key": 952,
          "isCountryDefault": false,
          "name": "West Island"
        }
      ],
      "isEu": false
    },
    {
      "key": "colombia",
      "name": "Kolumbien",
      "code": "CO",
      "region": "Südamerika",
      "states": [
        {
          "key": 78,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 955,
          "isCountryDefault": false,
          "name": "Amazonas"
        },
        {
          "key": 956,
          "isCountryDefault": false,
          "name": "Antioquia"
        },
        {
          "key": 957,
          "isCountryDefault": false,
          "name": "Arauca"
        },
        {
          "key": 958,
          "isCountryDefault": false,
          "name": "Atlántico"
        },
        {
          "key": 987,
          "isCountryDefault": false,
          "name": "Bogota"
        },
        {
          "key": 959,
          "isCountryDefault": false,
          "name": "Bolívar"
        },
        {
          "key": 960,
          "isCountryDefault": false,
          "name": "Boyacá"
        },
        {
          "key": 961,
          "isCountryDefault": false,
          "name": "Caldas"
        },
        {
          "key": 962,
          "isCountryDefault": false,
          "name": "Caquetá"
        },
        {
          "key": 963,
          "isCountryDefault": false,
          "name": "Casanare"
        },
        {
          "key": 964,
          "isCountryDefault": false,
          "name": "Cauca"
        },
        {
          "key": 965,
          "isCountryDefault": false,
          "name": "Cesar"
        },
        {
          "key": 966,
          "isCountryDefault": false,
          "name": "Chocó"
        },
        {
          "key": 967,
          "isCountryDefault": false,
          "name": "Córdoba"
        },
        {
          "key": 968,
          "isCountryDefault": false,
          "name": "Cundinamarca"
        },
        {
          "key": 969,
          "isCountryDefault": false,
          "name": "Guainía"
        },
        {
          "key": 970,
          "isCountryDefault": false,
          "name": "Guaviare"
        },
        {
          "key": 971,
          "isCountryDefault": false,
          "name": "Huila"
        },
        {
          "key": 972,
          "isCountryDefault": false,
          "name": "La Guajira"
        },
        {
          "key": 973,
          "isCountryDefault": false,
          "name": "Magdalena"
        },
        {
          "key": 974,
          "isCountryDefault": false,
          "name": "Meta"
        },
        {
          "key": 975,
          "isCountryDefault": false,
          "name": "Nariño"
        },
        {
          "key": 976,
          "isCountryDefault": false,
          "name": "Norte de Santander"
        },
        {
          "key": 977,
          "isCountryDefault": false,
          "name": "Putumayo"
        },
        {
          "key": 978,
          "isCountryDefault": false,
          "name": "Quindío"
        },
        {
          "key": 979,
          "isCountryDefault": false,
          "name": "Risaralda"
        },
        {
          "key": 980,
          "isCountryDefault": false,
          "name": "San Andrés and Providencia"
        },
        {
          "key": 981,
          "isCountryDefault": false,
          "name": "Santander"
        },
        {
          "key": 982,
          "isCountryDefault": false,
          "name": "Sucre"
        },
        {
          "key": 983,
          "isCountryDefault": false,
          "name": "Tolima"
        },
        {
          "key": 984,
          "isCountryDefault": false,
          "name": "Valle del Cauca"
        },
        {
          "key": 985,
          "isCountryDefault": false,
          "name": "Vaupés"
        },
        {
          "key": 986,
          "isCountryDefault": false,
          "name": "Vichada"
        }
      ],
      "isEu": false
    },
    {
      "key": "comoros",
      "name": "Komoren",
      "code": "KM",
      "region": "Afrika",
      "states": [
        {
          "key": 166,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 991,
          "isCountryDefault": false,
          "name": "Maore"
        },
        {
          "key": 989,
          "isCountryDefault": false,
          "name": "Mwali"
        },
        {
          "key": 988,
          "isCountryDefault": false,
          "name": "Ngazidja"
        },
        {
          "key": 990,
          "isCountryDefault": false,
          "name": "Nzwani"
        }
      ],
      "isEu": false
    },
    {
      "key": "congo",
      "name": "Kongo",
      "code": "CG",
      "region": "Afrika",
      "states": [
        {
          "key": 126,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 992,
          "isCountryDefault": false,
          "name": "Bouenza"
        },
        {
          "key": 997,
          "isCountryDefault": false,
          "name": "Brazzaville"
        },
        {
          "key": 993,
          "isCountryDefault": false,
          "name": "Cuvette"
        },
        {
          "key": 994,
          "isCountryDefault": false,
          "name": "Cuvette-Ouest"
        },
        {
          "key": 995,
          "isCountryDefault": false,
          "name": "Kouilou"
        },
        {
          "key": 996,
          "isCountryDefault": false,
          "name": "Lékoumou"
        },
        {
          "key": 998,
          "isCountryDefault": false,
          "name": "Likouala"
        },
        {
          "key": 999,
          "isCountryDefault": false,
          "name": "Niari"
        },
        {
          "key": 1000,
          "isCountryDefault": false,
          "name": "Plateaux"
        },
        {
          "key": 1003,
          "isCountryDefault": false,
          "name": "Pointe Noire"
        },
        {
          "key": 1001,
          "isCountryDefault": false,
          "name": "Pool"
        },
        {
          "key": 1002,
          "isCountryDefault": false,
          "name": "Sangha"
        }
      ],
      "isEu": false
    },
    {
      "key": "congoKinshasa",
      "name": "Kongo-Kinshasa",
      "code": "CD",
      "region": "Afrika",
      "states": [
        {
          "key": 124,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1004,
          "isCountryDefault": false,
          "name": "Bandundu"
        },
        {
          "key": 1005,
          "isCountryDefault": false,
          "name": "Bas-Congo"
        },
        {
          "key": 1006,
          "isCountryDefault": false,
          "name": "Équateur"
        },
        {
          "key": 1007,
          "isCountryDefault": false,
          "name": "Kasai-Occidental"
        },
        {
          "key": 1008,
          "isCountryDefault": false,
          "name": "Kasai-Oriental"
        },
        {
          "key": 1009,
          "isCountryDefault": false,
          "name": "Katanga"
        },
        {
          "key": 1010,
          "isCountryDefault": false,
          "name": "Kinshasa"
        },
        {
          "key": 1011,
          "isCountryDefault": false,
          "name": "Maniema"
        },
        {
          "key": 1012,
          "isCountryDefault": false,
          "name": "Nord-Kivu"
        },
        {
          "key": 1013,
          "isCountryDefault": false,
          "name": "Orientale"
        },
        {
          "key": 1014,
          "isCountryDefault": false,
          "name": "Sud-Kivu"
        }
      ],
      "isEu": false
    },
    {
      "key": "kosovo",
      "name": "Kosovo",
      "code": "XK",
      "region": "Europa",
      "states": [
        {
          "key": 238,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1845,
          "isCountryDefault": false,
          "name": "Đakovički okrug/Rajoni i Gjakovës"
        },
        {
          "key": 1846,
          "isCountryDefault": false,
          "name": "Gnjilanski okrug/Rajoni i Gjilanit"
        },
        {
          "key": 1847,
          "isCountryDefault": false,
          "name": "Kosovskomitrovački okrug/Rajoni i Mitrovicës"
        },
        {
          "key": 1848,
          "isCountryDefault": false,
          "name": "Pećki okrug/Rajoni i Pejës"
        },
        {
          "key": 1849,
          "isCountryDefault": false,
          "name": "Prištinski okrug/Rajoni i Prishtinës"
        },
        {
          "key": 1850,
          "isCountryDefault": false,
          "name": "Prizrenski okrug/Rajoni i Prizrenit"
        },
        {
          "key": 1851,
          "isCountryDefault": false,
          "name": "Uroševački okrug/Rajoni i Ferizajit"
        }
      ],
      "isEu": false
    },
    {
      "key": "croatia",
      "name": "Kroatien",
      "code": "HR",
      "region": "Europa",
      "states": [
        {
          "key": 73,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1036,
          "isCountryDefault": false,
          "name": "Bjelovarsko-bilogorska (Bjelovar-Bilogora)"
        },
        {
          "key": 1052,
          "isCountryDefault": false,
          "name": "Brodsko-posavska (Brod-Posavina)"
        },
        {
          "key": 1045,
          "isCountryDefault": false,
          "name": "Dubrovačko-neretvanska (Dubrovnik-Neretva)"
        },
        {
          "key": 1044,
          "isCountryDefault": false,
          "name": "Grad Zagreb (City of Zagreb)"
        },
        {
          "key": 1046,
          "isCountryDefault": false,
          "name": "Istarska županija (Istria)"
        },
        {
          "key": 1037,
          "isCountryDefault": false,
          "name": "Karlovačka županija (Karlovac)"
        },
        {
          "key": 1038,
          "isCountryDefault": false,
          "name": "Koprivničko-križevačka (Koprivnica-Križevci)"
        },
        {
          "key": 1039,
          "isCountryDefault": false,
          "name": "Krapinsko-zagorska (Krapina-Zagorje)"
        },
        {
          "key": 1047,
          "isCountryDefault": false,
          "name": "Ličko-senjska (Lika-Senj)"
        },
        {
          "key": 1040,
          "isCountryDefault": false,
          "name": "Međimurska (Međimurje)"
        },
        {
          "key": 1053,
          "isCountryDefault": false,
          "name": "Osječko-baranjska (Osijek-Baranja)"
        },
        {
          "key": 1054,
          "isCountryDefault": false,
          "name": "Požeško-slavonska (Požega-Slavonia)"
        },
        {
          "key": 1048,
          "isCountryDefault": false,
          "name": "Primorsko-goranska (Primorje-Gorski Kotar)"
        },
        {
          "key": 1050,
          "isCountryDefault": false,
          "name": "Šibensko-kninska (Šibenik-Knin)"
        },
        {
          "key": 1041,
          "isCountryDefault": false,
          "name": "Sisačko-moslavačka (Sisak-Moslavina)"
        },
        {
          "key": 1049,
          "isCountryDefault": false,
          "name": "Splitsko-dalmatinska (Split-Dalmatia)"
        },
        {
          "key": 1042,
          "isCountryDefault": false,
          "name": "Varaždinska (Varaždin)"
        },
        {
          "key": 1055,
          "isCountryDefault": false,
          "name": "Virovitičko-podravska (Virovitica-Podravina)"
        },
        {
          "key": 1056,
          "isCountryDefault": false,
          "name": "Vukovarsko-srijemska (Vukovar-Syrmia)"
        },
        {
          "key": 1051,
          "isCountryDefault": false,
          "name": "Zadarska (Zadar)"
        },
        {
          "key": 1043,
          "isCountryDefault": false,
          "name": "Zagrebačka (Zagreb County)"
        }
      ],
      "isEu": true
    },
    {
      "key": "cuba",
      "name": "Kuba",
      "code": "CU",
      "region": "Nordamerika",
      "states": [
        {
          "key": 8,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1058,
          "isCountryDefault": false,
          "name": "Artemisa"
        },
        {
          "key": 1066,
          "isCountryDefault": false,
          "name": "Camagüey"
        },
        {
          "key": 1065,
          "isCountryDefault": false,
          "name": "Ciego de Ávila"
        },
        {
          "key": 1062,
          "isCountryDefault": false,
          "name": "Cienfuegos"
        },
        {
          "key": 1068,
          "isCountryDefault": false,
          "name": "Granma"
        },
        {
          "key": 1071,
          "isCountryDefault": false,
          "name": "Guantánamo"
        },
        {
          "key": 1069,
          "isCountryDefault": false,
          "name": "Holguín"
        },
        {
          "key": 1059,
          "isCountryDefault": false,
          "name": "La Habana"
        },
        {
          "key": 1067,
          "isCountryDefault": false,
          "name": "Las Tunas"
        },
        {
          "key": 1061,
          "isCountryDefault": false,
          "name": "Matanzas"
        },
        {
          "key": 1060,
          "isCountryDefault": false,
          "name": "Mayabeque"
        },
        {
          "key": 1057,
          "isCountryDefault": false,
          "name": "Pinar del Río"
        },
        {
          "key": 1064,
          "isCountryDefault": false,
          "name": "Sancti Spíritus"
        },
        {
          "key": 1070,
          "isCountryDefault": false,
          "name": "Santiago de Cuba"
        },
        {
          "key": 1072,
          "isCountryDefault": false,
          "name": "sla de la Juventud"
        },
        {
          "key": 1063,
          "isCountryDefault": false,
          "name": "Villa Clara"
        }
      ],
      "isEu": false
    },
    {
      "key": "kuwait",
      "name": "Kuwait",
      "code": "KW",
      "region": "Naher und Mittlerer Osten",
      "states": [
        {
          "key": 169,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1852,
          "isCountryDefault": false,
          "name": "Al Ahmadi"
        },
        {
          "key": 1853,
          "isCountryDefault": false,
          "name": "Al Asimah"
        },
        {
          "key": 1854,
          "isCountryDefault": false,
          "name": "Al Farwaniyah"
        },
        {
          "key": 1855,
          "isCountryDefault": false,
          "name": "Al Jahra"
        },
        {
          "key": 1856,
          "isCountryDefault": false,
          "name": "Hawalli"
        },
        {
          "key": 1857,
          "isCountryDefault": false,
          "name": "Mubarak Al-Kabeer"
        }
      ],
      "isEu": false
    },
    {
      "key": "lao",
      "name": "Laos",
      "code": "LA",
      "region": "Asien",
      "states": [
        {
          "key": 171,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1867,
          "isCountryDefault": false,
          "name": "Attapeu"
        },
        {
          "key": 1868,
          "isCountryDefault": false,
          "name": "Bokeo"
        },
        {
          "key": 1869,
          "isCountryDefault": false,
          "name": "Bolikhamsai"
        },
        {
          "key": 1870,
          "isCountryDefault": false,
          "name": "Champasak"
        },
        {
          "key": 1872,
          "isCountryDefault": false,
          "name": "Hua Phan"
        },
        {
          "key": 1873,
          "isCountryDefault": false,
          "name": "Khammouane"
        },
        {
          "key": 1874,
          "isCountryDefault": false,
          "name": "Luang Namtha"
        },
        {
          "key": 1875,
          "isCountryDefault": false,
          "name": "Luang Phrabang"
        },
        {
          "key": 1876,
          "isCountryDefault": false,
          "name": "Oudomxay"
        },
        {
          "key": 1877,
          "isCountryDefault": false,
          "name": "Phongsali"
        },
        {
          "key": 1879,
          "isCountryDefault": false,
          "name": "Salavan"
        },
        {
          "key": 1880,
          "isCountryDefault": false,
          "name": "Savannakhet"
        },
        {
          "key": 1878,
          "isCountryDefault": false,
          "name": "Sayabouly"
        },
        {
          "key": 1881,
          "isCountryDefault": false,
          "name": "Sekong"
        },
        {
          "key": 1882,
          "isCountryDefault": false,
          "name": "Vientiane Capital"
        },
        {
          "key": 1883,
          "isCountryDefault": false,
          "name": "Vientiane Province"
        },
        {
          "key": 1885,
          "isCountryDefault": false,
          "name": "Xieng Khouang"
        }
      ],
      "isEu": false
    },
    {
      "key": "lesotho",
      "name": "Lesotho",
      "code": "LS",
      "region": "Afrika",
      "states": [
        {
          "key": 176,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1897,
          "isCountryDefault": false,
          "name": "Berea"
        },
        {
          "key": 1898,
          "isCountryDefault": false,
          "name": "Butha-Buthe"
        },
        {
          "key": 1899,
          "isCountryDefault": false,
          "name": "Leribe"
        },
        {
          "key": 1900,
          "isCountryDefault": false,
          "name": "Mafeteng"
        },
        {
          "key": 1901,
          "isCountryDefault": false,
          "name": "Maseru"
        },
        {
          "key": 1902,
          "isCountryDefault": false,
          "name": "Mohale's Hoek"
        },
        {
          "key": 1903,
          "isCountryDefault": false,
          "name": "Mokhotlong"
        },
        {
          "key": 1904,
          "isCountryDefault": false,
          "name": "Qacha's Nek"
        },
        {
          "key": 1905,
          "isCountryDefault": false,
          "name": "Quthing"
        },
        {
          "key": 1906,
          "isCountryDefault": false,
          "name": "Thaba-Tseka"
        }
      ],
      "isEu": false
    },
    {
      "key": "latvia",
      "name": "Lettland",
      "code": "LV",
      "region": "Europa",
      "states": [
        {
          "key": 89,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1887,
          "isCountryDefault": false,
          "name": "Kurzeme Region"
        },
        {
          "key": 1888,
          "isCountryDefault": false,
          "name": "Latgale Region"
        },
        {
          "key": 1886,
          "isCountryDefault": false,
          "name": "Riga Region"
        },
        {
          "key": 1890,
          "isCountryDefault": false,
          "name": "Vidzeme Region"
        },
        {
          "key": 1889,
          "isCountryDefault": false,
          "name": "Zemgale Region"
        }
      ],
      "isEu": true
    },
    {
      "key": "lebanon",
      "name": "Libanon",
      "code": "LB",
      "region": "Naher und Mittlerer Osten",
      "states": [
        {
          "key": 82,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1891,
          "isCountryDefault": false,
          "name": "Beirut"
        },
        {
          "key": 1894,
          "isCountryDefault": false,
          "name": "Beqaa"
        },
        {
          "key": 1892,
          "isCountryDefault": false,
          "name": "Mount Lebanon"
        },
        {
          "key": 1895,
          "isCountryDefault": false,
          "name": "Nabatiye"
        },
        {
          "key": 1893,
          "isCountryDefault": false,
          "name": "North"
        },
        {
          "key": 1896,
          "isCountryDefault": false,
          "name": "South"
        }
      ],
      "isEu": false
    },
    {
      "key": "liberia",
      "name": "Liberia",
      "code": "LR",
      "region": "Afrika",
      "states": [
        {
          "key": 175,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1907,
          "isCountryDefault": false,
          "name": "Bomi"
        },
        {
          "key": 1908,
          "isCountryDefault": false,
          "name": "Bong"
        },
        {
          "key": 1909,
          "isCountryDefault": false,
          "name": "Gbarpolu"
        },
        {
          "key": 1910,
          "isCountryDefault": false,
          "name": "Grand Bassa"
        },
        {
          "key": 1911,
          "isCountryDefault": false,
          "name": "Grand Cape Mount"
        },
        {
          "key": 1912,
          "isCountryDefault": false,
          "name": "Grand Gedeh"
        },
        {
          "key": 1913,
          "isCountryDefault": false,
          "name": "Grand Kru"
        },
        {
          "key": 1914,
          "isCountryDefault": false,
          "name": "Lofa"
        },
        {
          "key": 1915,
          "isCountryDefault": false,
          "name": "Margibi"
        },
        {
          "key": 1916,
          "isCountryDefault": false,
          "name": "Maryland"
        },
        {
          "key": 1917,
          "isCountryDefault": false,
          "name": "Montserrado"
        },
        {
          "key": 1918,
          "isCountryDefault": false,
          "name": "Nimba"
        },
        {
          "key": 1919,
          "isCountryDefault": false,
          "name": "River Cess"
        },
        {
          "key": 1920,
          "isCountryDefault": false,
          "name": "River Gee"
        },
        {
          "key": 1921,
          "isCountryDefault": false,
          "name": "Sinoe"
        }
      ],
      "isEu": false
    },
    {
      "key": "libyanArabJamahiriya",
      "name": "Libyen",
      "code": "LY",
      "region": "Afrika",
      "states": [
        {
          "key": 177,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1927,
          "isCountryDefault": false,
          "name": "Al Wahat"
        },
        {
          "key": 1926,
          "isCountryDefault": false,
          "name": "Benghazi"
        },
        {
          "key": 1922,
          "isCountryDefault": false,
          "name": "Butnan"
        },
        {
          "key": 1923,
          "isCountryDefault": false,
          "name": "Derna"
        },
        {
          "key": 1941,
          "isCountryDefault": false,
          "name": "Ghat"
        },
        {
          "key": 1924,
          "isCountryDefault": false,
          "name": "Jabal al Akhdar"
        },
        {
          "key": 1939,
          "isCountryDefault": false,
          "name": "Jabal al Gharbi"
        },
        {
          "key": 1936,
          "isCountryDefault": false,
          "name": "Jafara"
        },
        {
          "key": 1942,
          "isCountryDefault": false,
          "name": "Jufra"
        },
        {
          "key": 1928,
          "isCountryDefault": false,
          "name": "Kufra"
        },
        {
          "key": 1925,
          "isCountryDefault": false,
          "name": "Marj"
        },
        {
          "key": 1933,
          "isCountryDefault": false,
          "name": "Misrata"
        },
        {
          "key": 1934,
          "isCountryDefault": false,
          "name": "Murqub"
        },
        {
          "key": 1930,
          "isCountryDefault": false,
          "name": "Murzuq"
        },
        {
          "key": 1940,
          "isCountryDefault": false,
          "name": "Nalut"
        },
        {
          "key": 1938,
          "isCountryDefault": false,
          "name": "Nuqat al Khams"
        },
        {
          "key": 1931,
          "isCountryDefault": false,
          "name": "Sabha"
        },
        {
          "key": 1929,
          "isCountryDefault": false,
          "name": "Sirte"
        },
        {
          "key": 1935,
          "isCountryDefault": false,
          "name": "Tripoli"
        },
        {
          "key": 1932,
          "isCountryDefault": false,
          "name": "Wadi al Hayaa"
        },
        {
          "key": 1943,
          "isCountryDefault": false,
          "name": "Wadi al Shatii"
        },
        {
          "key": 1937,
          "isCountryDefault": false,
          "name": "Zawiya"
        }
      ],
      "isEu": false
    },
    {
      "key": "liechtenstein",
      "name": "Liechtenstein",
      "code": "LI",
      "region": "Europa",
      "states": [
        {
          "key": 173,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1954,
          "isCountryDefault": false,
          "name": "Balzers"
        },
        {
          "key": 1947,
          "isCountryDefault": false,
          "name": "Eschen"
        },
        {
          "key": 1946,
          "isCountryDefault": false,
          "name": "Gamprin"
        },
        {
          "key": 1948,
          "isCountryDefault": false,
          "name": "Mauren"
        },
        {
          "key": 1950,
          "isCountryDefault": false,
          "name": "Planken"
        },
        {
          "key": 1944,
          "isCountryDefault": false,
          "name": "Ruggell"
        },
        {
          "key": 1949,
          "isCountryDefault": false,
          "name": "Schaan"
        },
        {
          "key": 1945,
          "isCountryDefault": false,
          "name": "Schellenberg"
        },
        {
          "key": 1953,
          "isCountryDefault": false,
          "name": "Triesen"
        },
        {
          "key": 1952,
          "isCountryDefault": false,
          "name": "Triesenberg"
        },
        {
          "key": 1951,
          "isCountryDefault": false,
          "name": "Vaduz"
        }
      ],
      "isEu": false
    },
    {
      "key": "lithuania",
      "name": "Litauen",
      "code": "LT",
      "region": "Europa",
      "states": [
        {
          "key": 35,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1955,
          "isCountryDefault": false,
          "name": "Alytaus apskritis"
        },
        {
          "key": 1956,
          "isCountryDefault": false,
          "name": "Kauno apskritis"
        },
        {
          "key": 1957,
          "isCountryDefault": false,
          "name": "Klaipėdos apskritis"
        },
        {
          "key": 1958,
          "isCountryDefault": false,
          "name": "Marijampolės apskritis"
        },
        {
          "key": 1959,
          "isCountryDefault": false,
          "name": "Panevėžio apskritis"
        },
        {
          "key": 1960,
          "isCountryDefault": false,
          "name": "Šiaulių apskritis"
        },
        {
          "key": 1961,
          "isCountryDefault": false,
          "name": "Tauragės apskritis"
        },
        {
          "key": 1962,
          "isCountryDefault": false,
          "name": "Telšių apskritis"
        },
        {
          "key": 1963,
          "isCountryDefault": false,
          "name": "Utenos apskritis"
        },
        {
          "key": 1964,
          "isCountryDefault": false,
          "name": "Vilniaus apskritis"
        }
      ],
      "isEu": true
    },
    {
      "key": "luxembourg",
      "name": "Luxemburg",
      "code": "LU",
      "region": "Europa",
      "states": [
        {
          "key": 36,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3848,
          "isCountryDefault": false,
          "name": "Capellen"
        },
        {
          "key": 1965,
          "isCountryDefault": false,
          "name": "Clervaux"
        },
        {
          "key": 3846,
          "isCountryDefault": false,
          "name": "Diekirch"
        },
        {
          "key": 3849,
          "isCountryDefault": false,
          "name": "Echternach"
        },
        {
          "key": 3843,
          "isCountryDefault": false,
          "name": "Esch-sur-Alzette"
        },
        {
          "key": 3847,
          "isCountryDefault": false,
          "name": "Grevenmacher"
        },
        {
          "key": 3844,
          "isCountryDefault": false,
          "name": "Luxembourg"
        },
        {
          "key": 3845,
          "isCountryDefault": false,
          "name": "Mersch"
        },
        {
          "key": 1966,
          "isCountryDefault": false,
          "name": "Redange"
        },
        {
          "key": 3850,
          "isCountryDefault": false,
          "name": "Remich"
        },
        {
          "key": 3851,
          "isCountryDefault": false,
          "name": "Vianden"
        },
        {
          "key": 1967,
          "isCountryDefault": false,
          "name": "Wiltz"
        }
      ],
      "isEu": true
    },
    {
      "key": "macao",
      "name": "Macau",
      "code": "MO",
      "region": "Asien",
      "states": [
        {
          "key": 182,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1969,
          "isCountryDefault": false,
          "name": "Ilha da Taipa"
        },
        {
          "key": 1970,
          "isCountryDefault": false,
          "name": "Ilha de Coloane"
        },
        {
          "key": 1968,
          "isCountryDefault": false,
          "name": "Peninsula de Macau"
        }
      ],
      "isEu": false
    },
    {
      "key": "madagaskar",
      "name": "Madagaskar",
      "code": "MG",
      "region": "Afrika",
      "states": [
        {
          "key": 179,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 4074,
          "isCountryDefault": false,
          "name": "Alaotra-Mangoro"
        },
        {
          "key": 4076,
          "isCountryDefault": false,
          "name": "Amoron'i Mania"
        },
        {
          "key": 1982,
          "isCountryDefault": false,
          "name": "Analamanga"
        },
        {
          "key": 4075,
          "isCountryDefault": false,
          "name": "Analanjirofo"
        },
        {
          "key": 4083,
          "isCountryDefault": false,
          "name": "Androy"
        },
        {
          "key": 4084,
          "isCountryDefault": false,
          "name": "Anosy"
        },
        {
          "key": 4082,
          "isCountryDefault": false,
          "name": "Atsimo-Andrefana (South-West)"
        },
        {
          "key": 4079,
          "isCountryDefault": false,
          "name": "Atsimo-Atsinanana (South-East)"
        },
        {
          "key": 1984,
          "isCountryDefault": false,
          "name": "Atsinanana (East)"
        },
        {
          "key": 4072,
          "isCountryDefault": false,
          "name": "Betsiboka"
        },
        {
          "key": 4071,
          "isCountryDefault": false,
          "name": "Boeny"
        },
        {
          "key": 1983,
          "isCountryDefault": false,
          "name": "Bongolava"
        },
        {
          "key": 1979,
          "isCountryDefault": false,
          "name": "Diana"
        },
        {
          "key": 4077,
          "isCountryDefault": false,
          "name": "Haute Matsiatra (Upper Matsiatra)"
        },
        {
          "key": 4080,
          "isCountryDefault": false,
          "name": "Ihorombe"
        },
        {
          "key": 1981,
          "isCountryDefault": false,
          "name": "Itasy"
        },
        {
          "key": 4073,
          "isCountryDefault": false,
          "name": "Melaky"
        },
        {
          "key": 4081,
          "isCountryDefault": false,
          "name": "Menabe"
        },
        {
          "key": 1980,
          "isCountryDefault": false,
          "name": "Sava"
        },
        {
          "key": 4070,
          "isCountryDefault": false,
          "name": "Sofia"
        },
        {
          "key": 4085,
          "isCountryDefault": false,
          "name": "Vakinankaratra"
        },
        {
          "key": 4078,
          "isCountryDefault": false,
          "name": "Vatovavy-Fitovinany"
        }
      ],
      "isEu": false
    },
    {
      "key": "malawi",
      "name": "Malawi",
      "code": "MW",
      "region": "Afrika",
      "states": [
        {
          "key": 186,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1985,
          "isCountryDefault": false,
          "name": "Central Region"
        },
        {
          "key": 1986,
          "isCountryDefault": false,
          "name": "Northern Region"
        },
        {
          "key": 1987,
          "isCountryDefault": false,
          "name": "Southern Region"
        }
      ],
      "isEu": false
    },
    {
      "key": "malaysia",
      "name": "Malaysia",
      "code": "MY",
      "region": "Asien",
      "states": [
        {
          "key": 45,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2003,
          "isCountryDefault": false,
          "name": "Johor"
        },
        {
          "key": 1992,
          "isCountryDefault": false,
          "name": "Kedah"
        },
        {
          "key": 1995,
          "isCountryDefault": false,
          "name": "Kelantan"
        },
        {
          "key": 1999,
          "isCountryDefault": false,
          "name": "Kuala Lumpur"
        },
        {
          "key": 1988,
          "isCountryDefault": false,
          "name": "Labuan"
        },
        {
          "key": 2002,
          "isCountryDefault": false,
          "name": "Malacca"
        },
        {
          "key": 2001,
          "isCountryDefault": false,
          "name": "Negeri Sembilan"
        },
        {
          "key": 1997,
          "isCountryDefault": false,
          "name": "Pahang"
        },
        {
          "key": 1993,
          "isCountryDefault": false,
          "name": "Penang"
        },
        {
          "key": 1994,
          "isCountryDefault": false,
          "name": "Perak"
        },
        {
          "key": 1991,
          "isCountryDefault": false,
          "name": "Perlis"
        },
        {
          "key": 2000,
          "isCountryDefault": false,
          "name": "Putrajaya"
        },
        {
          "key": 1989,
          "isCountryDefault": false,
          "name": "Sabah"
        },
        {
          "key": 1990,
          "isCountryDefault": false,
          "name": "Sarawak"
        },
        {
          "key": 1998,
          "isCountryDefault": false,
          "name": "Selangor"
        },
        {
          "key": 1996,
          "isCountryDefault": false,
          "name": "Terengganu"
        }
      ],
      "isEu": false
    },
    {
      "key": "maldives",
      "name": "Malediven",
      "code": "MV",
      "region": "Asien",
      "states": [
        {
          "key": 43,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2011,
          "isCountryDefault": false,
          "name": "Dhekunu"
        },
        {
          "key": 2008,
          "isCountryDefault": false,
          "name": "Malé"
        },
        {
          "key": 2010,
          "isCountryDefault": false,
          "name": "Mathi-Dhekunu"
        },
        {
          "key": 2004,
          "isCountryDefault": false,
          "name": "Mathi-Uthuru"
        },
        {
          "key": 2007,
          "isCountryDefault": false,
          "name": "Medhu"
        },
        {
          "key": 2009,
          "isCountryDefault": false,
          "name": "Medhu-Dhekunu"
        },
        {
          "key": 2006,
          "isCountryDefault": false,
          "name": "Medhu-Uthuru"
        },
        {
          "key": 2005,
          "isCountryDefault": false,
          "name": "Uthuru"
        }
      ],
      "isEu": false
    },
    {
      "key": "mali",
      "name": "Mali",
      "code": "ML",
      "region": "Afrika",
      "states": [
        {
          "key": 38,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2020,
          "isCountryDefault": false,
          "name": "Bamako"
        },
        {
          "key": 2012,
          "isCountryDefault": false,
          "name": "Gao"
        },
        {
          "key": 2013,
          "isCountryDefault": false,
          "name": "Kayes"
        },
        {
          "key": 2014,
          "isCountryDefault": false,
          "name": "Kidal"
        },
        {
          "key": 2015,
          "isCountryDefault": false,
          "name": "Koulikoro"
        },
        {
          "key": 2016,
          "isCountryDefault": false,
          "name": "Mopti"
        },
        {
          "key": 2017,
          "isCountryDefault": false,
          "name": "Ségou"
        },
        {
          "key": 2018,
          "isCountryDefault": false,
          "name": "Sikasso"
        },
        {
          "key": 2019,
          "isCountryDefault": false,
          "name": "Tombouctou"
        }
      ],
      "isEu": false
    },
    {
      "key": "malta",
      "name": "Malta",
      "code": "MT",
      "region": "Europa",
      "states": [
        {
          "key": 42,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2050,
          "isCountryDefault": false,
          "name": "Gozo"
        },
        {
          "key": 2049,
          "isCountryDefault": false,
          "name": "Malta"
        }
      ],
      "isEu": true
    },
    {
      "key": "morocco",
      "name": "Marokko",
      "code": "MA",
      "region": "Afrika",
      "states": [
        {
          "key": 91,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2193,
          "isCountryDefault": false,
          "name": "Al Haouz Province"
        },
        {
          "key": 4002,
          "isCountryDefault": false,
          "name": "Al Hoceïma Province"
        },
        {
          "key": 4042,
          "isCountryDefault": false,
          "name": "Aousserd Province"
        },
        {
          "key": 4035,
          "isCountryDefault": false,
          "name": "Assa-Zag Province"
        },
        {
          "key": 2194,
          "isCountryDefault": false,
          "name": "Azilal Province"
        },
        {
          "key": 2189,
          "isCountryDefault": false,
          "name": "Ben Slimane Province"
        },
        {
          "key": 2191,
          "isCountryDefault": false,
          "name": "Béni-Mellal Province"
        },
        {
          "key": 4022,
          "isCountryDefault": false,
          "name": "Berkane Province"
        },
        {
          "key": 4040,
          "isCountryDefault": false,
          "name": "Boujdour Province"
        },
        {
          "key": 4001,
          "isCountryDefault": false,
          "name": "Boulemane Province"
        },
        {
          "key": 4014,
          "isCountryDefault": false,
          "name": "Chefchaouen Province"
        },
        {
          "key": 2184,
          "isCountryDefault": false,
          "name": "Chichaoua Province"
        },
        {
          "key": 4029,
          "isCountryDefault": false,
          "name": "Chtouka Aït Baha Province"
        },
        {
          "key": 4017,
          "isCountryDefault": false,
          "name": "El Hajeb Province"
        },
        {
          "key": 2187,
          "isCountryDefault": false,
          "name": "El Jadida Province"
        },
        {
          "key": 2185,
          "isCountryDefault": false,
          "name": "El Kelaa des Sraghna Province"
        },
        {
          "key": 4018,
          "isCountryDefault": false,
          "name": "Errachidia Province"
        },
        {
          "key": 4036,
          "isCountryDefault": false,
          "name": "Es Semara Province"
        },
        {
          "key": 3997,
          "isCountryDefault": false,
          "name": "Essaouira Province"
        },
        {
          "key": 4023,
          "isCountryDefault": false,
          "name": "Figuig Province"
        },
        {
          "key": 4037,
          "isCountryDefault": false,
          "name": "Guelmim Province"
        },
        {
          "key": 4024,
          "isCountryDefault": false,
          "name": "Jerada Province"
        },
        {
          "key": 4005,
          "isCountryDefault": false,
          "name": "Kénitra Province"
        },
        {
          "key": 4010,
          "isCountryDefault": false,
          "name": "Khémisset Province"
        },
        {
          "key": 4019,
          "isCountryDefault": false,
          "name": "Khénifra Province"
        },
        {
          "key": 2196,
          "isCountryDefault": false,
          "name": "Khouribga Province"
        },
        {
          "key": 4041,
          "isCountryDefault": false,
          "name": "Laâyoune Province"
        },
        {
          "key": 4015,
          "isCountryDefault": false,
          "name": "Larache Province"
        },
        {
          "key": 2195,
          "isCountryDefault": false,
          "name": "Médiouna Province"
        },
        {
          "key": 4020,
          "isCountryDefault": false,
          "name": "Midelt Province"
        },
        {
          "key": 3998,
          "isCountryDefault": false,
          "name": "Moulay Yacoub Province"
        },
        {
          "key": 4025,
          "isCountryDefault": false,
          "name": "Nador Province"
        },
        {
          "key": 2186,
          "isCountryDefault": false,
          "name": "Nouaceur Province"
        },
        {
          "key": 4030,
          "isCountryDefault": false,
          "name": "Ouarzazate Province"
        },
        {
          "key": 4043,
          "isCountryDefault": false,
          "name": "Oued Eddahab Province"
        },
        {
          "key": 4027,
          "isCountryDefault": false,
          "name": "Prefecture of Agadir-Ida Ou Tanane"
        },
        {
          "key": 4044,
          "isCountryDefault": false,
          "name": "Prefecture of Casablanca"
        },
        {
          "key": 4011,
          "isCountryDefault": false,
          "name": "Prefecture of Fahs Anjra"
        },
        {
          "key": 3999,
          "isCountryDefault": false,
          "name": "Prefecture of Fès-Dar-Dbibegh"
        },
        {
          "key": 4028,
          "isCountryDefault": false,
          "name": "Prefecture of Inezgane-Aït Melloul"
        },
        {
          "key": 4046,
          "isCountryDefault": false,
          "name": "Prefecture of Marrakech"
        },
        {
          "key": 4016,
          "isCountryDefault": false,
          "name": "Prefecture of Meknès-El Menzeh"
        },
        {
          "key": 4045,
          "isCountryDefault": false,
          "name": "Prefecture of Mohammedia"
        },
        {
          "key": 4021,
          "isCountryDefault": false,
          "name": "Prefecture of Oujda-Angad"
        },
        {
          "key": 4007,
          "isCountryDefault": false,
          "name": "Prefecture of Rabat"
        },
        {
          "key": 4008,
          "isCountryDefault": false,
          "name": "Prefecture of Salé"
        },
        {
          "key": 4009,
          "isCountryDefault": false,
          "name": "Prefecture of Skhirat-Témara"
        },
        {
          "key": 4012,
          "isCountryDefault": false,
          "name": "Prefecture of Tangier-Assilah"
        },
        {
          "key": 2190,
          "isCountryDefault": false,
          "name": "Safi Province"
        },
        {
          "key": 4000,
          "isCountryDefault": false,
          "name": "Sefrou Province"
        },
        {
          "key": 2192,
          "isCountryDefault": false,
          "name": "Settat Province"
        },
        {
          "key": 4032,
          "isCountryDefault": false,
          "name": "Sidi Ifni Province"
        },
        {
          "key": 4006,
          "isCountryDefault": false,
          "name": "Sidi Kacem Province"
        },
        {
          "key": 4038,
          "isCountryDefault": false,
          "name": "Tan-Tan Province"
        },
        {
          "key": 4003,
          "isCountryDefault": false,
          "name": "Taounate Province"
        },
        {
          "key": 4026,
          "isCountryDefault": false,
          "name": "Taourirt Province"
        },
        {
          "key": 4031,
          "isCountryDefault": false,
          "name": "Taroudant Province"
        },
        {
          "key": 4039,
          "isCountryDefault": false,
          "name": "Tata Province"
        },
        {
          "key": 4004,
          "isCountryDefault": false,
          "name": "Taza Province"
        },
        {
          "key": 4013,
          "isCountryDefault": false,
          "name": "Tétouan Province"
        },
        {
          "key": 4033,
          "isCountryDefault": false,
          "name": "Tiznit Province"
        },
        {
          "key": 4034,
          "isCountryDefault": false,
          "name": "Zagora Province"
        }
      ],
      "isEu": false
    },
    {
      "key": "marshallIslands",
      "name": "Marshallinseln",
      "code": "MH",
      "region": "Ozeanien",
      "states": [
        {
          "key": 180,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2021,
          "isCountryDefault": false,
          "name": "Ailinglaplap Atoll"
        },
        {
          "key": 2022,
          "isCountryDefault": false,
          "name": "Ailuk Atoll"
        },
        {
          "key": 2023,
          "isCountryDefault": false,
          "name": "Arno Atoll"
        },
        {
          "key": 2024,
          "isCountryDefault": false,
          "name": "Aur Atoll"
        },
        {
          "key": 2025,
          "isCountryDefault": false,
          "name": "Ebon Atoll"
        },
        {
          "key": 2026,
          "isCountryDefault": false,
          "name": "Enewetak Atoll"
        },
        {
          "key": 2027,
          "isCountryDefault": false,
          "name": "Jabat Island"
        },
        {
          "key": 2028,
          "isCountryDefault": false,
          "name": "Jaluit Atoll"
        },
        {
          "key": 2029,
          "isCountryDefault": false,
          "name": "Kili Island"
        },
        {
          "key": 2030,
          "isCountryDefault": false,
          "name": "Kwajalein Atoll"
        },
        {
          "key": 2031,
          "isCountryDefault": false,
          "name": "Lae Atoll"
        },
        {
          "key": 2032,
          "isCountryDefault": false,
          "name": "Lib Island"
        },
        {
          "key": 2033,
          "isCountryDefault": false,
          "name": "Likiep Atoll"
        },
        {
          "key": 2034,
          "isCountryDefault": false,
          "name": "Majuro Atoll"
        },
        {
          "key": 2035,
          "isCountryDefault": false,
          "name": "Maloelap Atoll"
        },
        {
          "key": 2036,
          "isCountryDefault": false,
          "name": "Mejit Island"
        },
        {
          "key": 2037,
          "isCountryDefault": false,
          "name": "Mili Atoll"
        },
        {
          "key": 2038,
          "isCountryDefault": false,
          "name": "Namorik Atoll"
        },
        {
          "key": 2039,
          "isCountryDefault": false,
          "name": "Namu Atoll"
        },
        {
          "key": 2040,
          "isCountryDefault": false,
          "name": "Rongelap Atoll"
        },
        {
          "key": 2041,
          "isCountryDefault": false,
          "name": "Ujae Atoll"
        },
        {
          "key": 2042,
          "isCountryDefault": false,
          "name": "Utirik Atoll"
        },
        {
          "key": 2043,
          "isCountryDefault": false,
          "name": "Wotho Atoll"
        },
        {
          "key": 2044,
          "isCountryDefault": false,
          "name": "Wotje Atoll"
        }
      ],
      "isEu": false
    },
    {
      "key": "martinique",
      "name": "Martinique",
      "code": "MQ",
      "region": "Nordamerika",
      "states": [
        {
          "key": 40,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2045,
          "isCountryDefault": false,
          "name": "Fort-de-France"
        },
        {
          "key": 2048,
          "isCountryDefault": false,
          "name": "La Trinité"
        },
        {
          "key": 2046,
          "isCountryDefault": false,
          "name": "Le Marin"
        },
        {
          "key": 2047,
          "isCountryDefault": false,
          "name": "Saint-Pierre"
        }
      ],
      "isEu": false
    },
    {
      "key": "mauritania",
      "name": "Mauretanien",
      "code": "MR",
      "region": "Afrika",
      "states": [
        {
          "key": 41,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2051,
          "isCountryDefault": false,
          "name": "Adrar"
        },
        {
          "key": 2052,
          "isCountryDefault": false,
          "name": "Assaba"
        },
        {
          "key": 2053,
          "isCountryDefault": false,
          "name": "Brakna"
        },
        {
          "key": 2054,
          "isCountryDefault": false,
          "name": "Dakhlet Nouadhibou"
        },
        {
          "key": 2055,
          "isCountryDefault": false,
          "name": "Gorgol"
        },
        {
          "key": 2056,
          "isCountryDefault": false,
          "name": "Guidimaka"
        },
        {
          "key": 2057,
          "isCountryDefault": false,
          "name": "Hodh Ech Chargui"
        },
        {
          "key": 2058,
          "isCountryDefault": false,
          "name": "Hodh El Gharbi"
        },
        {
          "key": 2059,
          "isCountryDefault": false,
          "name": "Inchiri"
        },
        {
          "key": 2060,
          "isCountryDefault": false,
          "name": "Nouakchott"
        },
        {
          "key": 2061,
          "isCountryDefault": false,
          "name": "Tagant"
        },
        {
          "key": 2062,
          "isCountryDefault": false,
          "name": "Tiris Zemmour"
        },
        {
          "key": 2063,
          "isCountryDefault": false,
          "name": "Trarza"
        }
      ],
      "isEu": false
    },
    {
      "key": "mauritius",
      "name": "Mauritius",
      "code": "MU",
      "region": "Afrika",
      "states": [
        {
          "key": 185,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2073,
          "isCountryDefault": false,
          "name": "Agalega Islands"
        },
        {
          "key": 2064,
          "isCountryDefault": false,
          "name": "Black River"
        },
        {
          "key": 2074,
          "isCountryDefault": false,
          "name": "Cargados Carajos"
        },
        {
          "key": 2065,
          "isCountryDefault": false,
          "name": "Flacq"
        },
        {
          "key": 2066,
          "isCountryDefault": false,
          "name": "Grand Port"
        },
        {
          "key": 2067,
          "isCountryDefault": false,
          "name": "Moka"
        },
        {
          "key": 2068,
          "isCountryDefault": false,
          "name": "Pamplemousses"
        },
        {
          "key": 2069,
          "isCountryDefault": false,
          "name": "Plaines Wilhems"
        },
        {
          "key": 2070,
          "isCountryDefault": false,
          "name": "Port Louis"
        },
        {
          "key": 2071,
          "isCountryDefault": false,
          "name": "Rivière du Rempart"
        },
        {
          "key": 2075,
          "isCountryDefault": false,
          "name": "Rodrigues"
        },
        {
          "key": 2072,
          "isCountryDefault": false,
          "name": "Savanne"
        }
      ],
      "isEu": false
    },
    {
      "key": "mayotte",
      "name": "Mayotte",
      "code": "YT",
      "region": "Afrika",
      "states": [
        {
          "key": 235,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2084,
          "isCountryDefault": false,
          "name": "Acoua"
        },
        {
          "key": 2088,
          "isCountryDefault": false,
          "name": "Bandraboua"
        },
        {
          "key": 2080,
          "isCountryDefault": false,
          "name": "Bandrele"
        },
        {
          "key": 2081,
          "isCountryDefault": false,
          "name": "Bouéni"
        },
        {
          "key": 2091,
          "isCountryDefault": false,
          "name": "Chiconi"
        },
        {
          "key": 2082,
          "isCountryDefault": false,
          "name": "Chirongui"
        },
        {
          "key": 2090,
          "isCountryDefault": false,
          "name": "Dembeni"
        },
        {
          "key": 2078,
          "isCountryDefault": false,
          "name": "Dzaoudzi"
        },
        {
          "key": 2083,
          "isCountryDefault": false,
          "name": "Kani-Kéli"
        },
        {
          "key": 2076,
          "isCountryDefault": false,
          "name": "Koungou"
        },
        {
          "key": 2077,
          "isCountryDefault": false,
          "name": "Mamoudzou"
        },
        {
          "key": 2086,
          "isCountryDefault": false,
          "name": "Mtsamboro"
        },
        {
          "key": 2089,
          "isCountryDefault": false,
          "name": "M'Tsangamouji"
        },
        {
          "key": 2092,
          "isCountryDefault": false,
          "name": "Ouangani"
        },
        {
          "key": 2079,
          "isCountryDefault": false,
          "name": "Pamandzi"
        },
        {
          "key": 2087,
          "isCountryDefault": false,
          "name": "Sada"
        },
        {
          "key": 2093,
          "isCountryDefault": false,
          "name": "Tsingoni"
        }
      ],
      "isEu": false
    },
    {
      "key": "mexico",
      "name": "Mexiko",
      "code": "MX",
      "region": "Nordamerika",
      "states": [
        {
          "key": 44,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 453,
          "isCountryDefault": false,
          "name": "Aguascalientes"
        },
        {
          "key": 454,
          "isCountryDefault": false,
          "name": "Baja California"
        },
        {
          "key": 455,
          "isCountryDefault": false,
          "name": "Baja California Sur"
        },
        {
          "key": 456,
          "isCountryDefault": false,
          "name": "Campeche"
        },
        {
          "key": 457,
          "isCountryDefault": false,
          "name": "Chiapas"
        },
        {
          "key": 458,
          "isCountryDefault": false,
          "name": "Chihuahua"
        },
        {
          "key": 3852,
          "isCountryDefault": false,
          "name": "Ciudad de México (Distrito Federal)"
        },
        {
          "key": 459,
          "isCountryDefault": false,
          "name": "Coahuila"
        },
        {
          "key": 460,
          "isCountryDefault": false,
          "name": "Colima"
        },
        {
          "key": 461,
          "isCountryDefault": false,
          "name": "Durango"
        },
        {
          "key": 462,
          "isCountryDefault": false,
          "name": "Guanajuato"
        },
        {
          "key": 463,
          "isCountryDefault": false,
          "name": "Guerrero"
        },
        {
          "key": 464,
          "isCountryDefault": false,
          "name": "Hidalgo"
        },
        {
          "key": 465,
          "isCountryDefault": false,
          "name": "Jalisco"
        },
        {
          "key": 466,
          "isCountryDefault": false,
          "name": "México"
        },
        {
          "key": 467,
          "isCountryDefault": false,
          "name": "Michoacán"
        },
        {
          "key": 468,
          "isCountryDefault": false,
          "name": "Morelos"
        },
        {
          "key": 469,
          "isCountryDefault": false,
          "name": "Nayarit"
        },
        {
          "key": 470,
          "isCountryDefault": false,
          "name": "Nuevo León"
        },
        {
          "key": 471,
          "isCountryDefault": false,
          "name": "Oaxaca"
        },
        {
          "key": 472,
          "isCountryDefault": false,
          "name": "Puebla"
        },
        {
          "key": 473,
          "isCountryDefault": false,
          "name": "Querétaro"
        },
        {
          "key": 474,
          "isCountryDefault": false,
          "name": "Quintana Roo"
        },
        {
          "key": 475,
          "isCountryDefault": false,
          "name": "San Luis Potosí"
        },
        {
          "key": 476,
          "isCountryDefault": false,
          "name": "Sinaloa"
        },
        {
          "key": 477,
          "isCountryDefault": false,
          "name": "Sonora"
        },
        {
          "key": 478,
          "isCountryDefault": false,
          "name": "Tabasco"
        },
        {
          "key": 479,
          "isCountryDefault": false,
          "name": "Tamaulipas"
        },
        {
          "key": 480,
          "isCountryDefault": false,
          "name": "Tlaxcala"
        },
        {
          "key": 481,
          "isCountryDefault": false,
          "name": "Veracruz"
        },
        {
          "key": 482,
          "isCountryDefault": false,
          "name": "Yucatán"
        },
        {
          "key": 483,
          "isCountryDefault": false,
          "name": "Zacatecas"
        }
      ],
      "isEu": false
    },
    {
      "key": "micronesia",
      "name": "Mikronesien",
      "code": "FM",
      "region": "Ozeanien",
      "states": [
        {
          "key": 142,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2094,
          "isCountryDefault": false,
          "name": "Chuuk"
        },
        {
          "key": 2095,
          "isCountryDefault": false,
          "name": "Kosrae"
        },
        {
          "key": 2096,
          "isCountryDefault": false,
          "name": "Pohnpei"
        },
        {
          "key": 2097,
          "isCountryDefault": false,
          "name": "Yap"
        }
      ],
      "isEu": false
    },
    {
      "key": "moldova",
      "name": "Moldau",
      "code": "MD",
      "region": "Europa",
      "states": [
        {
          "key": 178,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2098,
          "isCountryDefault": false,
          "name": "Anenii Noi"
        },
        {
          "key": 2099,
          "isCountryDefault": false,
          "name": "Basarabeasca"
        },
        {
          "key": 2100,
          "isCountryDefault": false,
          "name": "Briceni"
        },
        {
          "key": 2101,
          "isCountryDefault": false,
          "name": "Cahul"
        },
        {
          "key": 2103,
          "isCountryDefault": false,
          "name": "Călărași"
        },
        {
          "key": 2102,
          "isCountryDefault": false,
          "name": "Cantemir"
        },
        {
          "key": 2104,
          "isCountryDefault": false,
          "name": "Căuşeni"
        },
        {
          "key": 2105,
          "isCountryDefault": false,
          "name": "Cimișlia"
        },
        {
          "key": 2106,
          "isCountryDefault": false,
          "name": "Criuleni"
        },
        {
          "key": 2107,
          "isCountryDefault": false,
          "name": "Dondușeni"
        },
        {
          "key": 2108,
          "isCountryDefault": false,
          "name": "Drochia"
        },
        {
          "key": 2109,
          "isCountryDefault": false,
          "name": "Dubăsari"
        },
        {
          "key": 2110,
          "isCountryDefault": false,
          "name": "Edineț"
        },
        {
          "key": 2111,
          "isCountryDefault": false,
          "name": "Fălești"
        },
        {
          "key": 2112,
          "isCountryDefault": false,
          "name": "Florești"
        },
        {
          "key": 2113,
          "isCountryDefault": false,
          "name": "Glodeni"
        },
        {
          "key": 2114,
          "isCountryDefault": false,
          "name": "Hîncești"
        },
        {
          "key": 2115,
          "isCountryDefault": false,
          "name": "Ialoveni"
        },
        {
          "key": 2116,
          "isCountryDefault": false,
          "name": "Leova"
        },
        {
          "key": 2117,
          "isCountryDefault": false,
          "name": "Nisporeni"
        },
        {
          "key": 2118,
          "isCountryDefault": false,
          "name": "Ocniţa"
        },
        {
          "key": 2119,
          "isCountryDefault": false,
          "name": "Orhei"
        },
        {
          "key": 2120,
          "isCountryDefault": false,
          "name": "Rezina"
        },
        {
          "key": 2121,
          "isCountryDefault": false,
          "name": "Rîșcani"
        },
        {
          "key": 2122,
          "isCountryDefault": false,
          "name": "Sîngerei"
        },
        {
          "key": 2125,
          "isCountryDefault": false,
          "name": "Șoldănești"
        },
        {
          "key": 2123,
          "isCountryDefault": false,
          "name": "Soroca"
        },
        {
          "key": 2126,
          "isCountryDefault": false,
          "name": "Ştefan Vodă"
        },
        {
          "key": 2124,
          "isCountryDefault": false,
          "name": "Strășeni"
        },
        {
          "key": 2127,
          "isCountryDefault": false,
          "name": "Taraclia"
        },
        {
          "key": 2128,
          "isCountryDefault": false,
          "name": "Telenești"
        },
        {
          "key": 2129,
          "isCountryDefault": false,
          "name": "Ungheni"
        }
      ],
      "isEu": false
    },
    {
      "key": "monaco",
      "name": "Monaco",
      "code": "MC",
      "region": "Europa",
      "states": [
        {
          "key": 37,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2138,
          "isCountryDefault": false,
          "name": "Fontvieille"
        },
        {
          "key": 2135,
          "isCountryDefault": false,
          "name": "La Colle"
        },
        {
          "key": 2134,
          "isCountryDefault": false,
          "name": "La Condamine"
        },
        {
          "key": 2131,
          "isCountryDefault": false,
          "name": "La Rousse/Saint Roman"
        },
        {
          "key": 2132,
          "isCountryDefault": false,
          "name": "Larvotto/Bas Moulins"
        },
        {
          "key": 2136,
          "isCountryDefault": false,
          "name": "Les Révoires"
        },
        {
          "key": 2137,
          "isCountryDefault": false,
          "name": "Moneghetti/ Bd de Belgique"
        },
        {
          "key": 2130,
          "isCountryDefault": false,
          "name": "Monte Carlo/Spélugues"
        },
        {
          "key": 2133,
          "isCountryDefault": false,
          "name": "Saint Michel"
        }
      ],
      "isEu": false
    },
    {
      "key": "mongolia",
      "name": "Mongolei",
      "code": "MN",
      "region": "Asien",
      "states": [
        {
          "key": 39,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2139,
          "isCountryDefault": false,
          "name": "Arkhangai"
        },
        {
          "key": 2141,
          "isCountryDefault": false,
          "name": "Bayankhongor"
        },
        {
          "key": 2140,
          "isCountryDefault": false,
          "name": "Bayan-Ölgii"
        },
        {
          "key": 2142,
          "isCountryDefault": false,
          "name": "Bulgan"
        },
        {
          "key": 2143,
          "isCountryDefault": false,
          "name": "Darkhan-Uul"
        },
        {
          "key": 2144,
          "isCountryDefault": false,
          "name": "Dornod"
        },
        {
          "key": 2145,
          "isCountryDefault": false,
          "name": "Dornogovi"
        },
        {
          "key": 2146,
          "isCountryDefault": false,
          "name": "Dundgovi"
        },
        {
          "key": 2147,
          "isCountryDefault": false,
          "name": "Govi-Altai"
        },
        {
          "key": 2148,
          "isCountryDefault": false,
          "name": "Govisümber"
        },
        {
          "key": 2149,
          "isCountryDefault": false,
          "name": "Khentii"
        },
        {
          "key": 2150,
          "isCountryDefault": false,
          "name": "Khovd"
        },
        {
          "key": 2151,
          "isCountryDefault": false,
          "name": "Khövsgöl"
        },
        {
          "key": 2152,
          "isCountryDefault": false,
          "name": "Ömnögovi"
        },
        {
          "key": 2153,
          "isCountryDefault": false,
          "name": "Orkhon"
        },
        {
          "key": 2154,
          "isCountryDefault": false,
          "name": "Övörkhangai"
        },
        {
          "key": 2155,
          "isCountryDefault": false,
          "name": "Selenge"
        },
        {
          "key": 2156,
          "isCountryDefault": false,
          "name": "Sükhbaatar"
        },
        {
          "key": 2157,
          "isCountryDefault": false,
          "name": "Töv"
        },
        {
          "key": 2158,
          "isCountryDefault": false,
          "name": "Uvs"
        },
        {
          "key": 2159,
          "isCountryDefault": false,
          "name": "Zavkhan"
        }
      ],
      "isEu": false
    },
    {
      "key": "montenegro",
      "name": "Montenegro",
      "code": "ME",
      "region": "Europa",
      "states": [
        {
          "key": 239,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2163,
          "isCountryDefault": false,
          "name": "Andrijevica"
        },
        {
          "key": 2164,
          "isCountryDefault": false,
          "name": "Bar"
        },
        {
          "key": 2165,
          "isCountryDefault": false,
          "name": "Berane"
        },
        {
          "key": 2166,
          "isCountryDefault": false,
          "name": "Bijelo Polje"
        },
        {
          "key": 2167,
          "isCountryDefault": false,
          "name": "Budva"
        },
        {
          "key": 2168,
          "isCountryDefault": false,
          "name": "Cetinje"
        },
        {
          "key": 2169,
          "isCountryDefault": false,
          "name": "Danilovgrad"
        },
        {
          "key": 2170,
          "isCountryDefault": false,
          "name": "Herceg Novi"
        },
        {
          "key": 2171,
          "isCountryDefault": false,
          "name": "Kolašin"
        },
        {
          "key": 2172,
          "isCountryDefault": false,
          "name": "Kotor"
        },
        {
          "key": 2173,
          "isCountryDefault": false,
          "name": "Mojkovac"
        },
        {
          "key": 2174,
          "isCountryDefault": false,
          "name": "Nikšić"
        },
        {
          "key": 2175,
          "isCountryDefault": false,
          "name": "Plav"
        },
        {
          "key": 2177,
          "isCountryDefault": false,
          "name": "Pljevlja"
        },
        {
          "key": 2176,
          "isCountryDefault": false,
          "name": "Plužine"
        },
        {
          "key": 2178,
          "isCountryDefault": false,
          "name": "Podgorica"
        },
        {
          "key": 2179,
          "isCountryDefault": false,
          "name": "Rožaje"
        },
        {
          "key": 2180,
          "isCountryDefault": false,
          "name": "Šavnik"
        },
        {
          "key": 2181,
          "isCountryDefault": false,
          "name": "Tivat"
        },
        {
          "key": 2182,
          "isCountryDefault": false,
          "name": "Ulcinj"
        },
        {
          "key": 2183,
          "isCountryDefault": false,
          "name": "Žabljak"
        }
      ],
      "isEu": false
    },
    {
      "key": "monserrat",
      "name": "Montserrat",
      "code": "MS",
      "region": "Nordamerika",
      "states": [
        {
          "key": 184,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2160,
          "isCountryDefault": false,
          "name": "Saint Anthony Parish"
        },
        {
          "key": 2161,
          "isCountryDefault": false,
          "name": "Saint Georges Parish"
        },
        {
          "key": 2162,
          "isCountryDefault": false,
          "name": "Saint Peter Parish"
        }
      ],
      "isEu": false
    },
    {
      "key": "mozambique",
      "name": "Mosambik",
      "code": "MZ",
      "region": "Afrika",
      "states": [
        {
          "key": 187,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2197,
          "isCountryDefault": false,
          "name": "Cabo Delgado"
        },
        {
          "key": 2198,
          "isCountryDefault": false,
          "name": "Gaza"
        },
        {
          "key": 2199,
          "isCountryDefault": false,
          "name": "Inhambane"
        },
        {
          "key": 2200,
          "isCountryDefault": false,
          "name": "Manica"
        },
        {
          "key": 2201,
          "isCountryDefault": false,
          "name": "Maputo"
        },
        {
          "key": 2202,
          "isCountryDefault": false,
          "name": "Maputo (city)"
        },
        {
          "key": 2203,
          "isCountryDefault": false,
          "name": "Nampula"
        },
        {
          "key": 2204,
          "isCountryDefault": false,
          "name": "Niassa"
        },
        {
          "key": 2205,
          "isCountryDefault": false,
          "name": "Sofala"
        },
        {
          "key": 2206,
          "isCountryDefault": false,
          "name": "Tete"
        },
        {
          "key": 2207,
          "isCountryDefault": false,
          "name": "Zambezia"
        }
      ],
      "isEu": false
    },
    {
      "key": "myanmarBurma",
      "name": "Myanmar (Birma)",
      "code": "MM",
      "region": "Asien",
      "states": [
        {
          "key": 181,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2208,
          "isCountryDefault": false,
          "name": "Ayeyarwady"
        },
        {
          "key": 2209,
          "isCountryDefault": false,
          "name": "Bago"
        },
        {
          "key": 2210,
          "isCountryDefault": false,
          "name": "Chin"
        },
        {
          "key": 2211,
          "isCountryDefault": false,
          "name": "Kachin"
        },
        {
          "key": 2212,
          "isCountryDefault": false,
          "name": "Kayah"
        },
        {
          "key": 2213,
          "isCountryDefault": false,
          "name": "Kayin"
        },
        {
          "key": 2214,
          "isCountryDefault": false,
          "name": "Magway"
        },
        {
          "key": 2215,
          "isCountryDefault": false,
          "name": "Mandalay"
        },
        {
          "key": 2216,
          "isCountryDefault": false,
          "name": "Mon State"
        },
        {
          "key": 2222,
          "isCountryDefault": false,
          "name": "Naypyidaw Union"
        },
        {
          "key": 2217,
          "isCountryDefault": false,
          "name": "Rakhine"
        },
        {
          "key": 2219,
          "isCountryDefault": false,
          "name": "Sagaing"
        },
        {
          "key": 2218,
          "isCountryDefault": false,
          "name": "Shan State"
        },
        {
          "key": 2220,
          "isCountryDefault": false,
          "name": "Tanintharyi"
        },
        {
          "key": 2221,
          "isCountryDefault": false,
          "name": "Yangon"
        }
      ],
      "isEu": false
    },
    {
      "key": "namibia",
      "name": "Namibia",
      "code": "NA",
      "region": "Afrika",
      "states": [
        {
          "key": 46,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2223,
          "isCountryDefault": false,
          "name": "Caprivi"
        },
        {
          "key": 2224,
          "isCountryDefault": false,
          "name": "Erongo"
        },
        {
          "key": 2225,
          "isCountryDefault": false,
          "name": "Hardap"
        },
        {
          "key": 2226,
          "isCountryDefault": false,
          "name": "Karas"
        },
        {
          "key": 2227,
          "isCountryDefault": false,
          "name": "Kavango"
        },
        {
          "key": 2228,
          "isCountryDefault": false,
          "name": "Khomas"
        },
        {
          "key": 2229,
          "isCountryDefault": false,
          "name": "Kunene"
        },
        {
          "key": 2230,
          "isCountryDefault": false,
          "name": "Ohangwena"
        },
        {
          "key": 2231,
          "isCountryDefault": false,
          "name": "Omaheke"
        },
        {
          "key": 2232,
          "isCountryDefault": false,
          "name": "Omusati"
        },
        {
          "key": 2233,
          "isCountryDefault": false,
          "name": "Oshana"
        },
        {
          "key": 2234,
          "isCountryDefault": false,
          "name": "Oshikoto"
        },
        {
          "key": 2235,
          "isCountryDefault": false,
          "name": "Otjozondjupa"
        }
      ],
      "isEu": false
    },
    {
      "key": "nauru",
      "name": "Nauru",
      "code": "NR",
      "region": "Ozeanien",
      "states": [
        {
          "key": 191,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2236,
          "isCountryDefault": false,
          "name": "Aiwo"
        },
        {
          "key": 2237,
          "isCountryDefault": false,
          "name": "Anabar"
        },
        {
          "key": 2238,
          "isCountryDefault": false,
          "name": "Anetan"
        },
        {
          "key": 2239,
          "isCountryDefault": false,
          "name": "Anibare"
        },
        {
          "key": 2240,
          "isCountryDefault": false,
          "name": "Baiti"
        },
        {
          "key": 2241,
          "isCountryDefault": false,
          "name": "Boe"
        },
        {
          "key": 2242,
          "isCountryDefault": false,
          "name": "Buada"
        },
        {
          "key": 2243,
          "isCountryDefault": false,
          "name": "Denigomodu"
        },
        {
          "key": 2244,
          "isCountryDefault": false,
          "name": "Ewa"
        },
        {
          "key": 2245,
          "isCountryDefault": false,
          "name": "Ijuw"
        },
        {
          "key": 2246,
          "isCountryDefault": false,
          "name": "Meneng"
        },
        {
          "key": 2247,
          "isCountryDefault": false,
          "name": "Nibok"
        },
        {
          "key": 2248,
          "isCountryDefault": false,
          "name": "Uaboe"
        },
        {
          "key": 2249,
          "isCountryDefault": false,
          "name": "Yaren"
        }
      ],
      "isEu": false
    },
    {
      "key": "nepal",
      "name": "Nepal",
      "code": "NP",
      "region": "Asien",
      "states": [
        {
          "key": 49,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2250,
          "isCountryDefault": false,
          "name": "Bagmati"
        },
        {
          "key": 2251,
          "isCountryDefault": false,
          "name": "Bheri"
        },
        {
          "key": 2252,
          "isCountryDefault": false,
          "name": "Dhawalagiri"
        },
        {
          "key": 2253,
          "isCountryDefault": false,
          "name": "Gandaki"
        },
        {
          "key": 2254,
          "isCountryDefault": false,
          "name": "Janakpur"
        },
        {
          "key": 2255,
          "isCountryDefault": false,
          "name": "Karnali"
        },
        {
          "key": 2256,
          "isCountryDefault": false,
          "name": "Kosi"
        },
        {
          "key": 2257,
          "isCountryDefault": false,
          "name": "Lumbini"
        },
        {
          "key": 2258,
          "isCountryDefault": false,
          "name": "Mahakali"
        },
        {
          "key": 2259,
          "isCountryDefault": false,
          "name": "Mechi"
        },
        {
          "key": 2260,
          "isCountryDefault": false,
          "name": "Narayani"
        },
        {
          "key": 2261,
          "isCountryDefault": false,
          "name": "Rapti"
        },
        {
          "key": 2262,
          "isCountryDefault": false,
          "name": "Sagarmatha"
        },
        {
          "key": 2263,
          "isCountryDefault": false,
          "name": "Seti"
        }
      ],
      "isEu": false
    },
    {
      "key": "newCaledonia",
      "name": "Neukaledonien",
      "code": "NC",
      "region": "Ozeanien",
      "states": [
        {
          "key": 188,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2272,
          "isCountryDefault": false,
          "name": "Loyalty Islands Province"
        },
        {
          "key": 2271,
          "isCountryDefault": false,
          "name": "North Province"
        },
        {
          "key": 2270,
          "isCountryDefault": false,
          "name": "South Province"
        }
      ],
      "isEu": false
    },
    {
      "key": "newZealand",
      "name": "Neuseeland",
      "code": "NZ",
      "region": "Ozeanien",
      "states": [
        {
          "key": 50,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2274,
          "isCountryDefault": false,
          "name": "Auckland"
        },
        {
          "key": 2276,
          "isCountryDefault": false,
          "name": "Bay of Plenty"
        },
        {
          "key": 2286,
          "isCountryDefault": false,
          "name": "Canterbury"
        },
        {
          "key": 2277,
          "isCountryDefault": false,
          "name": "Gisborne"
        },
        {
          "key": 2278,
          "isCountryDefault": false,
          "name": "Hawke's Bay"
        },
        {
          "key": 2280,
          "isCountryDefault": false,
          "name": "Manawatu-Wanganui"
        },
        {
          "key": 2284,
          "isCountryDefault": false,
          "name": "Marlborough"
        },
        {
          "key": 2283,
          "isCountryDefault": false,
          "name": "Nelson"
        },
        {
          "key": 2273,
          "isCountryDefault": false,
          "name": "Northland"
        },
        {
          "key": 2287,
          "isCountryDefault": false,
          "name": "Otago"
        },
        {
          "key": 2288,
          "isCountryDefault": false,
          "name": "Southland"
        },
        {
          "key": 2279,
          "isCountryDefault": false,
          "name": "Taranaki"
        },
        {
          "key": 2282,
          "isCountryDefault": false,
          "name": "Tasman"
        },
        {
          "key": 2275,
          "isCountryDefault": false,
          "name": "Waikato"
        },
        {
          "key": 2281,
          "isCountryDefault": false,
          "name": "Wellington"
        },
        {
          "key": 2285,
          "isCountryDefault": false,
          "name": "West Coast"
        }
      ],
      "isEu": false
    },
    {
      "key": "nicaragua",
      "name": "Nicaragua",
      "code": "NI",
      "region": "Mittelamerika",
      "states": [
        {
          "key": 190,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2289,
          "isCountryDefault": false,
          "name": "Boaco"
        },
        {
          "key": 2290,
          "isCountryDefault": false,
          "name": "Carazo"
        },
        {
          "key": 2291,
          "isCountryDefault": false,
          "name": "Chinandega"
        },
        {
          "key": 2292,
          "isCountryDefault": false,
          "name": "Chontales"
        },
        {
          "key": 2293,
          "isCountryDefault": false,
          "name": "Estelí"
        },
        {
          "key": 2294,
          "isCountryDefault": false,
          "name": "Granada"
        },
        {
          "key": 2295,
          "isCountryDefault": false,
          "name": "Jinotega"
        },
        {
          "key": 2296,
          "isCountryDefault": false,
          "name": "Leon"
        },
        {
          "key": 2297,
          "isCountryDefault": false,
          "name": "Madriz"
        },
        {
          "key": 2298,
          "isCountryDefault": false,
          "name": "Managua"
        },
        {
          "key": 2299,
          "isCountryDefault": false,
          "name": "Masaya"
        },
        {
          "key": 2300,
          "isCountryDefault": false,
          "name": "Matagalpa"
        },
        {
          "key": 2301,
          "isCountryDefault": false,
          "name": "Nueva Segovia"
        },
        {
          "key": 2304,
          "isCountryDefault": false,
          "name": "Región Autónoma del Atlántico Norte"
        },
        {
          "key": 2305,
          "isCountryDefault": false,
          "name": "Región Autónoma del Atlántico Sur"
        },
        {
          "key": 2303,
          "isCountryDefault": false,
          "name": "Río San Juan"
        },
        {
          "key": 2302,
          "isCountryDefault": false,
          "name": "Rivas"
        }
      ],
      "isEu": false
    },
    {
      "key": "netherlands",
      "name": "Niederlande",
      "code": "NL",
      "region": "Europa",
      "states": [
        {
          "key": 47,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 249,
          "isCountryDefault": false,
          "name": "Drenthe"
        },
        {
          "key": 251,
          "isCountryDefault": false,
          "name": "Flevoland"
        },
        {
          "key": 248,
          "isCountryDefault": false,
          "name": "Friesland"
        },
        {
          "key": 252,
          "isCountryDefault": false,
          "name": "Gelderland"
        },
        {
          "key": 247,
          "isCountryDefault": false,
          "name": "Groningen"
        },
        {
          "key": 258,
          "isCountryDefault": false,
          "name": "Limburg"
        },
        {
          "key": 257,
          "isCountryDefault": false,
          "name": "Noord-Brabant"
        },
        {
          "key": 254,
          "isCountryDefault": false,
          "name": "Noord-Holland"
        },
        {
          "key": 250,
          "isCountryDefault": false,
          "name": "Overijssel"
        },
        {
          "key": 253,
          "isCountryDefault": false,
          "name": "Utrecht"
        },
        {
          "key": 256,
          "isCountryDefault": false,
          "name": "Zeeland"
        },
        {
          "key": 255,
          "isCountryDefault": false,
          "name": "Zuid-Holland"
        }
      ],
      "isEu": true
    },
    {
      "key": "niger",
      "name": "Niger",
      "code": "NE",
      "region": "Afrika",
      "states": [
        {
          "key": 98,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2306,
          "isCountryDefault": false,
          "name": "Agadez"
        },
        {
          "key": 2307,
          "isCountryDefault": false,
          "name": "Diffa"
        },
        {
          "key": 2308,
          "isCountryDefault": false,
          "name": "Dosso"
        },
        {
          "key": 2309,
          "isCountryDefault": false,
          "name": "Maradi"
        },
        {
          "key": 2313,
          "isCountryDefault": false,
          "name": "Niamey"
        },
        {
          "key": 2310,
          "isCountryDefault": false,
          "name": "Tahoua"
        },
        {
          "key": 2311,
          "isCountryDefault": false,
          "name": "Tillabéri"
        },
        {
          "key": 2312,
          "isCountryDefault": false,
          "name": "Zinder"
        }
      ],
      "isEu": false
    },
    {
      "key": "nigeria",
      "name": "Nigeria",
      "code": "NG",
      "region": "Afrika",
      "states": [
        {
          "key": 97,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2319,
          "isCountryDefault": false,
          "name": "Abia"
        },
        {
          "key": 2314,
          "isCountryDefault": false,
          "name": "Abuja"
        },
        {
          "key": 2318,
          "isCountryDefault": false,
          "name": "Adamawa"
        },
        {
          "key": 2317,
          "isCountryDefault": false,
          "name": "Akwa Ibom"
        },
        {
          "key": 2315,
          "isCountryDefault": false,
          "name": "Anambra"
        },
        {
          "key": 2320,
          "isCountryDefault": false,
          "name": "Bauchi"
        },
        {
          "key": 2321,
          "isCountryDefault": false,
          "name": "Bayelsa"
        },
        {
          "key": 2322,
          "isCountryDefault": false,
          "name": "Benue"
        },
        {
          "key": 2323,
          "isCountryDefault": false,
          "name": "Borno"
        },
        {
          "key": 2324,
          "isCountryDefault": false,
          "name": "Cross River"
        },
        {
          "key": 2325,
          "isCountryDefault": false,
          "name": "Delta"
        },
        {
          "key": 2326,
          "isCountryDefault": false,
          "name": "Ebonyi"
        },
        {
          "key": 2327,
          "isCountryDefault": false,
          "name": "Edo"
        },
        {
          "key": 2328,
          "isCountryDefault": false,
          "name": "Ekiti"
        },
        {
          "key": 2316,
          "isCountryDefault": false,
          "name": "Enugu"
        },
        {
          "key": 2329,
          "isCountryDefault": false,
          "name": "Gombe"
        },
        {
          "key": 2330,
          "isCountryDefault": false,
          "name": "Imo"
        },
        {
          "key": 2331,
          "isCountryDefault": false,
          "name": "Jigawa"
        },
        {
          "key": 2332,
          "isCountryDefault": false,
          "name": "Kaduna"
        },
        {
          "key": 2333,
          "isCountryDefault": false,
          "name": "Kano"
        },
        {
          "key": 2334,
          "isCountryDefault": false,
          "name": "Katsina"
        },
        {
          "key": 2335,
          "isCountryDefault": false,
          "name": "Kebbi"
        },
        {
          "key": 2336,
          "isCountryDefault": false,
          "name": "Kogi"
        },
        {
          "key": 2337,
          "isCountryDefault": false,
          "name": "Kwara"
        },
        {
          "key": 2338,
          "isCountryDefault": false,
          "name": "Lagos"
        },
        {
          "key": 2339,
          "isCountryDefault": false,
          "name": "Nasarawa"
        },
        {
          "key": 2340,
          "isCountryDefault": false,
          "name": "Niger"
        },
        {
          "key": 2341,
          "isCountryDefault": false,
          "name": "Ogun"
        },
        {
          "key": 2342,
          "isCountryDefault": false,
          "name": "Ondo"
        },
        {
          "key": 2343,
          "isCountryDefault": false,
          "name": "Osun"
        },
        {
          "key": 2344,
          "isCountryDefault": false,
          "name": "Oyo"
        },
        {
          "key": 2345,
          "isCountryDefault": false,
          "name": "Plateau"
        },
        {
          "key": 2346,
          "isCountryDefault": false,
          "name": "Rivers"
        },
        {
          "key": 2347,
          "isCountryDefault": false,
          "name": "Sokoto"
        },
        {
          "key": 2348,
          "isCountryDefault": false,
          "name": "Taraba"
        },
        {
          "key": 2349,
          "isCountryDefault": false,
          "name": "Yobe"
        },
        {
          "key": 2350,
          "isCountryDefault": false,
          "name": "Zamfara"
        }
      ],
      "isEu": false
    },
    {
      "key": "niue",
      "name": "Niue",
      "code": "NU",
      "region": "Ozeanien",
      "states": [
        {
          "key": 192,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2351,
          "isCountryDefault": false,
          "name": "Niue"
        }
      ],
      "isEu": false
    },
    {
      "key": "northKorea",
      "name": "Nordkorea",
      "code": "KP",
      "region": "Asien",
      "states": [
        {
          "key": 168,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2365,
          "isCountryDefault": false,
          "name": "Chagang"
        },
        {
          "key": 2370,
          "isCountryDefault": false,
          "name": "Hamgyongbuk"
        },
        {
          "key": 2369,
          "isCountryDefault": false,
          "name": "Hamgyongnam"
        },
        {
          "key": 2367,
          "isCountryDefault": false,
          "name": "Hwanghaebuk"
        },
        {
          "key": 2366,
          "isCountryDefault": false,
          "name": "Hwanghaenam"
        },
        {
          "key": 2368,
          "isCountryDefault": false,
          "name": "Kangwon"
        },
        {
          "key": 2364,
          "isCountryDefault": false,
          "name": "Pyonganbuk"
        },
        {
          "key": 2363,
          "isCountryDefault": false,
          "name": "Pyongannam"
        },
        {
          "key": 2371,
          "isCountryDefault": false,
          "name": "Ryanggang"
        }
      ],
      "isEu": false
    },
    {
      "key": "northernMarianaIslands",
      "name": "Nördliche Marianen",
      "code": "MP",
      "region": "Ozeanien",
      "states": [
        {
          "key": 183,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2381,
          "isCountryDefault": false,
          "name": "Agrihan"
        },
        {
          "key": 2391,
          "isCountryDefault": false,
          "name": "Aguijan"
        },
        {
          "key": 2383,
          "isCountryDefault": false,
          "name": "Alamagan"
        },
        {
          "key": 2387,
          "isCountryDefault": false,
          "name": "Anatahan"
        },
        {
          "key": 2380,
          "isCountryDefault": false,
          "name": "Asuncion"
        },
        {
          "key": 2388,
          "isCountryDefault": false,
          "name": "Farallon de Medinilla"
        },
        {
          "key": 2378,
          "isCountryDefault": false,
          "name": "Farallon de Pajaros"
        },
        {
          "key": 2384,
          "isCountryDefault": false,
          "name": "Guguan"
        },
        {
          "key": 2379,
          "isCountryDefault": false,
          "name": "Maug Islands"
        },
        {
          "key": 2382,
          "isCountryDefault": false,
          "name": "Pagan"
        },
        {
          "key": 2392,
          "isCountryDefault": false,
          "name": "Rota"
        },
        {
          "key": 2389,
          "isCountryDefault": false,
          "name": "Saipan"
        },
        {
          "key": 2386,
          "isCountryDefault": false,
          "name": "Sarigan"
        },
        {
          "key": 2390,
          "isCountryDefault": false,
          "name": "Tinian"
        },
        {
          "key": 2385,
          "isCountryDefault": false,
          "name": "Zealandia Bank"
        }
      ],
      "isEu": false
    },
    {
      "key": "northMacedonia",
      "name": "Nordmazedonien",
      "code": "MK",
      "region": "Europa",
      "states": [
        {
          "key": 84,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1971,
          "isCountryDefault": false,
          "name": "Eastern"
        },
        {
          "key": 1972,
          "isCountryDefault": false,
          "name": "Northeastern"
        },
        {
          "key": 1973,
          "isCountryDefault": false,
          "name": "Pelagonia"
        },
        {
          "key": 1974,
          "isCountryDefault": false,
          "name": "Polog"
        },
        {
          "key": 1975,
          "isCountryDefault": false,
          "name": "Skopje"
        },
        {
          "key": 1976,
          "isCountryDefault": false,
          "name": "Southeastern"
        },
        {
          "key": 1977,
          "isCountryDefault": false,
          "name": "Southwestern"
        },
        {
          "key": 1978,
          "isCountryDefault": false,
          "name": "Vardar"
        }
      ],
      "isEu": false
    },
    {
      "key": "norfolkIslands",
      "name": "Norfolkinsel",
      "code": "NF",
      "region": "Ozeanien",
      "states": [
        {
          "key": 189,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2354,
          "isCountryDefault": false,
          "name": "Norfolk Islands"
        }
      ],
      "isEu": false
    },
    {
      "key": "northernIreland",
      "name": "Northern Ireland",
      "code": "GB",
      "region": "Europa",
      "states": [
        {
          "key": 242,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2372,
          "isCountryDefault": false,
          "name": "County Antrim"
        },
        {
          "key": 2373,
          "isCountryDefault": false,
          "name": "County Armagh"
        },
        {
          "key": 2374,
          "isCountryDefault": false,
          "name": "County Down"
        },
        {
          "key": 2375,
          "isCountryDefault": false,
          "name": "County Fermanagh"
        },
        {
          "key": 2376,
          "isCountryDefault": false,
          "name": "County Londonderry"
        },
        {
          "key": 2377,
          "isCountryDefault": false,
          "name": "County Tyrone"
        }
      ],
      "isEu": false
    },
    {
      "key": "norway",
      "name": "Norwegen",
      "code": "NO",
      "region": "Europa",
      "states": [
        {
          "key": 48,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2403,
          "isCountryDefault": false,
          "name": "Agder"
        },
        {
          "key": 4112,
          "isCountryDefault": false,
          "name": "Akerus"
        },
        {
          "key": 4113,
          "isCountryDefault": false,
          "name": "Buskerud"
        },
        {
          "key": 4116,
          "isCountryDefault": false,
          "name": "Finnmark"
        },
        {
          "key": 2399,
          "isCountryDefault": false,
          "name": "Innlandet"
        },
        {
          "key": 2408,
          "isCountryDefault": false,
          "name": "Møre og Romsdal"
        },
        {
          "key": 2411,
          "isCountryDefault": false,
          "name": "Nordland"
        },
        {
          "key": 2397,
          "isCountryDefault": false,
          "name": "Oslo"
        },
        {
          "key": 2400,
          "isCountryDefault": false,
          "name": "Østfold"
        },
        {
          "key": 2405,
          "isCountryDefault": false,
          "name": "Rogaland"
        },
        {
          "key": 4114,
          "isCountryDefault": false,
          "name": "Telemark"
        },
        {
          "key": 2412,
          "isCountryDefault": false,
          "name": "Troms"
        },
        {
          "key": 2410,
          "isCountryDefault": false,
          "name": "Trøndelag"
        },
        {
          "key": 2401,
          "isCountryDefault": false,
          "name": "Vestfold"
        },
        {
          "key": 2406,
          "isCountryDefault": false,
          "name": "Vestland"
        }
      ],
      "isEu": false
    },
    {
      "key": "oman",
      "name": "Oman",
      "code": "OM",
      "region": "Naher und Mittlerer Osten",
      "states": [
        {
          "key": 193,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2414,
          "isCountryDefault": false,
          "name": "Ad Dakhiliyah"
        },
        {
          "key": 2418,
          "isCountryDefault": false,
          "name": "Ad Dhahirah"
        },
        {
          "key": 2415,
          "isCountryDefault": false,
          "name": "Al Batinah"
        },
        {
          "key": 2422,
          "isCountryDefault": false,
          "name": "Al Buraymi"
        },
        {
          "key": 2416,
          "isCountryDefault": false,
          "name": "Al Wusta"
        },
        {
          "key": 2417,
          "isCountryDefault": false,
          "name": "Ash Sharqiyah"
        },
        {
          "key": 2421,
          "isCountryDefault": false,
          "name": "Dhofar"
        },
        {
          "key": 2420,
          "isCountryDefault": false,
          "name": "Musandam"
        },
        {
          "key": 2419,
          "isCountryDefault": false,
          "name": "Muscat"
        }
      ],
      "isEu": false
    },
    {
      "key": "austria",
      "name": "Österreich",
      "code": "AT",
      "region": "Europa",
      "states": [
        {
          "key": 3,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 524,
          "isCountryDefault": false,
          "name": "Burgenland"
        },
        {
          "key": 525,
          "isCountryDefault": false,
          "name": "Kärnten"
        },
        {
          "key": 526,
          "isCountryDefault": false,
          "name": "Niederösterreich"
        },
        {
          "key": 527,
          "isCountryDefault": false,
          "name": "Oberösterreich"
        },
        {
          "key": 528,
          "isCountryDefault": false,
          "name": "Salzburg"
        },
        {
          "key": 529,
          "isCountryDefault": false,
          "name": "Steiermark"
        },
        {
          "key": 530,
          "isCountryDefault": false,
          "name": "Tirol"
        },
        {
          "key": 531,
          "isCountryDefault": false,
          "name": "Vorarlberg"
        },
        {
          "key": 532,
          "isCountryDefault": false,
          "name": "Wien"
        }
      ],
      "isEu": true
    },
    {
      "key": "pakistan",
      "name": "Pakistan",
      "code": "PK",
      "region": "Asien",
      "states": [
        {
          "key": 96,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2430,
          "isCountryDefault": false,
          "name": "Azad Kashmir"
        },
        {
          "key": 2424,
          "isCountryDefault": false,
          "name": "Balochistan"
        },
        {
          "key": 2429,
          "isCountryDefault": false,
          "name": "Federally Administered Tribal Areas"
        },
        {
          "key": 2431,
          "isCountryDefault": false,
          "name": "Gilgit-Baltistan"
        },
        {
          "key": 2428,
          "isCountryDefault": false,
          "name": "Islamabad Capital Territory"
        },
        {
          "key": 2425,
          "isCountryDefault": false,
          "name": "Khyber Pakhtunkhwa"
        },
        {
          "key": 2426,
          "isCountryDefault": false,
          "name": "Punjab"
        },
        {
          "key": 2427,
          "isCountryDefault": false,
          "name": "Sindh"
        }
      ],
      "isEu": false
    },
    {
      "key": "palestine",
      "name": "Palästina",
      "code": "PS",
      "region": "Naher und Mittlerer Osten",
      "states": [
        {
          "key": 4107,
          "isCountryDefault": true,
          "name": null
        }
      ],
      "isEu": false
    },
    {
      "key": "palau",
      "name": "Palästinensische Autonomiegebiete",
      "code": "PW",
      "region": "Ozeanien",
      "states": [
        {
          "key": 198,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2433,
          "isCountryDefault": false,
          "name": "Aimeliik"
        },
        {
          "key": 2434,
          "isCountryDefault": false,
          "name": "Airai"
        },
        {
          "key": 2443,
          "isCountryDefault": false,
          "name": "Angaur"
        },
        {
          "key": 2447,
          "isCountryDefault": false,
          "name": "Hatohobei"
        },
        {
          "key": 2432,
          "isCountryDefault": false,
          "name": "Kayangel"
        },
        {
          "key": 2444,
          "isCountryDefault": false,
          "name": "Koror"
        },
        {
          "key": 2435,
          "isCountryDefault": false,
          "name": "Melekeok"
        },
        {
          "key": 2436,
          "isCountryDefault": false,
          "name": "Ngaraard"
        },
        {
          "key": 2437,
          "isCountryDefault": false,
          "name": "Ngarchelong"
        },
        {
          "key": 2438,
          "isCountryDefault": false,
          "name": "Ngardmau"
        },
        {
          "key": 2439,
          "isCountryDefault": false,
          "name": "Ngaremlengui"
        },
        {
          "key": 2440,
          "isCountryDefault": false,
          "name": "Ngatpang"
        },
        {
          "key": 2441,
          "isCountryDefault": false,
          "name": "Ngchesar"
        },
        {
          "key": 2442,
          "isCountryDefault": false,
          "name": "Ngiwal"
        },
        {
          "key": 2445,
          "isCountryDefault": false,
          "name": "Peleliu"
        },
        {
          "key": 2446,
          "isCountryDefault": false,
          "name": "Rock Islands"
        },
        {
          "key": 2448,
          "isCountryDefault": false,
          "name": "Sonsorol"
        }
      ],
      "isEu": false
    },
    {
      "key": "panama",
      "name": "Panama",
      "code": "PA",
      "region": "Mittelamerika",
      "states": [
        {
          "key": 83,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2449,
          "isCountryDefault": false,
          "name": "Bocas del Toro"
        },
        {
          "key": 2450,
          "isCountryDefault": false,
          "name": "Chiriquí"
        },
        {
          "key": 2451,
          "isCountryDefault": false,
          "name": "Coclé"
        },
        {
          "key": 2452,
          "isCountryDefault": false,
          "name": "Colón"
        },
        {
          "key": 2453,
          "isCountryDefault": false,
          "name": "Darién"
        },
        {
          "key": 2458,
          "isCountryDefault": false,
          "name": "Emberá"
        },
        {
          "key": 2454,
          "isCountryDefault": false,
          "name": "Herrera"
        },
        {
          "key": 2461,
          "isCountryDefault": false,
          "name": "Kuna de Madugandí"
        },
        {
          "key": 2462,
          "isCountryDefault": false,
          "name": "Kuna de Wargandí"
        },
        {
          "key": 2459,
          "isCountryDefault": false,
          "name": "Kuna Yala"
        },
        {
          "key": 2455,
          "isCountryDefault": false,
          "name": "Los Santos"
        },
        {
          "key": 2460,
          "isCountryDefault": false,
          "name": "Ngöbe-Buglé"
        },
        {
          "key": 2456,
          "isCountryDefault": false,
          "name": "Panamá"
        },
        {
          "key": 2457,
          "isCountryDefault": false,
          "name": "Veraguas"
        }
      ],
      "isEu": false
    },
    {
      "key": "papuaNewGuinea",
      "name": "Papua-Neuguinea",
      "code": "PG",
      "region": "Ozeanien",
      "states": [
        {
          "key": 195,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2476,
          "isCountryDefault": false,
          "name": "Autonomous Region of Bougainville"
        },
        {
          "key": 2463,
          "isCountryDefault": false,
          "name": "Central"
        },
        {
          "key": 2466,
          "isCountryDefault": false,
          "name": "East New Britain"
        },
        {
          "key": 2467,
          "isCountryDefault": false,
          "name": "East Sepik"
        },
        {
          "key": 2465,
          "isCountryDefault": false,
          "name": "Eastern Highlands"
        },
        {
          "key": 2468,
          "isCountryDefault": false,
          "name": "Enga"
        },
        {
          "key": 2469,
          "isCountryDefault": false,
          "name": "Gulf"
        },
        {
          "key": 2470,
          "isCountryDefault": false,
          "name": "Madang"
        },
        {
          "key": 2471,
          "isCountryDefault": false,
          "name": "Manus"
        },
        {
          "key": 2472,
          "isCountryDefault": false,
          "name": "Milne Bay"
        },
        {
          "key": 2473,
          "isCountryDefault": false,
          "name": "Morobe"
        },
        {
          "key": 2482,
          "isCountryDefault": false,
          "name": "National Capital District"
        },
        {
          "key": 2474,
          "isCountryDefault": false,
          "name": "New Ireland"
        },
        {
          "key": 2475,
          "isCountryDefault": false,
          "name": "Oro (Northern)"
        },
        {
          "key": 2481,
          "isCountryDefault": false,
          "name": "Sandaun (West Sepik)"
        },
        {
          "key": 2464,
          "isCountryDefault": false,
          "name": "Simbu (Chimbu)"
        },
        {
          "key": 2477,
          "isCountryDefault": false,
          "name": "Southern Highlands"
        },
        {
          "key": 2480,
          "isCountryDefault": false,
          "name": "West New Britain"
        },
        {
          "key": 2478,
          "isCountryDefault": false,
          "name": "Western (Fly)"
        },
        {
          "key": 2479,
          "isCountryDefault": false,
          "name": "Western Highlands"
        }
      ],
      "isEu": false
    },
    {
      "key": "paraguay",
      "name": "Paraguay",
      "code": "PY",
      "region": "Südamerika",
      "states": [
        {
          "key": 199,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2483,
          "isCountryDefault": false,
          "name": "Alto Paraguay"
        },
        {
          "key": 2484,
          "isCountryDefault": false,
          "name": "Alto Paraná"
        },
        {
          "key": 2485,
          "isCountryDefault": false,
          "name": "Amambay"
        },
        {
          "key": 2487,
          "isCountryDefault": false,
          "name": "Boquerón"
        },
        {
          "key": 2488,
          "isCountryDefault": false,
          "name": "Caaguazú"
        },
        {
          "key": 2489,
          "isCountryDefault": false,
          "name": "Caazapá"
        },
        {
          "key": 2490,
          "isCountryDefault": false,
          "name": "Canindeyú"
        },
        {
          "key": 2491,
          "isCountryDefault": false,
          "name": "Central"
        },
        {
          "key": 2492,
          "isCountryDefault": false,
          "name": "Concepción"
        },
        {
          "key": 2493,
          "isCountryDefault": false,
          "name": "Cordillera"
        },
        {
          "key": 2486,
          "isCountryDefault": false,
          "name": "Distrito Capital"
        },
        {
          "key": 2494,
          "isCountryDefault": false,
          "name": "Guairá"
        },
        {
          "key": 2495,
          "isCountryDefault": false,
          "name": "Itapúa"
        },
        {
          "key": 2496,
          "isCountryDefault": false,
          "name": "Misiones"
        },
        {
          "key": 2497,
          "isCountryDefault": false,
          "name": "Ñeembucú"
        },
        {
          "key": 2498,
          "isCountryDefault": false,
          "name": "Paraguarí"
        },
        {
          "key": 2499,
          "isCountryDefault": false,
          "name": "Presidente Hayes"
        },
        {
          "key": 2500,
          "isCountryDefault": false,
          "name": "San Pedro"
        }
      ],
      "isEu": false
    },
    {
      "key": "peru",
      "name": "Peru",
      "code": "PE",
      "region": "Südamerika",
      "states": [
        {
          "key": 51,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2501,
          "isCountryDefault": false,
          "name": "Amazonas"
        },
        {
          "key": 2502,
          "isCountryDefault": false,
          "name": "Ancash"
        },
        {
          "key": 2503,
          "isCountryDefault": false,
          "name": "Apurímac"
        },
        {
          "key": 2504,
          "isCountryDefault": false,
          "name": "Arequipa"
        },
        {
          "key": 2505,
          "isCountryDefault": false,
          "name": "Ayacucho"
        },
        {
          "key": 2506,
          "isCountryDefault": false,
          "name": "Cajamarca"
        },
        {
          "key": 2507,
          "isCountryDefault": false,
          "name": "Callao"
        },
        {
          "key": 2508,
          "isCountryDefault": false,
          "name": "Cuzco"
        },
        {
          "key": 2509,
          "isCountryDefault": false,
          "name": "Huancavelica"
        },
        {
          "key": 2510,
          "isCountryDefault": false,
          "name": "Huánuco"
        },
        {
          "key": 2511,
          "isCountryDefault": false,
          "name": "Ica"
        },
        {
          "key": 2512,
          "isCountryDefault": false,
          "name": "Junín"
        },
        {
          "key": 2513,
          "isCountryDefault": false,
          "name": "La Libertad"
        },
        {
          "key": 2514,
          "isCountryDefault": false,
          "name": "Lambayeque"
        },
        {
          "key": 2515,
          "isCountryDefault": false,
          "name": "Lima"
        },
        {
          "key": 2516,
          "isCountryDefault": false,
          "name": "Loreto"
        },
        {
          "key": 2517,
          "isCountryDefault": false,
          "name": "Madre de Dios"
        },
        {
          "key": 2518,
          "isCountryDefault": false,
          "name": "Moquegua"
        },
        {
          "key": 2519,
          "isCountryDefault": false,
          "name": "Pasco"
        },
        {
          "key": 2520,
          "isCountryDefault": false,
          "name": "Piura"
        },
        {
          "key": 2521,
          "isCountryDefault": false,
          "name": "Puno"
        },
        {
          "key": 2522,
          "isCountryDefault": false,
          "name": "San Martín"
        },
        {
          "key": 2523,
          "isCountryDefault": false,
          "name": "Tacna"
        },
        {
          "key": 2524,
          "isCountryDefault": false,
          "name": "Tumbes"
        },
        {
          "key": 2525,
          "isCountryDefault": false,
          "name": "Ucayali"
        }
      ],
      "isEu": false
    },
    {
      "key": "philippines",
      "name": "Philippinen",
      "code": "PH",
      "region": "Asien",
      "states": [
        {
          "key": 92,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2526,
          "isCountryDefault": false,
          "name": "Abra"
        },
        {
          "key": 2527,
          "isCountryDefault": false,
          "name": "Agusan del Norte"
        },
        {
          "key": 2528,
          "isCountryDefault": false,
          "name": "Agusan del Sur"
        },
        {
          "key": 2529,
          "isCountryDefault": false,
          "name": "Aklan"
        },
        {
          "key": 2530,
          "isCountryDefault": false,
          "name": "Albay"
        },
        {
          "key": 2531,
          "isCountryDefault": false,
          "name": "Antique"
        },
        {
          "key": 2532,
          "isCountryDefault": false,
          "name": "Apayao"
        },
        {
          "key": 2533,
          "isCountryDefault": false,
          "name": "Aurora"
        },
        {
          "key": 2534,
          "isCountryDefault": false,
          "name": "Basilan"
        },
        {
          "key": 2535,
          "isCountryDefault": false,
          "name": "Bataan"
        },
        {
          "key": 2536,
          "isCountryDefault": false,
          "name": "Batanes"
        },
        {
          "key": 2537,
          "isCountryDefault": false,
          "name": "Batangas"
        },
        {
          "key": 2538,
          "isCountryDefault": false,
          "name": "Benguet"
        },
        {
          "key": 2539,
          "isCountryDefault": false,
          "name": "Biliran"
        },
        {
          "key": 2540,
          "isCountryDefault": false,
          "name": "Bohol"
        },
        {
          "key": 2541,
          "isCountryDefault": false,
          "name": "Bukidnon"
        },
        {
          "key": 2542,
          "isCountryDefault": false,
          "name": "Bulacan"
        },
        {
          "key": 2543,
          "isCountryDefault": false,
          "name": "Cagayan"
        },
        {
          "key": 2544,
          "isCountryDefault": false,
          "name": "Camarines Norte"
        },
        {
          "key": 2545,
          "isCountryDefault": false,
          "name": "Camarines Sur"
        },
        {
          "key": 2546,
          "isCountryDefault": false,
          "name": "Camiguin"
        },
        {
          "key": 2547,
          "isCountryDefault": false,
          "name": "Capiz"
        },
        {
          "key": 2548,
          "isCountryDefault": false,
          "name": "Catanduanes"
        },
        {
          "key": 2549,
          "isCountryDefault": false,
          "name": "Cavite"
        },
        {
          "key": 2550,
          "isCountryDefault": false,
          "name": "Cebu"
        },
        {
          "key": 2551,
          "isCountryDefault": false,
          "name": "Compostela Valley"
        },
        {
          "key": 2552,
          "isCountryDefault": false,
          "name": "Cotabato"
        },
        {
          "key": 2553,
          "isCountryDefault": false,
          "name": "Davao del Norte"
        },
        {
          "key": 2554,
          "isCountryDefault": false,
          "name": "Davao del Sur"
        },
        {
          "key": 2555,
          "isCountryDefault": false,
          "name": "Davao Oriental"
        },
        {
          "key": 2556,
          "isCountryDefault": false,
          "name": "Dinagat Islands"
        },
        {
          "key": 2557,
          "isCountryDefault": false,
          "name": "Eastern Samar"
        },
        {
          "key": 2558,
          "isCountryDefault": false,
          "name": "Guimaras"
        },
        {
          "key": 2559,
          "isCountryDefault": false,
          "name": "Ifugao"
        },
        {
          "key": 2560,
          "isCountryDefault": false,
          "name": "Ilocos Norte"
        },
        {
          "key": 2561,
          "isCountryDefault": false,
          "name": "Ilocos Sur"
        },
        {
          "key": 2562,
          "isCountryDefault": false,
          "name": "Iloilo"
        },
        {
          "key": 2563,
          "isCountryDefault": false,
          "name": "Isabela"
        },
        {
          "key": 2564,
          "isCountryDefault": false,
          "name": "Kalinga"
        },
        {
          "key": 2565,
          "isCountryDefault": false,
          "name": "La Union"
        },
        {
          "key": 2566,
          "isCountryDefault": false,
          "name": "Laguna"
        },
        {
          "key": 2567,
          "isCountryDefault": false,
          "name": "Lanao del Norte"
        },
        {
          "key": 2568,
          "isCountryDefault": false,
          "name": "Lanao del Sur"
        },
        {
          "key": 2569,
          "isCountryDefault": false,
          "name": "Leyte"
        },
        {
          "key": 2570,
          "isCountryDefault": false,
          "name": "Maguindanao"
        },
        {
          "key": 2571,
          "isCountryDefault": false,
          "name": "Marinduque"
        },
        {
          "key": 2572,
          "isCountryDefault": false,
          "name": "Masbate"
        },
        {
          "key": 2573,
          "isCountryDefault": false,
          "name": "Misamis Occidental"
        },
        {
          "key": 2574,
          "isCountryDefault": false,
          "name": "Misamis Oriental"
        },
        {
          "key": 2575,
          "isCountryDefault": false,
          "name": "Mountain Province"
        },
        {
          "key": 2576,
          "isCountryDefault": false,
          "name": "Negros Occidental"
        },
        {
          "key": 2577,
          "isCountryDefault": false,
          "name": "Negros Oriental"
        },
        {
          "key": 2578,
          "isCountryDefault": false,
          "name": "Northern Samar"
        },
        {
          "key": 2579,
          "isCountryDefault": false,
          "name": "Nueva Ecija"
        },
        {
          "key": 2580,
          "isCountryDefault": false,
          "name": "Nueva Vizcaya"
        },
        {
          "key": 2581,
          "isCountryDefault": false,
          "name": "Occidental Mindoro"
        },
        {
          "key": 2582,
          "isCountryDefault": false,
          "name": "Oriental Mindoro"
        },
        {
          "key": 2583,
          "isCountryDefault": false,
          "name": "Palawan"
        },
        {
          "key": 2584,
          "isCountryDefault": false,
          "name": "Pampanga"
        },
        {
          "key": 2585,
          "isCountryDefault": false,
          "name": "Pangasinan"
        },
        {
          "key": 2586,
          "isCountryDefault": false,
          "name": "Quezon"
        },
        {
          "key": 2587,
          "isCountryDefault": false,
          "name": "Quirino"
        },
        {
          "key": 2588,
          "isCountryDefault": false,
          "name": "Rizal"
        },
        {
          "key": 2589,
          "isCountryDefault": false,
          "name": "Romblon"
        },
        {
          "key": 2590,
          "isCountryDefault": false,
          "name": "Samar"
        },
        {
          "key": 2591,
          "isCountryDefault": false,
          "name": "Sarangani"
        },
        {
          "key": 2592,
          "isCountryDefault": false,
          "name": "Siquijor"
        },
        {
          "key": 2593,
          "isCountryDefault": false,
          "name": "Sorsogon"
        },
        {
          "key": 2594,
          "isCountryDefault": false,
          "name": "South Cotabato"
        },
        {
          "key": 2595,
          "isCountryDefault": false,
          "name": "Southern Leyte"
        },
        {
          "key": 2596,
          "isCountryDefault": false,
          "name": "Sultan Kudarat"
        },
        {
          "key": 2597,
          "isCountryDefault": false,
          "name": "Sulu"
        },
        {
          "key": 2598,
          "isCountryDefault": false,
          "name": "Surigao del Norte"
        },
        {
          "key": 2599,
          "isCountryDefault": false,
          "name": "Surigao del Sur"
        },
        {
          "key": 2600,
          "isCountryDefault": false,
          "name": "Tarlac"
        },
        {
          "key": 2601,
          "isCountryDefault": false,
          "name": "Tawi-Tawi"
        },
        {
          "key": 2602,
          "isCountryDefault": false,
          "name": "Zambales"
        },
        {
          "key": 2603,
          "isCountryDefault": false,
          "name": "Zamboanga del Norte"
        },
        {
          "key": 2604,
          "isCountryDefault": false,
          "name": "Zamboanga del Sur"
        },
        {
          "key": 2605,
          "isCountryDefault": false,
          "name": "Zamboanga Sibugay"
        }
      ],
      "isEu": false
    },
    {
      "key": "pitcairnIslands",
      "name": "Pitcairninseln",
      "code": "PN",
      "region": "Ozeanien",
      "states": [
        {
          "key": 197,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2606,
          "isCountryDefault": false,
          "name": "Ducie Island"
        },
        {
          "key": 2607,
          "isCountryDefault": false,
          "name": "Henderson Island"
        },
        {
          "key": 2608,
          "isCountryDefault": false,
          "name": "Oeno Island"
        },
        {
          "key": 2609,
          "isCountryDefault": false,
          "name": "Pitcairn Island"
        }
      ],
      "isEu": false
    },
    {
      "key": "poland",
      "name": "Polen",
      "code": "PL",
      "region": "Europa",
      "states": [
        {
          "key": 52,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2614,
          "isCountryDefault": false,
          "name": "Dolnośląskie (Lower Silesian)"
        },
        {
          "key": 2611,
          "isCountryDefault": false,
          "name": "Kujawsko-Pomorskie (Kuyavian-Pomeranian)"
        },
        {
          "key": 2613,
          "isCountryDefault": false,
          "name": "Łódzkie (Łódź)"
        },
        {
          "key": 2615,
          "isCountryDefault": false,
          "name": "Lubelskie (Lublin)"
        },
        {
          "key": 2616,
          "isCountryDefault": false,
          "name": "Lubuskie (Lubusz)"
        },
        {
          "key": 2612,
          "isCountryDefault": false,
          "name": "Małopolskie (Lesser Poland)"
        },
        {
          "key": 2617,
          "isCountryDefault": false,
          "name": "Mazowieckie (Masovian)"
        },
        {
          "key": 2618,
          "isCountryDefault": false,
          "name": "Opolskie (Opole)"
        },
        {
          "key": 2622,
          "isCountryDefault": false,
          "name": "Podkarpackie (Subcarpathian)"
        },
        {
          "key": 2619,
          "isCountryDefault": false,
          "name": "Podlaskie (Podlaskie)"
        },
        {
          "key": 2620,
          "isCountryDefault": false,
          "name": "Pomorskie (Pomeranian)"
        },
        {
          "key": 2621,
          "isCountryDefault": false,
          "name": "Śląskie (Silesian)"
        },
        {
          "key": 2623,
          "isCountryDefault": false,
          "name": "Świętokrzyskie (Holy Cross)"
        },
        {
          "key": 2624,
          "isCountryDefault": false,
          "name": "Warmińsko-Mazurskie (Warmian-Masurian)"
        },
        {
          "key": 2610,
          "isCountryDefault": false,
          "name": "Wielkopolskie (Greater Poland)"
        },
        {
          "key": 2625,
          "isCountryDefault": false,
          "name": "Zachodniopomorskie (West Pomeranian)"
        }
      ],
      "isEu": true
    },
    {
      "key": "portugal",
      "name": "Portugal",
      "code": "PT",
      "region": "Europa",
      "states": [
        {
          "key": 53,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2626,
          "isCountryDefault": false,
          "name": "Aveiro"
        },
        {
          "key": 2627,
          "isCountryDefault": false,
          "name": "Beja"
        },
        {
          "key": 2628,
          "isCountryDefault": false,
          "name": "Braga"
        },
        {
          "key": 2629,
          "isCountryDefault": false,
          "name": "Bragança"
        },
        {
          "key": 2630,
          "isCountryDefault": false,
          "name": "Castelo Branco"
        },
        {
          "key": 2631,
          "isCountryDefault": false,
          "name": "Coimbra"
        },
        {
          "key": 2632,
          "isCountryDefault": false,
          "name": "Évora"
        },
        {
          "key": 2633,
          "isCountryDefault": false,
          "name": "Faro"
        },
        {
          "key": 2634,
          "isCountryDefault": false,
          "name": "Guarda"
        },
        {
          "key": 2635,
          "isCountryDefault": false,
          "name": "Leiria"
        },
        {
          "key": 2636,
          "isCountryDefault": false,
          "name": "Lisbon"
        },
        {
          "key": 2637,
          "isCountryDefault": false,
          "name": "Portalegre"
        },
        {
          "key": 2638,
          "isCountryDefault": false,
          "name": "Porto"
        },
        {
          "key": 2645,
          "isCountryDefault": false,
          "name": "Região Autónoma da Madeira"
        },
        {
          "key": 2644,
          "isCountryDefault": false,
          "name": "Região Autónoma dos Açores"
        },
        {
          "key": 2639,
          "isCountryDefault": false,
          "name": "Santarém"
        },
        {
          "key": 2640,
          "isCountryDefault": false,
          "name": "Setúbal"
        },
        {
          "key": 2641,
          "isCountryDefault": false,
          "name": "Viana do Castelo"
        },
        {
          "key": 2642,
          "isCountryDefault": false,
          "name": "Vila Real"
        },
        {
          "key": 2643,
          "isCountryDefault": false,
          "name": "Viseu"
        }
      ],
      "isEu": true
    },
    {
      "key": "puertoRico",
      "name": "Puerto Rico",
      "code": "PR",
      "region": "Nordamerika",
      "states": [
        {
          "key": 93,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3853,
          "isCountryDefault": false,
          "name": "Adjuntas"
        },
        {
          "key": 3854,
          "isCountryDefault": false,
          "name": "Aguada"
        },
        {
          "key": 3855,
          "isCountryDefault": false,
          "name": "Aguadilla"
        },
        {
          "key": 3856,
          "isCountryDefault": false,
          "name": "Aguas Buenas"
        },
        {
          "key": 3857,
          "isCountryDefault": false,
          "name": "Aibonito"
        },
        {
          "key": 3858,
          "isCountryDefault": false,
          "name": "Añasco"
        },
        {
          "key": 3859,
          "isCountryDefault": false,
          "name": "Arecibo"
        },
        {
          "key": 3860,
          "isCountryDefault": false,
          "name": "Arroyo"
        },
        {
          "key": 3861,
          "isCountryDefault": false,
          "name": "Barceloneta"
        },
        {
          "key": 3862,
          "isCountryDefault": false,
          "name": "Barranquitas"
        },
        {
          "key": 3863,
          "isCountryDefault": false,
          "name": "Bayamón"
        },
        {
          "key": 3864,
          "isCountryDefault": false,
          "name": "Cabo Rojo"
        },
        {
          "key": 3865,
          "isCountryDefault": false,
          "name": "Caguas"
        },
        {
          "key": 3866,
          "isCountryDefault": false,
          "name": "Camuy"
        },
        {
          "key": 3867,
          "isCountryDefault": false,
          "name": "Canóvanas"
        },
        {
          "key": 3868,
          "isCountryDefault": false,
          "name": "Carolina"
        },
        {
          "key": 3869,
          "isCountryDefault": false,
          "name": "Cataño"
        },
        {
          "key": 3870,
          "isCountryDefault": false,
          "name": "Cayey"
        },
        {
          "key": 3871,
          "isCountryDefault": false,
          "name": "Ceiba"
        },
        {
          "key": 3872,
          "isCountryDefault": false,
          "name": "Ciales"
        },
        {
          "key": 3873,
          "isCountryDefault": false,
          "name": "Cidra"
        },
        {
          "key": 3874,
          "isCountryDefault": false,
          "name": "Coamo"
        },
        {
          "key": 3875,
          "isCountryDefault": false,
          "name": "Comerío"
        },
        {
          "key": 3876,
          "isCountryDefault": false,
          "name": "Corozal"
        },
        {
          "key": 3877,
          "isCountryDefault": false,
          "name": "Culebra"
        },
        {
          "key": 3878,
          "isCountryDefault": false,
          "name": "Dorado"
        },
        {
          "key": 3879,
          "isCountryDefault": false,
          "name": "Fajardo"
        },
        {
          "key": 3880,
          "isCountryDefault": false,
          "name": "Florida"
        },
        {
          "key": 3881,
          "isCountryDefault": false,
          "name": "Guánica"
        },
        {
          "key": 3882,
          "isCountryDefault": false,
          "name": "Guayama"
        },
        {
          "key": 3883,
          "isCountryDefault": false,
          "name": "Guayanilla"
        },
        {
          "key": 3884,
          "isCountryDefault": false,
          "name": "Guaynabo"
        },
        {
          "key": 3885,
          "isCountryDefault": false,
          "name": "Gurabo"
        },
        {
          "key": 3886,
          "isCountryDefault": false,
          "name": "Hatillo"
        },
        {
          "key": 3887,
          "isCountryDefault": false,
          "name": "Hormigueros"
        },
        {
          "key": 3888,
          "isCountryDefault": false,
          "name": "Humacao"
        },
        {
          "key": 3889,
          "isCountryDefault": false,
          "name": "Isabela"
        },
        {
          "key": 3890,
          "isCountryDefault": false,
          "name": "Jayuya"
        },
        {
          "key": 3891,
          "isCountryDefault": false,
          "name": "Juana Díaz"
        },
        {
          "key": 3892,
          "isCountryDefault": false,
          "name": "Juncos"
        },
        {
          "key": 3893,
          "isCountryDefault": false,
          "name": "Lajas"
        },
        {
          "key": 3894,
          "isCountryDefault": false,
          "name": "Lares"
        },
        {
          "key": 3895,
          "isCountryDefault": false,
          "name": "Las Marías"
        },
        {
          "key": 3896,
          "isCountryDefault": false,
          "name": "Las Piedras"
        },
        {
          "key": 3897,
          "isCountryDefault": false,
          "name": "Loíza"
        },
        {
          "key": 3898,
          "isCountryDefault": false,
          "name": "Luquillo"
        },
        {
          "key": 3899,
          "isCountryDefault": false,
          "name": "Manatí"
        },
        {
          "key": 3900,
          "isCountryDefault": false,
          "name": "Maricao"
        },
        {
          "key": 3901,
          "isCountryDefault": false,
          "name": "Maunabo"
        },
        {
          "key": 3902,
          "isCountryDefault": false,
          "name": "Mayagüez"
        },
        {
          "key": 3903,
          "isCountryDefault": false,
          "name": "Moca"
        },
        {
          "key": 3904,
          "isCountryDefault": false,
          "name": "Morovis"
        },
        {
          "key": 3905,
          "isCountryDefault": false,
          "name": "Naguabo"
        },
        {
          "key": 3906,
          "isCountryDefault": false,
          "name": "Naranjito"
        },
        {
          "key": 3907,
          "isCountryDefault": false,
          "name": "Orocovis"
        },
        {
          "key": 3908,
          "isCountryDefault": false,
          "name": "Patillas"
        },
        {
          "key": 3909,
          "isCountryDefault": false,
          "name": "Peñuelas"
        },
        {
          "key": 3910,
          "isCountryDefault": false,
          "name": "Ponce"
        },
        {
          "key": 3911,
          "isCountryDefault": false,
          "name": "Quebradillas"
        },
        {
          "key": 3912,
          "isCountryDefault": false,
          "name": "Rincón"
        },
        {
          "key": 3913,
          "isCountryDefault": false,
          "name": "Río Grande"
        },
        {
          "key": 3914,
          "isCountryDefault": false,
          "name": "Sabana Grande"
        },
        {
          "key": 3915,
          "isCountryDefault": false,
          "name": "Salinas"
        },
        {
          "key": 3916,
          "isCountryDefault": false,
          "name": "San Germán"
        },
        {
          "key": 3917,
          "isCountryDefault": false,
          "name": "San Juan"
        },
        {
          "key": 3918,
          "isCountryDefault": false,
          "name": "San Lorenzo"
        },
        {
          "key": 3919,
          "isCountryDefault": false,
          "name": "San Sebastián"
        },
        {
          "key": 3920,
          "isCountryDefault": false,
          "name": "Santa Isabel"
        },
        {
          "key": 3921,
          "isCountryDefault": false,
          "name": "Toa Alta"
        },
        {
          "key": 3922,
          "isCountryDefault": false,
          "name": "Toa Baja"
        },
        {
          "key": 3923,
          "isCountryDefault": false,
          "name": "Trujillo Alto"
        },
        {
          "key": 3924,
          "isCountryDefault": false,
          "name": "Utuado"
        },
        {
          "key": 3925,
          "isCountryDefault": false,
          "name": "Vega Alta"
        },
        {
          "key": 3926,
          "isCountryDefault": false,
          "name": "Vega Baja"
        },
        {
          "key": 3927,
          "isCountryDefault": false,
          "name": "Vieques"
        },
        {
          "key": 3928,
          "isCountryDefault": false,
          "name": "Villalba"
        },
        {
          "key": 3929,
          "isCountryDefault": false,
          "name": "Yabucoa"
        },
        {
          "key": 3930,
          "isCountryDefault": false,
          "name": "Yauco"
        }
      ],
      "isEu": false
    },
    {
      "key": "réunion",
      "name": "Réunion",
      "code": "RE",
      "region": "Afrika",
      "states": [
        {
          "key": 200,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2657,
          "isCountryDefault": false,
          "name": "Arrondissement of Saint-Benoît"
        },
        {
          "key": 2656,
          "isCountryDefault": false,
          "name": "Arrondissement of Saint-Denis"
        },
        {
          "key": 2658,
          "isCountryDefault": false,
          "name": "Arrondissement of Saint-Paul"
        },
        {
          "key": 2659,
          "isCountryDefault": false,
          "name": "Arrondissement of Saint-Pierre"
        }
      ],
      "isEu": false
    },
    {
      "key": "rwanda",
      "name": "Ruanda",
      "code": "RW",
      "region": "Afrika",
      "states": [
        {
          "key": 201,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2785,
          "isCountryDefault": false,
          "name": "Eastern Province"
        },
        {
          "key": 2788,
          "isCountryDefault": false,
          "name": "Kigali Province"
        },
        {
          "key": 2784,
          "isCountryDefault": false,
          "name": "Northern Province"
        },
        {
          "key": 2786,
          "isCountryDefault": false,
          "name": "Southern Province"
        },
        {
          "key": 2787,
          "isCountryDefault": false,
          "name": "Western Province"
        }
      ],
      "isEu": false
    },
    {
      "key": "romania",
      "name": "Rumänien",
      "code": "RO",
      "region": "Europa",
      "states": [
        {
          "key": 54,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2660,
          "isCountryDefault": false,
          "name": "Alba"
        },
        {
          "key": 2661,
          "isCountryDefault": false,
          "name": "Arad"
        },
        {
          "key": 2662,
          "isCountryDefault": false,
          "name": "Argeş"
        },
        {
          "key": 2663,
          "isCountryDefault": false,
          "name": "Bacău"
        },
        {
          "key": 2664,
          "isCountryDefault": false,
          "name": "Bihor"
        },
        {
          "key": 2665,
          "isCountryDefault": false,
          "name": "Bistriţa-Năsăud"
        },
        {
          "key": 2666,
          "isCountryDefault": false,
          "name": "Botoşani"
        },
        {
          "key": 2668,
          "isCountryDefault": false,
          "name": "Brăila"
        },
        {
          "key": 2667,
          "isCountryDefault": false,
          "name": "Braşov"
        },
        {
          "key": 2669,
          "isCountryDefault": false,
          "name": "Bucureşti"
        },
        {
          "key": 2670,
          "isCountryDefault": false,
          "name": "Buzău"
        },
        {
          "key": 2672,
          "isCountryDefault": false,
          "name": "Călăraşi"
        },
        {
          "key": 2671,
          "isCountryDefault": false,
          "name": "Caraş-Severin"
        },
        {
          "key": 2673,
          "isCountryDefault": false,
          "name": "Cluj"
        },
        {
          "key": 2674,
          "isCountryDefault": false,
          "name": "Constanţa"
        },
        {
          "key": 2675,
          "isCountryDefault": false,
          "name": "Covasna"
        },
        {
          "key": 2676,
          "isCountryDefault": false,
          "name": "Dâmboviţa"
        },
        {
          "key": 2677,
          "isCountryDefault": false,
          "name": "Dolj"
        },
        {
          "key": 2678,
          "isCountryDefault": false,
          "name": "Galaţi"
        },
        {
          "key": 2679,
          "isCountryDefault": false,
          "name": "Giurgiu"
        },
        {
          "key": 2680,
          "isCountryDefault": false,
          "name": "Gorj"
        },
        {
          "key": 2681,
          "isCountryDefault": false,
          "name": "Harghita"
        },
        {
          "key": 2682,
          "isCountryDefault": false,
          "name": "Ialomiţa"
        },
        {
          "key": 2683,
          "isCountryDefault": false,
          "name": "Iaşi"
        },
        {
          "key": 2684,
          "isCountryDefault": false,
          "name": "Ilfov"
        },
        {
          "key": 2685,
          "isCountryDefault": false,
          "name": "Maramureş"
        },
        {
          "key": 2686,
          "isCountryDefault": false,
          "name": "Mehedinţi"
        },
        {
          "key": 2687,
          "isCountryDefault": false,
          "name": "Mureş"
        },
        {
          "key": 2688,
          "isCountryDefault": false,
          "name": "Neamţ"
        },
        {
          "key": 2689,
          "isCountryDefault": false,
          "name": "Olt"
        },
        {
          "key": 2690,
          "isCountryDefault": false,
          "name": "Prahova"
        },
        {
          "key": 2692,
          "isCountryDefault": false,
          "name": "Sălaj"
        },
        {
          "key": 2691,
          "isCountryDefault": false,
          "name": "Satu Mare"
        },
        {
          "key": 2693,
          "isCountryDefault": false,
          "name": "Sibiu"
        },
        {
          "key": 2694,
          "isCountryDefault": false,
          "name": "Suceava"
        },
        {
          "key": 2695,
          "isCountryDefault": false,
          "name": "Teleorman"
        },
        {
          "key": 2696,
          "isCountryDefault": false,
          "name": "Timiş"
        },
        {
          "key": 2697,
          "isCountryDefault": false,
          "name": "Tulcea"
        },
        {
          "key": 2699,
          "isCountryDefault": false,
          "name": "Vâlcea"
        },
        {
          "key": 2698,
          "isCountryDefault": false,
          "name": "Vaslui"
        },
        {
          "key": 2700,
          "isCountryDefault": false,
          "name": "Vrancea"
        }
      ],
      "isEu": true
    },
    {
      "key": "russia",
      "name": "Russland",
      "code": "RU",
      "region": "Europa",
      "states": [
        {
          "key": 55,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2722,
          "isCountryDefault": false,
          "name": "Altai Krai"
        },
        {
          "key": 2704,
          "isCountryDefault": false,
          "name": "Altai Republic"
        },
        {
          "key": 2731,
          "isCountryDefault": false,
          "name": "Amur Oblast"
        },
        {
          "key": 2783,
          "isCountryDefault": false,
          "name": "Arkhangelsk Oblast"
        },
        {
          "key": 2732,
          "isCountryDefault": false,
          "name": "Astrakhan Oblast"
        },
        {
          "key": 2733,
          "isCountryDefault": false,
          "name": "Belgorod Oblast"
        },
        {
          "key": 2734,
          "isCountryDefault": false,
          "name": "Bryansk Oblast"
        },
        {
          "key": 2720,
          "isCountryDefault": false,
          "name": "Chechen Republic"
        },
        {
          "key": 2774,
          "isCountryDefault": false,
          "name": "Chelyabinsk Oblast"
        },
        {
          "key": 2781,
          "isCountryDefault": false,
          "name": "Chukotka Autonomous Okrug"
        },
        {
          "key": 2721,
          "isCountryDefault": false,
          "name": "Chuvash Republic"
        },
        {
          "key": 2740,
          "isCountryDefault": false,
          "name": "Irkutsk Oblast"
        },
        {
          "key": 2739,
          "isCountryDefault": false,
          "name": "Ivanovo Oblast"
        },
        {
          "key": 2778,
          "isCountryDefault": false,
          "name": "Jewish Autonomous Oblast"
        },
        {
          "key": 2707,
          "isCountryDefault": false,
          "name": "Kabardino-Balkar Republic"
        },
        {
          "key": 2741,
          "isCountryDefault": false,
          "name": "Kaliningrad Oblast"
        },
        {
          "key": 2742,
          "isCountryDefault": false,
          "name": "Kaluga Oblast"
        },
        {
          "key": 2724,
          "isCountryDefault": false,
          "name": "Kamchatka Krai"
        },
        {
          "key": 2709,
          "isCountryDefault": false,
          "name": "Karachay-Cherkess Republic"
        },
        {
          "key": 2743,
          "isCountryDefault": false,
          "name": "Kemerovo Oblast"
        },
        {
          "key": 2730,
          "isCountryDefault": false,
          "name": "Khabarovsk Krai"
        },
        {
          "key": 2780,
          "isCountryDefault": false,
          "name": "Khanty–Mansi Autonomous Okrug"
        },
        {
          "key": 2744,
          "isCountryDefault": false,
          "name": "Kirov Oblast"
        },
        {
          "key": 2711,
          "isCountryDefault": false,
          "name": "Komi Republic"
        },
        {
          "key": 2745,
          "isCountryDefault": false,
          "name": "Kostroma Oblast"
        },
        {
          "key": 2725,
          "isCountryDefault": false,
          "name": "Krasnodar Krai"
        },
        {
          "key": 2726,
          "isCountryDefault": false,
          "name": "Krasnoyarsk Krai"
        },
        {
          "key": 2746,
          "isCountryDefault": false,
          "name": "Kurgan Oblast"
        },
        {
          "key": 2747,
          "isCountryDefault": false,
          "name": "Kursk Oblast"
        },
        {
          "key": 2748,
          "isCountryDefault": false,
          "name": "Leningrad Oblast"
        },
        {
          "key": 2749,
          "isCountryDefault": false,
          "name": "Lipetsk Oblast"
        },
        {
          "key": 2750,
          "isCountryDefault": false,
          "name": "Magadan Oblast"
        },
        {
          "key": 2712,
          "isCountryDefault": false,
          "name": "Mari El Republic"
        },
        {
          "key": 2776,
          "isCountryDefault": false,
          "name": "Moscow"
        },
        {
          "key": 2751,
          "isCountryDefault": false,
          "name": "Moscow Oblast"
        },
        {
          "key": 2752,
          "isCountryDefault": false,
          "name": "Murmansk Oblast"
        },
        {
          "key": 2779,
          "isCountryDefault": false,
          "name": "Nenets Autonomous Okrug"
        },
        {
          "key": 2753,
          "isCountryDefault": false,
          "name": "Nizhny Novgorod Oblast"
        },
        {
          "key": 2754,
          "isCountryDefault": false,
          "name": "Novgorod Oblast"
        },
        {
          "key": 2755,
          "isCountryDefault": false,
          "name": "Novosibirsk Oblast"
        },
        {
          "key": 2756,
          "isCountryDefault": false,
          "name": "Omsk Oblast"
        },
        {
          "key": 2757,
          "isCountryDefault": false,
          "name": "Orenburg Oblast"
        },
        {
          "key": 2758,
          "isCountryDefault": false,
          "name": "Oryol Oblast"
        },
        {
          "key": 2759,
          "isCountryDefault": false,
          "name": "Penza Oblast"
        },
        {
          "key": 2727,
          "isCountryDefault": false,
          "name": "Perm Krai"
        },
        {
          "key": 2728,
          "isCountryDefault": false,
          "name": "Primorsky Krai"
        },
        {
          "key": 2760,
          "isCountryDefault": false,
          "name": "Pskov Oblast"
        },
        {
          "key": 2701,
          "isCountryDefault": false,
          "name": "Republic of Adygea"
        },
        {
          "key": 2702,
          "isCountryDefault": false,
          "name": "Republic of Bashkortostan"
        },
        {
          "key": 2703,
          "isCountryDefault": false,
          "name": "Republic of Buryatia"
        },
        {
          "key": 2705,
          "isCountryDefault": false,
          "name": "Republic of Dagestan"
        },
        {
          "key": 2706,
          "isCountryDefault": false,
          "name": "Republic of Ingushetia"
        },
        {
          "key": 2708,
          "isCountryDefault": false,
          "name": "Republic of Kalmykia"
        },
        {
          "key": 2710,
          "isCountryDefault": false,
          "name": "Republic of Karelia"
        },
        {
          "key": 2719,
          "isCountryDefault": false,
          "name": "Republic of Khakassia"
        },
        {
          "key": 2713,
          "isCountryDefault": false,
          "name": "Republic of Mordovia"
        },
        {
          "key": 2715,
          "isCountryDefault": false,
          "name": "Republic of North Ossetia–Alania"
        },
        {
          "key": 2716,
          "isCountryDefault": false,
          "name": "Republic of Tatarstan"
        },
        {
          "key": 2761,
          "isCountryDefault": false,
          "name": "Rostov Oblast"
        },
        {
          "key": 2762,
          "isCountryDefault": false,
          "name": "Ryazan Oblast"
        },
        {
          "key": 2777,
          "isCountryDefault": false,
          "name": "Saint Petersburg"
        },
        {
          "key": 2714,
          "isCountryDefault": false,
          "name": "Sakha (Yakutia) Republic"
        },
        {
          "key": 2765,
          "isCountryDefault": false,
          "name": "Sakhalin Oblast"
        },
        {
          "key": 2763,
          "isCountryDefault": false,
          "name": "Samara Oblast"
        },
        {
          "key": 2764,
          "isCountryDefault": false,
          "name": "Saratov Oblast"
        },
        {
          "key": 2767,
          "isCountryDefault": false,
          "name": "Smolensk Oblast"
        },
        {
          "key": 2729,
          "isCountryDefault": false,
          "name": "Stavropol Krai"
        },
        {
          "key": 2766,
          "isCountryDefault": false,
          "name": "Sverdlovsk Oblast"
        },
        {
          "key": 2768,
          "isCountryDefault": false,
          "name": "Tambov Oblast"
        },
        {
          "key": 2770,
          "isCountryDefault": false,
          "name": "Tomsk Oblast"
        },
        {
          "key": 2771,
          "isCountryDefault": false,
          "name": "Tula Oblast"
        },
        {
          "key": 2717,
          "isCountryDefault": false,
          "name": "Tuva Republic"
        },
        {
          "key": 2769,
          "isCountryDefault": false,
          "name": "Tver Oblast"
        },
        {
          "key": 2772,
          "isCountryDefault": false,
          "name": "Tyumen Oblast"
        },
        {
          "key": 2718,
          "isCountryDefault": false,
          "name": "Udmurt Republic"
        },
        {
          "key": 2773,
          "isCountryDefault": false,
          "name": "Ulyanovsk Oblast"
        },
        {
          "key": 2735,
          "isCountryDefault": false,
          "name": "Vladimir Oblast"
        },
        {
          "key": 2736,
          "isCountryDefault": false,
          "name": "Volgograd Oblast"
        },
        {
          "key": 2737,
          "isCountryDefault": false,
          "name": "Vologda Oblast"
        },
        {
          "key": 2738,
          "isCountryDefault": false,
          "name": "Voronezh Oblast"
        },
        {
          "key": 2782,
          "isCountryDefault": false,
          "name": "Yamalo-Nenets Autonomous Okrug"
        },
        {
          "key": 2775,
          "isCountryDefault": false,
          "name": "Yaroslavl Oblast"
        },
        {
          "key": 2723,
          "isCountryDefault": false,
          "name": "Zabaykalsky Krai"
        }
      ],
      "isEu": false
    },
    {
      "key": "solomonIslands",
      "name": "Salomonen",
      "code": "SB",
      "region": "Ozeanien",
      "states": [
        {
          "key": 202,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2987,
          "isCountryDefault": false,
          "name": "Central Province"
        },
        {
          "key": 2988,
          "isCountryDefault": false,
          "name": "Choiseul Province"
        },
        {
          "key": 2989,
          "isCountryDefault": false,
          "name": "Guadalcanal Province"
        },
        {
          "key": 2990,
          "isCountryDefault": false,
          "name": "Isabel Province"
        },
        {
          "key": 2991,
          "isCountryDefault": false,
          "name": "Makira-Ulawa Province"
        },
        {
          "key": 2992,
          "isCountryDefault": false,
          "name": "Malaita Province"
        },
        {
          "key": 2993,
          "isCountryDefault": false,
          "name": "Rennell and Bellona Province"
        },
        {
          "key": 2994,
          "isCountryDefault": false,
          "name": "Temotu Province"
        },
        {
          "key": 2995,
          "isCountryDefault": false,
          "name": "Western Province"
        }
      ],
      "isEu": false
    },
    {
      "key": "zambia",
      "name": "Sambia",
      "code": "ZM",
      "region": "Afrika",
      "states": [
        {
          "key": 236,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3695,
          "isCountryDefault": false,
          "name": "Central"
        },
        {
          "key": 3693,
          "isCountryDefault": false,
          "name": "Copperbelt"
        },
        {
          "key": 3698,
          "isCountryDefault": false,
          "name": "Eastern"
        },
        {
          "key": 3700,
          "isCountryDefault": false,
          "name": "Luapula"
        },
        {
          "key": 3696,
          "isCountryDefault": false,
          "name": "Lusaka"
        },
        {
          "key": 3699,
          "isCountryDefault": false,
          "name": "Northern"
        },
        {
          "key": 3692,
          "isCountryDefault": false,
          "name": "North-Western"
        },
        {
          "key": 3697,
          "isCountryDefault": false,
          "name": "Southern"
        },
        {
          "key": 3694,
          "isCountryDefault": false,
          "name": "Western"
        }
      ],
      "isEu": false
    },
    {
      "key": "samoa",
      "name": "Samoa",
      "code": "WS",
      "region": "Ozeanien",
      "states": [
        {
          "key": 233,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2830,
          "isCountryDefault": false,
          "name": "A'ana"
        },
        {
          "key": 2831,
          "isCountryDefault": false,
          "name": "Aiga-i-le-Tai"
        },
        {
          "key": 2832,
          "isCountryDefault": false,
          "name": "Atua2"
        },
        {
          "key": 2834,
          "isCountryDefault": false,
          "name": "Fa'asaleleaga"
        },
        {
          "key": 2835,
          "isCountryDefault": false,
          "name": "Gaga'emauga"
        },
        {
          "key": 2836,
          "isCountryDefault": false,
          "name": "Gaga'ifomauga"
        },
        {
          "key": 2839,
          "isCountryDefault": false,
          "name": "Palauli"
        },
        {
          "key": 2838,
          "isCountryDefault": false,
          "name": "Satupa'itea"
        },
        {
          "key": 2829,
          "isCountryDefault": false,
          "name": "Tuamasaga"
        },
        {
          "key": 2833,
          "isCountryDefault": false,
          "name": "Va'a-o-Fonoti"
        },
        {
          "key": 2837,
          "isCountryDefault": false,
          "name": "Vaisigano"
        }
      ],
      "isEu": false
    },
    {
      "key": "sanMarino",
      "name": "San Marino",
      "code": "SM",
      "region": "Europa",
      "states": [
        {
          "key": 61,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2845,
          "isCountryDefault": false,
          "name": "Castello di Acquaviva"
        },
        {
          "key": 2841,
          "isCountryDefault": false,
          "name": "Castello di Borgo Maggiore"
        },
        {
          "key": 2847,
          "isCountryDefault": false,
          "name": "Castello di Chiesanuova"
        },
        {
          "key": 2843,
          "isCountryDefault": false,
          "name": "Castello di Domagnano"
        },
        {
          "key": 2846,
          "isCountryDefault": false,
          "name": "Castello di Faetano"
        },
        {
          "key": 2844,
          "isCountryDefault": false,
          "name": "Castello di Fiorentino"
        },
        {
          "key": 2848,
          "isCountryDefault": false,
          "name": "Castello di Montegiardino"
        },
        {
          "key": 2840,
          "isCountryDefault": false,
          "name": "Castello di Serravalle"
        },
        {
          "key": 2842,
          "isCountryDefault": false,
          "name": "Città di San Marino"
        }
      ],
      "isEu": false
    },
    {
      "key": "saoTomeandPrincipe",
      "name": "São Tomé und Príncipe",
      "code": "ST",
      "region": "Afrika",
      "states": [
        {
          "key": 209,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2850,
          "isCountryDefault": false,
          "name": "Príncipe"
        },
        {
          "key": 2849,
          "isCountryDefault": false,
          "name": "São Tomé"
        }
      ],
      "isEu": false
    },
    {
      "key": "saudiArabia",
      "name": "Saudi-Arabien",
      "code": "SA",
      "region": "Naher und Mittlerer Osten",
      "states": [
        {
          "key": 56,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2861,
          "isCountryDefault": false,
          "name": "`Asīr"
        },
        {
          "key": 2857,
          "isCountryDefault": false,
          "name": "Al Bāhah"
        },
        {
          "key": 2858,
          "isCountryDefault": false,
          "name": "Al Ḥudūd ash Shamāliyah"
        },
        {
          "key": 2859,
          "isCountryDefault": false,
          "name": "Al Jawf"
        },
        {
          "key": 2855,
          "isCountryDefault": false,
          "name": "Al Madīnah"
        },
        {
          "key": 2852,
          "isCountryDefault": false,
          "name": "Al Qaṣīm"
        },
        {
          "key": 2853,
          "isCountryDefault": false,
          "name": "Ar Riyāḍ"
        },
        {
          "key": 2863,
          "isCountryDefault": false,
          "name": "Ash Sharqīyah"
        },
        {
          "key": 2851,
          "isCountryDefault": false,
          "name": "Ḥā'il"
        },
        {
          "key": 2860,
          "isCountryDefault": false,
          "name": "Jīzan"
        },
        {
          "key": 2856,
          "isCountryDefault": false,
          "name": "Makkah"
        },
        {
          "key": 2862,
          "isCountryDefault": false,
          "name": "Najrān"
        },
        {
          "key": 2854,
          "isCountryDefault": false,
          "name": "Tabūk"
        }
      ],
      "isEu": false
    },
    {
      "key": "scotland",
      "name": "Schottland",
      "code": "GB",
      "region": "Europa",
      "states": [
        {
          "key": 246,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2864,
          "isCountryDefault": false,
          "name": "Aberdeen"
        },
        {
          "key": 2865,
          "isCountryDefault": false,
          "name": "Aberdeenshire"
        },
        {
          "key": 2866,
          "isCountryDefault": false,
          "name": "Angus"
        },
        {
          "key": 2867,
          "isCountryDefault": false,
          "name": "Argyll and Bute"
        },
        {
          "key": 2875,
          "isCountryDefault": false,
          "name": "City of Edinburgh"
        },
        {
          "key": 2868,
          "isCountryDefault": false,
          "name": "Clackmannanshire"
        },
        {
          "key": 2869,
          "isCountryDefault": false,
          "name": "Dumfries and Galloway"
        },
        {
          "key": 2870,
          "isCountryDefault": false,
          "name": "Dundee City"
        },
        {
          "key": 2871,
          "isCountryDefault": false,
          "name": "East Ayrshire"
        },
        {
          "key": 2872,
          "isCountryDefault": false,
          "name": "East Dunbartonshire"
        },
        {
          "key": 2873,
          "isCountryDefault": false,
          "name": "East Lothian"
        },
        {
          "key": 2874,
          "isCountryDefault": false,
          "name": "East Renfrewshire"
        },
        {
          "key": 2876,
          "isCountryDefault": false,
          "name": "Falkirk"
        },
        {
          "key": 2877,
          "isCountryDefault": false,
          "name": "Fife"
        },
        {
          "key": 2878,
          "isCountryDefault": false,
          "name": "Glasgow"
        },
        {
          "key": 2879,
          "isCountryDefault": false,
          "name": "Highland"
        },
        {
          "key": 2880,
          "isCountryDefault": false,
          "name": "Inverclyde"
        },
        {
          "key": 2881,
          "isCountryDefault": false,
          "name": "Midlothian"
        },
        {
          "key": 2882,
          "isCountryDefault": false,
          "name": "Moray"
        },
        {
          "key": 2893,
          "isCountryDefault": false,
          "name": "Na h-Eileanan Siar"
        },
        {
          "key": 2883,
          "isCountryDefault": false,
          "name": "North Ayrshire"
        },
        {
          "key": 2884,
          "isCountryDefault": false,
          "name": "North Lanarkshire"
        },
        {
          "key": 2894,
          "isCountryDefault": false,
          "name": "Orkney Islands"
        },
        {
          "key": 2885,
          "isCountryDefault": false,
          "name": "Perth and Kinross"
        },
        {
          "key": 2886,
          "isCountryDefault": false,
          "name": "Renfrewshire"
        },
        {
          "key": 2887,
          "isCountryDefault": false,
          "name": "Scottish Borders"
        },
        {
          "key": 2895,
          "isCountryDefault": false,
          "name": "Shetland Islands"
        },
        {
          "key": 2888,
          "isCountryDefault": false,
          "name": "South Ayrshire"
        },
        {
          "key": 2889,
          "isCountryDefault": false,
          "name": "South Lanarkshire"
        },
        {
          "key": 2890,
          "isCountryDefault": false,
          "name": "Stirling"
        },
        {
          "key": 2891,
          "isCountryDefault": false,
          "name": "West Dunbartonshire"
        },
        {
          "key": 2892,
          "isCountryDefault": false,
          "name": "West Lothian"
        }
      ],
      "isEu": false
    },
    {
      "key": "sweden",
      "name": "Schweden",
      "code": "SE",
      "region": "Europa",
      "states": [
        {
          "key": 57,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 4068,
          "isCountryDefault": false,
          "name": "Ångermanland"
        },
        {
          "key": 3112,
          "isCountryDefault": false,
          "name": "Blekinge"
        },
        {
          "key": 4059,
          "isCountryDefault": false,
          "name": "Bohuslän"
        },
        {
          "key": 3119,
          "isCountryDefault": false,
          "name": "Dalarna"
        },
        {
          "key": 4060,
          "isCountryDefault": false,
          "name": "Dalsland"
        },
        {
          "key": 4061,
          "isCountryDefault": false,
          "name": "Gästrikland"
        },
        {
          "key": 3120,
          "isCountryDefault": false,
          "name": "Gävleborg"
        },
        {
          "key": 3111,
          "isCountryDefault": false,
          "name": "Gotland"
        },
        {
          "key": 3114,
          "isCountryDefault": false,
          "name": "Halland"
        },
        {
          "key": 4062,
          "isCountryDefault": false,
          "name": "Hälsingland"
        },
        {
          "key": 4063,
          "isCountryDefault": false,
          "name": "Härjedalen"
        },
        {
          "key": 3122,
          "isCountryDefault": false,
          "name": "Jämtland"
        },
        {
          "key": 3108,
          "isCountryDefault": false,
          "name": "Jönköping"
        },
        {
          "key": 3110,
          "isCountryDefault": false,
          "name": "Kalmar"
        },
        {
          "key": 3109,
          "isCountryDefault": false,
          "name": "Kronoberg"
        },
        {
          "key": 4064,
          "isCountryDefault": false,
          "name": "Lappland"
        },
        {
          "key": 4065,
          "isCountryDefault": false,
          "name": "Medelpad"
        },
        {
          "key": 4066,
          "isCountryDefault": false,
          "name": "Närke"
        },
        {
          "key": 3104,
          "isCountryDefault": false,
          "name": "Norrbotten"
        },
        {
          "key": 4069,
          "isCountryDefault": false,
          "name": "Öland"
        },
        {
          "key": 3117,
          "isCountryDefault": false,
          "name": "Örebro"
        },
        {
          "key": 3107,
          "isCountryDefault": false,
          "name": "Östergötland"
        },
        {
          "key": 3113,
          "isCountryDefault": false,
          "name": "Skåne"
        },
        {
          "key": 4067,
          "isCountryDefault": false,
          "name": "Småland"
        },
        {
          "key": 3106,
          "isCountryDefault": false,
          "name": "Södermanland"
        },
        {
          "key": 3102,
          "isCountryDefault": false,
          "name": "Stockholm"
        },
        {
          "key": 3105,
          "isCountryDefault": false,
          "name": "Uppsala"
        },
        {
          "key": 3116,
          "isCountryDefault": false,
          "name": "Värmland"
        },
        {
          "key": 3103,
          "isCountryDefault": false,
          "name": "Västerbotten"
        },
        {
          "key": 3121,
          "isCountryDefault": false,
          "name": "Västernorrland"
        },
        {
          "key": 3118,
          "isCountryDefault": false,
          "name": "Västmanland"
        },
        {
          "key": 3115,
          "isCountryDefault": false,
          "name": "Västra Götaland"
        }
      ],
      "isEu": true
    },
    {
      "key": "switzerland",
      "name": "Schweiz",
      "code": "CH",
      "region": "Europa",
      "states": [
        {
          "key": 9,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3141,
          "isCountryDefault": false,
          "name": "Aargau"
        },
        {
          "key": 3137,
          "isCountryDefault": false,
          "name": "Appenzell Ausserrhoden"
        },
        {
          "key": 3138,
          "isCountryDefault": false,
          "name": "Appenzell Innerrhoden"
        },
        {
          "key": 3135,
          "isCountryDefault": false,
          "name": "Basel-Landschaft"
        },
        {
          "key": 3134,
          "isCountryDefault": false,
          "name": "Basel-Stadt"
        },
        {
          "key": 3124,
          "isCountryDefault": false,
          "name": "Bern"
        },
        {
          "key": 3132,
          "isCountryDefault": false,
          "name": "Fribourg"
        },
        {
          "key": 3147,
          "isCountryDefault": false,
          "name": "Geneva"
        },
        {
          "key": 3130,
          "isCountryDefault": false,
          "name": "Glarus"
        },
        {
          "key": 3140,
          "isCountryDefault": false,
          "name": "Graubünden"
        },
        {
          "key": 3148,
          "isCountryDefault": false,
          "name": "Jura"
        },
        {
          "key": 3125,
          "isCountryDefault": false,
          "name": "Lucerne"
        },
        {
          "key": 3146,
          "isCountryDefault": false,
          "name": "Neuchâtel"
        },
        {
          "key": 3129,
          "isCountryDefault": false,
          "name": "Nidwalden"
        },
        {
          "key": 3128,
          "isCountryDefault": false,
          "name": "Obwalden"
        },
        {
          "key": 3136,
          "isCountryDefault": false,
          "name": "Schaffhausen"
        },
        {
          "key": 3127,
          "isCountryDefault": false,
          "name": "Schwyz"
        },
        {
          "key": 3133,
          "isCountryDefault": false,
          "name": "Solothurn"
        },
        {
          "key": 3139,
          "isCountryDefault": false,
          "name": "St. Gallen"
        },
        {
          "key": 3142,
          "isCountryDefault": false,
          "name": "Thurgau"
        },
        {
          "key": 3143,
          "isCountryDefault": false,
          "name": "Ticino"
        },
        {
          "key": 3126,
          "isCountryDefault": false,
          "name": "Uri"
        },
        {
          "key": 3145,
          "isCountryDefault": false,
          "name": "Valais"
        },
        {
          "key": 3144,
          "isCountryDefault": false,
          "name": "Vaud"
        },
        {
          "key": 3131,
          "isCountryDefault": false,
          "name": "Zug"
        },
        {
          "key": 3123,
          "isCountryDefault": false,
          "name": "Zurich"
        }
      ],
      "isEu": false
    },
    {
      "key": "senegal",
      "name": "Senegal",
      "code": "SN",
      "region": "Afrika",
      "states": [
        {
          "key": 62,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2896,
          "isCountryDefault": false,
          "name": "Dakar"
        },
        {
          "key": 2897,
          "isCountryDefault": false,
          "name": "Diourbel"
        },
        {
          "key": 2898,
          "isCountryDefault": false,
          "name": "Fatick"
        },
        {
          "key": 2899,
          "isCountryDefault": false,
          "name": "Kaffrine"
        },
        {
          "key": 2900,
          "isCountryDefault": false,
          "name": "Kaolack"
        },
        {
          "key": 2901,
          "isCountryDefault": false,
          "name": "Kédougou"
        },
        {
          "key": 2902,
          "isCountryDefault": false,
          "name": "Kolda"
        },
        {
          "key": 2903,
          "isCountryDefault": false,
          "name": "Louga"
        },
        {
          "key": 2904,
          "isCountryDefault": false,
          "name": "Matam"
        },
        {
          "key": 2905,
          "isCountryDefault": false,
          "name": "Saint-Louis"
        },
        {
          "key": 2906,
          "isCountryDefault": false,
          "name": "Sédhiou"
        },
        {
          "key": 2907,
          "isCountryDefault": false,
          "name": "Tambacounda"
        },
        {
          "key": 2908,
          "isCountryDefault": false,
          "name": "Thiès"
        },
        {
          "key": 2909,
          "isCountryDefault": false,
          "name": "Ziguinchor"
        }
      ],
      "isEu": false
    },
    {
      "key": "serbia",
      "name": "Serbien",
      "code": "RS",
      "region": "Europa",
      "states": [
        {
          "key": 94,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2910,
          "isCountryDefault": false,
          "name": "Borski okrug"
        },
        {
          "key": 2911,
          "isCountryDefault": false,
          "name": "Braničevski okrug"
        },
        {
          "key": 2912,
          "isCountryDefault": false,
          "name": "Jablanički okrug"
        },
        {
          "key": 4093,
          "isCountryDefault": false,
          "name": "Južnobački okrug"
        },
        {
          "key": 4094,
          "isCountryDefault": false,
          "name": "Južnobanatski okrug"
        },
        {
          "key": 2913,
          "isCountryDefault": false,
          "name": "Kolubarski okrug"
        },
        {
          "key": 4097,
          "isCountryDefault": false,
          "name": "Kosovski okrug"
        },
        {
          "key": 4099,
          "isCountryDefault": false,
          "name": "Kosovskomitrovički okrug"
        },
        {
          "key": 4098,
          "isCountryDefault": false,
          "name": "Kosovsko-Pomoravski okrug"
        },
        {
          "key": 2914,
          "isCountryDefault": false,
          "name": "Mačvanski okrug"
        },
        {
          "key": 2915,
          "isCountryDefault": false,
          "name": "Moravički okrug"
        },
        {
          "key": 2916,
          "isCountryDefault": false,
          "name": "Nišavski okrug"
        },
        {
          "key": 2917,
          "isCountryDefault": false,
          "name": "Pčinjski okrug"
        },
        {
          "key": 4100,
          "isCountryDefault": false,
          "name": "Pećki okrug"
        },
        {
          "key": 2918,
          "isCountryDefault": false,
          "name": "Pirotski okrug"
        },
        {
          "key": 2919,
          "isCountryDefault": false,
          "name": "Podunavski okrug"
        },
        {
          "key": 2920,
          "isCountryDefault": false,
          "name": "Pomoravski okrug"
        },
        {
          "key": 4101,
          "isCountryDefault": false,
          "name": "Prizrenski okrug"
        },
        {
          "key": 2921,
          "isCountryDefault": false,
          "name": "Rasinski okrug"
        },
        {
          "key": 2922,
          "isCountryDefault": false,
          "name": "Raški okrug"
        },
        {
          "key": 4091,
          "isCountryDefault": false,
          "name": "Severnobački okrug"
        },
        {
          "key": 4092,
          "isCountryDefault": false,
          "name": "Severnobanatski okrug"
        },
        {
          "key": 4090,
          "isCountryDefault": false,
          "name": "Srednjebanatski okrug"
        },
        {
          "key": 4095,
          "isCountryDefault": false,
          "name": "Sremski okrug"
        },
        {
          "key": 2923,
          "isCountryDefault": false,
          "name": "Šumadijski okrug"
        },
        {
          "key": 2924,
          "isCountryDefault": false,
          "name": "Toplički okrug"
        },
        {
          "key": 2925,
          "isCountryDefault": false,
          "name": "Zaječarski okrug"
        },
        {
          "key": 4096,
          "isCountryDefault": false,
          "name": "Zapadnobački okrug"
        },
        {
          "key": 2926,
          "isCountryDefault": false,
          "name": "Zlatiborski okrug"
        }
      ],
      "isEu": false
    },
    {
      "key": "seychelles",
      "name": "Seychellen",
      "code": "SC",
      "region": "Afrika",
      "states": [
        {
          "key": 203,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2927,
          "isCountryDefault": false,
          "name": "Anse aux Pins"
        },
        {
          "key": 2928,
          "isCountryDefault": false,
          "name": "Anse Boileau"
        },
        {
          "key": 2929,
          "isCountryDefault": false,
          "name": "Anse Etoile"
        },
        {
          "key": 2931,
          "isCountryDefault": false,
          "name": "Anse Royale"
        },
        {
          "key": 2930,
          "isCountryDefault": false,
          "name": "Au Cap"
        },
        {
          "key": 2932,
          "isCountryDefault": false,
          "name": "Baie Lazare"
        },
        {
          "key": 2933,
          "isCountryDefault": false,
          "name": "Baie Sainte Anne"
        },
        {
          "key": 2934,
          "isCountryDefault": false,
          "name": "Beau Vallon"
        },
        {
          "key": 2935,
          "isCountryDefault": false,
          "name": "Bel Air"
        },
        {
          "key": 2936,
          "isCountryDefault": false,
          "name": "Bel Ombre"
        },
        {
          "key": 2937,
          "isCountryDefault": false,
          "name": "Cascade"
        },
        {
          "key": 2942,
          "isCountryDefault": false,
          "name": "English River"
        },
        {
          "key": 2938,
          "isCountryDefault": false,
          "name": "Glacis"
        },
        {
          "key": 2939,
          "isCountryDefault": false,
          "name": "Grand'Anse"
        },
        {
          "key": 2940,
          "isCountryDefault": false,
          "name": "Grand'Anse Praslin"
        },
        {
          "key": 2941,
          "isCountryDefault": false,
          "name": "La Digue and Inner Islands"
        },
        {
          "key": 2950,
          "isCountryDefault": false,
          "name": "Les Mamelles"
        },
        {
          "key": 2943,
          "isCountryDefault": false,
          "name": "Mont Buxton"
        },
        {
          "key": 2944,
          "isCountryDefault": false,
          "name": "Mont Fleuri"
        },
        {
          "key": 2952,
          "isCountryDefault": false,
          "name": "Outer Islands"
        },
        {
          "key": 2945,
          "isCountryDefault": false,
          "name": "Plaisance"
        },
        {
          "key": 2946,
          "isCountryDefault": false,
          "name": "Pointe La Rue"
        },
        {
          "key": 2947,
          "isCountryDefault": false,
          "name": "Port Glaud"
        },
        {
          "key": 2951,
          "isCountryDefault": false,
          "name": "Roche Caïman"
        },
        {
          "key": 2948,
          "isCountryDefault": false,
          "name": "Saint Louis"
        },
        {
          "key": 2949,
          "isCountryDefault": false,
          "name": "Takamaka"
        }
      ],
      "isEu": false
    },
    {
      "key": "sierraLeone",
      "name": "Sierra Leone",
      "code": "SL",
      "region": "Afrika",
      "states": [
        {
          "key": 207,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2961,
          "isCountryDefault": false,
          "name": "Bo District"
        },
        {
          "key": 2953,
          "isCountryDefault": false,
          "name": "Bombali District"
        },
        {
          "key": 2962,
          "isCountryDefault": false,
          "name": "Bonthe District"
        },
        {
          "key": 2960,
          "isCountryDefault": false,
          "name": "Kailahun District"
        },
        {
          "key": 2957,
          "isCountryDefault": false,
          "name": "Kambia District"
        },
        {
          "key": 2958,
          "isCountryDefault": false,
          "name": "Kenema District"
        },
        {
          "key": 2954,
          "isCountryDefault": false,
          "name": "Koinadugu District"
        },
        {
          "key": 2959,
          "isCountryDefault": false,
          "name": "Kono District"
        },
        {
          "key": 2964,
          "isCountryDefault": false,
          "name": "Moyamba District"
        },
        {
          "key": 2955,
          "isCountryDefault": false,
          "name": "Port Loko District"
        },
        {
          "key": 2963,
          "isCountryDefault": false,
          "name": "Pujehun District"
        },
        {
          "key": 2956,
          "isCountryDefault": false,
          "name": "Tonkolili District"
        },
        {
          "key": 2966,
          "isCountryDefault": false,
          "name": "Western Area Rural District"
        },
        {
          "key": 2965,
          "isCountryDefault": false,
          "name": "Western Area Urban District"
        }
      ],
      "isEu": false
    },
    {
      "key": "zimbabwe",
      "name": "Simbabwe",
      "code": "ZW",
      "region": "Afrika",
      "states": [
        {
          "key": 237,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3701,
          "isCountryDefault": false,
          "name": "Bulawayo"
        },
        {
          "key": 3702,
          "isCountryDefault": false,
          "name": "Harare"
        },
        {
          "key": 3703,
          "isCountryDefault": false,
          "name": "Manicaland"
        },
        {
          "key": 3704,
          "isCountryDefault": false,
          "name": "Mashonaland Central"
        },
        {
          "key": 3705,
          "isCountryDefault": false,
          "name": "Mashonaland East"
        },
        {
          "key": 3706,
          "isCountryDefault": false,
          "name": "Mashonaland West"
        },
        {
          "key": 3707,
          "isCountryDefault": false,
          "name": "Masvingo"
        },
        {
          "key": 3708,
          "isCountryDefault": false,
          "name": "Matabeleland North"
        },
        {
          "key": 3709,
          "isCountryDefault": false,
          "name": "Matabeleland South"
        },
        {
          "key": 3710,
          "isCountryDefault": false,
          "name": "Midlands"
        }
      ],
      "isEu": false
    },
    {
      "key": "singapore",
      "name": "Singapur",
      "code": "SG",
      "region": "Asien",
      "states": [
        {
          "key": 58,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2967,
          "isCountryDefault": false,
          "name": "Central Singapore"
        },
        {
          "key": 2968,
          "isCountryDefault": false,
          "name": "North East"
        },
        {
          "key": 2969,
          "isCountryDefault": false,
          "name": "North West"
        },
        {
          "key": 2970,
          "isCountryDefault": false,
          "name": "South East"
        },
        {
          "key": 2971,
          "isCountryDefault": false,
          "name": "South West"
        }
      ],
      "isEu": false
    },
    {
      "key": "slovakia",
      "name": "Slowakei",
      "code": "SK",
      "region": "Europa",
      "states": [
        {
          "key": 59,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2977,
          "isCountryDefault": false,
          "name": "Banskobystrický kraj"
        },
        {
          "key": 2972,
          "isCountryDefault": false,
          "name": "Bratislavský kraj"
        },
        {
          "key": 2979,
          "isCountryDefault": false,
          "name": "Košický kraj"
        },
        {
          "key": 2975,
          "isCountryDefault": false,
          "name": "Nitriansky kraj"
        },
        {
          "key": 2978,
          "isCountryDefault": false,
          "name": "Prešovský kraj"
        },
        {
          "key": 2974,
          "isCountryDefault": false,
          "name": "Trenčiansky kraj"
        },
        {
          "key": 2973,
          "isCountryDefault": false,
          "name": "Trnavský kraj"
        },
        {
          "key": 2976,
          "isCountryDefault": false,
          "name": "Žilinský kraj"
        }
      ],
      "isEu": true
    },
    {
      "key": "slovenia",
      "name": "Slowenien",
      "code": "SI",
      "region": "Europa",
      "states": [
        {
          "key": 60,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2983,
          "isCountryDefault": false,
          "name": "Dolenjska (Lower Carniola)"
        },
        {
          "key": 2981,
          "isCountryDefault": false,
          "name": "Gorenjska (Upper Carniola)"
        },
        {
          "key": 2984,
          "isCountryDefault": false,
          "name": "Koroška (Carinthia)"
        },
        {
          "key": 2982,
          "isCountryDefault": false,
          "name": "Notranjska (Inner Carniola)"
        },
        {
          "key": 2986,
          "isCountryDefault": false,
          "name": "Prekmurje (Prekmurje)"
        },
        {
          "key": 2980,
          "isCountryDefault": false,
          "name": "Primorska (Slovenian Littoral)"
        },
        {
          "key": 2985,
          "isCountryDefault": false,
          "name": "Štajerska (Lower Styria)"
        }
      ],
      "isEu": true
    },
    {
      "key": "somalia",
      "name": "Somalia",
      "code": "SO",
      "region": "Afrika",
      "states": [
        {
          "key": 208,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3013,
          "isCountryDefault": false,
          "name": "Awdal"
        },
        {
          "key": 3000,
          "isCountryDefault": false,
          "name": "Bakool"
        },
        {
          "key": 3002,
          "isCountryDefault": false,
          "name": "Banaadir"
        },
        {
          "key": 3008,
          "isCountryDefault": false,
          "name": "Bari"
        },
        {
          "key": 2999,
          "isCountryDefault": false,
          "name": "Bay"
        },
        {
          "key": 3005,
          "isCountryDefault": false,
          "name": "Galguduud"
        },
        {
          "key": 2998,
          "isCountryDefault": false,
          "name": "Gedo"
        },
        {
          "key": 3004,
          "isCountryDefault": false,
          "name": "Hiiraan"
        },
        {
          "key": 2996,
          "isCountryDefault": false,
          "name": "Lower Juba"
        },
        {
          "key": 3001,
          "isCountryDefault": false,
          "name": "Lower Shabele"
        },
        {
          "key": 2997,
          "isCountryDefault": false,
          "name": "Middle Juba"
        },
        {
          "key": 3003,
          "isCountryDefault": false,
          "name": "Middle Shabele"
        },
        {
          "key": 3006,
          "isCountryDefault": false,
          "name": "Mudug"
        },
        {
          "key": 3007,
          "isCountryDefault": false,
          "name": "Nugaal"
        },
        {
          "key": 3010,
          "isCountryDefault": false,
          "name": "Sanaag"
        },
        {
          "key": 3009,
          "isCountryDefault": false,
          "name": "Sool"
        },
        {
          "key": 3011,
          "isCountryDefault": false,
          "name": "Togdheer"
        },
        {
          "key": 3012,
          "isCountryDefault": false,
          "name": "Woqooyi Galbeed"
        }
      ],
      "isEu": false
    },
    {
      "key": "spain",
      "name": "Spanien",
      "code": "ES",
      "region": "Europa",
      "states": [
        {
          "key": 16,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3059,
          "isCountryDefault": false,
          "name": "Andalucía"
        },
        {
          "key": 3048,
          "isCountryDefault": false,
          "name": "Aragón"
        },
        {
          "key": 3057,
          "isCountryDefault": false,
          "name": "Cantabria"
        },
        {
          "key": 4087,
          "isCountryDefault": false,
          "name": "Castilla la Mancha"
        },
        {
          "key": 3058,
          "isCountryDefault": false,
          "name": "Castilla y León"
        },
        {
          "key": 3054,
          "isCountryDefault": false,
          "name": "Cataluña"
        },
        {
          "key": 3060,
          "isCountryDefault": false,
          "name": "Comunidad de Madrid"
        },
        {
          "key": 3062,
          "isCountryDefault": false,
          "name": "Comunidad Foral de Navarra"
        },
        {
          "key": 3063,
          "isCountryDefault": false,
          "name": "Comunidad Valenciana"
        },
        {
          "key": 3053,
          "isCountryDefault": false,
          "name": "Extremadura"
        },
        {
          "key": 3052,
          "isCountryDefault": false,
          "name": "Galicia"
        },
        {
          "key": 3055,
          "isCountryDefault": false,
          "name": "Islas Baleares"
        },
        {
          "key": 3047,
          "isCountryDefault": false,
          "name": "Islas Canarias"
        },
        {
          "key": 3051,
          "isCountryDefault": false,
          "name": "La Rioja"
        },
        {
          "key": 3050,
          "isCountryDefault": false,
          "name": "País Vasco"
        },
        {
          "key": 3049,
          "isCountryDefault": false,
          "name": "Principado de Asturias"
        },
        {
          "key": 3061,
          "isCountryDefault": false,
          "name": "Región de Murcia"
        },
        {
          "key": 3056,
          "isCountryDefault": false,
          "name": "Vizcaya/Bizkaia"
        }
      ],
      "isEu": true
    },
    {
      "key": "sriLanka",
      "name": "Sri Lanka",
      "code": "LK",
      "region": "Asien",
      "states": [
        {
          "key": 174,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3064,
          "isCountryDefault": false,
          "name": "Central Province"
        },
        {
          "key": 3065,
          "isCountryDefault": false,
          "name": "Eastern Province"
        },
        {
          "key": 3070,
          "isCountryDefault": false,
          "name": "North Central Province"
        },
        {
          "key": 3069,
          "isCountryDefault": false,
          "name": "North Western Province"
        },
        {
          "key": 3066,
          "isCountryDefault": false,
          "name": "Northern Province"
        },
        {
          "key": 3072,
          "isCountryDefault": false,
          "name": "Sabaragamuwa"
        },
        {
          "key": 3067,
          "isCountryDefault": false,
          "name": "Southern Province"
        },
        {
          "key": 3071,
          "isCountryDefault": false,
          "name": "Uva"
        },
        {
          "key": 3068,
          "isCountryDefault": false,
          "name": "Western Province"
        }
      ],
      "isEu": false
    },
    {
      "key": "saintHelena",
      "name": "St. Helena",
      "code": "SH",
      "region": "Afrika",
      "states": [
        {
          "key": 205,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2789,
          "isCountryDefault": false,
          "name": "Alarm Forest"
        },
        {
          "key": 2790,
          "isCountryDefault": false,
          "name": "Blue Hill"
        },
        {
          "key": 2791,
          "isCountryDefault": false,
          "name": "Half Tree Hollow"
        },
        {
          "key": 2792,
          "isCountryDefault": false,
          "name": "Jamestown"
        },
        {
          "key": 2793,
          "isCountryDefault": false,
          "name": "Levelwood"
        },
        {
          "key": 2794,
          "isCountryDefault": false,
          "name": "Longwood"
        },
        {
          "key": 2796,
          "isCountryDefault": false,
          "name": "Saint Paul's"
        },
        {
          "key": 2795,
          "isCountryDefault": false,
          "name": "Sandy Bay"
        }
      ],
      "isEu": false
    },
    {
      "key": "saintKittsandNevis",
      "name": "St. Kitts und Nevis",
      "code": "KN",
      "region": "Nordamerika",
      "states": [
        {
          "key": 167,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2797,
          "isCountryDefault": false,
          "name": "Christ Church Nichola Town Parish"
        },
        {
          "key": 2798,
          "isCountryDefault": false,
          "name": "Saint Anne Sandy Point Parish"
        },
        {
          "key": 2799,
          "isCountryDefault": false,
          "name": "Saint George Basseterre Parish"
        },
        {
          "key": 2800,
          "isCountryDefault": false,
          "name": "Saint George Gingerland Parish"
        },
        {
          "key": 2801,
          "isCountryDefault": false,
          "name": "Saint James Windward Parish"
        },
        {
          "key": 2802,
          "isCountryDefault": false,
          "name": "Saint John Capisterre Parish"
        },
        {
          "key": 2803,
          "isCountryDefault": false,
          "name": "Saint John Figtree Parish"
        },
        {
          "key": 2804,
          "isCountryDefault": false,
          "name": "Saint Mary Cayon Parish"
        },
        {
          "key": 2805,
          "isCountryDefault": false,
          "name": "Saint Paul Capisterre Parish"
        },
        {
          "key": 2806,
          "isCountryDefault": false,
          "name": "Saint Paul Charlestown Parish"
        },
        {
          "key": 2807,
          "isCountryDefault": false,
          "name": "Saint Peter Basseterre Parish"
        },
        {
          "key": 2808,
          "isCountryDefault": false,
          "name": "Saint Thomas Lowland Parish"
        },
        {
          "key": 2809,
          "isCountryDefault": false,
          "name": "Saint Thomas Middle Island Parish"
        },
        {
          "key": 2810,
          "isCountryDefault": false,
          "name": "Trinity Palmetto Point Parish"
        }
      ],
      "isEu": false
    },
    {
      "key": "saintLucia",
      "name": "St. Lucia",
      "code": "LC",
      "region": "Nordamerika",
      "states": [
        {
          "key": 172,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2811,
          "isCountryDefault": false,
          "name": "Anse la Raye Quarter"
        },
        {
          "key": 2812,
          "isCountryDefault": false,
          "name": "Castries Quarter"
        },
        {
          "key": 2813,
          "isCountryDefault": false,
          "name": "Choiseul Quarter"
        },
        {
          "key": 2814,
          "isCountryDefault": false,
          "name": "Dauphin Quarter"
        },
        {
          "key": 2815,
          "isCountryDefault": false,
          "name": "Dennery Quarter"
        },
        {
          "key": 2816,
          "isCountryDefault": false,
          "name": "Gros Islet Quarter"
        },
        {
          "key": 2817,
          "isCountryDefault": false,
          "name": "Laborie Quarter"
        },
        {
          "key": 2818,
          "isCountryDefault": false,
          "name": "Micoud Quarter"
        },
        {
          "key": 2819,
          "isCountryDefault": false,
          "name": "Praslin Quarter"
        },
        {
          "key": 2820,
          "isCountryDefault": false,
          "name": "Soufrière Quarter"
        },
        {
          "key": 2821,
          "isCountryDefault": false,
          "name": "Vieux Fort Quarter"
        }
      ],
      "isEu": false
    },
    {
      "key": "saintMartin",
      "name": "St. Martin",
      "code": "SX",
      "region": "Nordamerika",
      "states": [
        {
          "key": 4102,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2266,
          "isCountryDefault": false,
          "name": "Sint Maarten"
        }
      ],
      "isEu": false
    },
    {
      "key": "saintPierreetMiquelon",
      "name": "St. Pierre und Miquelon",
      "code": "PM",
      "region": "Nordamerika",
      "states": [
        {
          "key": 196,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2822,
          "isCountryDefault": false,
          "name": "Charlotte Parish"
        }
      ],
      "isEu": false
    },
    {
      "key": "saintVincentandtheGrenadines",
      "name": "St. Vincent und die Grenadinen",
      "code": "VC",
      "region": "Nordamerika",
      "states": [
        {
          "key": 227,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 2823,
          "isCountryDefault": false,
          "name": "Charlotte Parish"
        },
        {
          "key": 2824,
          "isCountryDefault": false,
          "name": "Grenadines Parish"
        },
        {
          "key": 2825,
          "isCountryDefault": false,
          "name": "Saint Andrew Parish"
        },
        {
          "key": 2826,
          "isCountryDefault": false,
          "name": "Saint David Parish"
        },
        {
          "key": 2827,
          "isCountryDefault": false,
          "name": "Saint George Parish"
        },
        {
          "key": 2828,
          "isCountryDefault": false,
          "name": "Saint Patrick Parish"
        }
      ],
      "isEu": false
    },
    {
      "key": "southAfrica",
      "name": "Südafrika",
      "code": "ZA",
      "region": "Afrika",
      "states": [
        {
          "key": 74,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3014,
          "isCountryDefault": false,
          "name": "Eastern Cape"
        },
        {
          "key": 3015,
          "isCountryDefault": false,
          "name": "Free State"
        },
        {
          "key": 3016,
          "isCountryDefault": false,
          "name": "Gauteng"
        },
        {
          "key": 3017,
          "isCountryDefault": false,
          "name": "KwaZulu-Natal"
        },
        {
          "key": 3018,
          "isCountryDefault": false,
          "name": "Limpopo"
        },
        {
          "key": 3019,
          "isCountryDefault": false,
          "name": "Mpumalanga"
        },
        {
          "key": 3020,
          "isCountryDefault": false,
          "name": "North West"
        },
        {
          "key": 3021,
          "isCountryDefault": false,
          "name": "Northern Cape"
        },
        {
          "key": 3022,
          "isCountryDefault": false,
          "name": "Western Cape"
        }
      ],
      "isEu": false
    },
    {
      "key": "sudan",
      "name": "Sudan",
      "code": "SD",
      "region": "Afrika",
      "states": [
        {
          "key": 204,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3073,
          "isCountryDefault": false,
          "name": "Al Jazirah"
        },
        {
          "key": 3074,
          "isCountryDefault": false,
          "name": "Al Qadarif"
        },
        {
          "key": 3075,
          "isCountryDefault": false,
          "name": "Blue Nile"
        },
        {
          "key": 3076,
          "isCountryDefault": false,
          "name": "Kassala"
        },
        {
          "key": 3077,
          "isCountryDefault": false,
          "name": "Khartoum"
        },
        {
          "key": 3078,
          "isCountryDefault": false,
          "name": "North Darfur"
        },
        {
          "key": 3079,
          "isCountryDefault": false,
          "name": "North Kurdufan"
        },
        {
          "key": 3080,
          "isCountryDefault": false,
          "name": "Northern"
        },
        {
          "key": 3081,
          "isCountryDefault": false,
          "name": "Red Sea"
        },
        {
          "key": 3082,
          "isCountryDefault": false,
          "name": "River Nile"
        },
        {
          "key": 3083,
          "isCountryDefault": false,
          "name": "Sennar"
        },
        {
          "key": 3084,
          "isCountryDefault": false,
          "name": "South Darfur"
        },
        {
          "key": 3085,
          "isCountryDefault": false,
          "name": "South Kordofan"
        },
        {
          "key": 3086,
          "isCountryDefault": false,
          "name": "West Darfur"
        },
        {
          "key": 3087,
          "isCountryDefault": false,
          "name": "White Nile"
        }
      ],
      "isEu": false
    },
    {
      "key": "southGeorgiaandtheSouthSandwichIslands",
      "name": "Südgeorgien und die südlichen Sandwichinseln",
      "code": "GS",
      "region": "Ozeanien",
      "states": [
        {
          "key": 154,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3023,
          "isCountryDefault": false,
          "name": "Annenkov Island"
        },
        {
          "key": 3024,
          "isCountryDefault": false,
          "name": "Bird Island"
        },
        {
          "key": 3025,
          "isCountryDefault": false,
          "name": "Cooper Island"
        },
        {
          "key": 3026,
          "isCountryDefault": false,
          "name": "Grass Island"
        },
        {
          "key": 3027,
          "isCountryDefault": false,
          "name": "Pickersgill Islands"
        },
        {
          "key": 3030,
          "isCountryDefault": false,
          "name": "Trinity Island"
        },
        {
          "key": 3028,
          "isCountryDefault": false,
          "name": "Welcome Islands"
        },
        {
          "key": 3029,
          "isCountryDefault": false,
          "name": "Willis Islands"
        }
      ],
      "isEu": false
    },
    {
      "key": "southKorea",
      "name": "Südkorea",
      "code": "KR",
      "region": "Asien",
      "states": [
        {
          "key": 34,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3032,
          "isCountryDefault": false,
          "name": "Busan"
        },
        {
          "key": 3040,
          "isCountryDefault": false,
          "name": "Chungcheongbuk"
        },
        {
          "key": 3041,
          "isCountryDefault": false,
          "name": "Chungcheongnam"
        },
        {
          "key": 3034,
          "isCountryDefault": false,
          "name": "Daegu"
        },
        {
          "key": 3036,
          "isCountryDefault": false,
          "name": "Daejeon"
        },
        {
          "key": 3039,
          "isCountryDefault": false,
          "name": "Gangwon"
        },
        {
          "key": 3035,
          "isCountryDefault": false,
          "name": "Gwangju"
        },
        {
          "key": 3038,
          "isCountryDefault": false,
          "name": "Gyeonggi"
        },
        {
          "key": 3044,
          "isCountryDefault": false,
          "name": "Gyeongsangbuk"
        },
        {
          "key": 3045,
          "isCountryDefault": false,
          "name": "Gyeongsangnam"
        },
        {
          "key": 3033,
          "isCountryDefault": false,
          "name": "Incheon"
        },
        {
          "key": 3046,
          "isCountryDefault": false,
          "name": "Jeju"
        },
        {
          "key": 3042,
          "isCountryDefault": false,
          "name": "Jeollabuk"
        },
        {
          "key": 3043,
          "isCountryDefault": false,
          "name": "Jeollanam"
        },
        {
          "key": 3031,
          "isCountryDefault": false,
          "name": "Seoul"
        },
        {
          "key": 3037,
          "isCountryDefault": false,
          "name": "Ulsan"
        }
      ],
      "isEu": false
    },
    {
      "key": "southSudan",
      "name": "Südsudan",
      "code": "SS",
      "region": "Afrika",
      "states": [
        {
          "key": 4103,
          "isCountryDefault": true,
          "name": null
        }
      ],
      "isEu": false
    },
    {
      "key": "suriname",
      "name": "Suriname",
      "code": "SR",
      "region": "Südamerika",
      "states": [
        {
          "key": 63,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3088,
          "isCountryDefault": false,
          "name": "Brokopondo"
        },
        {
          "key": 3089,
          "isCountryDefault": false,
          "name": "Commewijne"
        },
        {
          "key": 3090,
          "isCountryDefault": false,
          "name": "Coronie"
        },
        {
          "key": 3091,
          "isCountryDefault": false,
          "name": "Marowijne"
        },
        {
          "key": 3092,
          "isCountryDefault": false,
          "name": "Nickerie"
        },
        {
          "key": 3093,
          "isCountryDefault": false,
          "name": "Para"
        },
        {
          "key": 3094,
          "isCountryDefault": false,
          "name": "Paramaribo"
        },
        {
          "key": 3095,
          "isCountryDefault": false,
          "name": "Saramacca"
        },
        {
          "key": 3096,
          "isCountryDefault": false,
          "name": "Sipaliwini"
        },
        {
          "key": 3097,
          "isCountryDefault": false,
          "name": "Wanica"
        }
      ],
      "isEu": false
    },
    {
      "key": "svalbard",
      "name": "Svalbard und Jan Mayen",
      "code": "SJ",
      "region": "Europa",
      "states": [
        {
          "key": 206,
          "isCountryDefault": true,
          "name": null
        }
      ],
      "isEu": false
    },
    {
      "key": "swaziland",
      "name": "Swasiland",
      "code": "SZ",
      "region": "Afrika",
      "states": [
        {
          "key": 64,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3098,
          "isCountryDefault": false,
          "name": "Hhohho"
        },
        {
          "key": 3099,
          "isCountryDefault": false,
          "name": "Lubombo"
        },
        {
          "key": 3100,
          "isCountryDefault": false,
          "name": "Manzini"
        },
        {
          "key": 3101,
          "isCountryDefault": false,
          "name": "Shiselweni"
        }
      ],
      "isEu": false
    },
    {
      "key": "syrianArabRepublic",
      "name": "Syrien, Arabische Republik",
      "code": "SY",
      "region": "Naher und Mittlerer Osten",
      "states": [
        {
          "key": 210,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3149,
          "isCountryDefault": false,
          "name": "Aleppo"
        },
        {
          "key": 3161,
          "isCountryDefault": false,
          "name": "Al-Hasakah"
        },
        {
          "key": 3160,
          "isCountryDefault": false,
          "name": "Ar-Raqqah"
        },
        {
          "key": 3156,
          "isCountryDefault": false,
          "name": "As-Suwayda"
        },
        {
          "key": 3153,
          "isCountryDefault": false,
          "name": "Damascus"
        },
        {
          "key": 3155,
          "isCountryDefault": false,
          "name": "Daraa"
        },
        {
          "key": 3162,
          "isCountryDefault": false,
          "name": "Deir ez-Zor"
        },
        {
          "key": 3159,
          "isCountryDefault": false,
          "name": "Hama"
        },
        {
          "key": 3158,
          "isCountryDefault": false,
          "name": "Homs"
        },
        {
          "key": 3150,
          "isCountryDefault": false,
          "name": "Idlib"
        },
        {
          "key": 3151,
          "isCountryDefault": false,
          "name": "Latakia"
        },
        {
          "key": 3154,
          "isCountryDefault": false,
          "name": "Quneitra"
        },
        {
          "key": 3157,
          "isCountryDefault": false,
          "name": "Rif Dimashq"
        },
        {
          "key": 3152,
          "isCountryDefault": false,
          "name": "Tartus"
        }
      ],
      "isEu": false
    },
    {
      "key": "tajikistan",
      "name": "Tadschikistan",
      "code": "TJ",
      "region": "Asien",
      "states": [
        {
          "key": 215,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3184,
          "isCountryDefault": false,
          "name": "Districts of Republican Subordination"
        },
        {
          "key": 3186,
          "isCountryDefault": false,
          "name": "Gorno-Badakhshan"
        },
        {
          "key": 3185,
          "isCountryDefault": false,
          "name": "Khatlon"
        },
        {
          "key": 3183,
          "isCountryDefault": false,
          "name": "Sughd"
        }
      ],
      "isEu": false
    },
    {
      "key": "taiwan",
      "name": "Taiwan",
      "code": "TW",
      "region": "Asien",
      "states": [
        {
          "key": 68,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3171,
          "isCountryDefault": false,
          "name": "Changhua"
        },
        {
          "key": 3168,
          "isCountryDefault": false,
          "name": "Chiayi"
        },
        {
          "key": 3169,
          "isCountryDefault": false,
          "name": "Hsinchu"
        },
        {
          "key": 3172,
          "isCountryDefault": false,
          "name": "Hualien"
        },
        {
          "key": 3163,
          "isCountryDefault": false,
          "name": "Kaohsiung"
        },
        {
          "key": 3170,
          "isCountryDefault": false,
          "name": "Keelung"
        },
        {
          "key": 3181,
          "isCountryDefault": false,
          "name": "Kinmen"
        },
        {
          "key": 3182,
          "isCountryDefault": false,
          "name": "Matsu"
        },
        {
          "key": 3173,
          "isCountryDefault": false,
          "name": "Miaoli"
        },
        {
          "key": 3174,
          "isCountryDefault": false,
          "name": "Nantou"
        },
        {
          "key": 3164,
          "isCountryDefault": false,
          "name": "New Taipei"
        },
        {
          "key": 3175,
          "isCountryDefault": false,
          "name": "Penghu"
        },
        {
          "key": 3176,
          "isCountryDefault": false,
          "name": "Pingtung"
        },
        {
          "key": 3165,
          "isCountryDefault": false,
          "name": "Taichung"
        },
        {
          "key": 3166,
          "isCountryDefault": false,
          "name": "Tainan"
        },
        {
          "key": 3167,
          "isCountryDefault": false,
          "name": "Taipei"
        },
        {
          "key": 3177,
          "isCountryDefault": false,
          "name": "Taitung"
        },
        {
          "key": 3178,
          "isCountryDefault": false,
          "name": "Taoyuan"
        },
        {
          "key": 3179,
          "isCountryDefault": false,
          "name": "Yilan"
        },
        {
          "key": 3180,
          "isCountryDefault": false,
          "name": "Yunlin"
        }
      ],
      "isEu": false
    },
    {
      "key": "tanzania",
      "name": "Tansania",
      "code": "TZ",
      "region": "Afrika",
      "states": [
        {
          "key": 222,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3187,
          "isCountryDefault": false,
          "name": "Arusha"
        },
        {
          "key": 3188,
          "isCountryDefault": false,
          "name": "Dar es Salaam"
        },
        {
          "key": 3189,
          "isCountryDefault": false,
          "name": "Dodoma"
        },
        {
          "key": 3190,
          "isCountryDefault": false,
          "name": "Iringa"
        },
        {
          "key": 3191,
          "isCountryDefault": false,
          "name": "Kagera"
        },
        {
          "key": 3192,
          "isCountryDefault": false,
          "name": "Kigoma"
        },
        {
          "key": 3193,
          "isCountryDefault": false,
          "name": "Kilimanjaro"
        },
        {
          "key": 3194,
          "isCountryDefault": false,
          "name": "Lindi"
        },
        {
          "key": 3195,
          "isCountryDefault": false,
          "name": "Manyara"
        },
        {
          "key": 3196,
          "isCountryDefault": false,
          "name": "Mara"
        },
        {
          "key": 3197,
          "isCountryDefault": false,
          "name": "Mbeya"
        },
        {
          "key": 3198,
          "isCountryDefault": false,
          "name": "Morogoro"
        },
        {
          "key": 3199,
          "isCountryDefault": false,
          "name": "Mtwara"
        },
        {
          "key": 3200,
          "isCountryDefault": false,
          "name": "Mwanza"
        },
        {
          "key": 3201,
          "isCountryDefault": false,
          "name": "Pemba North"
        },
        {
          "key": 3202,
          "isCountryDefault": false,
          "name": "Pemba South"
        },
        {
          "key": 3203,
          "isCountryDefault": false,
          "name": "Pwani"
        },
        {
          "key": 3204,
          "isCountryDefault": false,
          "name": "Rukwa"
        },
        {
          "key": 3205,
          "isCountryDefault": false,
          "name": "Ruvuma"
        },
        {
          "key": 3206,
          "isCountryDefault": false,
          "name": "Shinyanga"
        },
        {
          "key": 3207,
          "isCountryDefault": false,
          "name": "Singida"
        },
        {
          "key": 3208,
          "isCountryDefault": false,
          "name": "Tabora"
        },
        {
          "key": 3209,
          "isCountryDefault": false,
          "name": "Tanga"
        },
        {
          "key": 3210,
          "isCountryDefault": false,
          "name": "Zanzibar Central/South Region"
        },
        {
          "key": 3211,
          "isCountryDefault": false,
          "name": "Zanzibar North Region"
        },
        {
          "key": 3212,
          "isCountryDefault": false,
          "name": "Zanzibar Urban/West Region"
        }
      ],
      "isEu": false
    },
    {
      "key": "thailand",
      "name": "Thailand",
      "code": "TH",
      "region": "Asien",
      "states": [
        {
          "key": 65,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3249,
          "isCountryDefault": false,
          "name": "Amnat Charoen"
        },
        {
          "key": 3220,
          "isCountryDefault": false,
          "name": "Ang Thong"
        },
        {
          "key": 3214,
          "isCountryDefault": false,
          "name": "Bangkok"
        },
        {
          "key": 3250,
          "isCountryDefault": false,
          "name": "Bueng Kan"
        },
        {
          "key": 3243,
          "isCountryDefault": false,
          "name": "Buriram"
        },
        {
          "key": 3239,
          "isCountryDefault": false,
          "name": "Chachoengsao"
        },
        {
          "key": 3223,
          "isCountryDefault": false,
          "name": "Chainat"
        },
        {
          "key": 3248,
          "isCountryDefault": false,
          "name": "Chaiyaphum"
        },
        {
          "key": 3237,
          "isCountryDefault": false,
          "name": "Chanthaburi"
        },
        {
          "key": 3262,
          "isCountryDefault": false,
          "name": "Chiang Mai"
        },
        {
          "key": 3269,
          "isCountryDefault": false,
          "name": "Chiang Rai"
        },
        {
          "key": 3235,
          "isCountryDefault": false,
          "name": "Chonburi"
        },
        {
          "key": 3282,
          "isCountryDefault": false,
          "name": "Chumphon"
        },
        {
          "key": 3258,
          "isCountryDefault": false,
          "name": "Kalasin"
        },
        {
          "key": 3226,
          "isCountryDefault": false,
          "name": "Kamphaeng Phet"
        },
        {
          "key": 3273,
          "isCountryDefault": false,
          "name": "Kanchanaburi"
        },
        {
          "key": 3252,
          "isCountryDefault": false,
          "name": "Khon Kaen"
        },
        {
          "key": 3277,
          "isCountryDefault": false,
          "name": "Krabi"
        },
        {
          "key": 3264,
          "isCountryDefault": false,
          "name": "Lampang"
        },
        {
          "key": 3263,
          "isCountryDefault": false,
          "name": "Lamphun"
        },
        {
          "key": 3254,
          "isCountryDefault": false,
          "name": "Loei"
        },
        {
          "key": 3221,
          "isCountryDefault": false,
          "name": "Lopburi"
        },
        {
          "key": 3270,
          "isCountryDefault": false,
          "name": "Mae Hong Son"
        },
        {
          "key": 3256,
          "isCountryDefault": false,
          "name": "Maha Sarakham"
        },
        {
          "key": 3261,
          "isCountryDefault": false,
          "name": "Mukdahan"
        },
        {
          "key": 3215,
          "isCountryDefault": false,
          "name": "Nakhon Nayok"
        },
        {
          "key": 3233,
          "isCountryDefault": false,
          "name": "Nakhon Pathom"
        },
        {
          "key": 3260,
          "isCountryDefault": false,
          "name": "Nakhon Phanom"
        },
        {
          "key": 3242,
          "isCountryDefault": false,
          "name": "Nakhon Ratchasima"
        },
        {
          "key": 3216,
          "isCountryDefault": false,
          "name": "Nakhon Sawan"
        },
        {
          "key": 3276,
          "isCountryDefault": false,
          "name": "Nakhon Si Thammarat"
        },
        {
          "key": 3267,
          "isCountryDefault": false,
          "name": "Nan"
        },
        {
          "key": 3289,
          "isCountryDefault": false,
          "name": "Narathiwat"
        },
        {
          "key": 3251,
          "isCountryDefault": false,
          "name": "Nong Bua Lamphu"
        },
        {
          "key": 3255,
          "isCountryDefault": false,
          "name": "Nong Khai"
        },
        {
          "key": 3217,
          "isCountryDefault": false,
          "name": "Nonthaburi"
        },
        {
          "key": 3218,
          "isCountryDefault": false,
          "name": "Pathum Thani"
        },
        {
          "key": 3287,
          "isCountryDefault": false,
          "name": "Pattani"
        },
        {
          "key": 3278,
          "isCountryDefault": false,
          "name": "Phang Nga"
        },
        {
          "key": 3286,
          "isCountryDefault": false,
          "name": "Phatthalung"
        },
        {
          "key": 3268,
          "isCountryDefault": false,
          "name": "Phayao"
        },
        {
          "key": 3230,
          "isCountryDefault": false,
          "name": "Phetchabun"
        },
        {
          "key": 3274,
          "isCountryDefault": false,
          "name": "Phetchaburi"
        },
        {
          "key": 3229,
          "isCountryDefault": false,
          "name": "Phichit"
        },
        {
          "key": 3228,
          "isCountryDefault": false,
          "name": "Phitsanulok"
        },
        {
          "key": 3219,
          "isCountryDefault": false,
          "name": "Phra Nakhon Si Ayutthaya"
        },
        {
          "key": 3266,
          "isCountryDefault": false,
          "name": "Phrae"
        },
        {
          "key": 3279,
          "isCountryDefault": false,
          "name": "Phuket"
        },
        {
          "key": 3240,
          "isCountryDefault": false,
          "name": "Prachinburi"
        },
        {
          "key": 3275,
          "isCountryDefault": false,
          "name": "Prachuap Khiri Khan"
        },
        {
          "key": 3281,
          "isCountryDefault": false,
          "name": "Ranong"
        },
        {
          "key": 3272,
          "isCountryDefault": false,
          "name": "Ratchaburi"
        },
        {
          "key": 3236,
          "isCountryDefault": false,
          "name": "Rayong"
        },
        {
          "key": 3257,
          "isCountryDefault": false,
          "name": "Roi Et Province"
        },
        {
          "key": 3241,
          "isCountryDefault": false,
          "name": "Sa Kaeo"
        },
        {
          "key": 3259,
          "isCountryDefault": false,
          "name": "Sakon Nakhon"
        },
        {
          "key": 3213,
          "isCountryDefault": false,
          "name": "Samut Prakan"
        },
        {
          "key": 3232,
          "isCountryDefault": false,
          "name": "Samut Sakhon"
        },
        {
          "key": 3231,
          "isCountryDefault": false,
          "name": "Samut Songkhram"
        },
        {
          "key": 3224,
          "isCountryDefault": false,
          "name": "Saraburi"
        },
        {
          "key": 3284,
          "isCountryDefault": false,
          "name": "Satun"
        },
        {
          "key": 3222,
          "isCountryDefault": false,
          "name": "Sing Buri"
        },
        {
          "key": 3245,
          "isCountryDefault": false,
          "name": "Sisaket"
        },
        {
          "key": 3283,
          "isCountryDefault": false,
          "name": "Songkhla"
        },
        {
          "key": 3227,
          "isCountryDefault": false,
          "name": "Sukhothai"
        },
        {
          "key": 3234,
          "isCountryDefault": false,
          "name": "Suphan Buri"
        },
        {
          "key": 3280,
          "isCountryDefault": false,
          "name": "Surat Thani"
        },
        {
          "key": 3244,
          "isCountryDefault": false,
          "name": "Surin"
        },
        {
          "key": 3271,
          "isCountryDefault": false,
          "name": "Tak"
        },
        {
          "key": 3285,
          "isCountryDefault": false,
          "name": "Trang"
        },
        {
          "key": 3238,
          "isCountryDefault": false,
          "name": "Trat"
        },
        {
          "key": 3246,
          "isCountryDefault": false,
          "name": "Ubon Ratchathani"
        },
        {
          "key": 3253,
          "isCountryDefault": false,
          "name": "Udon Thani"
        },
        {
          "key": 3225,
          "isCountryDefault": false,
          "name": "Uthai Thani"
        },
        {
          "key": 3265,
          "isCountryDefault": false,
          "name": "Uttaradit"
        },
        {
          "key": 3288,
          "isCountryDefault": false,
          "name": "Yala"
        },
        {
          "key": 3247,
          "isCountryDefault": false,
          "name": "Yasothon"
        }
      ],
      "isEu": false
    },
    {
      "key": "timorLesteEastTimor",
      "name": "Timor-Leste (Osttimor)",
      "code": "TL",
      "region": "Asien",
      "states": [
        {
          "key": 219,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3295,
          "isCountryDefault": false,
          "name": "Aileu"
        },
        {
          "key": 3299,
          "isCountryDefault": false,
          "name": "Ainaro"
        },
        {
          "key": 3291,
          "isCountryDefault": false,
          "name": "Baucau"
        },
        {
          "key": 3300,
          "isCountryDefault": false,
          "name": "Bobonaro"
        },
        {
          "key": 3301,
          "isCountryDefault": false,
          "name": "Cova Lima"
        },
        {
          "key": 3294,
          "isCountryDefault": false,
          "name": "Dili"
        },
        {
          "key": 3298,
          "isCountryDefault": false,
          "name": "Ermera"
        },
        {
          "key": 3290,
          "isCountryDefault": false,
          "name": "Lautém"
        },
        {
          "key": 3297,
          "isCountryDefault": false,
          "name": "Liquiçá"
        },
        {
          "key": 3293,
          "isCountryDefault": false,
          "name": "Manatuto"
        },
        {
          "key": 3296,
          "isCountryDefault": false,
          "name": "Manufahi"
        },
        {
          "key": 3302,
          "isCountryDefault": false,
          "name": "Oecusse"
        },
        {
          "key": 3292,
          "isCountryDefault": false,
          "name": "Viqueque"
        }
      ],
      "isEu": false
    },
    {
      "key": "togo",
      "name": "Togo",
      "code": "TG",
      "region": "Afrika",
      "states": [
        {
          "key": 214,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3303,
          "isCountryDefault": false,
          "name": "Centrale"
        },
        {
          "key": 3304,
          "isCountryDefault": false,
          "name": "Kara"
        },
        {
          "key": 3305,
          "isCountryDefault": false,
          "name": "Maritime"
        },
        {
          "key": 3306,
          "isCountryDefault": false,
          "name": "Plateaux"
        },
        {
          "key": 3307,
          "isCountryDefault": false,
          "name": "Savanes"
        }
      ],
      "isEu": false
    },
    {
      "key": "tokelau",
      "name": "Tokelau",
      "code": "TK",
      "region": "Ozeanien",
      "states": [
        {
          "key": 216,
          "isCountryDefault": true,
          "name": null
        }
      ],
      "isEu": false
    },
    {
      "key": "tonga",
      "name": "Tonga",
      "code": "TO",
      "region": "Ozeanien",
      "states": [
        {
          "key": 218,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3311,
          "isCountryDefault": false,
          "name": "'Eua"
        },
        {
          "key": 3310,
          "isCountryDefault": false,
          "name": "Ha'apai"
        },
        {
          "key": 3312,
          "isCountryDefault": false,
          "name": "Ongo Niua"
        },
        {
          "key": 3308,
          "isCountryDefault": false,
          "name": "Tongatapu"
        },
        {
          "key": 3309,
          "isCountryDefault": false,
          "name": "Vava'u"
        }
      ],
      "isEu": false
    },
    {
      "key": "trinidadandTobago",
      "name": "Trinidad und Tobago",
      "code": "TT",
      "region": "Nordamerika",
      "states": [
        {
          "key": 220,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3316,
          "isCountryDefault": false,
          "name": "Arima"
        },
        {
          "key": 3315,
          "isCountryDefault": false,
          "name": "Chaguanas"
        },
        {
          "key": 3318,
          "isCountryDefault": false,
          "name": "Couva-Tabaquite-Talparo"
        },
        {
          "key": 3319,
          "isCountryDefault": false,
          "name": "Diego Martin"
        },
        {
          "key": 3320,
          "isCountryDefault": false,
          "name": "Penal-Debe"
        },
        {
          "key": 3317,
          "isCountryDefault": false,
          "name": "Point Fortin"
        },
        {
          "key": 3313,
          "isCountryDefault": false,
          "name": "Port of Spain"
        },
        {
          "key": 3321,
          "isCountryDefault": false,
          "name": "Princes Town"
        },
        {
          "key": 3322,
          "isCountryDefault": false,
          "name": "Rio Claro-Mayaro"
        },
        {
          "key": 3314,
          "isCountryDefault": false,
          "name": "San Fernando"
        },
        {
          "key": 3323,
          "isCountryDefault": false,
          "name": "San Juan-Laventille"
        },
        {
          "key": 3324,
          "isCountryDefault": false,
          "name": "Sangre Grande"
        },
        {
          "key": 3325,
          "isCountryDefault": false,
          "name": "Siparia"
        },
        {
          "key": 3326,
          "isCountryDefault": false,
          "name": "Tunapuna-Piarco"
        }
      ],
      "isEu": false
    },
    {
      "key": "chad",
      "name": "Tschad",
      "code": "TD",
      "region": "Afrika",
      "states": [
        {
          "key": 212,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 854,
          "isCountryDefault": false,
          "name": "Bahr el Gazel Region"
        },
        {
          "key": 834,
          "isCountryDefault": false,
          "name": "Batha"
        },
        {
          "key": 835,
          "isCountryDefault": false,
          "name": "Borkou-Ennedi"
        },
        {
          "key": 836,
          "isCountryDefault": false,
          "name": "Chari-Baguirmi"
        },
        {
          "key": 852,
          "isCountryDefault": false,
          "name": "Ennedi Region"
        },
        {
          "key": 837,
          "isCountryDefault": false,
          "name": "Guéra"
        },
        {
          "key": 838,
          "isCountryDefault": false,
          "name": "Hadjer-Lamis"
        },
        {
          "key": 839,
          "isCountryDefault": false,
          "name": "Kanem"
        },
        {
          "key": 840,
          "isCountryDefault": false,
          "name": "Lac"
        },
        {
          "key": 841,
          "isCountryDefault": false,
          "name": "Logone Occidental"
        },
        {
          "key": 842,
          "isCountryDefault": false,
          "name": "Logone Oriental"
        },
        {
          "key": 843,
          "isCountryDefault": false,
          "name": "Mandoul"
        },
        {
          "key": 844,
          "isCountryDefault": false,
          "name": "Mayo-Kebbi Est"
        },
        {
          "key": 845,
          "isCountryDefault": false,
          "name": "Mayo-Kebbi Ouest"
        },
        {
          "key": 846,
          "isCountryDefault": false,
          "name": "Moyen-Chari"
        },
        {
          "key": 851,
          "isCountryDefault": false,
          "name": "N'Djamena"
        },
        {
          "key": 847,
          "isCountryDefault": false,
          "name": "Ouaddaï"
        },
        {
          "key": 848,
          "isCountryDefault": false,
          "name": "Salamat"
        },
        {
          "key": 855,
          "isCountryDefault": false,
          "name": "Sila Region"
        },
        {
          "key": 849,
          "isCountryDefault": false,
          "name": "Tandjilé"
        },
        {
          "key": 853,
          "isCountryDefault": false,
          "name": "Tibesti Region"
        },
        {
          "key": 850,
          "isCountryDefault": false,
          "name": "Wadi Fira"
        }
      ],
      "isEu": false
    },
    {
      "key": "czechRepublic",
      "name": "Tschechien",
      "code": "CZ",
      "region": "Europa",
      "states": [
        {
          "key": 87,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1080,
          "isCountryDefault": false,
          "name": "Central Bohemian Region"
        },
        {
          "key": 1086,
          "isCountryDefault": false,
          "name": "Hradec Králové Region"
        },
        {
          "key": 1083,
          "isCountryDefault": false,
          "name": "Karlovy Vary Region"
        },
        {
          "key": 1085,
          "isCountryDefault": false,
          "name": "Liberec Region"
        },
        {
          "key": 1089,
          "isCountryDefault": false,
          "name": "Moravian-Silesian Region"
        },
        {
          "key": 1088,
          "isCountryDefault": false,
          "name": "Olomouc Region"
        },
        {
          "key": 1087,
          "isCountryDefault": false,
          "name": "Pardubice Region"
        },
        {
          "key": 1082,
          "isCountryDefault": false,
          "name": "Plzeň Region"
        },
        {
          "key": 1079,
          "isCountryDefault": false,
          "name": "Prague"
        },
        {
          "key": 1081,
          "isCountryDefault": false,
          "name": "South Bohemian Region"
        },
        {
          "key": 1090,
          "isCountryDefault": false,
          "name": "South Moravian Region"
        },
        {
          "key": 1084,
          "isCountryDefault": false,
          "name": "Ústí nad Labem Region"
        },
        {
          "key": 1092,
          "isCountryDefault": false,
          "name": "Vysočina Region"
        },
        {
          "key": 1091,
          "isCountryDefault": false,
          "name": "Zlín Region"
        }
      ],
      "isEu": true
    },
    {
      "key": "tunisia",
      "name": "Tunesien",
      "code": "TN",
      "region": "Afrika",
      "states": [
        {
          "key": 66,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3327,
          "isCountryDefault": false,
          "name": "Ariana"
        },
        {
          "key": 3328,
          "isCountryDefault": false,
          "name": "Béja"
        },
        {
          "key": 3329,
          "isCountryDefault": false,
          "name": "Ben Arous"
        },
        {
          "key": 3330,
          "isCountryDefault": false,
          "name": "Bizerte"
        },
        {
          "key": 3331,
          "isCountryDefault": false,
          "name": "Gabès"
        },
        {
          "key": 3332,
          "isCountryDefault": false,
          "name": "Gafsa"
        },
        {
          "key": 3333,
          "isCountryDefault": false,
          "name": "Jendouba"
        },
        {
          "key": 3334,
          "isCountryDefault": false,
          "name": "Kairouan"
        },
        {
          "key": 3335,
          "isCountryDefault": false,
          "name": "Kasserine"
        },
        {
          "key": 3336,
          "isCountryDefault": false,
          "name": "Kebili"
        },
        {
          "key": 3337,
          "isCountryDefault": false,
          "name": "Kef"
        },
        {
          "key": 3338,
          "isCountryDefault": false,
          "name": "Mahdia"
        },
        {
          "key": 3339,
          "isCountryDefault": false,
          "name": "Manouba"
        },
        {
          "key": 3340,
          "isCountryDefault": false,
          "name": "Medenine"
        },
        {
          "key": 3341,
          "isCountryDefault": false,
          "name": "Monastir"
        },
        {
          "key": 3342,
          "isCountryDefault": false,
          "name": "Nabeul"
        },
        {
          "key": 3343,
          "isCountryDefault": false,
          "name": "Sfax"
        },
        {
          "key": 3344,
          "isCountryDefault": false,
          "name": "Sidi Bouzid"
        },
        {
          "key": 3345,
          "isCountryDefault": false,
          "name": "Siliana"
        },
        {
          "key": 3346,
          "isCountryDefault": false,
          "name": "Sousse"
        },
        {
          "key": 3347,
          "isCountryDefault": false,
          "name": "Tataouine"
        },
        {
          "key": 3348,
          "isCountryDefault": false,
          "name": "Tozeur"
        },
        {
          "key": 3349,
          "isCountryDefault": false,
          "name": "Tunis"
        },
        {
          "key": 3350,
          "isCountryDefault": false,
          "name": "Zaghouan"
        }
      ],
      "isEu": false
    },
    {
      "key": "turkey",
      "name": "Türkei",
      "code": "TR",
      "region": "Naher und Mittlerer Osten",
      "states": [
        {
          "key": 67,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3351,
          "isCountryDefault": false,
          "name": "Adana"
        },
        {
          "key": 3352,
          "isCountryDefault": false,
          "name": "Adıyaman"
        },
        {
          "key": 3353,
          "isCountryDefault": false,
          "name": "Afyonkarahisar"
        },
        {
          "key": 3354,
          "isCountryDefault": false,
          "name": "Ağrı"
        },
        {
          "key": 3418,
          "isCountryDefault": false,
          "name": "Aksaray"
        },
        {
          "key": 3355,
          "isCountryDefault": false,
          "name": "Amasya"
        },
        {
          "key": 3356,
          "isCountryDefault": false,
          "name": "Ankara"
        },
        {
          "key": 3357,
          "isCountryDefault": false,
          "name": "Antalya"
        },
        {
          "key": 3425,
          "isCountryDefault": false,
          "name": "Ardahan"
        },
        {
          "key": 3358,
          "isCountryDefault": false,
          "name": "Artvin"
        },
        {
          "key": 3359,
          "isCountryDefault": false,
          "name": "Aydın"
        },
        {
          "key": 3360,
          "isCountryDefault": false,
          "name": "Balıkesir"
        },
        {
          "key": 3424,
          "isCountryDefault": false,
          "name": "Bartın"
        },
        {
          "key": 3422,
          "isCountryDefault": false,
          "name": "Batman"
        },
        {
          "key": 3419,
          "isCountryDefault": false,
          "name": "Bayburt"
        },
        {
          "key": 3361,
          "isCountryDefault": false,
          "name": "Bilecik"
        },
        {
          "key": 3362,
          "isCountryDefault": false,
          "name": "Bingöl"
        },
        {
          "key": 3363,
          "isCountryDefault": false,
          "name": "Bitlis"
        },
        {
          "key": 3364,
          "isCountryDefault": false,
          "name": "Bolu"
        },
        {
          "key": 3365,
          "isCountryDefault": false,
          "name": "Burdur"
        },
        {
          "key": 3366,
          "isCountryDefault": false,
          "name": "Bursa"
        },
        {
          "key": 3367,
          "isCountryDefault": false,
          "name": "Çanakkale"
        },
        {
          "key": 3368,
          "isCountryDefault": false,
          "name": "Çankırı"
        },
        {
          "key": 3369,
          "isCountryDefault": false,
          "name": "Çorum"
        },
        {
          "key": 3370,
          "isCountryDefault": false,
          "name": "Denizli"
        },
        {
          "key": 3371,
          "isCountryDefault": false,
          "name": "Diyarbakır"
        },
        {
          "key": 3431,
          "isCountryDefault": false,
          "name": "Düzce"
        },
        {
          "key": 3372,
          "isCountryDefault": false,
          "name": "Edirne"
        },
        {
          "key": 3373,
          "isCountryDefault": false,
          "name": "Elazığ"
        },
        {
          "key": 3374,
          "isCountryDefault": false,
          "name": "Erzincan"
        },
        {
          "key": 3375,
          "isCountryDefault": false,
          "name": "Erzurum"
        },
        {
          "key": 3376,
          "isCountryDefault": false,
          "name": "Eskişehir"
        },
        {
          "key": 3377,
          "isCountryDefault": false,
          "name": "Gaziantep"
        },
        {
          "key": 3378,
          "isCountryDefault": false,
          "name": "Giresun"
        },
        {
          "key": 3379,
          "isCountryDefault": false,
          "name": "Gümüşhane"
        },
        {
          "key": 3380,
          "isCountryDefault": false,
          "name": "Hakkâri"
        },
        {
          "key": 3381,
          "isCountryDefault": false,
          "name": "Hatay"
        },
        {
          "key": 3426,
          "isCountryDefault": false,
          "name": "Iğdır"
        },
        {
          "key": 3382,
          "isCountryDefault": false,
          "name": "Isparta"
        },
        {
          "key": 3384,
          "isCountryDefault": false,
          "name": "Istanbul"
        },
        {
          "key": 3385,
          "isCountryDefault": false,
          "name": "İzmir"
        },
        {
          "key": 3396,
          "isCountryDefault": false,
          "name": "Kahramanmaraş"
        },
        {
          "key": 3428,
          "isCountryDefault": false,
          "name": "Karabük"
        },
        {
          "key": 3420,
          "isCountryDefault": false,
          "name": "Karaman"
        },
        {
          "key": 3386,
          "isCountryDefault": false,
          "name": "Kars"
        },
        {
          "key": 3387,
          "isCountryDefault": false,
          "name": "Kastamonu"
        },
        {
          "key": 3388,
          "isCountryDefault": false,
          "name": "Kayseri"
        },
        {
          "key": 3429,
          "isCountryDefault": false,
          "name": "Kilis"
        },
        {
          "key": 3421,
          "isCountryDefault": false,
          "name": "Kırıkkale"
        },
        {
          "key": 3389,
          "isCountryDefault": false,
          "name": "Kırklareli"
        },
        {
          "key": 3390,
          "isCountryDefault": false,
          "name": "Kırşehir"
        },
        {
          "key": 3391,
          "isCountryDefault": false,
          "name": "Kocaeli"
        },
        {
          "key": 3392,
          "isCountryDefault": false,
          "name": "Konya"
        },
        {
          "key": 3393,
          "isCountryDefault": false,
          "name": "Kütahya"
        },
        {
          "key": 3394,
          "isCountryDefault": false,
          "name": "Malatya"
        },
        {
          "key": 3395,
          "isCountryDefault": false,
          "name": "Manisa"
        },
        {
          "key": 3397,
          "isCountryDefault": false,
          "name": "Mardin"
        },
        {
          "key": 3383,
          "isCountryDefault": false,
          "name": "Mersin"
        },
        {
          "key": 3398,
          "isCountryDefault": false,
          "name": "Muğla"
        },
        {
          "key": 3399,
          "isCountryDefault": false,
          "name": "Muş"
        },
        {
          "key": 3400,
          "isCountryDefault": false,
          "name": "Nevşehir"
        },
        {
          "key": 3401,
          "isCountryDefault": false,
          "name": "Niğde"
        },
        {
          "key": 3402,
          "isCountryDefault": false,
          "name": "Ordu"
        },
        {
          "key": 3430,
          "isCountryDefault": false,
          "name": "Osmaniye"
        },
        {
          "key": 3403,
          "isCountryDefault": false,
          "name": "Rize"
        },
        {
          "key": 3404,
          "isCountryDefault": false,
          "name": "Sakarya"
        },
        {
          "key": 3405,
          "isCountryDefault": false,
          "name": "Samsun"
        },
        {
          "key": 3413,
          "isCountryDefault": false,
          "name": "Şanlıurfa"
        },
        {
          "key": 3406,
          "isCountryDefault": false,
          "name": "Siirt"
        },
        {
          "key": 3407,
          "isCountryDefault": false,
          "name": "Sinop"
        },
        {
          "key": 3423,
          "isCountryDefault": false,
          "name": "Şırnak"
        },
        {
          "key": 3408,
          "isCountryDefault": false,
          "name": "Sivas"
        },
        {
          "key": 3409,
          "isCountryDefault": false,
          "name": "Tekirdağ"
        },
        {
          "key": 3410,
          "isCountryDefault": false,
          "name": "Tokat"
        },
        {
          "key": 3411,
          "isCountryDefault": false,
          "name": "Trabzon"
        },
        {
          "key": 3412,
          "isCountryDefault": false,
          "name": "Tunceli"
        },
        {
          "key": 3414,
          "isCountryDefault": false,
          "name": "Uşak"
        },
        {
          "key": 3415,
          "isCountryDefault": false,
          "name": "Van"
        },
        {
          "key": 3427,
          "isCountryDefault": false,
          "name": "Yalova"
        },
        {
          "key": 3416,
          "isCountryDefault": false,
          "name": "Yozgat"
        },
        {
          "key": 3417,
          "isCountryDefault": false,
          "name": "Zonguldak"
        }
      ],
      "isEu": false
    },
    {
      "key": "turkmenistan",
      "name": "Turkmenistan",
      "code": "TM",
      "region": "Asien",
      "states": [
        {
          "key": 217,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3432,
          "isCountryDefault": false,
          "name": "Ahal"
        },
        {
          "key": 3433,
          "isCountryDefault": false,
          "name": "Balkan"
        },
        {
          "key": 3434,
          "isCountryDefault": false,
          "name": "Daşoguz"
        },
        {
          "key": 3435,
          "isCountryDefault": false,
          "name": "Lebap"
        },
        {
          "key": 3436,
          "isCountryDefault": false,
          "name": "Mary"
        }
      ],
      "isEu": false
    },
    {
      "key": "turksandCaicosIslands",
      "name": "Turks- und Caicosinseln",
      "code": "TC",
      "region": "Nordamerika",
      "states": [
        {
          "key": 211,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3441,
          "isCountryDefault": false,
          "name": "Grand Turk"
        },
        {
          "key": 3439,
          "isCountryDefault": false,
          "name": "Middle Caicos"
        },
        {
          "key": 3438,
          "isCountryDefault": false,
          "name": "North Caicos"
        },
        {
          "key": 3437,
          "isCountryDefault": false,
          "name": "Providenciales and West Caicos"
        },
        {
          "key": 3442,
          "isCountryDefault": false,
          "name": "Salt Cay"
        },
        {
          "key": 3440,
          "isCountryDefault": false,
          "name": "South Caicos and East Caicos"
        }
      ],
      "isEu": false
    },
    {
      "key": "tuvalu",
      "name": "Tuvalu",
      "code": "TV",
      "region": "Ozeanien",
      "states": [
        {
          "key": 221,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3443,
          "isCountryDefault": false,
          "name": "Funafuti"
        },
        {
          "key": 3449,
          "isCountryDefault": false,
          "name": "Nanumanga"
        },
        {
          "key": 3444,
          "isCountryDefault": false,
          "name": "Nanumea"
        },
        {
          "key": 3450,
          "isCountryDefault": false,
          "name": "Niulakita"
        },
        {
          "key": 3451,
          "isCountryDefault": false,
          "name": "Niutao"
        },
        {
          "key": 3445,
          "isCountryDefault": false,
          "name": "Nui"
        },
        {
          "key": 3446,
          "isCountryDefault": false,
          "name": "Nukufetau"
        },
        {
          "key": 3447,
          "isCountryDefault": false,
          "name": "Nukulaelae"
        },
        {
          "key": 3448,
          "isCountryDefault": false,
          "name": "Vaitupu"
        }
      ],
      "isEu": false
    },
    {
      "key": "uganda",
      "name": "Uganda",
      "code": "UG",
      "region": "Afrika",
      "states": [
        {
          "key": 223,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3452,
          "isCountryDefault": false,
          "name": "Central region"
        },
        {
          "key": 3453,
          "isCountryDefault": false,
          "name": "Eastern region"
        },
        {
          "key": 3454,
          "isCountryDefault": false,
          "name": "Northern region"
        },
        {
          "key": 3455,
          "isCountryDefault": false,
          "name": "Western region"
        }
      ],
      "isEu": false
    },
    {
      "key": "ukraine",
      "name": "Ukraine",
      "code": "UA",
      "region": "Europa",
      "states": [
        {
          "key": 69,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3457,
          "isCountryDefault": false,
          "name": "Cherkasy Oblast"
        },
        {
          "key": 3458,
          "isCountryDefault": false,
          "name": "Chernihiv Oblast"
        },
        {
          "key": 3459,
          "isCountryDefault": false,
          "name": "Chernivtsi Oblast"
        },
        {
          "key": 3456,
          "isCountryDefault": false,
          "name": "Crimea"
        },
        {
          "key": 3460,
          "isCountryDefault": false,
          "name": "Dnipropetrovsk Oblast"
        },
        {
          "key": 3461,
          "isCountryDefault": false,
          "name": "Donetsk Oblast"
        },
        {
          "key": 3462,
          "isCountryDefault": false,
          "name": "Ivano-Frankivsk Oblast"
        },
        {
          "key": 3463,
          "isCountryDefault": false,
          "name": "Kharkiv Oblast"
        },
        {
          "key": 3464,
          "isCountryDefault": false,
          "name": "Kherson Oblast"
        },
        {
          "key": 3465,
          "isCountryDefault": false,
          "name": "Khmelnytskyi Oblast"
        },
        {
          "key": 3481,
          "isCountryDefault": false,
          "name": "Kiev"
        },
        {
          "key": 3466,
          "isCountryDefault": false,
          "name": "Kiev Oblast"
        },
        {
          "key": 3467,
          "isCountryDefault": false,
          "name": "Kirovohrad Oblast"
        },
        {
          "key": 3468,
          "isCountryDefault": false,
          "name": "Luhansk Oblast"
        },
        {
          "key": 3469,
          "isCountryDefault": false,
          "name": "Lviv Oblast"
        },
        {
          "key": 3470,
          "isCountryDefault": false,
          "name": "Mykolaiv Oblast"
        },
        {
          "key": 3471,
          "isCountryDefault": false,
          "name": "Odessa Oblast"
        },
        {
          "key": 3472,
          "isCountryDefault": false,
          "name": "Poltava Oblast"
        },
        {
          "key": 3473,
          "isCountryDefault": false,
          "name": "Rivne Oblast"
        },
        {
          "key": 3482,
          "isCountryDefault": false,
          "name": "Sevastopol"
        },
        {
          "key": 3474,
          "isCountryDefault": false,
          "name": "Sumy Oblast"
        },
        {
          "key": 3475,
          "isCountryDefault": false,
          "name": "Ternopil Oblast"
        },
        {
          "key": 3476,
          "isCountryDefault": false,
          "name": "Vinnytsia Oblast"
        },
        {
          "key": 3477,
          "isCountryDefault": false,
          "name": "Volyn Oblast"
        },
        {
          "key": 3478,
          "isCountryDefault": false,
          "name": "Zakarpattia Oblast"
        },
        {
          "key": 3479,
          "isCountryDefault": false,
          "name": "Zaporizhia Oblast"
        },
        {
          "key": 3480,
          "isCountryDefault": false,
          "name": "Zhytomyr Oblast"
        }
      ],
      "isEu": false
    },
    {
      "key": "hungary",
      "name": "Ungarn",
      "code": "HU",
      "region": "Europa",
      "states": [
        {
          "key": 24,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1532,
          "isCountryDefault": false,
          "name": "Bács-Kiskun"
        },
        {
          "key": 1533,
          "isCountryDefault": false,
          "name": "Baranya"
        },
        {
          "key": 1534,
          "isCountryDefault": false,
          "name": "Békés"
        },
        {
          "key": 1535,
          "isCountryDefault": false,
          "name": "Borsod-Abaúj-Zemplén"
        },
        {
          "key": 1551,
          "isCountryDefault": false,
          "name": "Budapest"
        },
        {
          "key": 1536,
          "isCountryDefault": false,
          "name": "Csongrád"
        },
        {
          "key": 1537,
          "isCountryDefault": false,
          "name": "Fejér"
        },
        {
          "key": 1538,
          "isCountryDefault": false,
          "name": "Győr-Moson-Sopron"
        },
        {
          "key": 1539,
          "isCountryDefault": false,
          "name": "Hajdú-Bihar"
        },
        {
          "key": 1540,
          "isCountryDefault": false,
          "name": "Heves"
        },
        {
          "key": 1541,
          "isCountryDefault": false,
          "name": "Jász-Nagykun-Szolnok"
        },
        {
          "key": 1542,
          "isCountryDefault": false,
          "name": "Komárom-Esztergom"
        },
        {
          "key": 1543,
          "isCountryDefault": false,
          "name": "Nógrád"
        },
        {
          "key": 1544,
          "isCountryDefault": false,
          "name": "Pest"
        },
        {
          "key": 1545,
          "isCountryDefault": false,
          "name": "Somogy"
        },
        {
          "key": 1546,
          "isCountryDefault": false,
          "name": "Szabolcs-Szatmár-Bereg"
        },
        {
          "key": 1547,
          "isCountryDefault": false,
          "name": "Tolna"
        },
        {
          "key": 1548,
          "isCountryDefault": false,
          "name": "Vas"
        },
        {
          "key": 1549,
          "isCountryDefault": false,
          "name": "Veszprém"
        },
        {
          "key": 1550,
          "isCountryDefault": false,
          "name": "Zala"
        }
      ],
      "isEu": true
    },
    {
      "key": "uruguay",
      "name": "Uruguay",
      "code": "UY",
      "region": "Südamerika",
      "states": [
        {
          "key": 71,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3553,
          "isCountryDefault": false,
          "name": "Artigas Departament"
        },
        {
          "key": 3554,
          "isCountryDefault": false,
          "name": "Canelones Department"
        },
        {
          "key": 3555,
          "isCountryDefault": false,
          "name": "Cerro Largo Department"
        },
        {
          "key": 3556,
          "isCountryDefault": false,
          "name": "Colonia Department"
        },
        {
          "key": 3557,
          "isCountryDefault": false,
          "name": "Durazno Department"
        },
        {
          "key": 3558,
          "isCountryDefault": false,
          "name": "Flores Department"
        },
        {
          "key": 3559,
          "isCountryDefault": false,
          "name": "Florida Department"
        },
        {
          "key": 3560,
          "isCountryDefault": false,
          "name": "Lavalleja Department"
        },
        {
          "key": 3561,
          "isCountryDefault": false,
          "name": "Maldonado Department"
        },
        {
          "key": 3562,
          "isCountryDefault": false,
          "name": "Montevideo Department"
        },
        {
          "key": 3563,
          "isCountryDefault": false,
          "name": "Paysandú Department"
        },
        {
          "key": 3564,
          "isCountryDefault": false,
          "name": "Río Negro Department"
        },
        {
          "key": 3565,
          "isCountryDefault": false,
          "name": "Rivera Department"
        },
        {
          "key": 3566,
          "isCountryDefault": false,
          "name": "Rocha Department"
        },
        {
          "key": 3567,
          "isCountryDefault": false,
          "name": "Salto Department"
        },
        {
          "key": 3568,
          "isCountryDefault": false,
          "name": "San José Department"
        },
        {
          "key": 3569,
          "isCountryDefault": false,
          "name": "Soriano Department"
        },
        {
          "key": 3570,
          "isCountryDefault": false,
          "name": "Tacuarembó Department"
        },
        {
          "key": 3571,
          "isCountryDefault": false,
          "name": "Treinta y Tres Department"
        }
      ],
      "isEu": false
    },
    {
      "key": "uzbekistan",
      "name": "Usbekistan",
      "code": "UZ",
      "region": "Asien",
      "states": [
        {
          "key": 225,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3582,
          "isCountryDefault": false,
          "name": "Andijon Viloyati"
        },
        {
          "key": 3572,
          "isCountryDefault": false,
          "name": "Buxoro viloyati"
        },
        {
          "key": 3581,
          "isCountryDefault": false,
          "name": "Farg'ona Viloyati"
        },
        {
          "key": 3573,
          "isCountryDefault": false,
          "name": "Jizzax viloyati"
        },
        {
          "key": 3583,
          "isCountryDefault": false,
          "name": "Namangan Viloyati"
        },
        {
          "key": 3574,
          "isCountryDefault": false,
          "name": "Navoiy viloyati"
        },
        {
          "key": 3585,
          "isCountryDefault": false,
          "name": "Qaraqalpaqstan Respublikasi"
        },
        {
          "key": 3575,
          "isCountryDefault": false,
          "name": "Qashqadaryo viloyati"
        },
        {
          "key": 3576,
          "isCountryDefault": false,
          "name": "Samarqand viloyati"
        },
        {
          "key": 3577,
          "isCountryDefault": false,
          "name": "Sirdaryo viloyati"
        },
        {
          "key": 3578,
          "isCountryDefault": false,
          "name": "Surxondaryo viloyati"
        },
        {
          "key": 3580,
          "isCountryDefault": false,
          "name": "Toshkent Shahri"
        },
        {
          "key": 3579,
          "isCountryDefault": false,
          "name": "Toshkent viloyati"
        },
        {
          "key": 3584,
          "isCountryDefault": false,
          "name": "Xorazm Viloyati"
        }
      ],
      "isEu": false
    },
    {
      "key": "vanuatu",
      "name": "Vanuatu",
      "code": "VU",
      "region": "Ozeanien",
      "states": [
        {
          "key": 231,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3586,
          "isCountryDefault": false,
          "name": "Malampa"
        },
        {
          "key": 3587,
          "isCountryDefault": false,
          "name": "Penama"
        },
        {
          "key": 3588,
          "isCountryDefault": false,
          "name": "Sanma"
        },
        {
          "key": 3589,
          "isCountryDefault": false,
          "name": "Shefa"
        },
        {
          "key": 3590,
          "isCountryDefault": false,
          "name": "Tafea"
        },
        {
          "key": 3591,
          "isCountryDefault": false,
          "name": "Torba"
        }
      ],
      "isEu": false
    },
    {
      "key": "vaticanCityState",
      "name": "Vatikanstadt",
      "code": "VA",
      "region": "Europa",
      "states": [
        {
          "key": 226,
          "isCountryDefault": true,
          "name": null
        }
      ],
      "isEu": false
    },
    {
      "key": "venezuela",
      "name": "Venezuela",
      "code": "VE",
      "region": "Südamerika",
      "states": [
        {
          "key": 72,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3592,
          "isCountryDefault": false,
          "name": "Amazonas"
        },
        {
          "key": 3593,
          "isCountryDefault": false,
          "name": "Anzoátegui"
        },
        {
          "key": 3594,
          "isCountryDefault": false,
          "name": "Apure"
        },
        {
          "key": 3595,
          "isCountryDefault": false,
          "name": "Aragua"
        },
        {
          "key": 3596,
          "isCountryDefault": false,
          "name": "Barinas"
        },
        {
          "key": 3597,
          "isCountryDefault": false,
          "name": "Bolívar"
        },
        {
          "key": 3598,
          "isCountryDefault": false,
          "name": "Carabobo"
        },
        {
          "key": 3599,
          "isCountryDefault": false,
          "name": "Cojedes"
        },
        {
          "key": 3600,
          "isCountryDefault": false,
          "name": "Delta Amacuro"
        },
        {
          "key": 3616,
          "isCountryDefault": false,
          "name": "Dependencias Federales de Venezuela"
        },
        {
          "key": 3615,
          "isCountryDefault": false,
          "name": "Distrito Capital"
        },
        {
          "key": 3601,
          "isCountryDefault": false,
          "name": "Falcón"
        },
        {
          "key": 3602,
          "isCountryDefault": false,
          "name": "Guárico"
        },
        {
          "key": 3603,
          "isCountryDefault": false,
          "name": "Lara"
        },
        {
          "key": 3604,
          "isCountryDefault": false,
          "name": "Mérida"
        },
        {
          "key": 3605,
          "isCountryDefault": false,
          "name": "Miranda"
        },
        {
          "key": 3606,
          "isCountryDefault": false,
          "name": "Monagas"
        },
        {
          "key": 3607,
          "isCountryDefault": false,
          "name": "Nueva Esparta"
        },
        {
          "key": 3608,
          "isCountryDefault": false,
          "name": "Portuguesa"
        },
        {
          "key": 3609,
          "isCountryDefault": false,
          "name": "Sucre"
        },
        {
          "key": 3610,
          "isCountryDefault": false,
          "name": "Táchira"
        },
        {
          "key": 3611,
          "isCountryDefault": false,
          "name": "Trujillo"
        },
        {
          "key": 3612,
          "isCountryDefault": false,
          "name": "Vargas"
        },
        {
          "key": 3613,
          "isCountryDefault": false,
          "name": "Yaracuy"
        },
        {
          "key": 3614,
          "isCountryDefault": false,
          "name": "Zulia"
        }
      ],
      "isEu": false
    },
    {
      "key": "unitedArabEmirates",
      "name": "Vereinigte Arabische Emirate",
      "code": "AE",
      "region": "Naher und Mittlerer Osten",
      "states": [
        {
          "key": 76,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3489,
          "isCountryDefault": false,
          "name": "Abu Dhabi"
        },
        {
          "key": 3486,
          "isCountryDefault": false,
          "name": "Ajman"
        },
        {
          "key": 3484,
          "isCountryDefault": false,
          "name": "Al Fujairah"
        },
        {
          "key": 3488,
          "isCountryDefault": false,
          "name": "Dubai"
        },
        {
          "key": 3483,
          "isCountryDefault": false,
          "name": "Ras al-Khaimah"
        },
        {
          "key": 3487,
          "isCountryDefault": false,
          "name": "Sharjah"
        },
        {
          "key": 3485,
          "isCountryDefault": false,
          "name": "Umm al-Quwain"
        }
      ],
      "isEu": false
    },
    {
      "key": "unitedStates",
      "name": "Vereinigte Staaten von Amerika",
      "code": "US",
      "region": "Nordamerika",
      "states": [
        {
          "key": 70,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3494,
          "isCountryDefault": false,
          "name": "Alabama"
        },
        {
          "key": 3495,
          "isCountryDefault": false,
          "name": "Alaska"
        },
        {
          "key": 3496,
          "isCountryDefault": false,
          "name": "Arizona"
        },
        {
          "key": 3497,
          "isCountryDefault": false,
          "name": "Arkansas"
        },
        {
          "key": 3498,
          "isCountryDefault": false,
          "name": "California"
        },
        {
          "key": 3499,
          "isCountryDefault": false,
          "name": "Colorado"
        },
        {
          "key": 3500,
          "isCountryDefault": false,
          "name": "Connecticut"
        },
        {
          "key": 3501,
          "isCountryDefault": false,
          "name": "Delaware"
        },
        {
          "key": 4086,
          "isCountryDefault": false,
          "name": "District of Columbia"
        },
        {
          "key": 3502,
          "isCountryDefault": false,
          "name": "Florida"
        },
        {
          "key": 3503,
          "isCountryDefault": false,
          "name": "Georgia"
        },
        {
          "key": 3504,
          "isCountryDefault": false,
          "name": "Hawaii"
        },
        {
          "key": 3505,
          "isCountryDefault": false,
          "name": "Idaho"
        },
        {
          "key": 3506,
          "isCountryDefault": false,
          "name": "Illinois"
        },
        {
          "key": 3507,
          "isCountryDefault": false,
          "name": "Indiana"
        },
        {
          "key": 3508,
          "isCountryDefault": false,
          "name": "Iowa"
        },
        {
          "key": 3509,
          "isCountryDefault": false,
          "name": "Kansas"
        },
        {
          "key": 3510,
          "isCountryDefault": false,
          "name": "Kentucky"
        },
        {
          "key": 3511,
          "isCountryDefault": false,
          "name": "Louisiana"
        },
        {
          "key": 3512,
          "isCountryDefault": false,
          "name": "Maine"
        },
        {
          "key": 3513,
          "isCountryDefault": false,
          "name": "Maryland"
        },
        {
          "key": 3514,
          "isCountryDefault": false,
          "name": "Massachusetts"
        },
        {
          "key": 3515,
          "isCountryDefault": false,
          "name": "Michigan"
        },
        {
          "key": 3516,
          "isCountryDefault": false,
          "name": "Minnesota"
        },
        {
          "key": 3517,
          "isCountryDefault": false,
          "name": "Mississippi"
        },
        {
          "key": 3518,
          "isCountryDefault": false,
          "name": "Missouri"
        },
        {
          "key": 3519,
          "isCountryDefault": false,
          "name": "Montana"
        },
        {
          "key": 3520,
          "isCountryDefault": false,
          "name": "Nebraska"
        },
        {
          "key": 3521,
          "isCountryDefault": false,
          "name": "Nevada"
        },
        {
          "key": 3522,
          "isCountryDefault": false,
          "name": "New Hampshire"
        },
        {
          "key": 3523,
          "isCountryDefault": false,
          "name": "New Jersey"
        },
        {
          "key": 3524,
          "isCountryDefault": false,
          "name": "New Mexico"
        },
        {
          "key": 3525,
          "isCountryDefault": false,
          "name": "New York"
        },
        {
          "key": 3526,
          "isCountryDefault": false,
          "name": "North Carolina"
        },
        {
          "key": 3527,
          "isCountryDefault": false,
          "name": "North Dakota"
        },
        {
          "key": 3528,
          "isCountryDefault": false,
          "name": "Ohio"
        },
        {
          "key": 3529,
          "isCountryDefault": false,
          "name": "Oklahoma"
        },
        {
          "key": 3530,
          "isCountryDefault": false,
          "name": "Oregon"
        },
        {
          "key": 3531,
          "isCountryDefault": false,
          "name": "Pennsylvania"
        },
        {
          "key": 3532,
          "isCountryDefault": false,
          "name": "Rhode Island"
        },
        {
          "key": 3533,
          "isCountryDefault": false,
          "name": "South Carolina"
        },
        {
          "key": 3534,
          "isCountryDefault": false,
          "name": "South Dakota"
        },
        {
          "key": 3535,
          "isCountryDefault": false,
          "name": "Tennessee"
        },
        {
          "key": 3536,
          "isCountryDefault": false,
          "name": "Texas"
        },
        {
          "key": 3537,
          "isCountryDefault": false,
          "name": "Utah"
        },
        {
          "key": 3538,
          "isCountryDefault": false,
          "name": "Vermont"
        },
        {
          "key": 3539,
          "isCountryDefault": false,
          "name": "Virginia"
        },
        {
          "key": 3540,
          "isCountryDefault": false,
          "name": "Washington (state)"
        },
        {
          "key": 3541,
          "isCountryDefault": false,
          "name": "West Virginia"
        },
        {
          "key": 3542,
          "isCountryDefault": false,
          "name": "Wisconsin"
        },
        {
          "key": 3543,
          "isCountryDefault": false,
          "name": "Wyoming"
        }
      ],
      "isEu": false
    },
    {
      "key": "vietnam",
      "name": "Vietnam",
      "code": "VN",
      "region": "Asien",
      "states": [
        {
          "key": 230,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3990,
          "isCountryDefault": false,
          "name": "An Giang province"
        },
        {
          "key": 3982,
          "isCountryDefault": false,
          "name": "Ba Ria–Vung Tau province"
        },
        {
          "key": 3948,
          "isCountryDefault": false,
          "name": "Bac Giang province"
        },
        {
          "key": 3623,
          "isCountryDefault": false,
          "name": "Bac Kan province"
        },
        {
          "key": 3995,
          "isCountryDefault": false,
          "name": "Bac Lieu province"
        },
        {
          "key": 3951,
          "isCountryDefault": false,
          "name": "Bac Ninh province"
        },
        {
          "key": 3986,
          "isCountryDefault": false,
          "name": "Ben Tre province"
        },
        {
          "key": 3968,
          "isCountryDefault": false,
          "name": "Binh Dinh province"
        },
        {
          "key": 3980,
          "isCountryDefault": false,
          "name": "Binh Duong province"
        },
        {
          "key": 3978,
          "isCountryDefault": false,
          "name": "Binh Phuoc province"
        },
        {
          "key": 3972,
          "isCountryDefault": false,
          "name": "Binh Thuan province"
        },
        {
          "key": 3996,
          "isCountryDefault": false,
          "name": "Ca Mau province"
        },
        {
          "key": 3992,
          "isCountryDefault": false,
          "name": "Cần Thơ (city)"
        },
        {
          "key": 3619,
          "isCountryDefault": false,
          "name": "Cao Bang province"
        },
        {
          "key": 3965,
          "isCountryDefault": false,
          "name": "Đà Nẵng (city)"
        },
        {
          "key": 3975,
          "isCountryDefault": false,
          "name": "Dak Lak province"
        },
        {
          "key": 3976,
          "isCountryDefault": false,
          "name": "Dak Nong province"
        },
        {
          "key": 3617,
          "isCountryDefault": false,
          "name": "Dien Bien province"
        },
        {
          "key": 3981,
          "isCountryDefault": false,
          "name": "Dong Nai province"
        },
        {
          "key": 3989,
          "isCountryDefault": false,
          "name": "Dong Thap province"
        },
        {
          "key": 3974,
          "isCountryDefault": false,
          "name": "Gia Lai province"
        },
        {
          "key": 3618,
          "isCountryDefault": false,
          "name": "Ha Giang province"
        },
        {
          "key": 3956,
          "isCountryDefault": false,
          "name": "Ha Nam province"
        },
        {
          "key": 3620,
          "isCountryDefault": false,
          "name": "Hà Nội (city)"
        },
        {
          "key": 3961,
          "isCountryDefault": false,
          "name": "Ha Tinh province"
        },
        {
          "key": 3952,
          "isCountryDefault": false,
          "name": "Hai Duong province"
        },
        {
          "key": 3953,
          "isCountryDefault": false,
          "name": "Hải Phòng (city)"
        },
        {
          "key": 3993,
          "isCountryDefault": false,
          "name": "Hau Giang province"
        },
        {
          "key": 3944,
          "isCountryDefault": false,
          "name": "Hoa Binh province"
        },
        {
          "key": 3954,
          "isCountryDefault": false,
          "name": "Hung Yen province"
        },
        {
          "key": 3970,
          "isCountryDefault": false,
          "name": "Khanh Hoa province"
        },
        {
          "key": 3991,
          "isCountryDefault": false,
          "name": "Kien Giang province"
        },
        {
          "key": 3973,
          "isCountryDefault": false,
          "name": "Kon Tum province"
        },
        {
          "key": 3622,
          "isCountryDefault": false,
          "name": "Lai Chau province"
        },
        {
          "key": 3977,
          "isCountryDefault": false,
          "name": "Lam Dong province"
        },
        {
          "key": 3946,
          "isCountryDefault": false,
          "name": "Lang Son province"
        },
        {
          "key": 3621,
          "isCountryDefault": false,
          "name": "Lao Cai province"
        },
        {
          "key": 3984,
          "isCountryDefault": false,
          "name": "Long An province"
        },
        {
          "key": 3957,
          "isCountryDefault": false,
          "name": "Nam Dinh province"
        },
        {
          "key": 3960,
          "isCountryDefault": false,
          "name": "Nghe An province"
        },
        {
          "key": 3958,
          "isCountryDefault": false,
          "name": "Ninh Binh province"
        },
        {
          "key": 3971,
          "isCountryDefault": false,
          "name": "Ninh Thuan province"
        },
        {
          "key": 3949,
          "isCountryDefault": false,
          "name": "Phu Tho province"
        },
        {
          "key": 3969,
          "isCountryDefault": false,
          "name": "Phu Yen province"
        },
        {
          "key": 3962,
          "isCountryDefault": false,
          "name": "Quang Binh province"
        },
        {
          "key": 3966,
          "isCountryDefault": false,
          "name": "Quang Nam province"
        },
        {
          "key": 3967,
          "isCountryDefault": false,
          "name": "Quang Ngai province"
        },
        {
          "key": 3947,
          "isCountryDefault": false,
          "name": "Quang Ninh province"
        },
        {
          "key": 3963,
          "isCountryDefault": false,
          "name": "Quang Tri province"
        },
        {
          "key": 3994,
          "isCountryDefault": false,
          "name": "Soc Trang province"
        },
        {
          "key": 3942,
          "isCountryDefault": false,
          "name": "Son La province"
        },
        {
          "key": 3979,
          "isCountryDefault": false,
          "name": "Tay Ninh province"
        },
        {
          "key": 3955,
          "isCountryDefault": false,
          "name": "Thai Binh province"
        },
        {
          "key": 3945,
          "isCountryDefault": false,
          "name": "Thai Nguyen province"
        },
        {
          "key": 3959,
          "isCountryDefault": false,
          "name": "Thanh Hoa province"
        },
        {
          "key": 3983,
          "isCountryDefault": false,
          "name": "Thành phố Hồ Chí Minh (city)"
        },
        {
          "key": 3964,
          "isCountryDefault": false,
          "name": "Thua Thien-Hue province"
        },
        {
          "key": 3985,
          "isCountryDefault": false,
          "name": "Tien Giang province"
        },
        {
          "key": 3987,
          "isCountryDefault": false,
          "name": "Tra Vinh province"
        },
        {
          "key": 3624,
          "isCountryDefault": false,
          "name": "Tuyen Quang province"
        },
        {
          "key": 3988,
          "isCountryDefault": false,
          "name": "Vinh Long province"
        },
        {
          "key": 3950,
          "isCountryDefault": false,
          "name": "Vinh Phuc province"
        },
        {
          "key": 3943,
          "isCountryDefault": false,
          "name": "Yen Bai province"
        }
      ],
      "isEu": false
    },
    {
      "key": "wales",
      "name": "Wales",
      "code": "GB",
      "region": "Europa",
      "states": [
        {
          "key": 241,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3628,
          "isCountryDefault": false,
          "name": "Aberconwy"
        },
        {
          "key": 3630,
          "isCountryDefault": false,
          "name": "Alyn and Deeside"
        },
        {
          "key": 3631,
          "isCountryDefault": false,
          "name": "Anglesey - Ynys Môn"
        },
        {
          "key": 3632,
          "isCountryDefault": false,
          "name": "Arfon"
        },
        {
          "key": 3633,
          "isCountryDefault": false,
          "name": "Blaenau Gwent"
        },
        {
          "key": 3634,
          "isCountryDefault": false,
          "name": "Brecknock"
        },
        {
          "key": 3635,
          "isCountryDefault": false,
          "name": "Cardiff"
        },
        {
          "key": 3636,
          "isCountryDefault": false,
          "name": "Carmarthen"
        },
        {
          "key": 3637,
          "isCountryDefault": false,
          "name": "Ceredigion"
        },
        {
          "key": 3638,
          "isCountryDefault": false,
          "name": "Colwyn"
        },
        {
          "key": 3639,
          "isCountryDefault": false,
          "name": "Cynon Valley"
        },
        {
          "key": 3640,
          "isCountryDefault": false,
          "name": "Delyn"
        },
        {
          "key": 3641,
          "isCountryDefault": false,
          "name": "Dinefwr"
        },
        {
          "key": 3642,
          "isCountryDefault": false,
          "name": "Dwyfor"
        },
        {
          "key": 3646,
          "isCountryDefault": false,
          "name": "Glyndŵr"
        },
        {
          "key": 3643,
          "isCountryDefault": false,
          "name": "Islwyn"
        },
        {
          "key": 3644,
          "isCountryDefault": false,
          "name": "Llanelli"
        },
        {
          "key": 3645,
          "isCountryDefault": false,
          "name": "Lliw Valley"
        },
        {
          "key": 3647,
          "isCountryDefault": false,
          "name": "Meirionnydd"
        },
        {
          "key": 3648,
          "isCountryDefault": false,
          "name": "Merthyr Tydfil"
        },
        {
          "key": 3649,
          "isCountryDefault": false,
          "name": "Monmouth"
        },
        {
          "key": 3650,
          "isCountryDefault": false,
          "name": "Montgomeryshire"
        },
        {
          "key": 3651,
          "isCountryDefault": false,
          "name": "Neath"
        },
        {
          "key": 3652,
          "isCountryDefault": false,
          "name": "Newport"
        },
        {
          "key": 3653,
          "isCountryDefault": false,
          "name": "Ogwr"
        },
        {
          "key": 3629,
          "isCountryDefault": false,
          "name": "Port Talbot"
        },
        {
          "key": 3654,
          "isCountryDefault": false,
          "name": "Preseli Pembrokeshire"
        },
        {
          "key": 3655,
          "isCountryDefault": false,
          "name": "Radnorshire"
        },
        {
          "key": 3656,
          "isCountryDefault": false,
          "name": "Rhondda"
        },
        {
          "key": 3657,
          "isCountryDefault": false,
          "name": "Rhuddlan"
        },
        {
          "key": 3658,
          "isCountryDefault": false,
          "name": "Rhymney Valley"
        },
        {
          "key": 3659,
          "isCountryDefault": false,
          "name": "South Pembrokeshire"
        },
        {
          "key": 3660,
          "isCountryDefault": false,
          "name": "Swansea"
        },
        {
          "key": 3661,
          "isCountryDefault": false,
          "name": "Taff-Ely"
        },
        {
          "key": 3662,
          "isCountryDefault": false,
          "name": "Torfaen"
        },
        {
          "key": 3663,
          "isCountryDefault": false,
          "name": "Vale of Glamorgan"
        },
        {
          "key": 3664,
          "isCountryDefault": false,
          "name": "Wrexham Maelor"
        }
      ],
      "isEu": false
    },
    {
      "key": "wallisandFutuna",
      "name": "Wallis und Futuna",
      "code": "WF",
      "region": "Ozeanien",
      "states": [
        {
          "key": 232,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3667,
          "isCountryDefault": false,
          "name": "Alofi"
        },
        {
          "key": 3666,
          "isCountryDefault": false,
          "name": "Futuna"
        },
        {
          "key": 3665,
          "isCountryDefault": false,
          "name": "Wallis Island"
        }
      ],
      "isEu": false
    },
    {
      "key": "christmasIsland",
      "name": "Weihnachtsinsel",
      "code": "CX",
      "region": "Asien",
      "states": [
        {
          "key": 132,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 951,
          "isCountryDefault": false,
          "name": "Drumsite"
        },
        {
          "key": 948,
          "isCountryDefault": false,
          "name": "Flying Fish Cove"
        },
        {
          "key": 950,
          "isCountryDefault": false,
          "name": "Poon Saan"
        },
        {
          "key": 949,
          "isCountryDefault": false,
          "name": "Silver City"
        }
      ],
      "isEu": false
    },
    {
      "key": "westernSahara",
      "name": "Westsahara",
      "code": "EH",
      "region": "Afrika",
      "states": [
        {
          "key": 137,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 3668,
          "isCountryDefault": false,
          "name": "Guelmim-Es Semara"
        },
        {
          "key": 3669,
          "isCountryDefault": false,
          "name": "Laâyoune-Boujdour-Sakia El Hamra"
        },
        {
          "key": 3670,
          "isCountryDefault": false,
          "name": "Oued Ed-Dahab-Lagouira"
        }
      ],
      "isEu": false
    },
    {
      "key": "centralAfricanRepublic",
      "name": "Zentralafrikanische Republik",
      "code": "CF",
      "region": "Afrika",
      "states": [
        {
          "key": 125,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 817,
          "isCountryDefault": false,
          "name": "Bamingui-Bangoran"
        },
        {
          "key": 833,
          "isCountryDefault": false,
          "name": "Bangui"
        },
        {
          "key": 818,
          "isCountryDefault": false,
          "name": "Basse-Kotto"
        },
        {
          "key": 819,
          "isCountryDefault": false,
          "name": "Haute-Kotto"
        },
        {
          "key": 820,
          "isCountryDefault": false,
          "name": "Haut-Mbomou"
        },
        {
          "key": 821,
          "isCountryDefault": false,
          "name": "Kémo"
        },
        {
          "key": 822,
          "isCountryDefault": false,
          "name": "Lobaye"
        },
        {
          "key": 823,
          "isCountryDefault": false,
          "name": "Mambéré-Kadéï"
        },
        {
          "key": 824,
          "isCountryDefault": false,
          "name": "Mbomou"
        },
        {
          "key": 831,
          "isCountryDefault": false,
          "name": "Nana-Grébizi"
        },
        {
          "key": 825,
          "isCountryDefault": false,
          "name": "Nana-Mambéré"
        },
        {
          "key": 826,
          "isCountryDefault": false,
          "name": "Ombella-M'Poko"
        },
        {
          "key": 827,
          "isCountryDefault": false,
          "name": "Ouaka"
        },
        {
          "key": 828,
          "isCountryDefault": false,
          "name": "Ouham"
        },
        {
          "key": 829,
          "isCountryDefault": false,
          "name": "Ouham-Pendé"
        },
        {
          "key": 832,
          "isCountryDefault": false,
          "name": "Sangha-Mbaéré"
        },
        {
          "key": 830,
          "isCountryDefault": false,
          "name": "Vakaga"
        }
      ],
      "isEu": false
    },
    {
      "key": "cyprus",
      "name": "Zypern",
      "code": "CY",
      "region": "Naher und Mittlerer Osten",
      "states": [
        {
          "key": 77,
          "isCountryDefault": true,
          "name": null
        },
        {
          "key": 1073,
          "isCountryDefault": false,
          "name": "Famagusta"
        },
        {
          "key": 1074,
          "isCountryDefault": false,
          "name": "Kyrenia"
        },
        {
          "key": 1075,
          "isCountryDefault": false,
          "name": "Larnaca"
        },
        {
          "key": 1076,
          "isCountryDefault": false,
          "name": "Lemesos"
        },
        {
          "key": 1077,
          "isCountryDefault": false,
          "name": "Nicosia"
        },
        {
          "key": 1078,
          "isCountryDefault": false,
          "name": "Paphos"
        }
      ],
      "isEu": true
    }
  ],
  "currencies": [
    {
      "value": "aed",
      "text": "AED",
      "name": "United Arab Emirates Dirham"
    },
    {
      "value": "ars",
      "text": "ARS",
      "name": "Argentine Peso"
    },
    {
      "value": "aud",
      "text": "AUD",
      "name": "Australian Dollar"
    },
    {
      "value": "bgn",
      "text": "BGN",
      "name": "Bulgarian Lev"
    },
    {
      "value": "brl",
      "text": "BRL",
      "name": "Brazil Real"
    },
    {
      "value": "cad",
      "text": "CAD",
      "name": "Canadian Dollar"
    },
    {
      "value": "chf",
      "text": "CHF",
      "name": "Swiss Franc"
    },
    {
      "value": "cny",
      "text": "CNY",
      "name": "Chinese yuan"
    },
    {
      "value": "czk",
      "text": "CZK",
      "name": "Czech Koruna"
    },
    {
      "value": "dkk",
      "text": "DKK",
      "name": "Danish Krone"
    },
    {
      "value": "eur",
      "text": "EUR",
      "name": "Euro"
    },
    {
      "value": "gbp",
      "text": "GBP",
      "name": "British Pound"
    },
    {
      "value": "hkd",
      "text": "HKD",
      "name": "Hong Kong Dollar"
    },
    {
      "value": "huf",
      "text": "HUF",
      "name": "Hungarian Forint"
    },
    {
      "value": "ils",
      "text": "ILS",
      "name": "Israeli Shekel"
    },
    {
      "value": "inr",
      "text": "INR",
      "name": "Indian Rupee"
    },
    {
      "value": "isk",
      "text": "ISK",
      "name": "Icelandic Króna"
    },
    {
      "value": "jpy",
      "text": "JPY",
      "name": "Japanese Yen"
    },
    {
      "value": "ngn",
      "text": "NGN",
      "name": "Nigerian Naira"
    },
    {
      "value": "nok",
      "text": "NOK",
      "name": "Norwegian Kroner"
    },
    {
      "value": "nzd",
      "text": "NZD",
      "name": "New Zealand Dollar"
    },
    {
      "value": "pln",
      "text": "PLN",
      "name": "Polish złoty"
    },
    {
      "value": "ron",
      "text": "RON",
      "name": "Romanian Leu"
    },
    {
      "value": "rub",
      "text": "RUB",
      "name": "Russian Ruble"
    },
    {
      "value": "sek",
      "text": "SEK",
      "name": "Swedish Krona"
    },
    {
      "value": "thb",
      "text": "THB",
      "name": "Thai baht"
    },
    {
      "value": "try",
      "text": "TRY",
      "name": "Turkish Lira"
    },
    {
      "value": "usd",
      "text": "USD",
      "name": "United States Dollar"
    },
    {
      "value": "zar",
      "text": "ZAR",
      "name": "South African Rand"
    }
  ],
  "dayNames": [
    "Sonntag",
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag"
  ],
  "formElementTypes": [
    {
      "value": "date",
      "text": "Datum"
    },
    {
      "value": "dateTime",
      "text": "Datum + Zeit"
    },
    {
      "value": "dropdown",
      "text": "Dropdown-Formular"
    },
    {
      "value": "email",
      "text": "E-Mail"
    },
    {
      "value": "booleanNullable",
      "text": "Ja, nein oder keine Auswahl."
    },
    {
      "value": "boolean",
      "text": "Kontrollkästchen"
    },
    {
      "value": "countryState",
      "text": "Land - Bundesland"
    },
    {
      "value": "decimal",
      "text": "Nummer mit Dezimalstellen"
    },
    {
      "value": "integer",
      "text": "Nummer ohne Dezimalstellen"
    },
    {
      "value": "integerNullable",
      "text": "Nummer ohne Dezimalstellen"
    },
    {
      "value": "textReadOnly",
      "text": "Nur Text anzeigen"
    },
    {
      "value": "percentage",
      "text": "Prozentsatz"
    },
    {
      "value": "textSingleLine",
      "text": "Text auf 1 Zeile"
    },
    {
      "value": "textMultiLine",
      "text": "Text in mehreren Zeilen"
    },
    {
      "value": "url",
      "text": "Website"
    },
    {
      "value": "time",
      "text": "Zeit"
    }
  ],
  "financialRiskLevels": [
    {
      "value": "unknown",
      "text": "Unbekannt",
      "hexColor": "#FFFFFF"
    },
    {
      "value": "fullTrust",
      "text": "Volles Vertrauen",
      "hexColor": "#23d2a0"
    },
    {
      "value": "lowRisk",
      "text": "Geringes Risiko",
      "hexColor": "#ffcc02"
    },
    {
      "value": "highRisk",
      "text": "Hohes Risiko",
      "hexColor": "#ff005c"
    },
    {
      "value": "blocklist",
      "text": "Sperrliste",
      "hexColor": "#000000"
    }
  ],
  "flightTravelClasses": [
    {
      "value": "unknown",
      "text": "Unbekannt"
    },
    {
      "value": "economy",
      "text": "Economy"
    },
    {
      "value": "premiumEconomy",
      "text": "Premium Economy"
    },
    {
      "value": "businessClass",
      "text": "Business Class"
    },
    {
      "value": "firstClass",
      "text": "First Class"
    }
  ],
  "interfaceCultures": [
    {
      "lcid": "dutch_NL",
      "text": "Niederländisch",
      "locale": "nl-NL",
      "language": "dutch"
    },
    {
      "lcid": "english_GB",
      "text": "Englisch (GB)",
      "locale": "en-GB",
      "language": "english"
    },
    {
      "lcid": "english_US",
      "text": "Englisch (US)",
      "locale": "en-US",
      "language": "english"
    },
    {
      "lcid": "german_DE",
      "text": "Deutsch",
      "locale": "de-DE",
      "language": "german"
    },
    {
      "lcid": "italian",
      "text": "Italienisch",
      "locale": "it-IT",
      "language": "italian"
    },
    {
      "lcid": "spanish",
      "text": "Spanisch",
      "locale": "es-ES",
      "language": "spanish"
    },
    {
      "lcid": "portuguese_BR",
      "text": "Portugiesisch (BR)",
      "locale": "pt-BR",
      "language": "portuguese"
    },
    {
      "lcid": "portuguese_PT",
      "text": "Portugiesisch (PT)",
      "locale": "pt-PT",
      "language": "portuguese"
    }
  ],
  "templateCultures": [
    {
      "lcid": "catalan",
      "text": "Catalan (Catalan)",
      "locale": "ca-ES",
      "language": "catalan"
    },
    {
      "lcid": "danish",
      "text": "Danish (Denmark)",
      "locale": "da-DK",
      "language": "danish"
    },
    {
      "lcid": "dutch_BE",
      "text": "Dutch (Belgium)",
      "locale": "nl-BE",
      "language": "dutch"
    },
    {
      "lcid": "dutch_NL",
      "text": "Dutch (Netherlands)",
      "locale": "nl-NL",
      "language": "dutch"
    },
    {
      "lcid": "english_AU",
      "text": "English (Australia)",
      "locale": "en-AU",
      "language": "english"
    },
    {
      "lcid": "english_GB",
      "text": "English (United Kingdom)",
      "locale": "en-GB",
      "language": "english"
    },
    {
      "lcid": "english_US",
      "text": "English (United States)",
      "locale": "en-US",
      "language": "english"
    },
    {
      "lcid": "finnish",
      "text": "Finnish (Finland)",
      "locale": "fi-FI",
      "language": "finnish"
    },
    {
      "lcid": "french",
      "text": "French (France)",
      "locale": "fr-FR",
      "language": "french"
    },
    {
      "lcid": "german_DE",
      "text": "German (Germany)",
      "locale": "de-DE",
      "language": "german"
    },
    {
      "lcid": "german_CH",
      "text": "German (Switzerland)",
      "locale": "de-CH",
      "language": "german"
    },
    {
      "lcid": "greek",
      "text": "Greek (Greece)",
      "locale": "el-GR",
      "language": "greek"
    },
    {
      "lcid": "hebrew",
      "text": "Hebrew (Israel)",
      "locale": "he-IL",
      "language": "hebrew"
    },
    {
      "lcid": "italian",
      "text": "Italian (Italy)",
      "locale": "it-IT",
      "language": "italian"
    },
    {
      "lcid": "latvian",
      "text": "Latvian (Latvia)",
      "locale": "lv-LV",
      "language": "latvian"
    },
    {
      "lcid": "lithuanian",
      "text": "Lithuanian (Lithuania)",
      "locale": "lt-LT",
      "language": "lithuanian"
    },
    {
      "lcid": "norwegian_NB",
      "text": "Norwegian, Bokmål (Norway)",
      "locale": "nb-NO",
      "language": "norwegian"
    },
    {
      "lcid": "norwegian_NN",
      "text": "Norwegian, Nynorsk (Norway)",
      "locale": "nn-NO",
      "language": "norwegian"
    },
    {
      "lcid": "polish",
      "text": "Polish (Poland)",
      "locale": "pl-PL",
      "language": "polish"
    },
    {
      "lcid": "portuguese_BR",
      "text": "Portuguese (Brazil)",
      "locale": "pt-BR",
      "language": "portuguese"
    },
    {
      "lcid": "portuguese_PT",
      "text": "Portuguese (Portugal)",
      "locale": "pt-PT",
      "language": "portuguese"
    },
    {
      "lcid": "slovak",
      "text": "Slovak (Slovakia)",
      "locale": "sk-SK",
      "language": "slovak"
    },
    {
      "lcid": "slovenian",
      "text": "Slovenian (Slovenia)",
      "locale": "sl-SI",
      "language": "slovenian"
    },
    {
      "lcid": "spanish",
      "text": "Spanish (Spain)",
      "locale": "es-ES",
      "language": "spanish"
    },
    {
      "lcid": "swedish",
      "text": "Swedish (Sweden)",
      "locale": "sv-SE",
      "language": "swedish"
    },
    {
      "lcid": "turkish",
      "text": "Turkish (Turkey)",
      "locale": "tr-TR",
      "language": "turkish"
    }
  ],
  "languages": [
    {
      "value": "danish",
      "nativeName": "Dansk",
      "localizedName": "Dänisch",
      "twoLetterTag": "da"
    },
    {
      "value": "german",
      "nativeName": "Deutsch",
      "localizedName": "Deutsch",
      "twoLetterTag": "de"
    },
    {
      "value": "english",
      "nativeName": "English",
      "localizedName": "Englisch",
      "twoLetterTag": "en"
    },
    {
      "value": "finnish",
      "nativeName": "Suomi",
      "localizedName": "Finnisch",
      "twoLetterTag": "fi"
    },
    {
      "value": "french",
      "nativeName": "Français",
      "localizedName": "Französisch",
      "twoLetterTag": "fr"
    },
    {
      "value": "greek",
      "nativeName": "ελληνικά",
      "localizedName": "Griechisch",
      "twoLetterTag": "el"
    },
    {
      "value": "hebrew",
      "nativeName": "עברית",
      "localizedName": "Hebräisch",
      "twoLetterTag": "he"
    },
    {
      "value": "italian",
      "nativeName": "italiano",
      "localizedName": "Italienisch",
      "twoLetterTag": "it"
    },
    {
      "value": "catalan",
      "nativeName": "Català",
      "localizedName": "Katalanisch",
      "twoLetterTag": "ca"
    },
    {
      "value": "latvian",
      "nativeName": "Latviešu",
      "localizedName": "Lettisch",
      "twoLetterTag": "lv"
    },
    {
      "value": "lithuanian",
      "nativeName": "Lietuvių",
      "localizedName": "Litauisch",
      "twoLetterTag": "lt"
    },
    {
      "value": "dutch",
      "nativeName": "Nederlands",
      "localizedName": "Niederländisch",
      "twoLetterTag": "nl"
    },
    {
      "value": "norwegian",
      "nativeName": "Norsk",
      "localizedName": "Norwegisch",
      "twoLetterTag": "nb"
    },
    {
      "value": "polish",
      "nativeName": "Polski",
      "localizedName": "Polnisch",
      "twoLetterTag": "pl"
    },
    {
      "value": "portuguese",
      "nativeName": "Português",
      "localizedName": "Portugiesisch",
      "twoLetterTag": "pt"
    },
    {
      "value": "swedish",
      "nativeName": "Svenska",
      "localizedName": "Schwedisch",
      "twoLetterTag": "se"
    },
    {
      "value": "slovak",
      "nativeName": "Slovenčina",
      "localizedName": "Slowakisch",
      "twoLetterTag": "sk"
    },
    {
      "value": "slovenian",
      "nativeName": "Slovenščina",
      "localizedName": "Slowenisch",
      "twoLetterTag": "sl"
    },
    {
      "value": "spanish",
      "nativeName": "Español",
      "localizedName": "Spanisch",
      "twoLetterTag": "es"
    },
    {
      "value": "turkish",
      "nativeName": "Türkçe",
      "localizedName": "Türkisch",
      "twoLetterTag": "tr"
    }
  ],
  "lodgingTypes": [
    {
      "value": "unknown",
      "text": "Unbekannt"
    },
    {
      "value": "hotel",
      "text": "Hotel"
    },
    {
      "value": "apartment",
      "text": "Apartment"
    },
    {
      "value": "other",
      "text": "Sonstige  "
    }
  ],
  "monthNames": [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember"
  ],
  "paymentMethods": [
    {
      "value": "writeOff",
      "text": "Abschreibung"
    },
    {
      "value": "balanceToCredit",
      "text": "Ausgleichen mit Gutschrift"
    },
    {
      "value": "balanceToInvoice",
      "text": "Ausgleichen mit Rechnung"
    },
    {
      "value": "cashiersCheck",
      "text": "Bankscheck"
    },
    {
      "value": "bankTransfer",
      "text": "Banküberweisung"
    },
    {
      "value": "cash",
      "text": "Barzahlung"
    },
    {
      "value": "directDeposit",
      "text": "Direkt Einzahlung"
    },
    {
      "value": "companyCheck",
      "text": "Firmenscheck"
    },
    {
      "value": "internal",
      "text": "Intern"
    },
    {
      "value": "balanceForPaymentSurplusDeficit",
      "text": "Kontostand für Zahlungsüberschuss/-defizit"
    },
    {
      "value": "payPal",
      "text": "PayPal"
    },
    {
      "value": "stripe",
      "text": "Stripe"
    },
    {
      "value": "wise",
      "text": "Wise"
    },
    {
      "value": "moneyOrder",
      "text": "Zahlungsanweisung"
    }
  ],
  "paymentTermOptions": [
    {
      "value": "beforeAnnouncement",
      "text": "Vor Bekanntgabe"
    },
    {
      "value": "month6Before",
      "text": "6 Monate vorher"
    },
    {
      "value": "month5Before",
      "text": "5 Monate vorher"
    },
    {
      "value": "month4Before",
      "text": "4 Monate vorher"
    },
    {
      "value": "month3Before",
      "text": "3 Monate vorher"
    },
    {
      "value": "month2Before",
      "text": "2 Monate vorher"
    },
    {
      "value": "month1Before",
      "text": "1 Monat vorher"
    },
    {
      "value": "week3Before",
      "text": "3 Wochen vorher"
    },
    {
      "value": "week2Before",
      "text": "2 Wochen vorher"
    },
    {
      "value": "week1Before",
      "text": "1 Woche vorher"
    },
    {
      "value": "sameDay",
      "text": "Am Showtag"
    },
    {
      "value": "firstWorkdayAfter",
      "text": "Erster Werktag nach Show"
    },
    {
      "value": "specificDate",
      "text": "Spezielles Datum"
    }
  ],
  "pickupLocationTypes": [
    {
      "value": "hotel",
      "text": "Hotel"
    },
    {
      "value": "venue",
      "text": "Veranstaltungsort"
    },
    {
      "value": "restaurant",
      "text": "Restaurant"
    },
    {
      "value": "airport",
      "text": "Flughafen"
    },
    {
      "value": "trainStation",
      "text": "Bahnhof"
    },
    {
      "value": "boatTerminal",
      "text": "Fährterminal"
    },
    {
      "value": "home",
      "text": "Zuhause"
    },
    {
      "value": "other",
      "text": "Sonstiges"
    }
  ],
  "productTypes": [
    {
      "value": "undefined",
      "text": "Nicht spezifiziert",
      "hexColor": "#c0392b"
    },
    {
      "value": "wht",
      "text": "Abgeltungssteuer (WHT)",
      "hexColor": "#2980b9"
    },
    {
      "value": "bankFee",
      "text": "Bank charge",
      "hexColor": "#3498db"
    },
    {
      "value": "custom1",
      "text": "Benutzerdefiniert 1",
      "hexColor": "#0e6655"
    },
    {
      "value": "custom2",
      "text": "Benutzerdefiniert 2",
      "hexColor": "#196f3d"
    },
    {
      "value": "custom3",
      "text": "Benutzerdefiniert 3",
      "hexColor": "#1d8348"
    },
    {
      "value": "bookingFee",
      "text": "Buchungsgebühr",
      "hexColor": "#9b59b6"
    },
    {
      "value": "buyoutCatering",
      "text": "Buyout Catering",
      "hexColor": "#7b241c"
    },
    {
      "value": "buyoutTravel",
      "text": "Buyout Flüge & sonstige Transportleistungen",
      "hexColor": "#943126"
    },
    {
      "value": "buyoutOther",
      "text": "Buyout Sonstiges",
      "hexColor": "#5b2c6f"
    },
    {
      "value": "buyoutTechnical",
      "text": "Buyout Technik",
      "hexColor": "#633974"
    },
    {
      "value": "buyoutAccommodation",
      "text": "Buyout Unterkunft",
      "hexColor": "#d35400"
    },
    {
      "value": "services",
      "text": "Dienstleistungen",
      "hexColor": "#e67e22"
    },
    {
      "value": "travel",
      "text": "Flüge & andere Transporte",
      "hexColor": "#8e44ad"
    },
    {
      "value": "catering",
      "text": "Gastronomie",
      "hexColor": "#f1c40f"
    },
    {
      "value": "ticketing",
      "text": "Kartenverkauf",
      "hexColor": "#117864"
    },
    {
      "value": "artistFee",
      "text": "Künstlergage",
      "hexColor": "#e74c3c"
    },
    {
      "value": "managementFee",
      "text": "Managementgebühr",
      "hexColor": "#f39c12"
    },
    {
      "value": "marketing",
      "text": "Marketing",
      "hexColor": "#1a5276"
    },
    {
      "value": "production",
      "text": "Produktion",
      "hexColor": "#2ecc71"
    },
    {
      "value": "accommodation",
      "text": "Unterkunft",
      "hexColor": "#16a085"
    },
    {
      "value": "copyright",
      "text": "Urheberrecht",
      "hexColor": "#27ae60"
    },
    {
      "value": "insurance",
      "text": "Versicherung",
      "hexColor": "#21618c"
    },
    {
      "value": "visa",
      "text": "Visum",
      "hexColor": "#1abc9c"
    }
  ],
  "regions": [
    {
      "value": "africa",
      "text": "Afrika"
    },
    {
      "value": "asia",
      "text": "Asien"
    },
    {
      "value": "europe",
      "text": "Europa"
    },
    {
      "value": "centralAmerica",
      "text": "Mittelamerika"
    },
    {
      "value": "middleEast",
      "text": "Naher und Mittlerer Osten"
    },
    {
      "value": "northAmerica",
      "text": "Nordamerika"
    },
    {
      "value": "oceania",
      "text": "Ozeanien"
    },
    {
      "value": "southAmerica",
      "text": "Südamerika"
    }
  ],
  "roomTypes": [
    {
      "value": "unknown",
      "text": "Unbekannt"
    },
    {
      "value": "single",
      "text": "Einzelzimmer"
    },
    {
      "value": "double",
      "text": "Doppelzimmer"
    },
    {
      "value": "twin",
      "text": "Twin-Zimmer"
    },
    {
      "value": "suite",
      "text": "Suite"
    },
    {
      "value": "juniorSuite",
      "text": "Junior Suite"
    }
  ],
  "routes": [
    {
      "key": "artistRider_New",
      "origin": "legacyPage",
      "relative": "/artistrider/new"
    },
    {
      "key": "artistRider_Edit",
      "origin": "legacyPage",
      "relative": "/artistrider/edit/{key}"
    },
    {
      "key": "company_New",
      "origin": "legacyPage",
      "relative": "/company/new"
    },
    {
      "key": "company_NewModal",
      "origin": "legacyPage",
      "relative": "/company/new/modal"
    },
    {
      "key": "company_Edit",
      "origin": "legacyPage",
      "relative": "/company/{key}/edit"
    },
    {
      "key": "company_EditModal",
      "origin": "legacyPage",
      "relative": "/company/{key}/edit/modal"
    },
    {
      "key": "company_Merge",
      "origin": "legacyPage",
      "relative": "/company/{key}/merge"
    },
    {
      "key": "company_ChooseMergeTarget",
      "origin": "legacyPage",
      "relative": "/company/{key}/choosetarget"
    },
    {
      "key": "company_VATNumber",
      "origin": "legacyPage",
      "relative": "/company/vatnumber"
    },
    {
      "key": "mergefield_Company_List",
      "origin": "legacyPage",
      "relative": "/company/mergefield"
    },
    {
      "key": "contact_New",
      "origin": "legacyPage",
      "relative": "/contact/new"
    },
    {
      "key": "contact_NewModal",
      "origin": "legacyPage",
      "relative": "/contact/new/modal"
    },
    {
      "key": "contact_Edit",
      "origin": "legacyPage",
      "relative": "/contact/{key}/edit"
    },
    {
      "key": "contact_EditModal",
      "origin": "legacyPage",
      "relative": "/contact/{key}/edit/modal"
    },
    {
      "key": "contact_Merge",
      "origin": "legacyPage",
      "relative": "/contact/{key}/merge"
    },
    {
      "key": "contact_ChooseMergeTarget",
      "origin": "legacyPage",
      "relative": "/contact/{key}/choosetarget"
    },
    {
      "key": "mergefield_Contact_List",
      "origin": "legacyPage",
      "relative": "/contact/mergefield"
    },
    {
      "key": "production_New",
      "origin": "legacyPage",
      "relative": "/production/new"
    },
    {
      "key": "production_Detail",
      "origin": "legacyPage",
      "relative": "/production/{key}"
    },
    {
      "key": "production_Edit",
      "origin": "legacyPage",
      "relative": "/production/{key}/edit"
    },
    {
      "key": "production_Delete",
      "origin": "legacyPage",
      "relative": "/production/{key}/delete"
    },
    {
      "key": "freeFieldCategory_Production_New",
      "origin": "legacyPage",
      "relative": "/production/freefieldcategory/new"
    },
    {
      "key": "freeFieldCategory_Production_Edit",
      "origin": "legacyPage",
      "relative": "/production/freefieldcategory/{key}/edit"
    },
    {
      "key": "freeFieldCategory_Production_List",
      "origin": "legacyPage",
      "relative": "/production/freefieldcategory"
    },
    {
      "key": "freeFieldDefinition_Production_New",
      "origin": "legacyPage",
      "relative": "/production/freefielddefinition/new"
    },
    {
      "key": "freeFieldDefinition_Production_Edit",
      "origin": "legacyPage",
      "relative": "/production/freefielddefinition/{key}/edit"
    },
    {
      "key": "mergefield_Production_List",
      "origin": "legacyPage",
      "relative": "/production/mergefield"
    },
    {
      "key": "freeFieldProfile_Internal_New",
      "origin": "legacyPage",
      "relative": "/freefieldprofile/internal/new"
    },
    {
      "key": "freeFieldProfile_Internal_Edit",
      "origin": "legacyPage",
      "relative": "/freefieldprofile/internal/{key}/edit"
    },
    {
      "key": "freeFieldProfile_External_New",
      "origin": "legacyPage",
      "relative": "/freefieldprofile/external/new"
    },
    {
      "key": "freeFieldProfile_External_Edit",
      "origin": "legacyPage",
      "relative": "/freefieldprofile/external/{key}/edit"
    },
    {
      "key": "planning_Itinerary",
      "origin": "legacyPage",
      "relative": "/planning/itinerary"
    },
    {
      "key": "show_FromWebform",
      "origin": "legacyPage",
      "relative": "/show/{key}/fromwebform"
    },
    {
      "key": "show_Totals",
      "origin": "legacyPage",
      "relative": "/show/total"
    },
    {
      "key": "show_LockShows",
      "origin": "legacyPage",
      "relative": "/show/lock"
    },
    {
      "key": "freeFieldCategory_Show_New",
      "origin": "legacyPage",
      "relative": "/show/freefieldcategory/new"
    },
    {
      "key": "freeFieldCategory_Show_Edit",
      "origin": "legacyPage",
      "relative": "/show/freefieldcategory/{key}/edit"
    },
    {
      "key": "freeFieldCategory_Show_List",
      "origin": "legacyPage",
      "relative": "/show/freefieldcategory"
    },
    {
      "key": "freeFieldDefinition_Show_New",
      "origin": "legacyPage",
      "relative": "/show/freefielddefinition/new"
    },
    {
      "key": "freeFieldDefinition_Show_Edit",
      "origin": "legacyPage",
      "relative": "/show/freefielddefinition/{key}/edit"
    },
    {
      "key": "mergefield_Show_List",
      "origin": "legacyPage",
      "relative": "/show/mergefield"
    },
    {
      "key": "show_NewInvoice",
      "origin": "legacyPage",
      "relative": "/show/{key}/invoice/new"
    },
    {
      "key": "show_NewPurchaseAgreement",
      "origin": "legacyPage",
      "relative": "/show/{key}/purchaseagreement/new"
    },
    {
      "key": "show_NewContract",
      "origin": "legacyPage",
      "relative": "/show/{key}/contract/new"
    },
    {
      "key": "show_NewQuote",
      "origin": "legacyPage",
      "relative": "/show/{key}/quote/new"
    },
    {
      "key": "train_Detail",
      "origin": "legacyPage",
      "relative": "/train/{key}"
    },
    {
      "key": "groundTransport_Detail",
      "origin": "legacyPage",
      "relative": "/groundtransport/{key}"
    },
    {
      "key": "user_ListAdminOne",
      "origin": "legacyPage",
      "relative": "/adminone/user"
    },
    {
      "key": "user_DetailAdminOne",
      "origin": "legacyPage",
      "relative": "/adminone/user/{key}"
    },
    {
      "key": "file_Overview",
      "origin": "legacyPage",
      "relative": "/file"
    },
    {
      "key": "gdpr_Agreement",
      "origin": "legacyPage",
      "relative": "/gdpr/agreement"
    },
    {
      "key": "taskManager_List",
      "origin": "legacyPage",
      "relative": "/taskmanager"
    },
    {
      "key": "invoice_New",
      "origin": "legacyPage",
      "relative": "/invoice/new"
    },
    {
      "key": "invoice_Detail",
      "origin": "legacyPage",
      "relative": "/invoice/{key}"
    },
    {
      "key": "invoice_Edit",
      "origin": "legacyPage",
      "relative": "/invoice/{key}/edit"
    },
    {
      "key": "invoice_Copy",
      "origin": "legacyPage",
      "relative": "/invoice/{key}/copy"
    },
    {
      "key": "invoice_MakeCredit",
      "origin": "legacyPage",
      "relative": "/invoice/{key}/makecredit"
    },
    {
      "key": "invoice_Statistics",
      "origin": "legacyPage",
      "relative": "/invoice/stats"
    },
    {
      "key": "invoice_Totals",
      "origin": "legacyPage",
      "relative": "/invoice/total"
    },
    {
      "key": "mergefield_Invoice_List",
      "origin": "legacyPage",
      "relative": "/invoice/mergefield"
    },
    {
      "key": "invoice_SalesDataExport",
      "origin": "legacyPage",
      "relative": "/invoice/dataexport"
    },
    {
      "key": "purchaseAgreement_New",
      "origin": "legacyPage",
      "relative": "/purchaseagreement/new"
    },
    {
      "key": "purchaseAgreement_Detail",
      "origin": "legacyPage",
      "relative": "/purchaseagreement/{key}"
    },
    {
      "key": "purchaseAgreement_Edit",
      "origin": "legacyPage",
      "relative": "/purchaseagreement/{key}/edit"
    },
    {
      "key": "purchaseAgreement_Copy",
      "origin": "legacyPage",
      "relative": "/purchaseagreement/{key}/copy"
    },
    {
      "key": "purchaseAgreement_Statistics",
      "origin": "legacyPage",
      "relative": "/purchaseagreement/stats"
    },
    {
      "key": "purchaseAgreement_Totals",
      "origin": "legacyPage",
      "relative": "/purchaseagreement/total"
    },
    {
      "key": "mergefield_PurgeAgreement_List",
      "origin": "legacyPage",
      "relative": "/purchaseagreement/mergefield"
    },
    {
      "key": "contract_New",
      "origin": "legacyPage",
      "relative": "/contract/new"
    },
    {
      "key": "contract_Detail",
      "origin": "legacyPage",
      "relative": "/contract/{key}"
    },
    {
      "key": "contract_Edit",
      "origin": "legacyPage",
      "relative": "/contract/{key}/edit"
    },
    {
      "key": "contract_Copy",
      "origin": "legacyPage",
      "relative": "/contract/{key}/copy"
    },
    {
      "key": "contract_Statistics",
      "origin": "legacyPage",
      "relative": "/contract/stats"
    },
    {
      "key": "contract_Totals",
      "origin": "legacyPage",
      "relative": "/contract/total"
    },
    {
      "key": "mergefield_Contract_List",
      "origin": "legacyPage",
      "relative": "/contract/mergefield"
    },
    {
      "key": "quote_New",
      "origin": "legacyPage",
      "relative": "/quote/new"
    },
    {
      "key": "quote_Detail",
      "origin": "legacyPage",
      "relative": "/quote/{key}"
    },
    {
      "key": "quote_Edit",
      "origin": "legacyPage",
      "relative": "/quote/{key}/edit"
    },
    {
      "key": "quote_Copy",
      "origin": "legacyPage",
      "relative": "/quote/{key}/copy"
    },
    {
      "key": "quote_Statistics",
      "origin": "legacyPage",
      "relative": "/quote/stats"
    },
    {
      "key": "quote_Totals",
      "origin": "legacyPage",
      "relative": "/quote/total"
    },
    {
      "key": "mergefield_Quote_List",
      "origin": "legacyPage",
      "relative": "/quote/mergefield"
    },
    {
      "key": "iCalendarTemplate_New",
      "origin": "legacyPage",
      "relative": "/template/icalendar/new"
    },
    {
      "key": "iCalendarTemplate_Edit",
      "origin": "legacyPage",
      "relative": "/template/icalendar/edit/{key}"
    },
    {
      "key": "wordTemplate_Company_New",
      "origin": "legacyPage",
      "relative": "/company/wordtemplate/new"
    },
    {
      "key": "wordTemplate_Contact_New",
      "origin": "legacyPage",
      "relative": "/contact/wordtemplate/new"
    },
    {
      "key": "wordTemplate_Show_New",
      "origin": "legacyPage",
      "relative": "/show/wordtemplate/new"
    },
    {
      "key": "wordTemplate_PurchaseAgreement_New",
      "origin": "legacyPage",
      "relative": "/purchaseagreement/wordtemplate/new"
    },
    {
      "key": "wordTemplate_Contract_New",
      "origin": "legacyPage",
      "relative": "/contract/wordtemplate/new"
    },
    {
      "key": "wordTemplate_Invoice_New",
      "origin": "legacyPage",
      "relative": "/invoice/wordtemplate/new"
    },
    {
      "key": "wordTemplate_Quote_New",
      "origin": "legacyPage",
      "relative": "/quote/wordtemplate/new"
    },
    {
      "key": "wordTemplate_Company_Edit",
      "origin": "legacyPage",
      "relative": "/company/wordtemplate/{key}/edit"
    },
    {
      "key": "wordTemplate_Contact_Edit",
      "origin": "legacyPage",
      "relative": "/contact/wordtemplate/{key}/edit"
    },
    {
      "key": "wordTemplate_Show_Edit",
      "origin": "legacyPage",
      "relative": "/show/wordtemplate/{key}/edit"
    },
    {
      "key": "wordTemplate_PurchaseAgreement_Edit",
      "origin": "legacyPage",
      "relative": "/purchaseagreement/wordtemplate/{key}/edit"
    },
    {
      "key": "wordTemplate_Contract_Edit",
      "origin": "legacyPage",
      "relative": "/contract/wordtemplate/{key}/edit"
    },
    {
      "key": "wordTemplate_Invoice_Edit",
      "origin": "legacyPage",
      "relative": "/invoice/wordtemplate/{key}/edit"
    },
    {
      "key": "wordTemplate_Quote_Edit",
      "origin": "legacyPage",
      "relative": "/quote/wordtemplate/{key}/edit"
    },
    {
      "key": "wordTemplateForListView_Company_List",
      "origin": "legacyPage",
      "relative": "/company/wordlistexporttemplate"
    },
    {
      "key": "wordTemplateForListView_Contact_List",
      "origin": "legacyPage",
      "relative": "/contact/wordlistexporttemplate"
    },
    {
      "key": "wordTemplateForListView_Show_List",
      "origin": "legacyPage",
      "relative": "/show/wordlistexporttemplate"
    },
    {
      "key": "wordTemplateForListView_PurchaseAgreement_List",
      "origin": "legacyPage",
      "relative": "/purchaseagreement/wordlistexporttemplate"
    },
    {
      "key": "wordTemplateForListView_Contract_List",
      "origin": "legacyPage",
      "relative": "/contract/wordlistexporttemplate"
    },
    {
      "key": "wordTemplateForListView_Invoice_List",
      "origin": "legacyPage",
      "relative": "/invoice/wordlistexporttemplate"
    },
    {
      "key": "wordTemplateForListView_Quote_List",
      "origin": "legacyPage",
      "relative": "/quote/wordlistexporttemplate"
    },
    {
      "key": "wordTemplateForListView_Company_New",
      "origin": "legacyPage",
      "relative": "/company/wordlistexporttemplate/new"
    },
    {
      "key": "wordTemplateForListView_Contact_New",
      "origin": "legacyPage",
      "relative": "/contact/wordlistexporttemplate/new"
    },
    {
      "key": "wordTemplateForListView_Show_New",
      "origin": "legacyPage",
      "relative": "/show/wordlistexporttemplate/new"
    },
    {
      "key": "wordTemplateForListView_PurchaseAgreement_New",
      "origin": "legacyPage",
      "relative": "/purchaseagreement/wordlistexporttemplate/new"
    },
    {
      "key": "wordTemplateForListView_Contract_New",
      "origin": "legacyPage",
      "relative": "/contract/wordlistexporttemplate/new"
    },
    {
      "key": "wordTemplateForListView_Invoice_New",
      "origin": "legacyPage",
      "relative": "/invoice/wordlistexporttemplate/new"
    },
    {
      "key": "wordTemplateForListView_Quote_New",
      "origin": "legacyPage",
      "relative": "/quote/wordlistexporttemplate/new"
    },
    {
      "key": "wordTemplateForListView_Company_Edit",
      "origin": "legacyPage",
      "relative": "/company/wordlistexporttemplate/{key}/edit"
    },
    {
      "key": "wordTemplateForListView_Contact_Edit",
      "origin": "legacyPage",
      "relative": "/contact/wordlistexporttemplate/{key}/edit"
    },
    {
      "key": "wordTemplateForListView_Show_Edit",
      "origin": "legacyPage",
      "relative": "/show/wordlistexporttemplate/{key}/edit"
    },
    {
      "key": "wordTemplateForListView_PurchaseAgreement_Edit",
      "origin": "legacyPage",
      "relative": "/purchaseagreement/wordlistexporttemplate/{key}/edit"
    },
    {
      "key": "wordTemplateForListView_Contract_Edit",
      "origin": "legacyPage",
      "relative": "/contract/wordlistexporttemplate/{key}/edit"
    },
    {
      "key": "wordTemplateForListView_Invoice_Edit",
      "origin": "legacyPage",
      "relative": "/invoice/wordlistexporttemplate/{key}/edit"
    },
    {
      "key": "wordTemplateForListView_Quote_Edit",
      "origin": "legacyPage",
      "relative": "/quote/wordlistexporttemplate/{key}/edit"
    },
    {
      "key": "excelTemplate_Company_New",
      "origin": "legacyPage",
      "relative": "/company/exceltemplate/new"
    },
    {
      "key": "excelTemplate_Contact_New",
      "origin": "legacyPage",
      "relative": "/contact/exceltemplate/new"
    },
    {
      "key": "excelTemplate_Production_New",
      "origin": "legacyPage",
      "relative": "/production/exceltemplate/new"
    },
    {
      "key": "excelTemplate_Show_New",
      "origin": "legacyPage",
      "relative": "/show/exceltemplate/new"
    },
    {
      "key": "excelTemplate_PurchaseAgreement_New",
      "origin": "legacyPage",
      "relative": "/purchaseagreement/exceltemplate/new"
    },
    {
      "key": "excelTemplate_Contract_New",
      "origin": "legacyPage",
      "relative": "/contract/exceltemplate/new"
    },
    {
      "key": "excelTemplate_Invoice_New",
      "origin": "legacyPage",
      "relative": "/invoice/exceltemplate/new"
    },
    {
      "key": "excelTemplate_Quote_New",
      "origin": "legacyPage",
      "relative": "/quote/exceltemplate/new"
    },
    {
      "key": "excelTemplate_Company_Edit",
      "origin": "legacyPage",
      "relative": "/company/exceltemplate/{key}/edit"
    },
    {
      "key": "excelTemplate_Contact_Edit",
      "origin": "legacyPage",
      "relative": "/contact/exceltemplate/{key}/edit"
    },
    {
      "key": "excelTemplate_Production_Edit",
      "origin": "legacyPage",
      "relative": "/production/exceltemplate/{key}/edit"
    },
    {
      "key": "excelTemplate_Show_Edit",
      "origin": "legacyPage",
      "relative": "/show/exceltemplate/{key}/edit"
    },
    {
      "key": "excelTemplate_PurchaseAgreement_Edit",
      "origin": "legacyPage",
      "relative": "/purchaseagreement/exceltemplate/{key}/edit"
    },
    {
      "key": "excelTemplate_Contract_Edit",
      "origin": "legacyPage",
      "relative": "/contract/exceltemplate/{key}/edit"
    },
    {
      "key": "excelTemplate_Invoice_Edit",
      "origin": "legacyPage",
      "relative": "/invoice/exceltemplate/{key}/edit"
    },
    {
      "key": "excelTemplate_Quote_Edit",
      "origin": "legacyPage",
      "relative": "/quote/exceltemplate/{key}/edit"
    },
    {
      "key": "emailTemplate_Company_List",
      "origin": "legacyPage",
      "relative": "/company/emailtemplate"
    },
    {
      "key": "emailTemplate_Contact_List",
      "origin": "legacyPage",
      "relative": "/contact/emailtemplate"
    },
    {
      "key": "emailTemplate_Show_List",
      "origin": "legacyPage",
      "relative": "/show/emailtemplate"
    },
    {
      "key": "emailTemplate_PurchaseAgreement_List",
      "origin": "legacyPage",
      "relative": "/purchaseagreement/emailtemplate"
    },
    {
      "key": "emailTemplate_Contract_List",
      "origin": "legacyPage",
      "relative": "/contract/emailtemplate"
    },
    {
      "key": "emailTemplate_Invoice_List",
      "origin": "legacyPage",
      "relative": "/invoice/emailtemplate"
    },
    {
      "key": "emailTemplate_Quote_List",
      "origin": "legacyPage",
      "relative": "/quote/emailtemplate"
    },
    {
      "key": "emailTemplate_Company_New",
      "origin": "legacyPage",
      "relative": "/company/emailtemplate/new"
    },
    {
      "key": "emailTemplate_Contact_New",
      "origin": "legacyPage",
      "relative": "/contact/emailtemplate/new"
    },
    {
      "key": "emailTemplate_Show_New",
      "origin": "legacyPage",
      "relative": "/show/emailtemplate/new"
    },
    {
      "key": "emailTemplate_PurchaseAgreement_New",
      "origin": "legacyPage",
      "relative": "/purchaseagreement/emailtemplate/new"
    },
    {
      "key": "emailTemplate_Contract_New",
      "origin": "legacyPage",
      "relative": "/contract/emailtemplate/new"
    },
    {
      "key": "emailTemplate_Invoice_New",
      "origin": "legacyPage",
      "relative": "/invoice/emailtemplate/new"
    },
    {
      "key": "emailTemplate_Quote_New",
      "origin": "legacyPage",
      "relative": "/quote/emailtemplate/new"
    },
    {
      "key": "emailTemplate_Company_Edit",
      "origin": "legacyPage",
      "relative": "/company/emailtemplate/{key}/edit"
    },
    {
      "key": "emailTemplate_Contact_Edit",
      "origin": "legacyPage",
      "relative": "/contact/emailtemplate/{key}/edit"
    },
    {
      "key": "emailTemplate_Show_Edit",
      "origin": "legacyPage",
      "relative": "/show/emailtemplate/{key}/edit"
    },
    {
      "key": "emailTemplate_PurchaseAgreement_Edit",
      "origin": "legacyPage",
      "relative": "/purchaseagreement/emailtemplate/{key}/edit"
    },
    {
      "key": "emailTemplate_Contract_Edit",
      "origin": "legacyPage",
      "relative": "/contract/emailtemplate/{key}/edit"
    },
    {
      "key": "emailTemplate_Invoice_Edit",
      "origin": "legacyPage",
      "relative": "/invoice/emailtemplate/{key}/edit"
    },
    {
      "key": "emailTemplate_Quote_Edit",
      "origin": "legacyPage",
      "relative": "/quote/emailtemplate/{key}/edit"
    },
    {
      "key": "internalUser_Modify",
      "origin": "legacyPage",
      "relative": "/user/internal/{key}/edit"
    },
    {
      "key": "externalUser_Modify",
      "origin": "legacyPage",
      "relative": "/user/external/{key}/edit"
    },
    {
      "key": "systemSetting_ICalendarFeed_Detail",
      "origin": "legacyPage",
      "relative": "/icalendarfeed/{key}"
    },
    {
      "key": "productGroup_New",
      "origin": "legacyPage",
      "relative": "/productgroup/new"
    },
    {
      "key": "productGroup_Edit",
      "origin": "legacyPage",
      "relative": "/productgroup/{key}/edit"
    },
    {
      "key": "productTemplate_New",
      "origin": "legacyPage",
      "relative": "/producttemplate/new"
    },
    {
      "key": "productTemplate_Edit",
      "origin": "legacyPage",
      "relative": "/producttemplate/{key}/edit"
    },
    {
      "key": "client_NewAdminOne",
      "origin": "legacyPage",
      "relative": "/adminone/client/new"
    },
    {
      "key": "client_EditAdminOne",
      "origin": "legacyPage",
      "relative": "/adminone/client/{key}/edit"
    },
    {
      "key": "client_DetailAdminOne",
      "origin": "legacyPage",
      "relative": "/adminone/client/{key}"
    },
    {
      "key": "client_ListAdminOne",
      "origin": "legacyPage",
      "relative": "/adminone/client"
    },
    {
      "key": "bookkeeping_Edit",
      "origin": "legacyPage",
      "relative": "/bookkeeping/{key}/edit"
    },
    {
      "key": "bookkeeping_List",
      "origin": "legacyPage",
      "relative": "/bookkeeping"
    },
    {
      "key": "country_Detail",
      "origin": "legacyPage",
      "relative": "/country/{key}"
    },
    {
      "key": "country_List",
      "origin": "legacyPage",
      "relative": "/country"
    },
    {
      "key": "iCalendarFeed_Help",
      "origin": "legacyPage",
      "relative": "/ical/{secret}/help"
    },
    {
      "key": "webform_Edit",
      "origin": "legacyPage",
      "relative": "/webform/{key}/edit"
    },
    {
      "key": "webformToFunctiongroup_EditArtist",
      "origin": "legacyPage",
      "relative": "/webform/{key}/artist"
    },
    {
      "key": "webformToFunctiongroup_EditVenue",
      "origin": "legacyPage",
      "relative": "/webform/{key}/venue"
    },
    {
      "key": "webformToFunctiongroup_EditPromoter",
      "origin": "legacyPage",
      "relative": "/webform/{key}/promoter"
    },
    {
      "key": "embedShowsWizard",
      "origin": "legacyPage",
      "relative": "/wizard/embedshow"
    },
    {
      "key": "externalEvent_Detail",
      "origin": "legacyPage",
      "relative": "/externalevent/{key}"
    },
    {
      "key": "externalCalendar_Detail",
      "origin": "legacyPage",
      "relative": "/externalcalendar/{key}"
    },
    {
      "key": "externalCalendar_Edit",
      "origin": "legacyPage",
      "relative": "/externalcalendar/{key}/edit"
    },
    {
      "key": "externalCalendar_New",
      "origin": "legacyPage",
      "relative": "/externalcalendar/new"
    },
    {
      "key": "airport_Detail",
      "origin": "legacyPage",
      "relative": "/airport/{key}"
    },
    {
      "key": "exchangeRate_List",
      "origin": "legacyPage",
      "relative": "/exchangerate"
    },
    {
      "key": "advancingTemplate_Detail",
      "origin": "legacyPage",
      "relative": "/advancing/template/{key}"
    },
    {
      "key": "advancingTemplate_New",
      "origin": "legacyPage",
      "relative": "/advancing/template/new"
    },
    {
      "key": "advancingApprove_Detail",
      "origin": "legacyPage",
      "relative": "/advancing/approve/{key}"
    },
    {
      "key": "notification_List",
      "origin": "legacyPage",
      "relative": "/notification"
    },
    {
      "key": "personal_ICalendarFeed_Detail",
      "origin": "legacyPage",
      "relative": "/personalsettings/icalendarfeed/{key}"
    },
    {
      "key": "account_ReprimandedForCheating",
      "origin": "corePage",
      "relative": "/account/multiuse/detected"
    },
    {
      "key": "account_ResetMyPassword",
      "origin": "corePage",
      "relative": "/account/resetmypassword"
    },
    {
      "key": "account_SignIn",
      "origin": "corePage",
      "relative": "/account/signin"
    },
    {
      "key": "advancing_InvitationLanding",
      "origin": "corePage",
      "relative": "/advancing/invitation/{secret}"
    },
    {
      "key": "advancing_Provide",
      "origin": "corePage",
      "relative": "/advancing/invitation/{secret}/provide"
    },
    {
      "key": "advancingTemplate_List",
      "origin": "corePage",
      "relative": "/advancingtemplate"
    },
    {
      "key": "airport_List",
      "origin": "corePage",
      "relative": "/airport"
    },
    {
      "key": "article_Detail",
      "origin": "corePage",
      "relative": "/article/{key}/edit"
    },
    {
      "key": "article_List",
      "origin": "corePage",
      "relative": "/article/{section}"
    },
    {
      "key": "articleCategory_List",
      "origin": "corePage",
      "relative": "/articlecategory/{section}"
    },
    {
      "key": "artistRider_List",
      "origin": "corePage",
      "relative": "/artistrider"
    },
    {
      "key": "bankAccount_Edit",
      "origin": "corePage",
      "relative": "/bankaccount/{key}/edit"
    },
    {
      "key": "bankAccount_List",
      "origin": "corePage",
      "relative": "/bankaccount"
    },
    {
      "key": "bankAccount_New",
      "origin": "corePage",
      "relative": "/bankaccount/new"
    },
    {
      "key": "bookkeeping_New",
      "origin": "corePage",
      "relative": "/bookkeeping/new"
    },
    {
      "key": "brand_Edit",
      "origin": "corePage",
      "relative": "/brand/{key}/edit"
    },
    {
      "key": "brand_List",
      "origin": "corePage",
      "relative": "/brand"
    },
    {
      "key": "businessCentralProject_List",
      "origin": "corePage",
      "relative": "/businesscentralproject"
    },
    {
      "key": "checklistPresetItem_List",
      "origin": "corePage",
      "relative": "/checklistpreset"
    },
    {
      "key": "client_AccessStatus",
      "origin": "corePage",
      "relative": "/client/access/status"
    },
    {
      "key": "clientOnboarding_Landing",
      "origin": "corePage",
      "relative": "/clientonboarding/{secret}"
    },
    {
      "key": "company_Artist_List",
      "origin": "corePage",
      "relative": "/artist"
    },
    {
      "key": "company_Detail",
      "origin": "corePage",
      "relative": "/company/{key}"
    },
    {
      "key": "company_List",
      "origin": "corePage",
      "relative": "/company"
    },
    {
      "key": "company_Promoter_List",
      "origin": "corePage",
      "relative": "/promoter"
    },
    {
      "key": "company_Venue_List",
      "origin": "corePage",
      "relative": "/venue"
    },
    {
      "key": "contact_Detail",
      "origin": "corePage",
      "relative": "/contact/{key}"
    },
    {
      "key": "contact_List",
      "origin": "corePage",
      "relative": "/contact"
    },
    {
      "key": "contract_ESign",
      "origin": "corePage",
      "relative": "/contract/{key}/esign"
    },
    {
      "key": "contract_List",
      "origin": "corePage",
      "relative": "/contract"
    },
    {
      "key": "costCenter_Edit",
      "origin": "corePage",
      "relative": "/costcenter/{key}/edit"
    },
    {
      "key": "costCenter_List",
      "origin": "corePage",
      "relative": "/costcenter"
    },
    {
      "key": "culture_List",
      "origin": "corePage",
      "relative": "/culture"
    },
    {
      "key": "dashboard",
      "origin": "corePage",
      "relative": "/"
    },
    {
      "key": "diagnostic_Page_Health",
      "origin": "corePage",
      "relative": "/diagnostic/5915ab75-f6a9-4514-abce-b01b476c9944/health"
    },
    {
      "key": "emailMessage_PublicDownload",
      "origin": "corePage",
      "relative": "/download/{secret}"
    },
    {
      "key": "eSign_ForMeList",
      "origin": "corePage",
      "relative": "/esign/forme"
    },
    {
      "key": "eSign_Landing",
      "origin": "corePage",
      "relative": "/esign/invitation/{secret}"
    },
    {
      "key": "excelTemplate_List",
      "origin": "corePage",
      "relative": "/{mainarea}/exceltemplate"
    },
    {
      "key": "externalCalendar_List",
      "origin": "corePage",
      "relative": "/externalcalendar"
    },
    {
      "key": "externalIdentity_ChooseUser",
      "origin": "corePage",
      "relative": "/externalidentity/chooseuser"
    },
    {
      "key": "externalUser_Invite",
      "origin": "corePage",
      "relative": "/user/external/invite"
    },
    {
      "key": "externalUser_List",
      "origin": "corePage",
      "relative": "/user/external"
    },
    {
      "key": "flight_Detail",
      "origin": "corePage",
      "relative": "/flight/{key}"
    },
    {
      "key": "flight_List",
      "origin": "corePage",
      "relative": "/flight"
    },
    {
      "key": "freeFieldProfile_List",
      "origin": "corePage",
      "relative": "/freefieldprofile/{environment}"
    },
    {
      "key": "groundTransport_List",
      "origin": "corePage",
      "relative": "/groundtransport"
    },
    {
      "key": "iCalendarTemplate_List",
      "origin": "corePage",
      "relative": "/icalendartemplate"
    },
    {
      "key": "internalUser_Invite",
      "origin": "corePage",
      "relative": "/user/internal/invite"
    },
    {
      "key": "internalUser_List",
      "origin": "corePage",
      "relative": "/user/internal"
    },
    {
      "key": "invoice_List",
      "origin": "corePage",
      "relative": "/invoice"
    },
    {
      "key": "messageTemplate_Edit",
      "origin": "corePage",
      "relative": "/template/message/{key}/edit"
    },
    {
      "key": "messageTemplate_List",
      "origin": "corePage",
      "relative": "/template/message"
    },
    {
      "key": "onlineInvoice_Edit",
      "origin": "corePage",
      "relative": "/onlineinvoice/{key}/edit"
    },
    {
      "key": "onlineInvoice_Landing",
      "origin": "corePage",
      "relative": "/onlineinvoice/invitation/{secret}"
    },
    {
      "key": "payment_Detail",
      "origin": "corePage",
      "relative": "/payment/{key}"
    },
    {
      "key": "payment_Edit",
      "origin": "corePage",
      "relative": "/payment/{key}/edit"
    },
    {
      "key": "payment_List",
      "origin": "corePage",
      "relative": "/payment"
    },
    {
      "key": "personal_ChangeName",
      "origin": "corePage",
      "relative": "/personalsettings/change-name"
    },
    {
      "key": "personal_ChangePassword",
      "origin": "corePage",
      "relative": "/personalsettings/change-password"
    },
    {
      "key": "personal_ChangeSecretQuestion",
      "origin": "corePage",
      "relative": "/personalsettings/change-secret-question"
    },
    {
      "key": "personal_ConnectedAccount_List",
      "origin": "corePage",
      "relative": "/personalsettings/connected-accounts"
    },
    {
      "key": "personal_EmailSettings",
      "origin": "corePage",
      "relative": "/personalsettings/email-settings"
    },
    {
      "key": "personal_FavoriteSubject_List",
      "origin": "corePage",
      "relative": "/personalsettings/favorite-subjects"
    },
    {
      "key": "personal_ICalendarFeed_List",
      "origin": "corePage",
      "relative": "/personalsettings/icalendar-feeds"
    },
    {
      "key": "personal_SettingMenu",
      "origin": "corePage",
      "relative": "/personalsettings"
    },
    {
      "key": "personal_SignatureSettings",
      "origin": "corePage",
      "relative": "/personalsettings/signature-settings"
    },
    {
      "key": "planning_Availability",
      "origin": "corePage",
      "relative": "/planning/availability"
    },
    {
      "key": "planning_Map",
      "origin": "corePage",
      "relative": "/planning/map"
    },
    {
      "key": "planning_Month",
      "origin": "corePage",
      "relative": "/planning/month"
    },
    {
      "key": "planning_Quarter",
      "origin": "corePage",
      "relative": "/planning/quarter"
    },
    {
      "key": "planning_Week",
      "origin": "corePage",
      "relative": "/planning/week"
    },
    {
      "key": "preference_Defaults",
      "origin": "corePage",
      "relative": "/personalsettings/defaults"
    },
    {
      "key": "preference_FeatureConfig",
      "origin": "corePage",
      "relative": "/personalsettings/feature-configure"
    },
    {
      "key": "preference_NotificationSettings",
      "origin": "corePage",
      "relative": "/personalsettings/notification-settings"
    },
    {
      "key": "preference_RegionalSettings",
      "origin": "corePage",
      "relative": "/personalsettings/language-and-region"
    },
    {
      "key": "preference_Styling",
      "origin": "corePage",
      "relative": "/personalsettings/style"
    },
    {
      "key": "productGroup_List",
      "origin": "corePage",
      "relative": "/productgroup"
    },
    {
      "key": "production_List",
      "origin": "corePage",
      "relative": "/production"
    },
    {
      "key": "productTemplate_List",
      "origin": "corePage",
      "relative": "/producttemplate"
    },
    {
      "key": "purchaseAgreement_ESign",
      "origin": "corePage",
      "relative": "/purchaseagreement/{key}/esign"
    },
    {
      "key": "purchaseAgreement_List",
      "origin": "corePage",
      "relative": "/purchaseagreement"
    },
    {
      "key": "quote_ESign",
      "origin": "corePage",
      "relative": "/quote/{key}/esign"
    },
    {
      "key": "quote_List",
      "origin": "corePage",
      "relative": "/quote"
    },
    {
      "key": "show_Copy",
      "origin": "corePage",
      "relative": "/show/{key}/copy"
    },
    {
      "key": "show_Detail",
      "origin": "corePage",
      "relative": "/show/{key}"
    },
    {
      "key": "show_Edit",
      "origin": "corePage",
      "relative": "/show/{key}/edit"
    },
    {
      "key": "show_List",
      "origin": "corePage",
      "relative": "/show"
    },
    {
      "key": "show_New",
      "origin": "corePage",
      "relative": "/show/new"
    },
    {
      "key": "stateAlias_Unmapped",
      "origin": "corePage",
      "relative": "/statealias"
    },
    {
      "key": "status_Detail",
      "origin": "corePage",
      "relative": "/{mainarea}/status/{key}"
    },
    {
      "key": "status_Edit",
      "origin": "corePage",
      "relative": "/{mainarea}/status/{key}/edit"
    },
    {
      "key": "status_List",
      "origin": "corePage",
      "relative": "/{mainarea}/status"
    },
    {
      "key": "status_New",
      "origin": "corePage",
      "relative": "/{mainarea}/status/new"
    },
    {
      "key": "subscription_Detail",
      "origin": "corePage",
      "relative": "/subscription"
    },
    {
      "key": "subscription_PaymentSetupComplete",
      "origin": "corePage",
      "relative": "/subscription/payment-setup-complete"
    },
    {
      "key": "systemSetting_Config",
      "origin": "corePage",
      "relative": "/systemsettings/config"
    },
    {
      "key": "systemSetting_Menu",
      "origin": "corePage",
      "relative": "/systemsettings"
    },
    {
      "key": "systemWideFiles_List",
      "origin": "corePage",
      "relative": "/file/systemwide"
    },
    {
      "key": "tag_Edit",
      "origin": "corePage",
      "relative": "/{mainarea}/tag/{key}/edit"
    },
    {
      "key": "tag_List",
      "origin": "corePage",
      "relative": "/{mainarea}/tag"
    },
    {
      "key": "tag_New",
      "origin": "corePage",
      "relative": "/{mainarea}/tag/new"
    },
    {
      "key": "templateSetting_Menu",
      "origin": "corePage",
      "relative": "/templatesettings"
    },
    {
      "key": "train_List",
      "origin": "corePage",
      "relative": "/train"
    },
    {
      "key": "userAction_List",
      "origin": "corePage",
      "relative": "/useraction"
    },
    {
      "key": "webform_List",
      "origin": "corePage",
      "relative": "/webform"
    },
    {
      "key": "wordTemplate_Edit",
      "origin": "corePage",
      "relative": "/wordtemplatev2/{key}/edit"
    },
    {
      "key": "wordTemplate_List",
      "origin": "corePage",
      "relative": "/{mainarea}/wordtemplate"
    },
    {
      "key": "wordTemplate_New",
      "origin": "corePage",
      "relative": "/{mainarea}/wordtemplatev2/new"
    }
  ],
  "timetableEntryTypes": [
    {
      "value": "dinner",
      "text": "Abendessen"
    },
    {
      "value": "arrival",
      "text": "Ankunft"
    },
    {
      "value": "backstageCurfew",
      "text": "Backstage Curfew"
    },
    {
      "value": "busCall",
      "text": "Bus Call"
    },
    {
      "value": "changeover",
      "text": "Changeover"
    },
    {
      "value": "changeoverAndLinecheck",
      "text": "Changeover & Linecheck"
    },
    {
      "value": "crewCall",
      "text": "Crew Call"
    },
    {
      "value": "curfew",
      "text": "Curfew"
    },
    {
      "value": "doorsOpen",
      "text": "Einlass"
    },
    {
      "value": "doorsClose",
      "text": "Ende"
    },
    {
      "value": "breakfast",
      "text": "Frühstück"
    },
    {
      "value": "getIn",
      "text": "Get In"
    },
    {
      "value": "getInAndDinner",
      "text": "Get In & Abendessen"
    },
    {
      "value": "getInAndBreakfast",
      "text": "Get In & Frühstück"
    },
    {
      "value": "getInAndLoadIn",
      "text": "Get In & Load In"
    },
    {
      "value": "getInAndLunch",
      "text": "Get In & Mittagessen"
    },
    {
      "value": "getInAndSetUp",
      "text": "Get In & Set Up"
    },
    {
      "value": "getInBand",
      "text": "Get In Band"
    },
    {
      "value": "getInCatering",
      "text": "Get In Catering"
    },
    {
      "value": "getInCrew",
      "text": "Get In Crew"
    },
    {
      "value": "getInArtist",
      "text": "Get In Künstler"
    },
    {
      "value": "getInLight",
      "text": "Get In Licht"
    },
    {
      "value": "getInSound",
      "text": "Get In Sound"
    },
    {
      "value": "getInSoundAndLight",
      "text": "Get In Sound & Licht"
    },
    {
      "value": "getInSupport",
      "text": "Get In Support"
    },
    {
      "value": "interview",
      "text": "Interview"
    },
    {
      "value": "programmingLight",
      "text": "Lichtprogrammierung"
    },
    {
      "value": "lineCheck",
      "text": "Linecheck"
    },
    {
      "value": "loadIn",
      "text": "Load In"
    },
    {
      "value": "loadInAndSetUp",
      "text": "Load In & Set Up"
    },
    {
      "value": "loadOut",
      "text": "Load out"
    },
    {
      "value": "meetAndGreet",
      "text": "Meet & Greet"
    },
    {
      "value": "lunch",
      "text": "Mittagessen"
    },
    {
      "value": "offStage",
      "text": "Off Stage"
    },
    {
      "value": "onStage",
      "text": "On Stage"
    },
    {
      "value": "performance",
      "text": "Performance"
    },
    {
      "value": "pickUp",
      "text": "Pick Up"
    },
    {
      "value": "rehearsal",
      "text": "Probe"
    },
    {
      "value": "securityMeeting",
      "text": "Security Meeting"
    },
    {
      "value": "setUp",
      "text": "Set Up"
    },
    {
      "value": "setUpAndSoundcheck",
      "text": "Set Up & Soundcheck"
    },
    {
      "value": "setUpLight",
      "text": "Set Up Licht"
    },
    {
      "value": "setUpSound",
      "text": "Set Up Sound"
    },
    {
      "value": "setUpSoundAndLight",
      "text": "Set Up Sound & Licht"
    },
    {
      "value": "showtime",
      "text": "Showtime"
    },
    {
      "value": "soundcheck",
      "text": "Soundcheck"
    },
    {
      "value": "soundcheckSupport",
      "text": "Soundcheck Support"
    },
    {
      "value": "stageClear",
      "text": "Stage Clear"
    },
    {
      "value": "stageCurfew",
      "text": "Stage Curfew"
    },
    {
      "value": "stageTime",
      "text": "Stagetime"
    }
  ],
  "trainTravelClasses": [
    {
      "value": "unknown",
      "text": "Unbekannt"
    },
    {
      "value": "firstClass",
      "text": "Erste Klasse"
    },
    {
      "value": "secondClass",
      "text": "Zweite Klasse"
    },
    {
      "value": "thirdClass",
      "text": "Dritte Klasse"
    }
  ],
  "timezones": [
    {
      "value": "Dateline Standard Time",
      "utcOffset": "UTC-12:00",
      "displayName": "International Date Line West"
    },
    {
      "value": "UTC-11",
      "utcOffset": "UTC-11:00",
      "displayName": "Coordinated Universal Time-11"
    },
    {
      "value": "Aleutian Standard Time",
      "utcOffset": "UTC-10:00",
      "displayName": "Aleutian Islands"
    },
    {
      "value": "Hawaiian Standard Time",
      "utcOffset": "UTC-10:00",
      "displayName": "Hawaii"
    },
    {
      "value": "Marquesas Standard Time",
      "utcOffset": "UTC-09:30",
      "displayName": "Marquesas Islands"
    },
    {
      "value": "Alaskan Standard Time",
      "utcOffset": "UTC-09:00",
      "displayName": "Alaska"
    },
    {
      "value": "UTC-09",
      "utcOffset": "UTC-09:00",
      "displayName": "Coordinated Universal Time-09"
    },
    {
      "value": "Pacific Standard Time (Mexico)",
      "utcOffset": "UTC-08:00",
      "displayName": "Baja California"
    },
    {
      "value": "UTC-08",
      "utcOffset": "UTC-08:00",
      "displayName": "Coordinated Universal Time-08"
    },
    {
      "value": "Pacific Standard Time",
      "utcOffset": "UTC-08:00",
      "displayName": "Pacific Time (US & Canada)"
    },
    {
      "value": "US Mountain Standard Time",
      "utcOffset": "UTC-07:00",
      "displayName": "Arizona"
    },
    {
      "value": "Mountain Standard Time (Mexico)",
      "utcOffset": "UTC-07:00",
      "displayName": "Chihuahua, La Paz, Mazatlan"
    },
    {
      "value": "Mountain Standard Time",
      "utcOffset": "UTC-07:00",
      "displayName": "Mountain Time (US & Canada)"
    },
    {
      "value": "Yukon Standard Time",
      "utcOffset": "UTC-07:00",
      "displayName": "Yukon"
    },
    {
      "value": "Central America Standard Time",
      "utcOffset": "UTC-06:00",
      "displayName": "Central America"
    },
    {
      "value": "Central Standard Time",
      "utcOffset": "UTC-06:00",
      "displayName": "Central Time (US & Canada)"
    },
    {
      "value": "Easter Island Standard Time",
      "utcOffset": "UTC-06:00",
      "displayName": "Easter Island"
    },
    {
      "value": "Central Standard Time (Mexico)",
      "utcOffset": "UTC-06:00",
      "displayName": "Guadalajara, Mexico City, Monterrey"
    },
    {
      "value": "Canada Central Standard Time",
      "utcOffset": "UTC-06:00",
      "displayName": "Saskatchewan"
    },
    {
      "value": "SA Pacific Standard Time",
      "utcOffset": "UTC-05:00",
      "displayName": "Bogota, Lima, Quito, Rio Branco"
    },
    {
      "value": "Eastern Standard Time (Mexico)",
      "utcOffset": "UTC-05:00",
      "displayName": "Chetumal"
    },
    {
      "value": "Eastern Standard Time",
      "utcOffset": "UTC-05:00",
      "displayName": "Eastern Time (US & Canada)"
    },
    {
      "value": "Haiti Standard Time",
      "utcOffset": "UTC-05:00",
      "displayName": "Haiti"
    },
    {
      "value": "Cuba Standard Time",
      "utcOffset": "UTC-05:00",
      "displayName": "Havana"
    },
    {
      "value": "US Eastern Standard Time",
      "utcOffset": "UTC-05:00",
      "displayName": "Indiana (East)"
    },
    {
      "value": "Turks And Caicos Standard Time",
      "utcOffset": "UTC-05:00",
      "displayName": "Turks and Caicos"
    },
    {
      "value": "Paraguay Standard Time",
      "utcOffset": "UTC-04:00",
      "displayName": "Asuncion"
    },
    {
      "value": "Atlantic Standard Time",
      "utcOffset": "UTC-04:00",
      "displayName": "Atlantic Time (Canada)"
    },
    {
      "value": "Venezuela Standard Time",
      "utcOffset": "UTC-04:00",
      "displayName": "Caracas"
    },
    {
      "value": "Central Brazilian Standard Time",
      "utcOffset": "UTC-04:00",
      "displayName": "Cuiaba"
    },
    {
      "value": "SA Western Standard Time",
      "utcOffset": "UTC-04:00",
      "displayName": "Georgetown, La Paz, Manaus, San Juan"
    },
    {
      "value": "Pacific SA Standard Time",
      "utcOffset": "UTC-04:00",
      "displayName": "Santiago"
    },
    {
      "value": "Newfoundland Standard Time",
      "utcOffset": "UTC-03:30",
      "displayName": "Newfoundland"
    },
    {
      "value": "Tocantins Standard Time",
      "utcOffset": "UTC-03:00",
      "displayName": "Araguaina"
    },
    {
      "value": "E. South America Standard Time",
      "utcOffset": "UTC-03:00",
      "displayName": "Brasilia"
    },
    {
      "value": "SA Eastern Standard Time",
      "utcOffset": "UTC-03:00",
      "displayName": "Cayenne, Fortaleza"
    },
    {
      "value": "Argentina Standard Time",
      "utcOffset": "UTC-03:00",
      "displayName": "City of Buenos Aires"
    },
    {
      "value": "Montevideo Standard Time",
      "utcOffset": "UTC-03:00",
      "displayName": "Montevideo"
    },
    {
      "value": "Magallanes Standard Time",
      "utcOffset": "UTC-03:00",
      "displayName": "Punta Arenas"
    },
    {
      "value": "Saint Pierre Standard Time",
      "utcOffset": "UTC-03:00",
      "displayName": "Saint Pierre and Miquelon"
    },
    {
      "value": "Bahia Standard Time",
      "utcOffset": "UTC-03:00",
      "displayName": "Salvador"
    },
    {
      "value": "UTC-02",
      "utcOffset": "UTC-02:00",
      "displayName": "Coordinated Universal Time-02"
    },
    {
      "value": "Greenland Standard Time",
      "utcOffset": "UTC-02:00",
      "displayName": "Greenland"
    },
    {
      "value": "Mid-Atlantic Standard Time",
      "utcOffset": "UTC-02:00",
      "displayName": "Mid-Atlantic - Old"
    },
    {
      "value": "Azores Standard Time",
      "utcOffset": "UTC-01:00",
      "displayName": "Azores"
    },
    {
      "value": "Cape Verde Standard Time",
      "utcOffset": "UTC-01:00",
      "displayName": "Cabo Verde Is."
    },
    {
      "value": "Morocco Standard Time",
      "utcOffset": "UTC",
      "displayName": "Casablanca"
    },
    {
      "value": "UTC",
      "utcOffset": "UTC",
      "displayName": "Coordinated Universal Time"
    },
    {
      "value": "GMT Standard Time",
      "utcOffset": "UTC",
      "displayName": "Dublin, Edinburgh, Lisbon, London"
    },
    {
      "value": "Greenwich Standard Time",
      "utcOffset": "UTC",
      "displayName": "Monrovia, Reykjavik"
    },
    {
      "value": "Sao Tome Standard Time",
      "utcOffset": "UTC",
      "displayName": "Sao Tome"
    },
    {
      "value": "W. Europe Standard Time",
      "utcOffset": "UTC+01:00",
      "displayName": "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"
    },
    {
      "value": "Central Europe Standard Time",
      "utcOffset": "UTC+01:00",
      "displayName": "Belgrade, Bratislava, Budapest, Ljubljana, Prague"
    },
    {
      "value": "Romance Standard Time",
      "utcOffset": "UTC+01:00",
      "displayName": "Brussels, Copenhagen, Madrid, Paris"
    },
    {
      "value": "Central European Standard Time",
      "utcOffset": "UTC+01:00",
      "displayName": "Sarajevo, Skopje, Warsaw, Zagreb"
    },
    {
      "value": "W. Central Africa Standard Time",
      "utcOffset": "UTC+01:00",
      "displayName": "West Central Africa"
    },
    {
      "value": "GTB Standard Time",
      "utcOffset": "UTC+02:00",
      "displayName": "Athens, Bucharest"
    },
    {
      "value": "Middle East Standard Time",
      "utcOffset": "UTC+02:00",
      "displayName": "Beirut"
    },
    {
      "value": "Egypt Standard Time",
      "utcOffset": "UTC+02:00",
      "displayName": "Cairo"
    },
    {
      "value": "E. Europe Standard Time",
      "utcOffset": "UTC+02:00",
      "displayName": "Chisinau"
    },
    {
      "value": "West Bank Standard Time",
      "utcOffset": "UTC+02:00",
      "displayName": "Gaza, Hebron"
    },
    {
      "value": "South Africa Standard Time",
      "utcOffset": "UTC+02:00",
      "displayName": "Harare, Pretoria"
    },
    {
      "value": "FLE Standard Time",
      "utcOffset": "UTC+02:00",
      "displayName": "Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius"
    },
    {
      "value": "Israel Standard Time",
      "utcOffset": "UTC+02:00",
      "displayName": "Jerusalem"
    },
    {
      "value": "South Sudan Standard Time",
      "utcOffset": "UTC+02:00",
      "displayName": "Juba"
    },
    {
      "value": "Kaliningrad Standard Time",
      "utcOffset": "UTC+02:00",
      "displayName": "Kaliningrad"
    },
    {
      "value": "Sudan Standard Time",
      "utcOffset": "UTC+02:00",
      "displayName": "Khartoum"
    },
    {
      "value": "Libya Standard Time",
      "utcOffset": "UTC+02:00",
      "displayName": "Tripoli"
    },
    {
      "value": "Namibia Standard Time",
      "utcOffset": "UTC+02:00",
      "displayName": "Windhoek"
    },
    {
      "value": "Jordan Standard Time",
      "utcOffset": "UTC+03:00",
      "displayName": "Amman"
    },
    {
      "value": "Arabic Standard Time",
      "utcOffset": "UTC+03:00",
      "displayName": "Baghdad"
    },
    {
      "value": "Syria Standard Time",
      "utcOffset": "UTC+03:00",
      "displayName": "Damascus"
    },
    {
      "value": "Turkey Standard Time",
      "utcOffset": "UTC+03:00",
      "displayName": "Istanbul"
    },
    {
      "value": "Arab Standard Time",
      "utcOffset": "UTC+03:00",
      "displayName": "Kuwait, Riyadh"
    },
    {
      "value": "Belarus Standard Time",
      "utcOffset": "UTC+03:00",
      "displayName": "Minsk"
    },
    {
      "value": "Russian Standard Time",
      "utcOffset": "UTC+03:00",
      "displayName": "Moscow, St. Petersburg"
    },
    {
      "value": "E. Africa Standard Time",
      "utcOffset": "UTC+03:00",
      "displayName": "Nairobi"
    },
    {
      "value": "Volgograd Standard Time",
      "utcOffset": "UTC+03:00",
      "displayName": "Volgograd"
    },
    {
      "value": "Iran Standard Time",
      "utcOffset": "UTC+03:30",
      "displayName": "Tehran"
    },
    {
      "value": "Arabian Standard Time",
      "utcOffset": "UTC+04:00",
      "displayName": "Abu Dhabi, Muscat"
    },
    {
      "value": "Astrakhan Standard Time",
      "utcOffset": "UTC+04:00",
      "displayName": "Astrakhan, Ulyanovsk"
    },
    {
      "value": "Azerbaijan Standard Time",
      "utcOffset": "UTC+04:00",
      "displayName": "Baku"
    },
    {
      "value": "Russia Time Zone 3",
      "utcOffset": "UTC+04:00",
      "displayName": "Izhevsk, Samara"
    },
    {
      "value": "Mauritius Standard Time",
      "utcOffset": "UTC+04:00",
      "displayName": "Port Louis"
    },
    {
      "value": "Saratov Standard Time",
      "utcOffset": "UTC+04:00",
      "displayName": "Saratov"
    },
    {
      "value": "Georgian Standard Time",
      "utcOffset": "UTC+04:00",
      "displayName": "Tbilisi"
    },
    {
      "value": "Caucasus Standard Time",
      "utcOffset": "UTC+04:00",
      "displayName": "Yerevan"
    },
    {
      "value": "Afghanistan Standard Time",
      "utcOffset": "UTC+04:30",
      "displayName": "Kabul"
    },
    {
      "value": "West Asia Standard Time",
      "utcOffset": "UTC+05:00",
      "displayName": "Ashgabat, Tashkent"
    },
    {
      "value": "Ekaterinburg Standard Time",
      "utcOffset": "UTC+05:00",
      "displayName": "Ekaterinburg"
    },
    {
      "value": "Pakistan Standard Time",
      "utcOffset": "UTC+05:00",
      "displayName": "Islamabad, Karachi"
    },
    {
      "value": "Qyzylorda Standard Time",
      "utcOffset": "UTC+05:00",
      "displayName": "Qyzylorda"
    },
    {
      "value": "India Standard Time",
      "utcOffset": "UTC+05:30",
      "displayName": "Chennai, Kolkata, Mumbai, New Delhi"
    },
    {
      "value": "Sri Lanka Standard Time",
      "utcOffset": "UTC+05:30",
      "displayName": "Sri Jayawardenepura"
    },
    {
      "value": "Nepal Standard Time",
      "utcOffset": "UTC+05:45",
      "displayName": "Kathmandu"
    },
    {
      "value": "Central Asia Standard Time",
      "utcOffset": "UTC+06:00",
      "displayName": "Astana"
    },
    {
      "value": "Bangladesh Standard Time",
      "utcOffset": "UTC+06:00",
      "displayName": "Dhaka"
    },
    {
      "value": "Omsk Standard Time",
      "utcOffset": "UTC+06:00",
      "displayName": "Omsk"
    },
    {
      "value": "Myanmar Standard Time",
      "utcOffset": "UTC+06:30",
      "displayName": "Yangon (Rangoon)"
    },
    {
      "value": "SE Asia Standard Time",
      "utcOffset": "UTC+07:00",
      "displayName": "Bangkok, Hanoi, Jakarta"
    },
    {
      "value": "Altai Standard Time",
      "utcOffset": "UTC+07:00",
      "displayName": "Barnaul, Gorno-Altaysk"
    },
    {
      "value": "W. Mongolia Standard Time",
      "utcOffset": "UTC+07:00",
      "displayName": "Hovd"
    },
    {
      "value": "North Asia Standard Time",
      "utcOffset": "UTC+07:00",
      "displayName": "Krasnoyarsk"
    },
    {
      "value": "N. Central Asia Standard Time",
      "utcOffset": "UTC+07:00",
      "displayName": "Novosibirsk"
    },
    {
      "value": "Tomsk Standard Time",
      "utcOffset": "UTC+07:00",
      "displayName": "Tomsk"
    },
    {
      "value": "China Standard Time",
      "utcOffset": "UTC+08:00",
      "displayName": "Beijing, Chongqing, Hong Kong, Urumqi"
    },
    {
      "value": "North Asia East Standard Time",
      "utcOffset": "UTC+08:00",
      "displayName": "Irkutsk"
    },
    {
      "value": "Singapore Standard Time",
      "utcOffset": "UTC+08:00",
      "displayName": "Kuala Lumpur, Singapore"
    },
    {
      "value": "W. Australia Standard Time",
      "utcOffset": "UTC+08:00",
      "displayName": "Perth"
    },
    {
      "value": "Taipei Standard Time",
      "utcOffset": "UTC+08:00",
      "displayName": "Taipei"
    },
    {
      "value": "Ulaanbaatar Standard Time",
      "utcOffset": "UTC+08:00",
      "displayName": "Ulaanbaatar"
    },
    {
      "value": "Aus Central W. Standard Time",
      "utcOffset": "UTC+08:45",
      "displayName": "Eucla"
    },
    {
      "value": "Transbaikal Standard Time",
      "utcOffset": "UTC+09:00",
      "displayName": "Chita"
    },
    {
      "value": "Tokyo Standard Time",
      "utcOffset": "UTC+09:00",
      "displayName": "Osaka, Sapporo, Tokyo"
    },
    {
      "value": "North Korea Standard Time",
      "utcOffset": "UTC+09:00",
      "displayName": "Pyongyang"
    },
    {
      "value": "Korea Standard Time",
      "utcOffset": "UTC+09:00",
      "displayName": "Seoul"
    },
    {
      "value": "Yakutsk Standard Time",
      "utcOffset": "UTC+09:00",
      "displayName": "Yakutsk"
    },
    {
      "value": "Cen. Australia Standard Time",
      "utcOffset": "UTC+09:30",
      "displayName": "Adelaide"
    },
    {
      "value": "AUS Central Standard Time",
      "utcOffset": "UTC+09:30",
      "displayName": "Darwin"
    },
    {
      "value": "E. Australia Standard Time",
      "utcOffset": "UTC+10:00",
      "displayName": "Brisbane"
    },
    {
      "value": "AUS Eastern Standard Time",
      "utcOffset": "UTC+10:00",
      "displayName": "Canberra, Melbourne, Sydney"
    },
    {
      "value": "West Pacific Standard Time",
      "utcOffset": "UTC+10:00",
      "displayName": "Guam, Port Moresby"
    },
    {
      "value": "Tasmania Standard Time",
      "utcOffset": "UTC+10:00",
      "displayName": "Hobart"
    },
    {
      "value": "Vladivostok Standard Time",
      "utcOffset": "UTC+10:00",
      "displayName": "Vladivostok"
    },
    {
      "value": "Lord Howe Standard Time",
      "utcOffset": "UTC+10:30",
      "displayName": "Lord Howe Island"
    },
    {
      "value": "Bougainville Standard Time",
      "utcOffset": "UTC+11:00",
      "displayName": "Bougainville Island"
    },
    {
      "value": "Russia Time Zone 10",
      "utcOffset": "UTC+11:00",
      "displayName": "Chokurdakh"
    },
    {
      "value": "Magadan Standard Time",
      "utcOffset": "UTC+11:00",
      "displayName": "Magadan"
    },
    {
      "value": "Norfolk Standard Time",
      "utcOffset": "UTC+11:00",
      "displayName": "Norfolk Island"
    },
    {
      "value": "Sakhalin Standard Time",
      "utcOffset": "UTC+11:00",
      "displayName": "Sakhalin"
    },
    {
      "value": "Central Pacific Standard Time",
      "utcOffset": "UTC+11:00",
      "displayName": "Solomon Is., New Caledonia"
    },
    {
      "value": "Russia Time Zone 11",
      "utcOffset": "UTC+12:00",
      "displayName": "Anadyr, Petropavlovsk-Kamchatsky"
    },
    {
      "value": "New Zealand Standard Time",
      "utcOffset": "UTC+12:00",
      "displayName": "Auckland, Wellington"
    },
    {
      "value": "UTC+12",
      "utcOffset": "UTC+12:00",
      "displayName": "Coordinated Universal Time+12"
    },
    {
      "value": "Fiji Standard Time",
      "utcOffset": "UTC+12:00",
      "displayName": "Fiji"
    },
    {
      "value": "Kamchatka Standard Time",
      "utcOffset": "UTC+12:00",
      "displayName": "Petropavlovsk-Kamchatsky - Old"
    },
    {
      "value": "Chatham Islands Standard Time",
      "utcOffset": "UTC+12:45",
      "displayName": "Chatham Islands"
    },
    {
      "value": "UTC+13",
      "utcOffset": "UTC+13:00",
      "displayName": "Coordinated Universal Time+13"
    },
    {
      "value": "Tonga Standard Time",
      "utcOffset": "UTC+13:00",
      "displayName": "Nuku'alofa"
    },
    {
      "value": "Samoa Standard Time",
      "utcOffset": "UTC+13:00",
      "displayName": "Samoa"
    },
    {
      "value": "Line Islands Standard Time",
      "utcOffset": "UTC+14:00",
      "displayName": "Kiritimati Island"
    }
  ],
  "venueCategories": [
    {
      "value": "pub",
      "text": "Bar"
    },
    {
      "value": "business",
      "text": "Business"
    },
    {
      "value": "church",
      "text": "Church"
    },
    {
      "value": "nightclub",
      "text": "Club / Diskothek"
    },
    {
      "value": "festival",
      "text": "Festival"
    },
    {
      "value": "banquetHall",
      "text": "Festsaal"
    },
    {
      "value": "hotel",
      "text": "Hotel"
    },
    {
      "value": "indoorArena",
      "text": "Indoor-Arena"
    },
    {
      "value": "conferenceCenter",
      "text": "Konferenzzentrum"
    },
    {
      "value": "concertHall",
      "text": "Konzertsaal"
    },
    {
      "value": "library",
      "text": "Library"
    },
    {
      "value": "liveMusicBar",
      "text": "Live music bar"
    },
    {
      "value": "livingRoom",
      "text": "Living room"
    },
    {
      "value": "outdoor",
      "text": "Open Air"
    },
    {
      "value": "restaurant",
      "text": "Restaurant"
    },
    {
      "value": "boat",
      "text": "Schiff"
    },
    {
      "value": "school",
      "text": "Schule"
    },
    {
      "value": "other",
      "text": "Sonstiges"
    },
    {
      "value": "sportsStadium",
      "text": "Sportstadion"
    },
    {
      "value": "theatre",
      "text": "Theater"
    },
    {
      "value": "unknown",
      "text": "Unbekannt"
    },
    {
      "value": "tent",
      "text": "Zelt"
    }
  ],
  "constants": {
    "account": {
      "defaultInterfaceLocale": "en-GB"
    },
    "address": {
      "maxAddressLineLength": 100,
      "maxPostalCodeLength": 50,
      "maxCityLength": 50
    },
    "advancingTemplate": {
      "maxNameLength": 100
    },
    "advancingUpload": {
      "maxMessageLength": 10000
    },
    "appointment": {
      "minDate": "2000-01-01T00:00:00Z",
      "maxDate": "2059-01-01T00:00:00Z",
      "maxInNightOfHour": 8,
      "minYear": 2000,
      "maxYear": 2059,
      "maxDurationDays": 365
    },
    "artistPicker": {
      "threshold": 6
    },
    "artistRider": {
      "maxAttachedBytes": 10485760
    },
    "availability": {
      "defaultPageSize": 100,
      "maxDateCount": 90
    },
    "bankAccount": {
      "maxTitleLength": 100,
      "maxBeneficiaryNameLength": 100,
      "maxAccountNumberLength": 30,
      "maxIbanNumberLength": 50,
      "maxBankAddressLength": 1000,
      "maxBranchNameLength": 50,
      "maxBranchCodeLength": 10,
      "maxSwiftCodeLength": 11,
      "maxSortCodeLength": 8,
      "maxBsbCodeLength": 7,
      "maxAbaRoutingNumberLength": 9,
      "maxMemoLength": 1000
    },
    "bookkeeping": {
      "maxNameLength": 50,
      "maxTextForTemplateLength": 2500
    },
    "bookingRequest": {
      "maxNoteLength": 2500
    },
    "brand": {
      "noLogoUploaded": "https://desktopsystemone.azureedge.net/images/noavataruploaded.png",
      "noIconUploaded": "https://desktopsystemone.azureedge.net/images/noclienticonuploaded.png",
      "maxNameLength": 50,
      "minNameLength": 2,
      "maxDomainLength": 100,
      "maxBrandsPerClient": 3,
      "maxBrandLogoWidth": 1000,
      "maxBrandLogoHeight": 500,
      "maxBrandIconWidth": 500,
      "maxBrandIconHeight": 500
    },
    "businessCentral": {
      "maxProjectDisplayNameLength": 65,
      "maxProjectCodeLength": 30
    },
    "checklist": {
      "maxQuestionLength": 100,
      "maxDeclinedReasonLength": 2000
    },
    "client": {
      "maxNameLength": 50,
      "maxDiscountReasonLength": 255
    },
    "clientOnboarding": {
      "expirationInDays": 30
    },
    "colors": {
      "red": "#ff005c",
      "green": "#23d2a0",
      "yellow": "#ffcc02",
      "purple": "#b48cfa",
      "blue": "#46a0fa",
      "darkGrey": "#3e3e3e",
      "white": "#FFFFFF"
    },
    "company": {
      "maxNameLength": 100,
      "maxPhoneLength": 50,
      "maxEmailLength": 200,
      "maxFacebookLength": 200,
      "maxInternalIdLength": 25,
      "maxTaxNumberLength": 50,
      "maxBusinessRegistrationNumberLength": 50,
      "financialRiskCommentMaxLength": 3000,
      "maxNetworkPathLength": 200,
      "maxPageSize": 250,
      "maxAdditionalLength": 2500,
      "minGooglePlaceIdLength": 10,
      "maxGooglePlaceIdLength": 400,
      "maxBusinessCentralNumberLength": 30
    },
    "contact": {
      "noProfileImageUploaded": "https://desktopsystemone.azureedge.net/images/noavataruploaded.png",
      "maxPageSize": 250,
      "maxAttnLength": 128,
      "maxSalutationLength": 128,
      "maxPositionLength": 100,
      "maxDepartmentLength": 100,
      "maxBirthPlaceLength": 50,
      "maxSocialSecurityLength": 50,
      "maxNationalityLength": 50,
      "maxNetworkPathLength": 200,
      "maxAdditionalLength": 2500,
      "maxProfileImageWidth": 1000,
      "maxProfileImageHeight": 1000
    },
    "costCenter": {
      "maxTitleLength": 100,
      "maxInternalIdLength": 30
    },
    "document": {
      "maxSubjectLength": 2000,
      "maxTextLength": 2000,
      "maxPersonalMessageLength": 20000,
      "maxPageSize": 250,
      "maxDueDateInterval": 100
    },
    "eSign": {
      "maxReminders": 3,
      "maxReminderInterval": 90,
      "maxSignatureImageWidth": 1000,
      "maxSignatureImageHeight": 500
    },
    "eSignFile": {
      "maxFileThatNeedsToBeSignedContentLength": 10485760,
      "maxOtherFileContentLength": 209715200,
      "filesThatNeedToBeSignedLimit": 10,
      "otherFilesLimit": 15,
      "maxPdfCompletedContentLength": 209715200
    },
    "eSignParticipant": {
      "maxViewersLimit": 10,
      "viewersPageSize": 10
    },
    "excelTemplate": {
      "maxTitleLength": 60
    },
    "externalCalendar": {
      "maxTimezoneLength": 50,
      "maxTitleLength": 100,
      "maxICalendarUrlLength": 2000,
      "maxEventCount": 3000,
      "defaultMonthsInPast": 2,
      "defaultMonthsInFuture": 24
    },
    "externalEvent": {
      "maxSummaryLength": 75,
      "maxDescriptionLength": 2500,
      "maxLocationLength": 150,
      "maxUIDLength": 255
    },
    "favoriteSubject": {
      "maxSubjectLength": 25
    },
    "file": {
      "maxNameLength": 100,
      "maxExtensionLength": 7,
      "maximumClientFileContentLength": 209715200,
      "maximumTravelFileContentLength": 10485760,
      "maximumImageFileContentLength": 10485760,
      "defaultMaximumRequestBodySize": 3145728
    },
    "filterPreset": {
      "maxTitleLength": 50
    },
    "flight": {
      "maxNotesLength": 2500,
      "maxBookingCodeLength": 50,
      "maxTravelAgencyLength": 50,
      "maxAircraftLength": 50,
      "maxGateLength": 5,
      "maxTerminalLength": 5,
      "maxETicketLength": 500,
      "maxSeatsLength": 50,
      "maxPrivateJetAccessLength": 500,
      "maxPrivateJetConfirmationNumberLength": 50
    },
    "freeField": {
      "maxMultiLineTextLength": 2500,
      "maxSingleLineTextLength": 255
    },
    "freeFieldProfile": {
      "maxTitleLength": 100
    },
    "groundTransport": {
      "maxNotesLength": 2500,
      "maxDriverNameLength": 100,
      "maxDriverMobileLength": 100,
      "maxVehicleModelLength": 100,
      "maxGoogleDirectionsStatusLength": 100,
      "maxGooglePlaceIdLength": 255,
      "maxGooglePlaceNameLength": 100,
      "maxGooglePlaceAddressLength": 500
    },
    "guestlist": {
      "maxPassportLength": 20,
      "maxPhoneLength": 50,
      "maxPlaceCustomNameLength": 50,
      "maxGuestsPerList": 500
    },
    "iCalendarFeed": {
      "maxSummaryLength": 500,
      "maxDescriptionLength": 5000,
      "cacheDuration": 30
    },
    "iCalendarTemplate": {
      "maxTitleLength": 60,
      "maxSubjectLength": 255,
      "maxBodyLength": 2500
    },
    "invoice": {
      "maxReminders": 3,
      "maxReminderInterval": 90,
      "maxDueDateInterval": 100
    },
    "invoiceParticipant": {
      "maxViewersLimit": 5,
      "viewersPageSize": 5
    },
    "layout": {
      "mobileBreakpoint": 768,
      "desktopSmallBreakpoint": 1024,
      "desktopMediumBreakpoint": 1390,
      "desktopWide": 1920
    },
    "note": {
      "minLastModifiedUtc": "1990-01-01T00:00:00Z",
      "maxTitleLength": 100,
      "maxBodyLength": 10000
    },
    "participant": {
      "maxReminders": 3,
      "maxReminderInterval": 90,
      "maxDueDateInterval": 100,
      "maxPersonalMessageSubjectLength": 255,
      "maxPersonalMessageBodyLength": 15000
    },
    "passport": {
      "maxPassportNameLength": 100,
      "maxPassportNumberLength": 50
    },
    "path": {
      "signInRelative": "/account/login"
    },
    "payment": {
      "maxDescriptionLength": 100,
      "maxPageSize": 250
    },
    "pdfApi": {
      "minFontSize": 7,
      "maxFontSize": 50
    },
    "planningMap": null,
    "messageTemplate": {
      "maxTitleLength": 45,
      "maxTranslationSubjectLength": 255,
      "maxTranslationBodyLength": 15000
    },
    "mailAddress": {
      "maxEmailLength": 100,
      "maxExportPageSize": 100000
    },
    "mySelection": {
      "maxItemsInMySelection": 500
    },
    "emailMessage": {
      "maxAttachedBytes": 10485760,
      "maxSubjectLength": 255,
      "maxBodyLength": 15000,
      "downloadLinkExpiration": 60,
      "maxFileDownloads": 50
    },
    "personName": {
      "maxFirstNameLength": 30,
      "maxInsertionLength": 15,
      "maxLastNameLength": 50
    },
    "product": {
      "maxDescriptionLength": 2500,
      "maxTitleLength": 100,
      "maxUnitLength": 50
    },
    "productGroup": {
      "maxTitleLength": 30
    },
    "productTemplate": {
      "maxLedgerNumberLength": 8,
      "maxDescriptionLength": 2500,
      "maxTitleLength": 100,
      "maxTitleForTemplateLength": 100,
      "maxUnitLength": 50
    },
    "production": {
      "maxNameLength": 100,
      "maxPageSize": 250
    },
    "recentlyUsed": {
      "maxArtistListSize": 15,
      "maxContactListSize": 15
    },
    "show": {
      "maxEventNameLength": 255,
      "maxInternalCodeLength": 100,
      "maxTimeOfPerformanceLength": 100,
      "maxPageSize": 250,
      "maxCopies": 50
    },
    "status": {
      "maxPerClient": 70,
      "maxTitleLength": 60
    },
    "tag": {
      "maxItemCount": 400,
      "maxTitleLength": 60,
      "maxCodeLength": 10
    },
    "train": {
      "maxNotesLength": 2500,
      "maxBookingCodeLength": 50,
      "maxTravelAgencyLength": 100,
      "maxTrainNumberLength": 10,
      "maxCarriageLength": 5,
      "maxSeatsLength": 50,
      "maxPlatformLength": 5
    },
    "user": {
      "noUserAvatarUploaded": "https://desktopsystemone.azureedge.net/images/noavataruploaded.png",
      "minUsernameLength": 3,
      "maxUsernameLength": 60,
      "maxSecretAnswerLength": 60
    },
    "userTask": {
      "minDeadline": "2000-01-01T00:00:00Z",
      "maxTitleLength": 100,
      "maxBodyLength": 10000,
      "minDeadlineOffset": -365,
      "maxDeadlineOffset": 365
    },
    "venue": {
      "maxNameLength": 100,
      "maxVehicleAccessLength": 150,
      "maxParkingLength": 150,
      "maxGearAccessLength": 150,
      "maxPowerLength": 150,
      "maxLightingLength": 150,
      "maxDressingRoomsLength": 150,
      "maxNoiseRestrictionsLength": 150,
      "maxCapacity": 999999
    },
    "wordTemplate": {
      "maxAttachedBytes": 10485760,
      "maxTitleLength": 60
    }
  },
  "supportArticleIds": {
    "accountReprimandedForCheating": 372,
    "addressLookupPending": 291,
    "artistProfile": 367,
    "autoSign": 480,
    "bookingRequests": 169,
    "bookkeepingResetPerYear": 174,
    "branding": 225,
    "bookkeepingCode": 566,
    "bookkeepingCountry": 567,
    "bookkeepingCurrency": 568,
    "cannotFindAddressOnMap": 195,
    "changeMyBillingDetails": 286,
    "configureChecklistPresets": 410,
    "configureFreeFieldsForMobileApp": 226,
    "createAccountFromESign": 417,
    "embedShowsOnYourSite": 244,
    "eSignOtherFiles": 393,
    "excelTemplates": 525,
    "exchangeRates": 293,
    "externalUserPermissions": 283,
    "freeFieldPermissionProfile": 294,
    "helpWithSignIn": 541,
    "hideArtistFromFilters": 197,
    "howDoesTheAvailabilityCheckWork": 159,
    "howToCreateICalendarFeeds": 227,
    "iCalendarFeeds": 284,
    "internalUserPermissions": 282,
    "invitationAcceptedBySomeoneElse": 384,
    "invoiceExport": 201,
    "legacyXmlEventFeeds": 297,
    "lockShows": 252,
    "newBookkeeping": 383,
    "paymentMethod": 492,
    "pdfsThatNeedToBeSigned": 395,
    "promoterProfile": 369,
    "readOnlySubscription": 290,
    "requirements": 296,
    "showTotals": 292,
    "templatesESignReady": 388,
    "transferToOtherUser": 285,
    "unitInProduct": 157,
    "usersInSubscription": 287,
    "uStIdBestaetigung": 203,
    "vatRates": 162,
    "venueProfile": 368,
    "whatAreBookingRequestForms": 170,
    "whatArePersonalMessageTemplates": 407,
    "whatAreReminders": 481,
    "whatAreVersions": 394,
    "whatAreSignatories": 396,
    "whatAreSocialLogins": 254,
    "whatAreSystemWideFiles": 408,
    "whatAreViewers": 434,
    "whatIsABankAccount": 429,
    "whatIsAChecklist": 409,
    "whatIsAChecklistPreset": 410,
    "whatIsAdvancing": 206,
    "whatIsATimetable": 424,
    "whatIsESigning": 404,
    "whatIsProductType": 161,
    "whatIsTheLedgerFor": 163
  }
};
window.RESX = {
  "AppointmentTypes": {
    "Accommodation": "Accomodation",
    "ExternalCalendar": "Externer Kalender",
    "Flight": "Flug",
    "GroundTransport": "Ground transport",
    "Show": "Buchung",
    "Train": "Zugreise"
  },
  "BrowserFamilyName": {
    "Android": "Android",
    "Chrome": "Chrome",
    "ChromeMobile": "Chrome mobile",
    "Edge": "Edge",
    "Facebook": "Facebook",
    "Firefox": "Firefox",
    "FirefoxMobile": "Firefox mobile",
    "IE": "Internet Explorer",
    "Opera": "Opera",
    "Safari": "Safari",
    "SafariMobile": "Safari mobile",
    "SamsungBrowser": "Samsung",
    "Undetermined": "Undetermined"
  },
  "ClientAccessHexColors": {
    "ActiveFree": "#3498db",
    "ActivePaid": "#17b699",
    "ActiveReadOnly": "#7d4000",
    "ActiveTrial": "#d35400",
    "CancelledByClient": "#c21902",
    "CancelledBySystemOne": "#c0392b",
    "Paused": "#8e44ad",
    "SuspendedForAbuse": "#7b241c",
    "SuspendedForNotPaying": "#943126",
    "TemporarilyOpened": "#ec0fb5",
    "TrialEnd": "#2980b9",
    "Unknown": "#000000"
  },
  "ClientAccessLabels": {
    "ActiveFree": "Free",
    "ActivePaid": "Normal",
    "ActiveReadOnly": "Read-only",
    "ActiveTrial": "Trial",
    "CancelledByClient": "Cancelled by client",
    "CancelledBySystemOne": "Cancelled by SystemOne",
    "Paused": "Paused",
    "SuspendedForAbuse": "Suspended for abuse",
    "SuspendedForNotPaying": "Suspended for not paying",
    "TemporarilyOpened": "Temporarily opened",
    "TrialEnd": "Trial end"
  },
  "ClientAccessUserMessage": {
    "ActiveFree": "{client} has a running subscription (free).",
    "ActivePaid": "{client} has a running subscription.",
    "ActiveReadOnly": "{client} has a read-only subscription.",
    "ActiveTrial": "{client} has a trial subscription.",
    "CancelledByClient": "{client} has cancelled their subscription.",
    "CancelledBySystemOne": "The subscription for {client} has been cancelled by SystemOne. Contact billing@systemonesoftware.com for more information.",
    "Paused": "Per request of {client}, the account has been temporarily suspended",
    "SuspendedForAbuse": "Access for {client} has been temporarily suspended because there have been violations of the agreed-upon terms and conditions. Contact support@systemonesoftware.com for more information.",
    "SuspendedForNotPaying": "Access for {client} has been suspended because subscription fees are over due. Contact billing@systemonesoftware.com for more information.",
    "TemporarilyOpened": "The subscription for {client} has been temporarily opened.",
    "TrialEnd": "The trial for {client} has ended. Contact support@systemonesoftware.com for more information."
  },
  "CountryCallingCodes": {
    "Afghanistan": "+93",
    "ÅlandIslands": "+358",
    "Albania": "+355",
    "Algeria": "+213",
    "AmericanSamoa": "+1684",
    "Andorra": "+376",
    "Angola": "+244",
    "Anguilla": "+1264",
    "Antarctica": "+0",
    "AntiguaandBarbuda": "+1268",
    "Argentina": "+54",
    "Armenia": "+374",
    "Aruba": "+297",
    "Australia": "+61",
    "Austria": "+43",
    "Azerbaijan": "+994",
    "Bahamas": "+1242",
    "Bahrain": "+973",
    "Bangladesh": "+880",
    "Barbados": "+1246",
    "Belarus": "+375",
    "Belgium": "+32",
    "Belize": "+501",
    "Benin": "+229",
    "Bermuda": "+1441",
    "Bhutan": "+975",
    "Bolivia": "+591",
    "BonaireSintEustatiusandSaba": "+599",
    "BosniaandHerzegovina": "+387",
    "Botswana": "+267",
    "BouvetIsland": "+0",
    "Brazil": "+55",
    "BritishIndianOceanTerritory": "+246",
    "BritishVirginIslands": "+1284",
    "BruneiDarussalam": "+673",
    "Bulgaria": "+359",
    "BurkinaFaso": "+226",
    "Burundi": "+257",
    "Cambodia": "+855",
    "Cameroon": "+237",
    "Canada": "+1",
    "CapeVerde": "+238",
    "CaymanIslands": "+1345",
    "CentralAfricanRepublic": "+236",
    "Chad": "+235",
    "Chile": "+56",
    "China": "+86",
    "ChristmasIsland": "+61",
    "CocosKeelingIslands": "+61891",
    "Colombia": "+57",
    "Comoros": "+2690",
    "Congo": "+242",
    "CongoKinshasa": "+243",
    "CookIslands": "+6820",
    "CostaRica": "+506",
    "Croatia": "+385",
    "Cuba": "+53",
    "Curaçao": "+599",
    "Cyprus": "+357",
    "CzechRepublic": "+420",
    "Denmark": "+45",
    "Djibouti": "+253",
    "Dominica": "+1767",
    "DominicanRepublic": "+1809",
    "Ecuador": "+593",
    "Egypt": "+20",
    "ElSalvador": "+503",
    "England": "+44",
    "EquatorialGuinea": "+240",
    "Eritrea": "+291",
    "Estonia": "+372",
    "Ethiopia": "+251",
    "FalklandIslandsMalvinas": "+500",
    "FaroeIslands": "+298",
    "Fiji": "+679",
    "Finland": "+358",
    "France": "+33",
    "FrenchGuiana": "+0",
    "FrenchPolynesia": "+689",
    "FrenchSouthernTerritories": "+0",
    "Gabon": "+241",
    "Gambia": "+220",
    "Georgia": "+995",
    "Germany": "+49",
    "Ghana": "+233",
    "Gibraltar": "+350",
    "Greece": "+30",
    "Greenland": "+229",
    "Grenada": "+1473",
    "Guadeloupe": "+0",
    "Guam": "+1671",
    "Guatemala": "+502",
    "Guernsey": "+44",
    "Guinea": "+224",
    "GuineaBissau": "+0",
    "Guyana": "+592",
    "Haiti": "+509",
    "HeardandMcDonaldIslands": "+0",
    "Honduras": "+504",
    "HongKong": "+852",
    "Hungary": "+36",
    "Iceland": "+354",
    "India": "+91",
    "Indonesia": "+62",
    "Iran": "+98",
    "Iraq": "+964",
    "Ireland": "+353",
    "IsleOfMan": "+44",
    "Israel": "+972",
    "Italy": "+39",
    "IvoryCoast": "+225",
    "Jamaica": "+1876",
    "Japan": "+81",
    "Jersey": "+44153",
    "Jordan": "+962",
    "Kazakhstan": "+76",
    "Kenya": "+254",
    "Kiribati": "+686",
    "Kosovo": "+381",
    "Kuwait": "+965",
    "Kyrgyzstan": "+996",
    "Lao": "+856",
    "Latvia": "+371",
    "Lebanon": "+961",
    "Lesotho": "+266",
    "Liberia": "+231",
    "LibyanArabJamahiriya": "+218",
    "Liechtenstein": "+423",
    "Lithuania": "+370",
    "Luxembourg": "+352",
    "Macao": "+853",
    "Madagaskar": "+261",
    "Malawi": "+265",
    "Malaysia": "+60",
    "Maldives": "+960",
    "Mali": "+223",
    "Malta": "+356",
    "MarshallIslands": "+692",
    "Martinique": "+33",
    "Mauritania": "+222",
    "Mauritius": "+230",
    "Mayotte": "+262",
    "Mexico": "+52",
    "Micronesia": "+691",
    "Moldova": "+373",
    "Monaco": "+377",
    "Mongolia": "+976",
    "Monserrat": "+1664",
    "Montenegro": "+382",
    "Morocco": "+212",
    "Mozambique": "+258",
    "MyanmarBurma": "+95",
    "Namibia": "+264",
    "Nauru": "+674",
    "Nepal": "+977",
    "Netherlands": "+31",
    "NewCaledonia": "+687",
    "NewZealand": "+64",
    "Nicaragua": "+505",
    "Niger": "+227",
    "Nigeria": "+234",
    "Niue": "+683",
    "NorfolkIslands": "+672",
    "NorthernIreland": "+44",
    "NorthernMarianaIslands": "+1670",
    "NorthKorea": "+850",
    "NorthMacedonia": "+389",
    "Norway": "+47",
    "Oman": "+968",
    "Pakistan": "+92",
    "Palau": "+680",
    "Palestine": "+970",
    "Panama": "+507",
    "PapuaNewGuinea": "+675",
    "Paraguay": "+595",
    "Peru": "+51",
    "Philippines": "+63",
    "PitcairnIslands": "+0",
    "Poland": "+48",
    "Portugal": "+351",
    "PuertoRico": "+1939",
    "Qatar": "+974",
    "Réunion": "+0",
    "Romania": "+40",
    "Russia": "+7",
    "Rwanda": "+250",
    "SaintHelena": "+290",
    "SaintKittsandNevis": "+1869",
    "SaintLucia": "+1758",
    "SaintMartin": "+1721",
    "SaintPierreetMiquelon": "+508",
    "SaintVincentandtheGrenadines": "+1784",
    "Samoa": "+685",
    "SanMarino": "+378",
    "SaoTomeandPrincipe": "+239",
    "SaudiArabia": "+966",
    "Scotland": "+44",
    "Senegal": "+221",
    "Serbia": "+381",
    "Seychelles": "+248",
    "SierraLeone": "+232",
    "Singapore": "+65",
    "Slovakia": "+421",
    "Slovenia": "+386",
    "SolomonIslands": "+677",
    "Somalia": "+252",
    "SouthAfrica": "+27",
    "SouthGeorgiaandtheSouthSandwichIslands": "+0",
    "SouthKorea": "+82",
    "SouthSudan": "+211",
    "Spain": "+34",
    "SriLanka": "+94",
    "Sudan": "+249",
    "Suriname": "+597",
    "Svalbard": "+47",
    "Swaziland": "+268",
    "Sweden": "+46",
    "Switzerland": "+41",
    "SyrianArabRepublic": "+963",
    "Taiwan": "+886",
    "Tajikistan": "+992",
    "Tanzania": "+255",
    "Thailand": "+66",
    "TimorLesteEastTimor": "+670",
    "Togo": "+228",
    "Tokelau": "+690",
    "Tonga": "+676",
    "TrinidadandTobago": "+1868",
    "Tunisia": "+216",
    "Turkey": "+90",
    "Turkmenistan": "+993",
    "TurksandCaicosIslands": "+1649",
    "Tuvalu": "+688",
    "Uganda": "+256",
    "Ukraine": "+380",
    "UnitedArabEmirates": "+971",
    "UnitedStates": "+1",
    "UnitedStatesMinorOutlyingIslandsIsle": "+0",
    "Uruguay": "+598",
    "Uzbekistan": "+998",
    "Vanuatu": "+678",
    "VaticanCityState": "+379",
    "Venezuela": "+58",
    "Vietnam": "+84",
    "VirginIslandsUS": "+1340",
    "Wales": "+44",
    "WallisandFutuna": "+681",
    "WesternSahara": "+212",
    "Yemen": "+967",
    "Zambia": "+260",
    "Zimbabwe": "+263"
  },
  "CountryISO3166Codes": {
    "Afghanistan": "AF",
    "ÅlandIslands": "AX",
    "Albania": "AL",
    "Algeria": "DZ",
    "AmericanSamoa": "AS",
    "Andorra": "AD",
    "Angola": "AO",
    "Anguilla": "AI",
    "Antarctica": "AQ",
    "AntiguaandBarbuda": "AG",
    "Argentina": "AR",
    "Armenia": "AM",
    "Aruba": "AW",
    "Australia": "AU",
    "Austria": "AT",
    "Azerbaijan": "AZ",
    "Bahamas": "BS",
    "Bahrain": "BH",
    "Bangladesh": "BD",
    "Barbados": "BB",
    "Belarus": "BY",
    "Belgium": "BE",
    "Belize": "BZ",
    "Benin": "BJ",
    "Bermuda": "BM",
    "Bhutan": "BT",
    "Bolivia": "BO",
    "BonaireSintEustatiusandSaba": "BQ",
    "BosniaandHerzegovina": "BA",
    "Botswana": "BW",
    "BouvetIsland": "BV",
    "Brazil": "BR",
    "BritishIndianOceanTerritory": "IO",
    "BritishVirginIslands": "VG",
    "BruneiDarussalam": "BN",
    "Bulgaria": "BG",
    "BurkinaFaso": "BF",
    "Burundi": "BI",
    "Cambodia": "KH",
    "Cameroon": "CM",
    "Canada": "CA",
    "CapeVerde": "CV",
    "CaymanIslands": "KY",
    "CentralAfricanRepublic": "CF",
    "Chad": "TD",
    "Chile": "CL",
    "China": "CN",
    "ChristmasIsland": "CX",
    "CocosKeelingIslands": "CC",
    "Colombia": "CO",
    "Comoros": "KM",
    "Congo": "CG",
    "CongoKinshasa": "CD",
    "CookIslands": "CK",
    "CostaRica": "CR",
    "Croatia": "HR",
    "Cuba": "CU",
    "Curaçao": "CW",
    "Cyprus": "CY",
    "CzechRepublic": "CZ",
    "Denmark": "DK",
    "Djibouti": "DJ",
    "Dominica": "DM",
    "DominicanRepublic": "DO",
    "Ecuador": "EC",
    "Egypt": "EG",
    "ElSalvador": "SV",
    "England": "GB",
    "EquatorialGuinea": "GQ",
    "Eritrea": "ER",
    "Estonia": "EE",
    "Ethiopia": "ET",
    "FalklandIslandsMalvinas": "FK",
    "FaroeIslands": "FO",
    "Fiji": "FJ",
    "Finland": "FI",
    "France": "FR",
    "FrenchGuiana": "GF",
    "FrenchPolynesia": "PF",
    "FrenchSouthernTerritories": "TF",
    "Gabon": "GA",
    "Gambia": "GM",
    "Georgia": "GE",
    "Germany": "DE",
    "Ghana": "GH",
    "Gibraltar": "GI",
    "Greece": "GR",
    "Greenland": "GL",
    "Grenada": "GD",
    "Guadeloupe": "GP",
    "Guam": "GU",
    "Guatemala": "GT",
    "Guernsey": "GG",
    "Guinea": "GN",
    "GuineaBissau": "GW",
    "Guyana": "GY",
    "Haiti": "HT",
    "HeardandMcDonaldIslands": "HM",
    "Honduras": "HN",
    "HongKong": "HK",
    "Hungary": "HU",
    "Iceland": "IS",
    "India": "IN",
    "Indonesia": "ID",
    "Iran": "IR",
    "Iraq": "IQ",
    "Ireland": "IE",
    "IsleOfMan": "IM",
    "Israel": "IL",
    "Italy": "IT",
    "IvoryCoast": "CI",
    "Jamaica": "JM",
    "Japan": "JP",
    "Jersey": "JE",
    "Jordan": "JO",
    "Kazakhstan": "KZ",
    "Kenya": "KE",
    "Kiribati": "KI",
    "Kosovo": "XK",
    "Kuwait": "KW",
    "Kyrgyzstan": "KG",
    "Lao": "LA",
    "Latvia": "LV",
    "Lebanon": "LB",
    "Lesotho": "LS",
    "Liberia": "LR",
    "LibyanArabJamahiriya": "LY",
    "Liechtenstein": "LI",
    "Lithuania": "LT",
    "Luxembourg": "LU",
    "Macao": "MO",
    "Madagaskar": "MG",
    "Malawi": "MW",
    "Malaysia": "MY",
    "Maldives": "MV",
    "Mali": "ML",
    "Malta": "MT",
    "MarshallIslands": "MH",
    "Martinique": "MQ",
    "Mauritania": "MR",
    "Mauritius": "MU",
    "Mayotte": "YT",
    "Mexico": "MX",
    "Micronesia": "FM",
    "Moldova": "MD",
    "Monaco": "MC",
    "Mongolia": "MN",
    "Monserrat": "MS",
    "Montenegro": "ME",
    "Morocco": "MA",
    "Mozambique": "MZ",
    "MyanmarBurma": "MM",
    "Namibia": "NA",
    "Nauru": "NR",
    "Nepal": "NP",
    "Netherlands": "NL",
    "NewCaledonia": "NC",
    "NewZealand": "NZ",
    "Nicaragua": "NI",
    "Niger": "NE",
    "Nigeria": "NG",
    "Niue": "NU",
    "NorfolkIslands": "NF",
    "NorthernIreland": "GB",
    "NorthernMarianaIslands": "MP",
    "NorthKorea": "KP",
    "NorthMacedonia": "MK",
    "Norway": "NO",
    "Oman": "OM",
    "Pakistan": "PK",
    "Palau": "PW",
    "Palestine": "PS",
    "Panama": "PA",
    "PapuaNewGuinea": "PG",
    "Paraguay": "PY",
    "Peru": "PE",
    "Philippines": "PH",
    "PitcairnIslands": "PN",
    "Poland": "PL",
    "Portugal": "PT",
    "PuertoRico": "PR",
    "Qatar": "QA",
    "Réunion": "RE",
    "Romania": "RO",
    "Russia": "RU",
    "Rwanda": "RW",
    "SaintHelena": "SH",
    "SaintKittsandNevis": "KN",
    "SaintLucia": "LC",
    "SaintMartin": "SX",
    "SaintPierreetMiquelon": "PM",
    "SaintVincentandtheGrenadines": "VC",
    "Samoa": "WS",
    "SanMarino": "SM",
    "SaoTomeandPrincipe": "ST",
    "SaudiArabia": "SA",
    "Scotland": "GB",
    "Senegal": "SN",
    "Serbia": "RS",
    "Seychelles": "SC",
    "SierraLeone": "SL",
    "Singapore": "SG",
    "Slovakia": "SK",
    "Slovenia": "SI",
    "SolomonIslands": "SB",
    "Somalia": "SO",
    "SouthAfrica": "ZA",
    "SouthGeorgiaandtheSouthSandwichIslands": "GS",
    "SouthKorea": "KR",
    "SouthSudan": "SS",
    "Spain": "ES",
    "SriLanka": "LK",
    "Sudan": "SD",
    "Suriname": "SR",
    "Svalbard": "SJ",
    "Swaziland": "SZ",
    "Sweden": "SE",
    "Switzerland": "CH",
    "SyrianArabRepublic": "SY",
    "Taiwan": "TW",
    "Tajikistan": "TJ",
    "Tanzania": "TZ",
    "Thailand": "TH",
    "TimorLesteEastTimor": "TL",
    "Togo": "TG",
    "Tokelau": "TK",
    "Tonga": "TO",
    "TrinidadandTobago": "TT",
    "Tunisia": "TN",
    "Turkey": "TR",
    "Turkmenistan": "TM",
    "TurksandCaicosIslands": "TC",
    "Tuvalu": "TV",
    "Uganda": "UG",
    "Ukraine": "UA",
    "UnitedArabEmirates": "AE",
    "UnitedStates": "US",
    "UnitedStatesMinorOutlyingIslandsIsle": "UM",
    "Uruguay": "UY",
    "Uzbekistan": "UZ",
    "Vanuatu": "VU",
    "VaticanCityState": "VA",
    "Venezuela": "VE",
    "Vietnam": "VN",
    "VirginIslandsUS": "VI",
    "Wales": "GB",
    "WallisandFutuna": "WF",
    "WesternSahara": "EH",
    "Yemen": "YE",
    "Zambia": "ZM",
    "Zimbabwe": "ZW"
  },
  "CountryRegions": {
    "Afghanistan": "Asia",
    "ÅlandIslands": "Europe",
    "Albania": "Europe",
    "Algeria": "Africa",
    "AmericanSamoa": "Oceania",
    "Andorra": "Europe",
    "Angola": "Africa",
    "Anguilla": "NorthAmerica",
    "Antarctica": "Oceania",
    "AntiguaandBarbuda": "NorthAmerica",
    "Argentina": "SouthAmerica",
    "Armenia": "Asia",
    "Aruba": "NorthAmerica",
    "Australia": "Oceania",
    "Austria": "Europe",
    "Azerbaijan": "Asia",
    "Bahamas": "NorthAmerica",
    "Bahrain": "MiddleEast",
    "Bangladesh": "Asia",
    "Barbados": "NorthAmerica",
    "Belarus": "Europe",
    "Belgium": "Europe",
    "Belize": "CentralAmerica",
    "Benin": "Africa",
    "Bermuda": "NorthAmerica",
    "Bhutan": "Asia",
    "Bolivia": "SouthAmerica",
    "BonaireSintEustatiusandSaba": "NorthAmerica",
    "BosniaandHerzegovina": "Europe",
    "Botswana": "Africa",
    "BouvetIsland": "Oceania",
    "Brazil": "SouthAmerica",
    "BritishIndianOceanTerritory": "Asia",
    "BritishVirginIslands": "NorthAmerica",
    "BruneiDarussalam": "Asia",
    "Bulgaria": "Europe",
    "BurkinaFaso": "Africa",
    "Burundi": "Africa",
    "Cambodia": "Asia",
    "Cameroon": "Africa",
    "Canada": "NorthAmerica",
    "CapeVerde": "Africa",
    "CaymanIslands": "NorthAmerica",
    "CentralAfricanRepublic": "Africa",
    "Chad": "Africa",
    "Chile": "SouthAmerica",
    "China": "Asia",
    "ChristmasIsland": "Asia",
    "CocosKeelingIslands": "Asia",
    "Colombia": "SouthAmerica",
    "Comoros": "Africa",
    "Congo": "Africa",
    "CongoKinshasa": "Africa",
    "CookIslands": "Oceania",
    "CostaRica": "CentralAmerica",
    "Croatia": "Europe",
    "Cuba": "NorthAmerica",
    "Curaçao": "NorthAmerica",
    "Cyprus": "MiddleEast",
    "CzechRepublic": "Europe",
    "Denmark": "Europe",
    "Djibouti": "Africa",
    "Dominica": "NorthAmerica",
    "DominicanRepublic": "NorthAmerica",
    "Ecuador": "SouthAmerica",
    "Egypt": "MiddleEast",
    "ElSalvador": "CentralAmerica",
    "England": "Europe",
    "EquatorialGuinea": "Africa",
    "Eritrea": "Africa",
    "Estonia": "Europe",
    "Ethiopia": "Africa",
    "FalklandIslandsMalvinas": "SouthAmerica",
    "FaroeIslands": "Europe",
    "Fiji": "Oceania",
    "Finland": "Europe",
    "France": "Europe",
    "FrenchGuiana": "SouthAmerica",
    "FrenchPolynesia": "Oceania",
    "FrenchSouthernTerritories": "Oceania",
    "Gabon": "Africa",
    "Gambia": "Africa",
    "Georgia": "Asia",
    "Germany": "Europe",
    "Ghana": "Africa",
    "Gibraltar": "Europe",
    "Greece": "Europe",
    "Greenland": "NorthAmerica",
    "Grenada": "NorthAmerica",
    "Guadeloupe": "NorthAmerica",
    "Guam": "Oceania",
    "Guatemala": "CentralAmerica",
    "Guernsey": "Europe",
    "Guinea": "Africa",
    "GuineaBissau": "Africa",
    "Guyana": "SouthAmerica",
    "Haiti": "NorthAmerica",
    "HeardandMcDonaldIslands": "Oceania",
    "Honduras": "CentralAmerica",
    "HongKong": "Asia",
    "Hungary": "Europe",
    "Iceland": "Europe",
    "India": "Asia",
    "Indonesia": "Asia",
    "Iran": "MiddleEast",
    "Iraq": "MiddleEast",
    "Ireland": "Europe",
    "IsleOfMan": "Europe",
    "Israel": "MiddleEast",
    "Italy": "Europe",
    "IvoryCoast": "Africa",
    "Jamaica": "NorthAmerica",
    "Japan": "Asia",
    "Jersey": "Europe",
    "Jordan": "MiddleEast",
    "Kazakhstan": "Asia",
    "Kenya": "Africa",
    "Kiribati": "Oceania",
    "Kosovo": "Europe",
    "Kuwait": "MiddleEast",
    "Kyrgyzstan": "Asia",
    "Lao": "Asia",
    "Latvia": "Europe",
    "Lebanon": "MiddleEast",
    "Lesotho": "Africa",
    "Liberia": "Africa",
    "LibyanArabJamahiriya": "Africa",
    "Liechtenstein": "Europe",
    "Lithuania": "Europe",
    "Luxembourg": "Europe",
    "Macao": "Asia",
    "Madagaskar": "Africa",
    "Malawi": "Africa",
    "Malaysia": "Asia",
    "Maldives": "Asia",
    "Mali": "Africa",
    "Malta": "Europe",
    "MarshallIslands": "Oceania",
    "Martinique": "NorthAmerica",
    "Mauritania": "Africa",
    "Mauritius": "Africa",
    "Mayotte": "Africa",
    "Mexico": "NorthAmerica",
    "Micronesia": "Oceania",
    "Moldova": "Europe",
    "Monaco": "Europe",
    "Mongolia": "Asia",
    "Monserrat": "NorthAmerica",
    "Montenegro": "Europe",
    "Morocco": "Africa",
    "Mozambique": "Africa",
    "MyanmarBurma": "Asia",
    "Namibia": "Africa",
    "Nauru": "Oceania",
    "Nepal": "Asia",
    "Netherlands": "Europe",
    "NewCaledonia": "Oceania",
    "NewZealand": "Oceania",
    "Nicaragua": "CentralAmerica",
    "Niger": "Africa",
    "Nigeria": "Africa",
    "Niue": "Oceania",
    "NorfolkIslands": "Oceania",
    "NorthernIreland": "Europe",
    "NorthernMarianaIslands": "Oceania",
    "NorthKorea": "Asia",
    "NorthMacedonia": "Europe",
    "Norway": "Europe",
    "Oman": "MiddleEast",
    "Pakistan": "Asia",
    "Palau": "Oceania",
    "Palestine": "MiddleEast",
    "Panama": "CentralAmerica",
    "PapuaNewGuinea": "Oceania",
    "Paraguay": "SouthAmerica",
    "Peru": "SouthAmerica",
    "Philippines": "Asia",
    "PitcairnIslands": "Oceania",
    "Poland": "Europe",
    "Portugal": "Europe",
    "PuertoRico": "NorthAmerica",
    "Qatar": "MiddleEast",
    "Réunion": "Africa",
    "Romania": "Europe",
    "Russia": "Europe",
    "Rwanda": "Africa",
    "SaintHelena": "Africa",
    "SaintKittsandNevis": "NorthAmerica",
    "SaintLucia": "NorthAmerica",
    "SaintMartin": "NorthAmerica",
    "SaintPierreetMiquelon": "NorthAmerica",
    "SaintVincentandtheGrenadines": "NorthAmerica",
    "Samoa": "Oceania",
    "SanMarino": "Europe",
    "SaoTomeandPrincipe": "Africa",
    "SaudiArabia": "MiddleEast",
    "Scotland": "Europe",
    "Senegal": "Africa",
    "Serbia": "Europe",
    "Seychelles": "Africa",
    "SierraLeone": "Africa",
    "Singapore": "Asia",
    "Slovakia": "Europe",
    "Slovenia": "Europe",
    "SolomonIslands": "Oceania",
    "Somalia": "Africa",
    "SouthAfrica": "Africa",
    "SouthGeorgiaandtheSouthSandwichIslands": "Oceania",
    "SouthKorea": "Asia",
    "SouthSudan": "Africa",
    "Spain": "Europe",
    "SriLanka": "Asia",
    "Sudan": "Africa",
    "Suriname": "SouthAmerica",
    "Svalbard": "Europe",
    "Swaziland": "Africa",
    "Sweden": "Europe",
    "Switzerland": "Europe",
    "SyrianArabRepublic": "MiddleEast",
    "Taiwan": "Asia",
    "Tajikistan": "Asia",
    "Tanzania": "Africa",
    "Thailand": "Asia",
    "TimorLesteEastTimor": "Asia",
    "Togo": "Africa",
    "Tokelau": "Oceania",
    "Tonga": "Oceania",
    "TrinidadandTobago": "NorthAmerica",
    "Tunisia": "Africa",
    "Turkey": "MiddleEast",
    "Turkmenistan": "Asia",
    "TurksandCaicosIslands": "NorthAmerica",
    "Tuvalu": "Oceania",
    "Uganda": "Africa",
    "Ukraine": "Europe",
    "UnitedArabEmirates": "MiddleEast",
    "UnitedStates": "NorthAmerica",
    "UnitedStatesMinorOutlyingIslandsIsle": "Oceania",
    "Uruguay": "SouthAmerica",
    "Uzbekistan": "Asia",
    "Vanuatu": "Oceania",
    "VaticanCityState": "Europe",
    "Venezuela": "SouthAmerica",
    "Vietnam": "Asia",
    "VirginIslandsUS": "NorthAmerica",
    "Wales": "Europe",
    "WallisandFutuna": "Oceania",
    "WesternSahara": "Africa",
    "Yemen": "MiddleEast",
    "Zambia": "Africa",
    "Zimbabwe": "Africa"
  },
  "CurrencyName": {
    "AED": "United Arab Emirates Dirham",
    "ARS": "Argentine Peso",
    "AUD": "Australian Dollar",
    "BGN": "Bulgarian Lev",
    "BRL": "Brazil Real",
    "CAD": "Canadian Dollar",
    "CHF": "Swiss Franc",
    "CNY": "Chinese yuan",
    "CZK": "Czech Koruna",
    "DKK": "Danish Krone",
    "EUR": "Euro",
    "GBP": "British Pound",
    "HKD": "Hong Kong Dollar",
    "HUF": "Hungarian Forint",
    "ILS": "Israeli Shekel",
    "INR": "Indian Rupee",
    "ISK": "Icelandic Króna",
    "JPY": "Japanese Yen",
    "NGN": "Nigerian Naira",
    "NOK": "Norwegian Kroner",
    "NZD": "New Zealand Dollar",
    "PLN": "Polish złoty",
    "RON": "Romanian Leu",
    "RUB": "Russian Ruble",
    "SEK": "Swedish Krona",
    "THB": "Thai baht",
    "TRY": "Turkish Lira",
    "USD": "United States Dollar",
    "ZAR": "South African Rand"
  },
  "CustomTranslationDescriptions": {
    "Appointments": "Appointments",
    "Artist": "Artist",
    "Artists": "Artists",
    "Contract": "Contract",
    "Contracts": "Contracts",
    "EditContract": "Edit contract",
    "EditPurchaseAgreement": "Edit purchase agreement",
    "EditQuote": "Edit quote",
    "EditShow": "Edit show",
    "Hotel": "Hotel",
    "Hotels": "Hotels",
    "NewArtist": "New artist",
    "NewContract": "New contract",
    "NewHotel": "New hotel",
    "NewPromoter": "New promoter",
    "NewPurchaseAgreement": "New purchase agreement",
    "NewQuote": "New quote",
    "NewShow": "New show",
    "NewVenue": "New venue",
    "Promoter": "Promoter",
    "Promoters": "Promoters",
    "PurchaseAgreement": "Purchase agreement",
    "PurchaseAgreements": "Purchase agreements",
    "Quote": "Quote",
    "Quotes": "Quotes",
    "Show": "Show",
    "Shows": "Shows",
    "Venue": "Label for venue functiongroup",
    "Venues": "Venues",
    "WebformArtistFee": "Webform artist fee",
    "WebformEstimatedTimeOfPerformance": "Webform estimated time of performance",
    "WebformEventDetails": "Webform event details",
    "WebformNameOfEvent": "Webform name of event",
    "WebformPreferredDate": "Webform preferred date",
    "WebformPromoterCompanyName": "Webform promoter company name",
    "WebformVenueCompanyName": "Webform venue company name"
  },
  "ExportMasks": {
    "Bmd": "BMD",
    "BusinessCentral": "Business Central",
    "CountE": "Count E",
    "ExactGlobe": "Exact Globe",
    "ExactOnline": "Exact online",
    "ExportMask": "Export mask",
    "Generic": "Generic",
    "iMuis": "iMuis",
    "MYOB": "MYOB",
    "Octopus": "Octopus",
    "Quickbooks": "Quickbooks",
    "Reeleezee": "Reeleezee",
    "Sage": "Sage",
    "Snelstart": "Snelstart",
    "Unit4": "Unit 4",
    "Xero": "Xero"
  },
  "LanguageNames": {
    "Catalan": "Katalanisch",
    "Danish": "Dänisch",
    "Dutch": "Niederländisch",
    "English": "Englisch",
    "Finnish": "Finnisch",
    "French": "Französisch",
    "German": "Deutsch",
    "Greek": "Griechisch",
    "Hebrew": "Hebräisch",
    "Italian": "Italienisch",
    "Latvian": "Lettisch",
    "Lithuanian": "Litauisch",
    "Norwegian": "Norwegisch",
    "Polish": "Polnisch",
    "Portuguese": "Portugiesisch",
    "Russian": "Russisch",
    "Slovak": "Slowakisch",
    "Slovenian": "Slowenisch",
    "Spanish": "Spanisch",
    "Swedish": "Schwedisch",
    "Turkish": "Türkisch"
  },
  "NativeLanguageNames": {
    "Catalan": "Català",
    "Danish": "Dansk",
    "Dutch": "Nederlands",
    "English": "English",
    "Finnish": "Suomi",
    "French": "Français",
    "German": "Deutsch",
    "Greek": "ελληνικά",
    "Hebrew": "עברית",
    "Italian": "italiano",
    "Latvian": "Latviešu",
    "Lithuanian": "Lietuvių",
    "Norwegian": "Norsk",
    "Polish": "Polski",
    "Portuguese": "Português",
    "Russian": "Русский язык",
    "Slovak": "Slovenčina",
    "Slovenian": "Slovenščina",
    "Spanish": "Español",
    "Swedish": "Svenska",
    "Turkish": "Türkçe"
  },
  "TimeZoneNames": {
    "AfghanistanStandardTime": "Kabul",
    "AlaskanStandardTime": "Alaska",
    "AleutianStandardTime": "Aleutian Islands",
    "AltaiStandardTime": "Barnaul, Gorno-Altaysk",
    "ArabianStandardTime": "Abu Dhabi, Muscat",
    "ArabicStandardTime": "Baghdad",
    "ArabStandardTime": "Kuwait, Riyadh",
    "ArgentinaStandardTime": "City of Buenos Aires",
    "AstrakhanStandardTime": "Astrakhan, Ulyanovsk",
    "AtlanticStandardTime": "Atlantic Time (Canada)",
    "AUSCentralStandardTime": "Darwin",
    "AusCentralWStandardTime": "Eucla",
    "AUSEasternStandardTime": "Canberra, Melbourne, Sydney",
    "AzerbaijanStandardTime": "Baku",
    "AzoresStandardTime": "Azores",
    "BahiaStandardTime": "Salvador",
    "BangladeshStandardTime": "Dhaka",
    "BelarusStandardTime": "Minsk",
    "BougainvilleStandardTime": "Bougainville Island",
    "CanadaCentralStandardTime": "Saskatchewan",
    "CapeVerdeStandardTime": "Cabo Verde Is.",
    "CaucasusStandardTime": "Yerevan",
    "CenAustraliaStandardTime": "Adelaide",
    "CentralAmericaStandardTime": "Central America",
    "CentralAsiaStandardTime": "Astana",
    "CentralBrazilianStandardTime": "Cuiaba",
    "CentralEuropeanStandardTime": "Sarajevo, Skopje, Warsaw, Zagreb",
    "CentralEuropeStandardTime": "Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    "CentralPacificStandardTime": "Solomon Is., New Caledonia",
    "CentralStandardTime": "Central Time (US & Canada)",
    "CentralStandardTimeMexico": "Guadalajara, Mexico City, Monterrey",
    "ChathamIslandsStandardTime": "Chatham Islands",
    "ChinaStandardTime": "Beijing, Chongqing, Hong Kong, Urumqi",
    "CubaStandardTime": "Havana",
    "DatelineStandardTime": "International Date Line West",
    "EAfricaStandardTime": "Nairobi",
    "EasterIslandStandardTime": "Easter Island",
    "EasternStandardTime": "Eastern Time (US & Canada)",
    "EasternStandardTimeMexico": "Chetumal",
    "EAustraliaStandardTime": "Brisbane",
    "EEuropeStandardTime": "Chisinau",
    "EgyptStandardTime": "Cairo",
    "EkaterinburgStandardTime": "Ekaterinburg",
    "ESouthAmericaStandardTime": "Brasilia",
    "FijiStandardTime": "Fiji",
    "FLEStandardTime": "Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    "GeorgianStandardTime": "Tbilisi",
    "GMTStandardTime": "Dublin, Edinburgh, Lisbon, London",
    "GreenlandStandardTime": "Greenland",
    "GreenwichStandardTime": "Monrovia, Reykjavik",
    "GTBStandardTime": "Athens, Bucharest",
    "HaitiStandardTime": "Haiti",
    "HawaiianStandardTime": "Hawaii",
    "IndiaStandardTime": "Chennai, Kolkata, Mumbai, New Delhi",
    "IranStandardTime": "Tehran",
    "IsraelStandardTime": "Jerusalem",
    "JordanStandardTime": "Amman",
    "KaliningradStandardTime": "Kaliningrad",
    "KamchatkaStandardTime": "Petropavlovsk-Kamchatsky - Old",
    "KoreaStandardTime": "Seoul",
    "LibyaStandardTime": "Tripoli",
    "LineIslandsStandardTime": "Kiritimati Island",
    "LordHoweStandardTime": "Lord Howe Island",
    "MagadanStandardTime": "Magadan",
    "MagallanesStandardTime": "Punta Arenas",
    "MarquesasStandardTime": "Marquesas Islands",
    "MauritiusStandardTime": "Port Louis",
    "MidAtlanticStandardTime": "Mid-Atlantic - Old",
    "MiddleEastStandardTime": "Beirut",
    "MontevideoStandardTime": "Montevideo",
    "MoroccoStandardTime": "Casablanca",
    "MountainStandardTime": "Mountain Time (US & Canada)",
    "MountainStandardTimeMexico": "Chihuahua, La Paz, Mazatlan",
    "MyanmarStandardTime": "Yangon (Rangoon)",
    "NamibiaStandardTime": "Windhoek",
    "NCentralAsiaStandardTime": "Novosibirsk",
    "NepalStandardTime": "Kathmandu",
    "NewfoundlandStandardTime": "Newfoundland",
    "NewZealandStandardTime": "Auckland, Wellington",
    "NorfolkStandardTime": "Norfolk Island",
    "NorthAsiaEastStandardTime": "Irkutsk",
    "NorthAsiaStandardTime": "Krasnoyarsk",
    "NorthKoreaStandardTime": "Pyongyang",
    "OmskStandardTime": "Omsk",
    "PacificSAStandardTime": "Santiago",
    "PacificStandardTime": "Pacific Time (US & Canada)",
    "PacificStandardTimeMexico": "Baja California",
    "PakistanStandardTime": "Islamabad, Karachi",
    "ParaguayStandardTime": "Asuncion",
    "QyzylordaStandardTime": "Qyzylorda",
    "RomanceStandardTime": "Brussels, Copenhagen, Madrid, Paris",
    "RussianStandardTime": "Moscow, St. Petersburg",
    "RussiaTimeZone10": "Chokurdakh",
    "RussiaTimeZone11": "Anadyr, Petropavlovsk-Kamchatsky",
    "RussiaTimeZone3": "Izhevsk, Samara",
    "SAEasternStandardTime": "Cayenne, Fortaleza",
    "SaintPierreStandardTime": "Saint Pierre and Miquelon",
    "SakhalinStandardTime": "Sakhalin",
    "SamoaStandardTime": "Samoa",
    "SaoTomeStandardTime": "Sao Tome",
    "SAPacificStandardTime": "Bogota, Lima, Quito, Rio Branco",
    "SaratovStandardTime": "Saratov",
    "SAWesternStandardTime": "Georgetown, La Paz, Manaus, San Juan",
    "SEAsiaStandardTime": "Bangkok, Hanoi, Jakarta",
    "SingaporeStandardTime": "Kuala Lumpur, Singapore",
    "SouthAfricaStandardTime": "Harare, Pretoria",
    "SouthSudanStandardTime": "Juba",
    "SriLankaStandardTime": "Sri Jayawardenepura",
    "SudanStandardTime": "Khartoum",
    "SyriaStandardTime": "Damascus",
    "TaipeiStandardTime": "Taipei",
    "TasmaniaStandardTime": "Hobart",
    "TocantinsStandardTime": "Araguaina",
    "TokyoStandardTime": "Osaka, Sapporo, Tokyo",
    "TomskStandardTime": "Tomsk",
    "TongaStandardTime": "Nuku'alofa",
    "TransbaikalStandardTime": "Chita",
    "TurkeyStandardTime": "Istanbul",
    "TurksAndCaicosStandardTime": "Turks and Caicos",
    "UlaanbaatarStandardTime": "Ulaanbaatar",
    "USEasternStandardTime": "Indiana (East)",
    "USMountainStandardTime": "Arizona",
    "UTC": "Coordinated Universal Time",
    "UTC02": "Coordinated Universal Time-02",
    "UTC08": "Coordinated Universal Time-08",
    "UTC09": "Coordinated Universal Time-09",
    "UTC11": "Coordinated Universal Time-11",
    "UTC12": "Coordinated Universal Time+12",
    "UTC13": "Coordinated Universal Time+13",
    "VenezuelaStandardTime": "Caracas",
    "VladivostokStandardTime": "Vladivostok",
    "VolgogradStandardTime": "Volgograd",
    "WAustraliaStandardTime": "Perth",
    "WCentralAfricaStandardTime": "West Central Africa",
    "WestAsiaStandardTime": "Ashgabat, Tashkent",
    "WestBankStandardTime": "Gaza, Hebron",
    "WestPacificStandardTime": "Guam, Port Moresby",
    "WEuropeStandardTime": "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    "WMongoliaStandardTime": "Hovd",
    "YakutskStandardTime": "Yakutsk",
    "YukonStandardTime": "Yukon"
  },
  "VatRateEnglishNames": {
    "Australia": "GST",
    "Austria": "VAT",
    "Belgium": "VAT",
    "Brazil": "TAX",
    "Bulgaria": "VAT",
    "Canada": "GST/HST",
    "Croatia": "VAT",
    "Cyprus": "VAT",
    "CzechRepublic": "VAT",
    "Denmark": "VAT",
    "England": "VAT",
    "Estonia": "VAT",
    "Finland": "VAT",
    "France": "VAT",
    "Germany": "VAT",
    "Greece": "VAT",
    "Iceland": "VAT",
    "Ireland": "VAT",
    "Italy": "VAT",
    "Latvia": "VAT",
    "Lithuania": "VAT",
    "Luxembourg": "VAT",
    "Malta": "VAT",
    "Mexico": "VAT",
    "Netherlands": "VAT",
    "NewZealand": "GST",
    "Nigeria": "VAT",
    "NorthernIreland": "VAT",
    "Norway": "VAT",
    "Poland": "VAT",
    "Portugal": "VAT",
    "Romania": "VAT",
    "Russia": "TAX",
    "Scotland": "VAT",
    "Slovenia": "VAT",
    "SouthAfrica": "VAT",
    "Spain": "VAT",
    "Sweden": "VAT",
    "Switzerland": "VAT",
    "Turkey": "VAT",
    "UnitedStates": "TAX",
    "Wales": "VAT"
  },
  "VatRateLocalNames": {
    "Australia": "GST",
    "Austria": "USt",
    "Belgium": "BTW",
    "Brazil": "TAX",
    "Bulgaria": "VAT",
    "Canada": "GST/HST",
    "Croatia": "VAT",
    "Cyprus": "VAT",
    "CzechRepublic": "VAT",
    "Denmark": "MOMS",
    "England": "VAT",
    "Estonia": "VAT",
    "Finland": "VAT",
    "France": "TVA",
    "Germany": "USt",
    "Greece": "VAT",
    "Iceland": "VAT",
    "Ireland": "VAT",
    "Italy": "IVA",
    "Latvia": "VAT",
    "Lithuania": "VAT",
    "Luxembourg": "VAT",
    "Malta": "VAT",
    "Mexico": "VAT",
    "Netherlands": "BTW",
    "NewZealand": "GST",
    "Nigeria": "VAT",
    "NorthernIreland": "VAT",
    "Norway": "MVA",
    "Poland": "VAT",
    "Portugal": "IVA",
    "Romania": "VAT",
    "Russia": "TAX",
    "Scotland": "VAT",
    "Slovenia": "DDV",
    "SouthAfrica": "VAT",
    "Spain": "IVA",
    "Sweden": "MOMS",
    "Switzerland": "USt",
    "Turkey": "VAT",
    "UnitedStates": "TAX",
    "Wales": "VAT"
  },
  "VatRateTypeNames": {
    "IncreasedRate": "Increased rate",
    "No": "No VAT",
    "ParkingRate": "Parking rate",
    "ReducedRate1": "Reduced rate 1",
    "ReducedRate2": "Reduced rate 2",
    "ReducedRate3": "Reduced rate 3",
    "StandardRate": "Standard rate"
  },
  "AccessTypes": {
    "Backstage": "Backstage",
    "Guest": "Kostenloser Eintritt",
    "GuestPaysFullPrice": "Voller Preis",
    "GuestPaysHalfPrice": "Halber Preis",
    "OnStage": "Auf der Bühne",
    "Other": "Sonstiges",
    "Press": "Presse",
    "Vip": "VIP"
  },
  "Accommodation": {
    "AddressNearby": "Adresse in der Nähe",
    "AndOtherGuest": "und 1 anderer Gast",
    "AndOtherGuests": "und {0} andere Gast/Gäste",
    "BedType": "Bettentyp",
    "Breakfast": "Frühstück",
    "BreakfastIncluded": "Frühstück inklusive",
    "CheckIn": "Einchecken",
    "CheckOut": "Auschecken",
    "ContinueWithoutLodging": "Ohne Übernachtung fortsetzen",
    "CouldNotRetrievePlaceInfo": "Google Places konnte die Information nicht finden. Versuche die Adresse zu suchen und füge die Details manuell hinzu. ",
    "CreateRoom": "Erstelle Zimmer",
    "DayRoom": "Tageszimmer",
    "EditLodging": "Unterbringung bearbeiten",
    "ErrorFetchingData": "Fehler in der Datenübertragung. Bitte versuche es später noch ein mal. ",
    "Guest": "Gast",
    "Guests": "Gäste",
    "Gym": "Fitnesscenter",
    "Hotel": "Hotel",
    "Hotels": "Hotels",
    "Lodgings": "Unterbringungen",
    "NewAccommodation": "Neue Unterkunft",
    "NewHotel": "Neues Hotel",
    "NewLodging": "Neue Unterbringung",
    "Night": "1 Nacht",
    "Nights": "{0} Nächte",
    "NoGuests": "Keine Gäste",
    "NoLodgingCreated": "Keine Übernachtung erstellt. Erstelle eine Übernachtung oben rechts. ",
    "NoLodgingSelected": "Keine Übernachtung gewählt",
    "NoRoomsCreated": "Keine Zimmer erstellt. ",
    "NumberOfNights": "Anzahl der Nächte",
    "NumberOfRooms": "Anzahl der Zimmer",
    "Overview": "Überblick",
    "Phase": "Status",
    "PhoneNumber": "Telefonnummer",
    "Pool": "Pool",
    "Reservation": "Reservierung",
    "ReservationNr": "Reservierungsnr.",
    "ReservationNumber": "Reservierungsnummer",
    "resxLodging": "Unterbringung",
    "RoomCredit": "Zimmergutschein",
    "Rooms": "Zimmer",
    "RoomService24": "24-Stunden Zimmerservice",
    "RoomType": "Zimmertyp",
    "SearchByNameOrAddress": "Suche nach Übernachtung oder Adresse",
    "SelectLodgingBtn": "Unterkunft auswählen",
    "Smoking": "Raucher",
    "Spa": "Spa",
    "TryAddingCityName": "Füge Deiner Suche einen Stadtnamen bei wenn Du nicht die richtigen Ergebnisse siehst.",
    "VeganFacilities": "Vegane Einrichtungen",
    "Wifi": "WiFi"
  },
  "BedTypes": {
    "DoubleBed": "Doppelbett",
    "KingSizeBed": "King-Size-Bett",
    "QueenSizeBed": "Queen-Size-Bett",
    "Unknown": "Unbekannt"
  },
  "LodgingTypes": {
    "Apartment": "Apartment",
    "Hotel": "Hotel",
    "Other": "Sonstige  "
  },
  "RoomTypes": {
    "Double": "Doppelzimmer",
    "JuniorSuite": "Junior Suite",
    "Single": "Einzelzimmer",
    "Suite": "Suite",
    "Twin": "Twin-Zimmer"
  },
  "Account": {
    "AlreadyHaveAnAccount": "Hast du schon ein Konto?",
    "btnCreateAccount": "Konto erstellen",
    "ChooseWhichAccount": "Wähle, welches Konto Du nutzen möchtest",
    "CreateAnAccount": "Ein Konto erstellen",
    "ResetPassword": "Passwort zurücksetzen",
    "resxAccount": "Konto",
    "UseThisAccount": "Benutze dieses Konto?",
    "YouAreSignedInAs": "Sie sind angemeldet als"
  },
  "Activity": {
    "ActivityIsCleanedAfter12Months": "Aktivität wird nach 12 Monaten bereinigt.",
    "resxActivity": "Aktivität"
  },
  "Address": {
    "AddressBook": "Adressbuch",
    "AddressBR": "Adresszeilen; haben 2 Zeilen, wenn Adresszeilen 1 und 2 ausgefüllt sind",
    "AddressCS": "Adresszeilen, Adresszeilen 1 und 2 durch Komma getrennt",
    "AddressDetails": "Adressdetails",
    "AddressLine": "Adresszeile",
    "City": "Ort",
    "Countries": "Länder",
    "Country": "Land",
    "CountryCode": "Ländercode",
    "FullAddressOnMultipleLines": "Vollständige Adresse in mehreren Zeilen",
    "FullAddressOnSingleLine": "Vollständige Adresse in einer Zeile",
    "LocatedIn": "Standort",
    "OverviewOfCountries": "Länderübersicht",
    "PostalCode": "Postleitzahl",
    "PurposeOfThisOverviewIsToGiveUsers": "Diese Übersicht soll den BenutzerInnen einen Einblick in die hinter den Benutzernamen gespeicherten Werte geben.",
    "resxAddress": "Adresse",
    "SelectAContactFromAddressBook": "Wähle einen Kontakt aus dem Adressbuch aus",
    "SelectByCity": "Nach Stadt auswählen",
    "SelectByCountry": "Nach Land auswählen",
    "SelectByPostalCode": "Nach Postleitzahl auswählen",
    "SelectByRegion": "Nach Region auswählen",
    "SelectByState": "Nach Bundesstaat/Bundesland auswählen",
    "State": "Bundesland",
    "StateCode": "Bundeslandcode",
    "States": "Bundesländer",
    "TimeZoneIsLinkedToState": "Zeitzone ist mit Bundesstaat/Bundesland verknüpft",
    "XStatesInY": "{0} Bundesländer in {1}"
  },
  "Advancing": {
    "AcceptedByRecipient": "Von Empfänger akzeptiert",
    "AcceptedInput": "hat Ihre Eingabe akzeptiert. Vielen Dank für die Angabe der Informationen!",
    "AddPersonalMessage": "Persönliche Nachricht zur Einladung hinzufügen (optional)",
    "AdvancingRequestRemoved": "Diese Advancing-Anfrage wurde entfernt.",
    "AdvancingSession": "Advancing-Sitzung",
    "AdvancingSessions": "Advancing-Sitzungen",
    "AdvancingTemplate": "Advancing-Vorlage",
    "AdvancingTemplates": "Advancing-Vorlagen",
    "ApprovedResponse": "Genehmigt",
    "ClosedSession": "Geschlossene Sitzung",
    "Currently": "Aktuell",
    "DeclinedItemsRequiringAttention": "Es liegen abgelehnte Eingaben vor, die möglicherweise Ihrer Aufmerksamkeit bedürfen.",
    "DeclinedResponse": "Abgelehnt",
    "DeclinedResponseReasonPlaceholder": "Geben Sie einen Grund für die Ablehnung der Eingabe an.",
    "deleted": "gelöscht",
    "DeletedByX": "Gelöscht von {0}",
    "DeleteInvitationWarning": "Diese Einladung löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
    "DeleteRequestWarning": "Diese Anfrage löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
    "EditTemplate": "Vorlage bearbeiten",
    "EmailRequired": "E-Mail-Adresse ist erforderlich",
    "EmailSentForRequest": "Warten auf Antwort",
    "Hidden": "Ausgeblendet",
    "InitializedRequest": "Anfrage wird gesendet",
    "InputRequired": "Eingabe erforderlich. Bitte machen Sie eine Angabe hierzu.",
    "InvitedByX": "Eingeladen von {0}",
    "InviteParticipantsToJoinThisSession": "Teilnehmer einladen, dieser Sitzung beizutreten.",
    "InviteSomeone": "Jemanden einladen",
    "itemApproved": "Element genehmigt",
    "itemPendingApproval": "Element wartet auf Genehmigung",
    "itemsApproved": "Elemente genehmigt",
    "itemsPendingApproval": "Elemente warten auf Genehmigung",
    "ManageTemplates": "Vorlagen verwalten",
    "MessagesWaiting": "Nachrichten warten auf Sie",
    "NewTemplate": "Neue Vorlage",
    "NoParticipantsInvited": "Keine Teilnehmer eingeladen",
    "NoPersonalMessage": "Keine persönliche Nachricht",
    "NoValue": "Kein Wert",
    "NoValueYet": "Noch kein Wert",
    "Optional": "Optional",
    "OptionalInformation": "Optionale Informationen.",
    "Participants": "Teilnehmer",
    "PendingResponse": "Ausstehend",
    "PersonalMessage": "Persönliche Nachricht",
    "PhaseClosedByUser": "Vom Nutzer geschlossen",
    "PhaseCompleted": "Abgeschlossen",
    "PhaseInProgress": "In Arbeit",
    "PhaseNotYetAccepted": "Einladung noch nicht angenommen",
    "PleaseProvideInformation": "Bitte stelle Informationen bereit.",
    "PleaseProvideInformationForRequiredItems": "Bitte machen Sie Angaben zu diesen {0} erforderlichen Elementen",
    "Readonly": "Schreibgeschützt",
    "ReadonlyItem": "Schreibgeschütztes Element.",
    "RemoveFromAdvancingSession": "Aus Advancing-Sitzung entfernen",
    "Required": "Erforderlich",
    "RequiredInformation": "Erforderliche Angabe",
    "resxAdvancing": "Advancing",
    "TemplateIsRequired": "Vorlage erforderlich",
    "TemplateName": "Name der Vorlage",
    "ThanksForConfirming": "Vielen Dank, dass Sie diese Angaben bestätigt haben.",
    "ThanksForProviding": "Vielen Dank, dass Sie diese Angaben gemacht haben.",
    "UploadFromComputer": "Von Computer hochladen",
    "UploadingFiles": "Dateien werden hochgeladen",
    "WriteAMessage": "Nachricht schreiben",
    "xAccepted": "{0} akzeptiert",
    "xDeleted": "{0} gelöscht",
    "xInvited": "{0} eingeladen"
  },
  "AdvancingInvitation": {
    "DoYouHaveASystemOneAccount": "Hast Du ein SystemOne-Konto?",
    "InvitionToProvideInformation": "Einladung zur Bereitstellung von Informationen",
    "NoCreateAccount": "Nein, Konto erstellen",
    "NoUseDifferentAccount": "Nein, anderes Konto verwenden",
    "YesLogin": "Ja, anmelden",
    "YesUseThisAccount": "Ja, dieses Konto verwenden"
  },
  "AdvancingTimeline": {
    "acceptedTheInput": "hat die Eingabe akzeptiert.",
    "acceptedTheInvitation": "hat die Einladung angenommen.",
    "closedTheSession": "hat die Sitzung geschlossen.",
    "confirmedTheInput": "hat die Eingabe bestätigt.",
    "declinedTheInput": "hat die Eingabe abgelehnt.",
    "deletedAFile": "hat eine Datei gelöscht.",
    "deletedAnInvitation": "hat eine Einladung gelöscht.",
    "hasBeenNotified": "wurde benachrichtigt und wird Deine Eingabe prüfen.",
    "hasNotYetAccepted": "hat die Einladung noch nicht angenommen.",
    "invitedANewParticipant": "hat einen neuen Teilnehmer eingeladen.",
    "providedInformation": "hat eine Eingabe vorgenommen.",
    "uploadedAFile": "hat eine Datei hochgeladen.",
    "uploadedFiles": "hat {0} Dateien hochgeladen.",
    "wroteAMessage": "hat eine Nachricht geschrieben."
  },
  "Agency": {
    "Agencies": "Agencies",
    "NewAgency": "New agency",
    "resxAgency": "Agency"
  },
  "AirportSizeFilter": {
    "IncludeSmallerAirports": "Einschließlich kleinerer Flughäfen",
    "IncludeSmallestAirports": "Einschließlich kleinster Flughäfen (alle)",
    "OnlyBusiestAirports": "Nur die verkehrsreichsten Flughäfen",
    "Size": "Größe"
  },
  "Appointment": {
    "Appointments": "Termine",
    "AppointmentType": "Art des Termins",
    "NewAppointment": "Neuer Termin",
    "NoAppoinmentsInSelection": "Keine Termine ausgewählt",
    "resxAppointment": "Termin",
    "SelectAppointmentType": "Art des Termins auswählen"
  },
  "Artist": {
    "Artists": "Künstler",
    "NewArtist": "Neuer Künstler",
    "NoArtistsFound": "Keine Künstler gefunden",
    "NoLongerActive": "Nicht mehr aktiv",
    "OnlyActive": "Nur aktive",
    "OnlyActiveArtists": "Nur aktive Künstler ",
    "resxArtist": "Künstler",
    "SelectArtist": "Künstler auswählen",
    "YesButNoLongerActive": "Ja, aber nicht mehr aktiv"
  },
  "ArtistRider": {
    "ArtistRiders": "Rider",
    "EditArtistRider": "Rider bearbeiten",
    "EraseHistory": "Verlauf löschen",
    "FileHistory": "Dateiverlauf",
    "NewArtistRider": "Neuer Rider",
    "NoArtistRidersAvailable": "Keine Rider verfügbar",
    "resxArtistRider": "Rider"
  },
  "BankAccount": {
    "AccountType": "Kontotyp",
    "Bank": "Bank",
    "BankAccounts": "Bankkonten",
    "BankPlaceholder": "Name und Anschrift der Bank",
    "BranchCode": "Code der Niederlassung",
    "BranchName": "Name der Niederlassung",
    "BsbCode": "BSB-Code",
    "Checking": "Girokonto",
    "Format": "Format",
    "Memo": "Memo",
    "NewBankAccount": "Neues Bankkonto",
    "resxBankAccount": "Bankkonto",
    "RoutingNumber": "Routing number",
    "SortCode": "Sort Code"
  },
  "BankAccountFormats": {
    "AmericanChecking": "Amerikanisches Girokonto",
    "Australian": "Australisches Konto",
    "British": "Britisches Konto",
    "GenericIban": "Generisches IBAN-Konto",
    "SouthAfrican": "Südafrikanisches Konto"
  },
  "Boat": {
    "BoatTerminal": "Bootsterminal"
  },
  "BookingRequest": {
    "BookingRequests": "Buchungsanfragen"
  },
  "DataProcessingDisclaimer": {
    "DefaultText": "Um Sie zu kontaktieren und Ihnen die gewünschten Informationen zukommen zu lassen, müssen wir Ihre persönlichen Daten speichern und verarbeiten. Wenn Sie damit einverstanden sind, dass wir Ihre persönlichen Daten für diesen Zweck speichern, aktivieren Sie bitte das folgende Kontrollkästchen.",
    "DisclaimerText": "Text Haftungsausschluss",
    "Header": "Zustimmung zur Datenverarbeitung",
    "IAgree": "O Ich stimme zu"
  },
  "Bookkeeping": {
    "ABookKeepingWithThisCode": "Eine Buchführung mit diesem Code ist bereits vorhanden. Wählen Sie einen anderen 3-stelligen Code aus.",
    "AccessToBookkeepings": "Zugriff auf Buchführungen",
    "BookKeepingCodeDesc": "Abkürzung mit 3 Buchstaben",
    "BookKeepings": "Buchführung",
    "ChangeBookkeeping": "Buchhaltung ändern",
    "ChangeTheBookkeepingOfAllItemsInTheSelection": "Ändern Sie die Buchhaltung aller {0} in der Auswahl.",
    "Code": "Code",
    "DefaultBookkeeping": "Standardbuchführung",
    "EditBookKeeping": "Buchführung bearbeiten",
    "NewBookKeeping": "Neue Buchführung",
    "ResetOnNewYear": "Wird im neuen Jahr zurückgesetzt",
    "ResetOnNewYearDesc": "Soll die Nummerierung jedes Jahr von vorne beginnen oder durchgängig sein.",
    "resxBookkeeping": "Buchführung",
    "StartingNumber": "Startnummer",
    "StartingNumberDesc": "Nummer, mit der die Nummerierung der Buchführung jedes Jahr beginnt.",
    "StartsOnMonth": "Beginnt mit dem Monat",
    "StartsOnMonthDesc": "Monat, mit dem das administrative Jahr beginnt. In der Regel im Januar.",
    "TheBookkeepingIsChangedForNumberItems": "Die Buchhaltung für {0} {1} wird geändert.",
    "ThisBookkeepingIsLastVisible": "Diese Buchführung kann nicht entfernt werden, da es sich dabei um die letzte sichtbare Buchführung handelt.",
    "ThisUserAccountHasNoAccessToAnyBookkeepings": "Dieses Konto hat keinen Zugriff auf Buchführungen."
  },
  "Branding": {
    "CompanyLogo": "Unternehmenslogo",
    "FriendlyIdCharacterException": "Darf nur Kleinbuchstaben, Nummern, - oder _ beeinhalten",
    "Handle": "Handle",
    "HandleExplanation": "Identifizierungsschlüssel der gebraucht wird, um Shows auf Deiner Agenturwebseite zu veröffentlichen. ",
    "NameExplanation": "Zur Nutzung in externer Kommunikation",
    "ProvideYourCompanyLogo": "Stelle Dein Unternehmenslogo ein",
    "resxBranding": "Branding",
    "Settings": "Branding einstellungen",
    "SquareLogo": "Quadratisches Logo",
    "ThisHandleIsAlreadyInUse": "Dieser Handle wird bereits genutzt. Wähle ein anderes aus"
  },
  "Browser": {
    "BrowserNotSupported": "Browser nicht unterstützt",
    "BrowserNotSupportedMessage": "Sie verwenden {0}. Dieser Browser wird nicht mehr unterstützt. Wir empfehlen Ihnen, einen anderen Browser zu verwenden, zum Beispiel Chrome.",
    "BrowserOutOfDate": "Der Browser ist veraltet",
    "BrowserOutOfDateMessage": "Sie verwenden {0}. Wir können nicht garantieren, dass SystemOne korrekt funktioniert, da es sich um eine nicht aktuelle Version dieses Browsers handelt. Bitte aktualisieren Sie Ihren Browser."
  },
  "BusinessCentral": {
    "CouldNotFindItemInBusinessCentral": "Could not find item in Business Central",
    "FindCostCenterByNumber": "Find cost center by number",
    "FindCustomerByNumber": "Find customer by number",
    "FindProjectCodeByNumber": "Find project code by number"
  },
  "Buttons": {
    "Accept": "Annehmen",
    "Add": "Hinzufügen",
    "AddLayer": "Ebene hinzufügen",
    "Approve": "Genehmigen",
    "btnActivate": "Aktivieren",
    "btnActualize": "Aktualisieren",
    "btnAdjustRemoveLink": "Link anpassen/entfernen",
    "btnAssign": "Zuweisen",
    "btnCancel": "Abbrechen",
    "btnClearAll": "Alles löschen",
    "btnCopy": "Kopieren",
    "btnDelete": "Entfernen",
    "btnDeselect": "Nicht auswählen",
    "btnDiscard": "Verwerfen",
    "btnEdit": "Bearbeiten",
    "btnErase": "Löschen",
    "BtnHide": "Ausblenden",
    "btnIAgree": "Ich stimme zu.",
    "btnLinkItems": "Verknüpfung {0}",
    "btnMakeSelection": "Auswählen",
    "btnMoveDown": "Nach unten verschieben",
    "btnMoveUp": "Nach oben verschieben",
    "btnNew": "Neu",
    "btnNextStep": "Nächster Schritt",
    "BtnOk": "OK",
    "btnRefresh": "Aktualisieren",
    "btnRemoveLink": "Link entfernen",
    "btnSave": "Speichern",
    "btnSearch": "Suchen",
    "btnSelect": "Auswählen",
    "btnSend": "Senden",
    "btnSendOut": "Aussenden",
    "btnSubmitInformation": "Informationen senden",
    "BtnUnhide": "Einblenden",
    "Change": "Ändern",
    "Close_Imperative_Singular": "Schließen",
    "Confirm": "Bestätigen",
    "Copy_Imperative_Singular": "Kopieren",
    "CopyNoun": "Kopie",
    "CopyToClipboard": "In Zwischenspeicher kopieren",
    "Decline": "Ablehnen",
    "Delete_Imperative_Singular": "Löschen",
    "DetailView": "Detailansicht",
    "Invite": "Einladen",
    "ListView": "Listenansicht",
    "Remove": "Entfernen",
    "Restore": "Wiederherstellen",
    "Undo": "Rückgängig machen",
    "Upload": "Hochladen",
    "UploadPhoto": "Foto hochladen"
  },
  "Capacity": {
    "BetweenXandYPax": "Zwischen {0} und {1} PAX",
    "LessThanXPax": "Weniger als {0} PAX",
    "Maximum": "Maximum",
    "MinCannotExceedMax": "Minimum darf das Maximum nicht überschreiten",
    "Minimum": "Minimum",
    "MoreThanXPax": "Mehr als {0} PAX"
  },
  "Checklist": {
    "ApprovedBy": "Genehmigt von",
    "ChecklistPreset": "Voreinstellung Checkliste",
    "ChecklistPresets": "Voreinstellungen Checkliste",
    "DeclinedBy": "Abgelehnt von",
    "NoUsersHavePermissionToReview": "Die Benutzer haben keine Berechtigung zum Überarbeiten.",
    "ProvideAReasonWhyYouDecline": "Gib einen Grund für die Ablehnung dieses Elements an (optional).",
    "QuestionInputExamplePlaceholder": "Beispiel: „Sind die Informationen im Dokument korrekt?\"",
    "resxChecklist": "Checkliste",
    "WaitingForReview": "Warten auf Überprüfung",
    "WhoCanReview": "Wer kann überprüfen"
  },
  "Client": {
    "AccountLimits": "Kontobeschränkungen",
    "Administrator": "Administrator",
    "Clients": "Kunden",
    "ContractEnd": "Ende der Vereinbarung",
    "ContractStart": "Vertragsbeginn",
    "EditClient": "Kunde bearbeiten",
    "InUse": "{0}% verwendet",
    "NewClient": "Neuer Kunde",
    "NewClientError": "Fehler beim Erstellen eines neuen Kunden",
    "OurInvoicesWillBeFreeOfVAT": "Unsere Rechnungen enthalten keine Mehrwertsteuer. Dies entspricht den Mehrwertsteuer-Regelungen für innergemeinschaftliche Transaktionen innerhalb der EU. Du bist für die Zahlung aller örtlichen Steuern verantwortlich.",
    "PleaseContactUsIfYouHaveAVATNumber": "Anderenfalls werden unseren Diensten MwSt. hinzugerechnet. Dies entspricht den Mehrwertsteuer-Regelungen für innergemeinschaftliche Transaktionen innerhalb der EU.",
    "PleaseProvideUsWithYourVATNumber": "Bitte geben Deine USt-IdNr an.",
    "ReleaseDate": "Veröffentlichungsdatum",
    "resxClient": "Kunde",
    "resxSystemSettings": "Systemeinstellungen",
    "SiteCategories": "Websitekategorien",
    "SystemAccount": "Systemkonto",
    "SystemAccountOverview": "Systemkonto - Übersicht",
    "SystemName": "Systemname",
    "Version": "Version"
  },
  "Cobra": {
    "MiscellaneousGuestlistAmountFallback": "Künstler erhält kostenfrei {0} Freikarten für die Veranstaltung.",
    "MiscellaneousMerchAmountFallback": "Künstler/Künstlerpersonal hat das Recht Merchandiseartikel selbst zu verkaufen. Der Künstler bzw. Vertragspartner von Künstler bezahlen eine Pauschale von maximal {0} (netto) als Verkaufsgebühr. Der Veranstalter ist verpflichtet dem Künstler im Gegenzug einen geeigneten Platz und Merchandise Verkaufsstand inklusive Tische, Licht, Verkaufsfläche, Präsentationsfläche etc. zur Verfügung zu stellen.",
    "MiscellaneousMerchPercentageFallback": "Der Verkauf sämtlicher Merchandiseartikel obliegt der Venue/dem Veranstalter. Künstler bzw. Vertragspartner von Künstler sind verpflichtet ein Maximum von {0} der Netto-Verkaufserlöse als Verkaufsgebühr bzw. Verkaufsprovision zu bezahlen. Sämtliche Kosten für Verkaufspersonal, Material für den Merchandise Verkauf, Strom, Standmiete, Licht, Kreditkartengebühren, Überweisungsgebühren sowie sämtliche weitere, anfallende Kosten für den Verkauf von Merchandise Artikeln sind mit der vorgenannten Verkaufsgebühr/Verkaufsprovision abgegolten. Der Verkauf von Event-Merchandise, bedarf der vorherigen Zusage des Künstlers.",
    "NotApplicable": "Nicht zutreffend.",
    "PromoterAccommodationFallback": "Promoter ist verpflichtet einen Kostenbeitrag in der Höhe von {0} für die Unterkunft der Travelparty zu bezahlen",
    "PromoterBacklineFallback": "Promoter ist verpflichtet einen Kostenbeitrag in der Höhe von {0} für die Bereitstellung der Backline zu bezahlen",
    "PromoterCateringFallback": "Promoter ist verpflichtet einen Kostenbeitrag in der Höhe von {0} für die Bereitstellung des Tour-Catering zu bezahlen",
    "PromoterFlightsFallback": "Promoter ist verpflichtet einen Kostenbeitrag in der Höhe von {0} für die Flüge der Travelparty zu bezahlen",
    "PromoterGroundTransportFallback": "Promoter ist verpflichtet einen Kostenbeitrag in der Höhe von {0} für den Transport der Travelparty zu bezahlen",
    "PromoterMarketingFallback": "Promoter ist verpflichtet einen Beitrag in der Höhe von {0} Werbekostenpauschale für Marketing, Social Media Kampagnen, Video-Trailer, Artworks etc. zu bezahlen",
    "PromoterPaLightsFallback": "Promoter ist verpflichtet einen Kostenbeitrag in der Höhe von {0} für die Bereitstellung der Ton & Lichtanlage zu bezahlen",
    "PromoterStagingFallback": "Promoter ist verpflichtet einen Kostenbeitrag in der Höhe von {0} für die Bereitstellung der Bühne zu bezahlen",
    "PromoterVisaFallback": "Promoter ist verpflichtet einen Kostenbeitrag in der Höhe von {0} für die Visa / Work Permits der Travelparty zu bezahlen"
  },
  "Company": {
    "AccountNumber": "Kontonummer",
    "ACompanyWithThisNameAlreadyExists": "Eine Firma mit diesem Namen ist bereits vorhanden.",
    "AddCompanyToDatabase": "Firma zur Datenbank hinzufügen",
    "AdditionalInformation": "Weitere Informationen",
    "Bank": "Bank",
    "BankAccountDetails": "Bankdaten",
    "BankName": "Bank Name",
    "BeneficiaryName": "Kontoinhaber",
    "BsbNumber": "Bankleitzahl (BLZ) / Sort Code",
    "BusinessLicenceNumber": "Betriebslizenznummer",
    "Companies": "Firmen",
    "CompanyDetails": "Firmendetails",
    "CompanyName": "Firmenname",
    "CompanyWebsite": "Firmenwebsite",
    "DeleteBatchText": "Du bist im Begriff, die ausgewählten Firmen zu löschen. Beachte Folgendes:<ul><li>Die Firmen werden endgültig aus der Datenbank gelöscht.</li><li>Mit diesen Firmen verknüpfte Kontaktpersonen, die in anderen Bereichen des Systems nicht verwendet werden, werden ebenfalls gelöscht.</li><li>Aufgaben, Notizen und E-Mails werden gelöscht.</li><li>Firmen, die in anderen Bereichen des Systems verwendet werden, werden nicht gelöscht. Sie müssen sie manuell löschen.</li></ul>",
    "DeleteCompany": "Firma löschen",
    "DeleteCompanyText": "Du bist im Begriff, diese Firma zu löschen. Bitte beachte Folgendes:<ul><li>Die Firma wird endgültig aus der Datenbank entfernt.</li><li>Alle Notizen und Aufgaben, die für diese Firma eingetragen sind, werden ebenfalls entfernt.</li></ul>",
    "EditCompany": "Firma bearbeiten",
    "EmailAddressesFromContacts": "E-Mail-Adressen von Kontakten",
    "EmailIsBlacklisted": "E-Mail-Adresse wird blockiert",
    "ExpiresOn": "Läuft ab am",
    "FirstLinkedCompany": "Erste verknüpfte Firma",
    "GeneralEmail": "Allgemeine E-Mail-Adresse",
    "GeneralPhone": "Allgemeine Telefonnummer",
    "IbanNumber": "IBAN-Nummer",
    "KeepTheLink": "Behalte die Verknüpfung und lege fest, dass der Kontakt nicht mehr für die Firma aktiv ist.",
    "LinkedCompanies": "Verknüpfte Firmen",
    "LinkedCompany": "Verknüpfte Firma",
    "LinkExistingCompany": "Vorhandene Firma verknüpfen",
    "MergeCompanies": "Firmen zusammenführen",
    "MergeWithACompanyThatIsAlreadyInTheDatabase": "Mit Firma zusammenführen, die bereits in der Datenbank vorhanden ist.",
    "MoveLinksToOtherCompany": "Kontaktpersonen, Aufgaben und Verweise aus anderen Bereichen können zu einer anderen Firma verschoben werden.",
    "MustSelectCompany": "Diese Firma wurde bereits in anderen Bereichen verwendet. Wähle eine Firma aus, zu der Du diese Verknüpfungen verschieben möchtest.",
    "NetworkPath": "Netzwerkpfad",
    "NewCompany": "Neue Firma",
    "NewPositionIsSaved": "Neue Position wird auf der Karte gespeichert...",
    "NoCompaniesHaveBeenMarkedAsAvailableInSelectionTool": "Es wurden keine Firmen als \"Im Auswahl-Tool verfügbar\" markiert. Diese Einstellung finden Sie auf der Seite \"Firma bearbeiten\".",
    "NoCompanySelected": "Keine Firma ausgewählt.",
    "NumberOfCompaniesFoundInDatabaseThatAreSimilarTo": "Anzahl der in der Datenbank gefundenen Firmen, die ähnlich sind wie",
    "OtherDetails": "Weitere Informationen",
    "Phone": "Telefon",
    "PhoneShort": "T",
    "PostalAddress": "Postadresse",
    "PostalAddressIsDifferent": "Unterschiedliche Postanschrift",
    "Profile": "Profil",
    "ReactivateTheLink": "Verknüpfung wiederherstellen und festlegen, dass der Kontakt wieder für die Firma aktiv ist.",
    "RegistrationDetails": "Registration Details",
    "RelationNumber": "Firmennummer",
    "RemoveLinkBetweenThisCompanyAndContact": "Verknüpfung zwischen dieser Firma und dem Kontakt löschen. Der Kontakt wird dadurch nicht gelöscht.",
    "resxCompany": "Firma",
    "RetrieveAListOfEmailAddresses": "Eine Liste von E-Mail-Adressen von Kontakten abrufen, die nicht blockiert wurden. Doppelt vorhandene E-Mail-Adressen wurden entfernt.",
    "SearchByName": "Suche nach Name",
    "SelectCompanyFromDatabase": "Firma aus Datenbank auswählen",
    "SwiftCode": "BIC/SWIFT-Code",
    "TheSelectedCompanyIsTheSame": "Du kannst keine Verknüpfungen zu der Firma verschieben, die Du löschen möchtest. Wähle eine andere Firma aus.",
    "VarStatement": "Erklärung zur Scheinselbständigkeit",
    "VisitingAddress": "Besucheradresse",
    "YouHaveInsufficientAccessToTheCompanySection": "Du verfügst nicht über ausreichend Zugriffsrechte für den Firmenbereich."
  },
  "ConnectedAccount": {
    "Connect": "Verbinden",
    "ConnectedAccounts": "Verbundene Konten",
    "ConnectWith": "Verbinden mit",
    "Disconnect": "Verbindung trennen",
    "LastVerified": "Zuletzt bestätigt",
    "NameSAccount": "{0}'s {1} Account",
    "NeverVerified": "Noch nicht bestätigt",
    "Provider": "Anbieter",
    "resxConnectedAccount": "Verbundenes Konto",
    "RetrievedFrom": "erneuert von",
    "SolveProblem": "Löse Problem",
    "Verify": "Verifizieren"
  },
  "Contact": {
    "AContactWithThisNameAlreadyExists": "Ein Kontakt mit diesem Namen ist bereits vorhanden.",
    "AddContactToDatabase": "Kontakt zur Datenbank hinzufügen",
    "AttnLineOnEnvelope": "z. H. in Adresse",
    "AttnLineOnEnvelopeDesc": "z. H.: Herr V.W. van Gogh",
    "AValidAOLInstantMessengerScreenName": "Ein gültiger AIM-Benutzername enthält nur Buchstaben, Zahlen, @, _, -, . (keine Leer- oder Sonderzeichen).",
    "AValidTwitterScreenName": "Ein gültiger Twitter-Benutzername enthält nur Buchstaben, Zahlen, _, -. (keine Leer- oder Sonderzeichen)",
    "ContactDetails": "Kontaktdetails",
    "ContactName": "Kontaktname",
    "Contacts": "Kontakte",
    "DateOfBirth": "Geburtsdatum",
    "DefaultAttendee": "Standardteilnehmer",
    "DeleteBatchText": "Du bist im Begriff, die ausgewählten Kontakte zu löschen. Bitte beachte Folgendes:<ul><li>Die Kontakte werden endgültig aus der Datenbank gelöscht.</li><li>Alle für diese Kontakte eingetragenen Aufgaben, Notizen und E-Mails werden ebenfalls gelöscht.</li><li>Kontakte, die in anderen Bereichen des Systems verwendet werden, werden nicht gelöscht. Du musst sie manuell löschen.</li></ul>",
    "DeleteContact": "Kontakt löschen",
    "DeleteContactText": "Du bist im Begriff, diesen Kontakt zu löschen. Bitte beachte Folgendes:<ul><li>Der Kontakt wird endgültig aus der Datenbank gelöscht.</li><li>Alle für diesen Kontakt eingetragenen Notizen und Aufgaben werden ebenfalls gelöscht.</li></ul>",
    "DeleteTheFollowingContacts": "Folgende Kontakte löschen",
    "Department": "Abteilung",
    "DirectPhone": "Telefonnummer",
    "EditContact": "Kontakt bearbeiten",
    "FilterByContact": "Nach Kontakt filtern",
    "FindContact": "Kontakt suchen",
    "FirstInsertionLastName": "Vorname + Zusatz + Nachname",
    "FirstLinkedContact": "Erster verknüpfter Kontakt",
    "FirstName": "Vorname",
    "FirstNameHelpText": "z. B. Vincent",
    "FirstNameInitials": "Initialen Vorname",
    "FirstNameInitialsHelpText": "z. B. V. W.",
    "Gender": "Geschlecht",
    "HomeAddress": "Privatadresse",
    "KeywordPlaceholder": "Suchen nach Name, email, Position",
    "LanguageDesc": "Hauptsprache für Kontakt. Kann im Auswahltool gefiltert werden.",
    "LastName": "Nachname",
    "LastNameHelpText": "z. B. Gogh",
    "LinkedContactPerson": "Verknüpfter Kontakt",
    "LinkedContactPersons": "Verknüpfter Kontakt",
    "LinkExistingContact": "Vorhandenen Kontakt verknüpfen",
    "MaritalStatus": "Familienstand",
    "MergeContacts": "Kontakte zusammenführen",
    "MergeWithAContactPersonThatIsAlreadyInTheDatabase": "Mit einer bereits in der Datenbank vorhandenen Kontaktperson zusammenführen",
    "MiddleName": "Zweiter Vorname",
    "MiddleNameHelpText": "z. B. van",
    "Mobile": "Handy",
    "MobileShort": "M",
    "MoveLinks": "Verknüpfung verschieben",
    "MoveLinksToOtherContact": "Notizen, Aufgaben und Verweise zu bzw. auf diesem/diesen Kontakt aus anderen Bereichen können zu einer anderen Kontaktperson verschoben werden:",
    "NameOnPassport": "Name im Reisepass",
    "Nationality": "Nationalität",
    "NewContactPerson": "Neuer Kontakt",
    "NobodySelected": "Es wurde niemand ausgewählt",
    "NoCompany": "Keine Firma",
    "NoContactSelected": "Keine Kontaktperson ausgewählt",
    "NoLongerActive": "Nicht mehr aktiv",
    "NumberOfContactPersonsFoundInDatabaseThatAreSimilarTo": "Anzahl der in der Datenbank gefundenen Firmen, die ähnlich sind wie",
    "OneCompany": "1 Firma",
    "PassportExpiresOn": "Reisepass läuft ab am",
    "PassportIssuedOn": "Ausstelldatum Reisepass",
    "PassportNumber": "Reisepassnummer",
    "Person": "Person",
    "PersonalDetails": "Persönliche Angaben",
    "Persons": "Personen",
    "PlaceOfBirth": "Geburtsort",
    "Position": "Position",
    "PrivateEmail": "Private E-Mail",
    "PrivateMobile": "Privates Mobiltelefon",
    "PrivatePhone": "Privates Telefon",
    "PrivateWebsite": "Private Webseite",
    "resxContact": "Kontakt",
    "Salutation": "Anfang",
    "SalutationDesc": "Sehr geehrter Herr van Gogh,",
    "SelectContact": "Kontakt auswählen",
    "SelectContactFromDatabase": "Kontakt aus Datenbank auswählen",
    "Skype": "Skype",
    "SkypeShort": "S",
    "SocialSecurityNumber": "Sozialversicherungsnummer",
    "TheSelectedContactIsTheSame": "Du kannst keine Verknüpfungen für den Kontakt verschieben, den Du löschen möchtest. Wähle eine andere Kontaktperson aus.",
    "ThisContactCannotBeDeletedBecauseItsUsedInOtherSections": "Dieser Kontakt kann nicht gelöscht werden, da er in anderen Bereichen verwendet wird.",
    "ThisContactHasNoName": "Dieser Kontakt verfügt über keinen Namen. Bitte gebe einen Namen an.",
    "Twitter": "Twitter",
    "WhenAnInsertionIsProvidedTheLastNameCantBeEmpty": "Wenn ein Namenszusatz angegeben wird, muss auch ein Nachname angegeben werden.",
    "xCompanies": "{0} Firmen",
    "YearsOld": "Jahre",
    "YourSearchHasYieldedNoResult": "Deine Suche ergab keine Treffer. Beachte, wenn eine Person über keine E-Mail-Adresse verfügt, kann diese nicht gefunden werden."
  },
  "ContactTypes": {
    "PromoterContact": "Kontakt zum Veranstalter",
    "VenueContact": "Kontakt zum Veranstaltungsort"
  },
  "ContactDetails": {
    "PhoneNumber": "Telefonnummer"
  },
  "CostCenter": {
    "CostCenters": "Kostenstellen",
    "EditCostCenter": "Kostenstelle bearbeiten",
    "FilterByCostCenter": "Nach Kostenstelle filtern",
    "NewCostCenter": "Neue Kostenstelle",
    "resxCostCenter": "Kostenstelle  "
  },
  "CountryNames": {
    "Afghanistan": "Afghanistan",
    "ÅlandIslands": "Åland-Inseln",
    "Albania": "Albanien",
    "Algeria": "Algerien",
    "AmericanSamoa": "Amerikanisch-Samoa",
    "Andorra": "Andorra",
    "Angola": "Angola",
    "Anguilla": "Anguilla",
    "Antarctica": "Antarktis",
    "AntiguaandBarbuda": "Antigua und Barbuda",
    "Argentina": "Argentinien",
    "Armenia": "Armenien",
    "Aruba": "Aruba",
    "Australia": "Australien",
    "Austria": "Österreich",
    "Azerbaijan": "Aserbaidschan",
    "Bahamas": "Bahamas",
    "Bahrain": "Bahrein",
    "Bangladesh": "Bangladesch",
    "Barbados": "Barbados",
    "Belarus": "Belarus (Weißrussland)",
    "Belgium": "Belgien",
    "Belize": "Belize",
    "Benin": "Benin",
    "Bermuda": "Bermuda",
    "Bhutan": "Bhutan",
    "Bolivia": "Bolivien",
    "BonaireSintEustatiusandSaba": "Bonaire, St. Eustatius und Saba",
    "BosniaandHerzegovina": "Bosnien und Herzegowina",
    "Botswana": "Botsuana",
    "BouvetIsland": "Bouvetinsel",
    "Brazil": "Brasilien",
    "BritishIndianOceanTerritory": "Britisches Territorium im Indischen Ozean",
    "BritishVirginIslands": "Britische Jungferninseln",
    "BruneiDarussalam": "Brunei Darussalam",
    "Bulgaria": "Bulgarien",
    "BurkinaFaso": "Burkina Faso",
    "Burundi": "Burundi",
    "Cambodia": "Kambodscha",
    "Cameroon": "Kamerun",
    "Canada": "Kanada",
    "CapeVerde": "Kap Verde",
    "CaymanIslands": "Kaimaninseln",
    "CentralAfricanRepublic": "Zentralafrikanische Republik",
    "Chad": "Tschad",
    "Chile": "Chile",
    "China": "China",
    "ChristmasIsland": "Weihnachtsinsel",
    "CocosKeelingIslands": "Kokosinseln (Keeling)",
    "Colombia": "Kolumbien",
    "Comoros": "Komoren",
    "Congo": "Kongo",
    "CongoKinshasa": "Kongo-Kinshasa",
    "CookIslands": "Cookinseln",
    "CostaRica": "Costa Rica",
    "Croatia": "Kroatien",
    "Cuba": "Kuba",
    "Curaçao": "Curaçao",
    "Cyprus": "Zypern",
    "CzechRepublic": "Tschechien",
    "Denmark": "Dänemark",
    "Djibouti": "Dschibuti",
    "Dominica": "Dominica",
    "DominicanRepublic": "Dominikanische Republik",
    "Ecuador": "Ecuador",
    "Egypt": "Ägypten",
    "ElSalvador": "El Salvador",
    "England": "England",
    "EquatorialGuinea": "Äquatorialguinea",
    "Eritrea": "Eritrea",
    "Estonia": "Estland",
    "Ethiopia": "Äthiopien",
    "FalklandIslandsMalvinas": "Falklandinseln (Malvinen)",
    "FaroeIslands": "Färöer",
    "Fiji": "Fidschi",
    "Finland": "Finnland",
    "France": "Frankreich",
    "FrenchGuiana": "Französisch-Guayana",
    "FrenchPolynesia": "Französisch-Polynesien",
    "FrenchSouthernTerritories": "Französische Süd- und Antarktisgebiete",
    "Gabon": "Gabun",
    "Gambia": "Gambia",
    "Georgia": "Georgien",
    "Germany": "Deutschland",
    "Ghana": "Ghana",
    "Gibraltar": "Gibraltar",
    "Greece": "Griechenland",
    "Greenland": "Grönland",
    "Grenada": "Grenada",
    "Guadeloupe": "Guadeloupe",
    "Guam": "Guam",
    "Guatemala": "Guatemala",
    "Guernsey": "Guernsey",
    "Guinea": "Guinea",
    "GuineaBissau": "Guinea-Bissau",
    "Guyana": "Guyana",
    "Haiti": "Haiti",
    "HeardandMcDonaldIslands": "Heard und die Mcdonaldinseln",
    "Honduras": "Honduras",
    "HongKong": "Hongkong",
    "Hungary": "Ungarn",
    "Iceland": "Island",
    "India": "Indien",
    "Indonesia": "Indonesien",
    "Iran": "Iran",
    "Iraq": "Irak",
    "Ireland": "Irland",
    "IsleOfMan": "Isle Of Man",
    "Israel": "Israel",
    "Italy": "Italien",
    "IvoryCoast": "Elfenbeinküste",
    "Jamaica": "Jamaika",
    "Japan": "Japan",
    "Jersey": "Jersey",
    "Jordan": "Jordanien",
    "Kazakhstan": "Kasachstan",
    "Kenya": "Kenya",
    "Kiribati": "Kiribati",
    "Kosovo": "Kosovo",
    "Kuwait": "Kuwait",
    "Kyrgyzstan": "Kirgisistan",
    "Lao": "Laos",
    "Latvia": "Lettland",
    "Lebanon": "Libanon",
    "Lesotho": "Lesotho",
    "Liberia": "Liberia",
    "LibyanArabJamahiriya": "Libyen",
    "Liechtenstein": "Liechtenstein",
    "Lithuania": "Litauen",
    "Luxembourg": "Luxemburg",
    "Macao": "Macau",
    "Madagaskar": "Madagaskar",
    "Malawi": "Malawi",
    "Malaysia": "Malaysia",
    "Maldives": "Malediven",
    "Mali": "Mali",
    "Malta": "Malta",
    "MarshallIslands": "Marshallinseln",
    "Martinique": "Martinique",
    "Mauritania": "Mauretanien",
    "Mauritius": "Mauritius",
    "Mayotte": "Mayotte",
    "Mexico": "Mexiko",
    "Micronesia": "Mikronesien",
    "Moldova": "Moldau",
    "Monaco": "Monaco",
    "Mongolia": "Mongolei",
    "Monserrat": "Montserrat",
    "Montenegro": "Montenegro",
    "Morocco": "Marokko",
    "Mozambique": "Mosambik",
    "MyanmarBurma": "Myanmar (Birma)",
    "Namibia": "Namibia",
    "Nauru": "Nauru",
    "Nepal": "Nepal",
    "Netherlands": "Niederlande",
    "NewCaledonia": "Neukaledonien",
    "NewZealand": "Neuseeland",
    "Nicaragua": "Nicaragua",
    "Niger": "Niger",
    "Nigeria": "Nigeria",
    "Niue": "Niue",
    "NorfolkIslands": "Norfolkinsel",
    "NorthernIreland": "Northern Ireland",
    "NorthernMarianaIslands": "Nördliche Marianen",
    "NorthKorea": "Nordkorea",
    "NorthMacedonia": "Nordmazedonien",
    "Norway": "Norwegen",
    "Oman": "Oman",
    "Pakistan": "Pakistan",
    "Palau": "Palästinensische Autonomiegebiete",
    "Palestine": "Palästina",
    "Panama": "Panama",
    "PapuaNewGuinea": "Papua-Neuguinea",
    "Paraguay": "Paraguay",
    "Peru": "Peru",
    "Philippines": "Philippinen",
    "PitcairnIslands": "Pitcairninseln",
    "Poland": "Polen",
    "Portugal": "Portugal",
    "PuertoRico": "Puerto Rico",
    "Qatar": "Katar",
    "Réunion": "Réunion",
    "Romania": "Rumänien",
    "Russia": "Russland",
    "Rwanda": "Ruanda",
    "SaintHelena": "St. Helena",
    "SaintKittsandNevis": "St. Kitts und Nevis",
    "SaintLucia": "St. Lucia",
    "SaintMartin": "St. Martin",
    "SaintPierreetMiquelon": "St. Pierre und Miquelon",
    "SaintVincentandtheGrenadines": "St. Vincent und die Grenadinen",
    "Samoa": "Samoa",
    "SanMarino": "San Marino",
    "SaoTomeandPrincipe": "São Tomé und Príncipe",
    "SaudiArabia": "Saudi-Arabien",
    "Scotland": "Schottland",
    "Senegal": "Senegal",
    "Serbia": "Serbien",
    "Seychelles": "Seychellen",
    "SierraLeone": "Sierra Leone",
    "Singapore": "Singapur",
    "Slovakia": "Slowakei",
    "Slovenia": "Slowenien",
    "SolomonIslands": "Salomonen",
    "Somalia": "Somalia",
    "SouthAfrica": "Südafrika",
    "SouthGeorgiaandtheSouthSandwichIslands": "Südgeorgien und die südlichen Sandwichinseln",
    "SouthKorea": "Südkorea",
    "SouthSudan": "Südsudan",
    "Spain": "Spanien",
    "SriLanka": "Sri Lanka",
    "Sudan": "Sudan",
    "Suriname": "Suriname",
    "Svalbard": "Svalbard und Jan Mayen",
    "Swaziland": "Swasiland",
    "Sweden": "Schweden",
    "Switzerland": "Schweiz",
    "SyrianArabRepublic": "Syrien, Arabische Republik",
    "Taiwan": "Taiwan",
    "Tajikistan": "Tadschikistan",
    "Tanzania": "Tansania",
    "Thailand": "Thailand",
    "TimorLesteEastTimor": "Timor-Leste (Osttimor)",
    "Togo": "Togo",
    "Tokelau": "Tokelau",
    "Tonga": "Tonga",
    "TrinidadandTobago": "Trinidad und Tobago",
    "Tunisia": "Tunesien",
    "Turkey": "Türkei",
    "Turkmenistan": "Turkmenistan",
    "TurksandCaicosIslands": "Turks- und Caicosinseln",
    "Tuvalu": "Tuvalu",
    "Uganda": "Uganda",
    "Ukraine": "Ukraine",
    "UnitedArabEmirates": "Vereinigte Arabische Emirate",
    "UnitedStates": "Vereinigte Staaten von Amerika",
    "UnitedStatesMinorOutlyingIslandsIsle": "Amerikanische Überseeinseln, Kleinere",
    "Uruguay": "Uruguay",
    "Uzbekistan": "Usbekistan",
    "Vanuatu": "Vanuatu",
    "VaticanCityState": "Vatikanstadt",
    "Venezuela": "Venezuela",
    "Vietnam": "Vietnam",
    "VirginIslandsUS": "Amerikanische Jungferninseln",
    "Wales": "Wales",
    "WallisandFutuna": "Wallis und Futuna",
    "WesternSahara": "Westsahara",
    "Yemen": "Jemen",
    "Zambia": "Sambia",
    "Zimbabwe": "Simbabwe"
  },
  "Cultures": {
    "xCultures": "Kulturen"
  },
  "Dashboard": {
    "Birthdays": "Geburtstage",
    "BirthdaysNoData": "Kontaktpersonen, die bald Geburtstag haben, werden hier angezeigt.",
    "GoToDashboard": "Gehe zum Dashboard",
    "HasTurned": "ist {0} geworden",
    "NoTasksArePlanned": "Es wurden keine Aufgaben geplant. Wenn Du nicht weißt, wie Du eine Aufgabe planen kannst, wenden Dich an uns, um Support zu dieser praktischen Funktion zu erhalten.",
    "OneItemToBeChecked": "1 zu prüfendes Element",
    "WelcomeToSystemOne": "Wilkommen bei SystemOne.",
    "WillTurn": "wird {0}",
    "xDashboard": "Dashboard",
    "XItemsToBeChecked": "{0} zu prüfende Elemente"
  },
  "DateTime": {
    "AboutADay": "etwa einem Tag",
    "AboutAMinute": "etwa einer Minute",
    "AboutAMonth": "etwa einem Monat",
    "AboutAnHour": "etwa einer Stunde",
    "AboutAYear": "etwa einem Jahr",
    "AboutXHours": "etwa {0} Stunden",
    "After": "Nach",
    "afterSendDate": "nach Sendungsdatum",
    "AllDay": "Ganzer Tag",
    "Annual": "Jährlich",
    "Annually": "Jährlich",
    "Before": "Vor",
    "Calendar": "Kalender",
    "CalendarStartsOn": "Kalender beginnt am",
    "CurrentMonth": "laufender Monat",
    "CurrentQuarter": "laufendes Quartal",
    "CurrentWeek": "laufende Woche",
    "CurrentYear": "laufendes Jahr",
    "Daily": "Täglich",
    "Date2Error": "Das Enddatum liegt vor dem Startdatum oder das Enddatum stimmt mit dem Startdatum überein.",
    "DateAndTimeDetails": "Datums- und Zeitinformationen",
    "DateDetails": "Details zum Datum",
    "DateDifferenceTooBig": "Der Abstand zwischen Start- und Enddatum ist zu groß.",
    "days": "Tage",
    "DaysOverDue": "Tage überfällig",
    "DeliveryDate": "Lieferdatum",
    "Due": "Fälligkeit",
    "DueDate": "Fälligkeitsdatum",
    "DueDates": "Fälligkeitsdaten",
    "DueNowDifference": "Anzahl der Tage zwischen dem Fälligkeitsdatum und heute",
    "Duration": "Dauer",
    "EndDate": "Enddatum",
    "EndTime": "Endzeit",
    "EveryTwoWeeks": "Alle 2 Wochen",
    "From": "von",
    "FromToday": "ab heute",
    "FromUntil": "von / bis",
    "FromUntilAndIncluding": "von / bis und einschließlich",
    "HasOtherItems": "Hat andere {0}:",
    "InTheNight": "Nachts",
    "InTheNightOf": "In der Nacht vom",
    "InTheNightOfXY": "In der Nacht von {0} - {1}",
    "InvalidDate": "Ungültiges Datum",
    "IsYourLocalTime": "Ist  Ihre lokale Zeit <b>{0}</b>? Wenn dies nicht richtig ist, ändern Sie die Zeitzone in Ihrem <a href=\"/Personal/Settings\">persönlichen Einstellungen</a>.",
    "lblDate": "Datum",
    "lblEnd": "Ende",
    "lblIn": "In",
    "lblOn": "Am",
    "lblUntil": "bis",
    "LessThanAMinute": "wenigen Sekunden",
    "LocalTime": "Lokale Zeit",
    "Month": "Monat",
    "MonthCalendar": "Monatskalender",
    "Monthly": "Monatlich",
    "MonthView": "Monatsansicht",
    "MoreThanNrDaysTooLate": "Mehr als {0} Tage zu spät",
    "NextDay": "Nächster Tag",
    "NextMonth": "Nächster Monat",
    "Night": "Nacht",
    "NoAvailableDates": "Keine verfügbaren Tage",
    "On": "Am",
    "OneDayLeft": "Noch 1 Tag über",
    "OnShowDate": "Am Tag der Show",
    "OverDueAstrisk": "Gibt ein *-Zeichen zurück, wenn die Fälligkeit für das Dokument überschritten ist",
    "PerDay": "pro Tag",
    "PerHalfYear": "pro Halbjahr",
    "Period": "Termin",
    "PerMonth": "pro Monat",
    "PerQuarter": "pro Quartal",
    "PerWeek": "pro Woche",
    "PerYear": "pro Jahr",
    "PickADate": "Datum auswählen",
    "PreciseDate": "Genaues Datum",
    "PreferredDate": "Bevorzugtes Datum",
    "PreviousMonth": "Vorheriger Monat",
    "Quarter": "Quartal",
    "Quarter1": "1. Quartal",
    "Quarter2": "2. Quartal",
    "Quarter3": "3. Quartal",
    "Quarter4": "4. Quartal",
    "Quarterly": "Vierteljährlich",
    "SelectDatesInCalendar": "Tage im Kalender auswählen",
    "SelectMaximumXDates": "Select maximum {0} dates",
    "Semiannual": "Halbjährlich",
    "Semiannually": "Halbjährlich",
    "Send": "Versand",
    "SendDate": "Versanddatum",
    "SendDates": "Versanddaten",
    "SendDueDifference": "Anzahl der Tage zwischen dem Sende- und dem Fälligkeitsdatum",
    "SendNowDifference": "Anzahl der Tage zwischen dem Sendedatum und heute",
    "Since": "Seit",
    "StartDate": "Startdatum",
    "StartTime": "Startzeit",
    "SummerTime": "Sommerzeit",
    "Term": "Termin",
    "TheEndDateIsBeforeTheBeginDate": "Das Enddatum liegt vor dem Startdatum.",
    "ThisAppointmentBeginsInTheNight": "Dieser Termin beginnt <b>in der Nacht vom {0}</b>, dieses Datum wird in allen Ansichten und Dokumenten in SystemOne angezeigt. Tatsächlich findet dieser Termin statt am <b>{1}</b>.",
    "ThisAppointmentTakesPlace": "Dieser Termin findet statt",
    "Time": "Zeit",
    "Timezone": "Zeitzone",
    "Today": "Heute",
    "UntilAndIncluding": "Bis und einschließlich",
    "UntilAndIncludingAbbreviated": "u./i.",
    "UntilNrDaysTooLate": "Bis {0} Tage zu spät",
    "Week": "Woche",
    "Weekly": "Wöchentlich",
    "XAgo": "vor {0}",
    "XDaysAbbreviated": "{0}T",
    "XDaysAfterShow": "{0} Tage nach der Show",
    "XDaysBeforeShow": "{0} Tage vor der Show",
    "XDaysLeft": "noch {0} Tage",
    "XDaysOpen": "{0} Tage offen",
    "XDaysOverDue": "{0} Tage überfällig",
    "XFromNow": "{0} ab jetzt",
    "XHours": "{0} Stunden",
    "XHoursAbbreviated": "{0}Std.",
    "XHoursAfterShow": "{0} Stunden nach der Show",
    "XHoursBeforeShow": "{0} Stunden vor der Show",
    "XMinutes": "{0} Minuten",
    "XMinutesAbbreviated": "{0}Min.",
    "XMonths": "{0} Monate",
    "XMonthsAbbreviated": "{0}Mon.",
    "XQuarters": "{0} Quartale",
    "XSeconds": "{0} Sekunden",
    "XSecondsAbbreviated": "{0}Sek.",
    "XWeeks": "{0} Wochen",
    "XWeeksAfterShow": "{0} Wochen nach der Show",
    "XWeeksBeforeShow": "{0} Wochen vor der Show",
    "XYearAgo": "{0} Jahr her",
    "XYears": "{0} Jahre",
    "XYearsAbbreviated": "{0}J",
    "XYearsAgo": "{0} Jahre her",
    "YDay": "1 Tag",
    "YDayOpen": "1 Tag offen",
    "YDayOverDue": "1 Tag überfällig",
    "Year": "Jahr",
    "YHour": "1 Stunde",
    "YMinute": "1 Minute",
    "YMonth": "1 Monat",
    "YQuarter": "1 Quartal",
    "YSecond": "1 Sekunde",
    "YWeek": "1 Woche",
    "YYear": "1 Jahr"
  },
  "DealTypes": {
    "Landed": "Landed"
  },
  "Distance": {
    "Kilometers": "Kilometer",
    "Miles": "Meilen",
    "Radius": "Radius"
  },
  "Contract": {
    "Contracts": "Verträge",
    "EditContract": "Vertrag bearbeiten",
    "NewContract": "Neuer Vertrag",
    "resxContract": "Vertrag"
  },
  "Document": {
    "Addressee": "Empfänger",
    "CopyDocument": "Dokument kopieren",
    "CreditForInvoiceNumber": "Gutschrift für Rechnung {0}",
    "DocumentDetails": "Dokumentdetails",
    "DocumentNumber": "Dokumentnummer",
    "DocumentNumberPlaceHolder": "Nach Dokumentnummer suchen",
    "FilterByAddressee": "Nach Empfänger filtern",
    "ForInvoice": "Für Rechnung",
    "ForPossibleUseInTemplate": "Für mögliche Verwendung in Exportvorlage",
    "ItemsPerStatus": "{0} per status",
    "LinkedDocument": "Verknüpftes Dokument",
    "LinkedDocuments": "Verknüpfte Dokumente",
    "MakeCredit": "Gutschrift erstellen",
    "MakeCreditInformation": "Wenn eine Gutschrift aus einer bestehenden Rechnung generiert wird, wird ein neues Dokument erstellt. Produkt- und Serviceelemente werden kopiert und der Betrag wird in Minusbeträge umgerechnet.\r\n\r\nBei direkter Begleichung werden interne Zahlungen automatisch in das vorhandene und neue Dokument eingefügt. Diese Option ist nur für Rechnungen geeignet, die als Gesamtbetrag gutgeschrieben werden müssen und die noch nicht beglichen wurden.",
    "NoLinkedDocumentsFound": "Es wurden keine verknüpften Dokumente gefunden",
    "NoNumber": "keine Nummer",
    "NumberOfDocuments": "Anzahl Dokumente",
    "OnlyDeleteLast": "Nur das letzte Dokument einer Serie kann entfernt werden, da sonst die Nummerierung nicht korrekt ist. Das letzte Dokument in dieser Serie ist {0}.",
    "ProForma": "Pro forma",
    "ProFormaDocumentsHaveNoNumberYet": "Pro forma Dokumenten haben noch keine Nummer",
    "Progress": "Fortschritt",
    "ReceivedOrCancelled": "Erhalten oder abgesagt",
    "SelectTheProductAndServiceItemsThatNeedToBeCopied": "Wähle die Produkt- und Dienstelemente aus, die in das neue Dokument kopiert werden sollen.",
    "Subject": "Betreff",
    "TheDueDateMustBeBetween": "Das Fälligkeitsdatum dieses Dokuments muss zwischen {0} und {1} liegen.",
    "TheSendDateMustBeBetween": "Das Absendedatum dieses Dokuments muss zwischen dem {0} und dem {1} liegen.",
    "ThisActionIsNotAllowedWithALockedRecord": "Diese Aktion ist mit einem gesperrten Eintrag nicht möglich.",
    "ThisActionIsNotAllowedWithProforma": "Diese Aktion ist mit einem Proforma-Dokument nicht erlaubt. Verwenden Sie das Dialogfenster \"Bearbeiten\", um das Proforma-Dokument in ein nummeriertes Dokument umzuwandeln.",
    "TryingToAddDocumentWithoutAddingProductAndServiceItems": "Du versuchst gerade, ein Dokument ohne Produkte zu erstellen. Möchtest Du den Vorgang wirklich fortsetzen?",
    "UnlinkWarning": "Du bist dabei, die Verknüpfung für dieses Element aufzuheben. Dies hat keinen Einfluss auf die Produkte im Dokument.",
    "YouCanLinkUpToItemsAtOnce": "Du kannst bis zu {0} {1} gleichzeitig verlinken. Wenn Du mehr verlinken möchtest, wiederhole diesen Schritt nach dem Speichern."
  },
  "DocumentLifeCycle": {
    "Completed": "Abgeschlossen",
    "Discarded": "Verworfen",
    "Draft": "Konzept",
    "InDraft": "im Konzept",
    "InReview": "zur Überprüfung",
    "OutForSignature": "Zur Unterschrift",
    "Review": "Überprüfung"
  },
  "PurchaseAgreement": {
    "EditPurchaseAgreement": "Einkaufsvertrag bearbeiten",
    "NewPurchaseAgreement": "Neuer Einkaufsvertrag",
    "PurchaseAgreements": "Einkaufsverträge",
    "resxPurchaseAgreement": "Einkaufsvertrag"
  },
  "Quote": {
    "EditQuote": "Angebot bearbeiten",
    "NewQuote": "Neues Angebot",
    "Quotes": "Angebote",
    "resxQuote": "Angebot"
  },
  "Email": {
    "AttachFilesToEmail": "Dateien an E-Mail anhängen",
    "DownloadLinkInEmail": "Download Link in E-Mail",
    "NewEmail": "Neue E-Mail",
    "OnlyOneEmail": "Nur eine E-Mail-Adresse darf angegeben werden"
  },
  "EmbedWizard": {
    "ConfigurationWizard": "Einstellungsmodul",
    "Dark": "Dunkel",
    "Display": "Anzeigen",
    "EmbedShows": "Shows einbetten",
    "EmbedShowsOnYourSite": "Shows auf Webseite einbetten",
    "Font": "Schrifttyp",
    "Header": "Kopfzeile",
    "Height": "Höhe",
    "Light": "Hell",
    "NoPublishedShows": "Aktuell keine Shows veröffentlicht",
    "Pagination": "Seitenumbruch",
    "PastShows": "Vergangene Shows",
    "PerPage": "Per Seite",
    "resxYourWebsite": "Meine Webseite",
    "Theme": "Anzeigemodus",
    "UpcomingShows": "Zukünftige Shows",
    "Width": "Breite"
  },
  "ESign": {
    "AddedFromStorage": "Aus Speicher hinzugefügt",
    "AddViewer": "Betrachter hinzufügen",
    "AllowAutoSign": "Autosignatur erlauben",
    "AllSignaturesWillBeRemovedWarning": "Alle Unterschriften werden entfernt. Jeder Teilnehmer muss erneut unterschreiben.",
    "AlreadyParticipantWarning": "Bereits als Teilnehmer für dieses Dokument vorhanden.",
    "AutomaticallySigned": "Automatisch signiert",
    "AutoSign": "Autosignatur",
    "AutosignActive": "Autosignatur aktiv",
    "ClassicWorkflow": "Klassischer Workflow",
    "CompleteManually": "Manuell ausfüllen",
    "CompleteManuallyWarning": "Du kannst ein Dokument abschließen, zum Beispiel, wenn du ein unterschriebenes Dokument per Post erhalten hast.",
    "DiscardAccessibleWarning": "Das Dokument wird für die Teilnehmer nicht mehr zugänglich sein.",
    "DiscardWarning": "Du verwerfst ein Dokument, wenn du es nicht mehr unterschreiben möchtest.",
    "DocumentToBeSignedIsCombinedFrom": "Dokument zum Unterschreiben wird zusammengestellt aus",
    "DownloadDocument": "Dokument herunterladen",
    "eSign": "eSign",
    "eSignReady": "eSign-fähig",
    "eSignSignatureSettings": "eSign Signatureinstellungen",
    "GeneratedFromTemplate": "Aus Vorlage erstellt",
    "inVersion": "in Version",
    "ManuallyCompleted": "Manuell abgeschlossen",
    "MissingSignature": "Fehlende Signatur",
    "NoOrderPreference": "Keine Präferenz für Reihenfolge",
    "OtherFiles": "Sonstige Dateien",
    "ParticipantsCannotAccessDocumentUnderRevisionWarning": "Teilnehmer können nicht auf das Dokument zugreifen, während eine neue Version bearbeitet wird.",
    "PdfsThatNeedToBeSigned": "PDF, die signiert werden müssen",
    "PendingSignature": "Warten auf Unterschrift",
    "Representing": "Vertritt",
    "Sender": "Absender",
    "SenderShouldSignFirst": "Der Absender sollte zuerst signieren",
    "SendInvitations": "Einladungen senden",
    "SendOutWarning": "Sobald das Dokument zur Unterschrift vorliegt, können darin keine Änderungen mehr vorgenommen werden. Es sei denn, du startest eine neue Version.",
    "Signatories": "Unterzeichner",
    "Signatory": "Unterzeichner",
    "Signature": "Signatur",
    "Signed": "Signiert",
    "SignedBySender": "Vom Absender unterschrieben",
    "SignedBySigner": "Vom Unterzeichner unterschrieben",
    "Signer": "Unterzeichner",
    "SignerShouldSignFirst": "Der Unterzeichner sollte zuerst signieren",
    "SigningOrder": "Signierreihenfolge",
    "SignNow": "Jetzt signieren",
    "SignThisDocument": "Dieses Dokument signieren",
    "StartNewVersion": "Neue Version starten",
    "StartNewVersionWarning": "Diese Version wird verworfen und Sie kehren zum Entwurfsmodus zurück.",
    "ThisDocumentWasAgreedUponAndSignedBy": "Dieses Dokument wurde vereinbart und signiert von",
    "Uploaded": "Hochgeladen",
    "Version": "Version",
    "Versions": "Versionen",
    "ViewDocument": "Dokument anzeigen",
    "Viewer": "Betrachter",
    "Viewers": "Betrachter",
    "ViewSignedDocument": "Signiertes Dokument anzeigen",
    "WaitingForSender": "Warten auf den Absender",
    "WaitingForSigner": "Warten auf Unterzeichner",
    "YouHaveSigned": "Du hast unterzeichnet"
  },
  "ESignEmailMessage": {
    "Completed": "Jeder hat {{document.type}} {{document.number}} von <strong style=\"color: #1e1e1e\">{{brand.name}}</strong> unterschrieben.",
    "InvitationForSignatory": "<strong style=\"color: #1e1e1e\">{{brand.name}}</strong> bittet dich, {{document.type}} {{document.number}} zu unterschreiben.",
    "InvitationForViewer": "<strong style=\"color: #1e1e1e\">{{brand.name}}</strong> bittet dich, einen Blick auf {{document.type}} {{document.number}} zu werfen. Du kannst dieses Dokument überprüfen, musst es aber nicht unterschreiben.",
    "Reminder": "Wichtige Erinnerung: <strong style=\"color: #1e1e1e\">{{brand.name}}</strong> wartet seit {{document.daysAfterSend}} darauf, dass {{document.type}} {{document.number}} unterschrieben wird."
  },
  "ESignEmailSubject": {
    "Completed": "Alle Unterschriften für das {{document.type}} {{document.number}} von {{brand.name}} wurden gesammelt",
    "InvitationForSignatory": "{{brand.name}} hat dich eingeladen, {{document.type}} {{document.number}} zu unterschreiben",
    "InvitationForViewer": "{{brand.name}} hat dich eingeladen, {{document.type}} {{document.number}} zu überprüfen",
    "Reminder": "Vergiss nicht, {{document.type}} {{document.number}} für {{brand.name}} zu unterschreiben"
  },
  "ESignLanding": {
    "AllSignaturesCollected": "Alle Unterschriften erfolgreich gesammelt",
    "btnDraw": "Zeichne",
    "btnImage": "Bild",
    "DocumentDiscarded": "Dokument verworfen",
    "DocumentDiscardedMessage": "Dokument nicht verfügbar, da es verworfen wurde.",
    "Hi": "Liebe",
    "HoweverTheNameOnYourAccountIs": "Allerdings ist der Name auf deinem Konto",
    "NeverMindIAm": "Macht nichts. Ich bin",
    "ThisDocumentIsToBeReviewedBy": "Dieses Dokument soll von überprüft werden",
    "ThisDocumentShouldBeSignedBy": "Dieses Dokument sollte von unterschrieben werden",
    "WorkingOnDocument": "Am Dokument wird gearbeitet",
    "WorkingOnDocumentMessage": "Dieses Dokument ist vorübergehend nicht verfügbar, da eine neue Version erstellt wird. Du erhältst eine E-Mail, wenn eine neue Version verfügbar ist.",
    "YouCanSaveThisInASystemOneAccountMessage": "Du kannst das in einem SystemOne-Konto speichern. Das ist besonders nützlich, wenn du häufig Dokumente von verschiedenen Agenturen bekommst, die SystemOne verwenden."
  },
  "ExcelTemplate": {
    "EditExcelTemplate": "Excel-Vorlage bearbeiten",
    "ExcelTemplates": "Excel-Vorlagen",
    "NewExcelTemplate": "Neue Excel-Vorlage",
    "NewExcelTemplateFor": "Neue Excel-Vorlage für {0}",
    "resxExcelTemplate": "Excel-Vorlage",
    "UniqueRows": "Eindeutige Zeilen"
  },
  "ExceptionMessage": {
    "AskYourSystemAdministrator": "Frage Deine/n SystemadministratorIn nach Hilfe hiermit",
    "AssignAtLeast1ArtistToAccount": "Dein/e SystemadministratorIn muss Deinem Konto mindestens eine/n KünstlerIn zuweisen.",
    "MaximumXItemsCanBeCreated": "Maximal können {0} Elemente erstellt werden",
    "NoAccessToAnyBookkeepings": "Du hast keinen Zugriff auf Buchführungen",
    "NoAccessToSystemSettings": "Du hast keinen Zugriff auf die Systemeinstellungen",
    "NoAccessToTemplateSettings": "Du hast keinen Zugriff auf Vorlageneinstellungen.",
    "ThereIsAProblem": "Es gibt ein Problem",
    "ThereWasAnError": "Das war ein Fehler. Unsere Techniker sind informiert und werden das Problem beheben. Es hilft uns trotzdem, <a href=\"https://www.systemonesoftware.com/report-an-issue\">wenn Du uns erklärst</a>, was Du gemacht hast und was passiert ist.",
    "YouHaveInsufficientAccess": "Du hast keine ausreichenden Zugriffsrechte um diese Aktion durchzuführen."
  },
  "GeneralWarnings": {
    "AnEmailHasBeenSentToInformTheUser": "Es wurde eine E-Mail zur Information an die BenutzerIn gesendet.",
    "AreYouSure": "Bist Du sicher?",
    "BetaWarning": "Dieser Bereich von SystemOne befindet sich noch in der Entwicklung und wurde noch nicht veröffentlichet. Daher ist noch kein Support für diesen Bereich verfügbar. Du verwendest diese Funktion auf eigenes Risiko.",
    "CanOnlyUploadFileExtensions": "Es können nur Dateien mit den Erweiterungen {0} hochgeladen werden.",
    "ChangePersonalSettings": "Bitte aktualisiere die Informationen in der Seite \"Persönliche Einstellungen\" in SystemOne, bevor Du fortfährst.",
    "DeleteSure": "Endgültig löschen. Der Vorgang kann nicht rückgängig gemacht werden. Möchtest Du fortfahren?",
    "HasToBeAtLeastXCharacters": "Muss mindestens {0} Zeichen lang sein.",
    "InputMandatory": "Eingabe erforderlich",
    "LengthXExceedsTheMaximumOfYCharacters": "Länge {0} überschreitet die maximale {1} Anzahl Zeichen. ",
    "MaxAttachedFiles": "Maximale Grösse für angehängte Dateien ist {0} Mb.",
    "MaxUploadSize": "Die maximale Upload-Größe beträgt {0} MB.",
    "NoItemsHaveBeenSelected": "Keine Elemente ausgewählt",
    "OnlyUploadJPEGImages": "Nur JPEG-Bilder hochladen",
    "OperationCompletedSuccessfully": "Vorgang erfolgreich abgeschlossen",
    "PleaseBePatientTheServerIsProcessingYourRequest": "Bitte warten. Deine Anfrage wird vom Server verarbeitet.",
    "PleaseFillInThisField": "Bitte fülle dieses Feld aus",
    "SelectASection": "Wähle einen Bereich aus",
    "TheFileContainsNoData": "Die Datei enthält keine Daten.",
    "TheItemHasSuccessfullyBeenCopied": "Das Element wurde erfolgreich kopiert.",
    "TheLastItemCantBeDeleted": "Das letzte Element kann nicht gelöscht werden.",
    "TheMaximumHasBeenReached": "Das Maximum von ({0}) wurde erreicht.",
    "ThisActionCannotBeUndone": "Diese Aktion kann nicht rückgängig gemacht werden!",
    "ThisActionIsNotAllowedWithALockedItem": "Diese Aktion ist bei gesperrten Elementen nicht zulässig.",
    "ThisItemCanNotBeRemovedBecauseItIsInUse": "Das Element kann nicht entfernt werden, da es gerade verwendet wird",
    "Warning": "Warnung",
    "YouHaveInsufficientRightsToViewSalesAmounts": "Du verfügst nicht über ausreichende Berechtigungen, um die Umsatzbeträge anzuzeigen.",
    "YouHaveInsufficientRightToAccessThisSection": "Du verfügst nicht über ausreichende Berechtigungen, um diesen Bereich anzuzeigen."
  },
  "Exclusivity": {
    "Conflicts": "Es liegen Exklusivitätskonflikte vor",
    "ConflictsWithCurrent": "Konflikte mit aktuellen Exklusivitätsklauseln der Show",
    "daysAfterShow": "Tage nach der Show",
    "daysBeforeShow": "Tage vor der Show",
    "DisplayScheduleConflicts": "Terminkonflikte anzeigen",
    "ExclusivityConflicts": "Exklusivitätskonflikte",
    "NoArtistSelected": "Kein Künstler ausgewählt",
    "NoConflicts": "Es liegen keine Exklusivitätskonflikte vor",
    "NoExclusivityDefined": "Keine Exklusivität für diese Show definiert",
    "NoScheduleConflicts": "Keine Terminkonflikte",
    "NoVenueSelected": "Kein Veranstaltungsort ausgewählt",
    "resxConflicts": "Konflikte",
    "resxExclusivity": "Exklusivität",
    "ScheduleConflicts": "Terminkonflikte",
    "ShowCancelled": "Show abgesagt",
    "VenueNotFoundOnMap": "Veranstaltungsort nicht auf Karte gefunden"
  },
  "ExternalCalendar": {
    "Active": "Active",
    "AutomaticRefresh": "Automatic refresh",
    "AutomaticRefreshStopped": "Automatic refresh stopped",
    "EditExternalCalendar": "Externen Kalender bearbeiten",
    "ExternalCalendars": "Externe Kalender",
    "ExternalEvent": "Externes Ereignis",
    "ExternalEvents": "Externe Ereignisse",
    "IncludeExternalEvents": "Include external events",
    "LastRefresh": "Last refresh",
    "NewExternalCalendar": "Neuer externer Kalender",
    "resxExternalCalendar": "Externer Kalender",
    "Stopped": "Stopped"
  },
  "Feed": {
    "AllowIpAddress": "IP-Adresse erlauben",
    "AllowIpAddressDesc": "Geben Sie die IP-Adresse ein, der Sie Zugriff auf den Feed erlauben möchten. Ihre aktuelle IP-Adresse lautet: {0}",
    "AvailableFeeds": "Verfügbare Feeds",
    "DataSource": "Datenquelle",
    "EmailFeedOverview": "Übersicht E-Mail-Feed",
    "FeedCreateWarningMaximum": "Die maximale Anzahl von {0} wurde erreicht. Lösche einen vorhandenen Feed oder wende Dich an SystemOne, um ein Upgrade für Dein Konto zu erhalten.",
    "FeedOverview": "Übersicht Feed",
    "FeedOwner": "Feed-Eigentümer",
    "FeedType": "Feedtyp",
    "FeedUrl": "Feed-URL",
    "iCalendarFeed": "iCalendar-Feed",
    "iCalendarFeedDescription": "Dieser Feed enthält Kalenderdaten im <a href=\"http://en.wikipedia.org/wiki/ICalendar\" target=\"_blank\">iCalendar/RFC 5545-Format</a>. Kompatible Anwendungen wie iCal können diesen Feed abonnieren.",
    "iCalendarFeeds": "iCalendar-Feeds",
    "IncludeFlights": "Flüge einbeziehen",
    "IncludePreviousEvents": "Vorherige Ereignisse einschließen",
    "IpAddress": "IP-Adresse",
    "IPAddresses": "IP-Adressen",
    "IpAddressRestriction": "IP-Adresseinschränkung",
    "ItemsInFeed": "Einträge im Feed",
    "MyOwnIpAddress": "Meine eigene IP-Adresse",
    "NoIPAddressesFound": "Es wurden keine IP-Adressen zugewiesen.",
    "NoRecordsInQueryAreYouSureThePublicationStatusHasBeenSetCorrectly": "Kein(e) Datensätze angefragt. Sind Sie sicher, dass der Veröffentlichungsstatus ordnungsgemäß festgelegt wurde?",
    "NotStoredInCacheMemory": "Nicht im Zwischenspeicher gespeichert",
    "OnlyVisibleToTheUserOfThisFeed": "Nur für den Benutzer dieses Feeds sichtbar",
    "OutdatedTechnologyWarning": "Hierbei handelt es sich um veraltete Technologie, die ab dem 1. Juli 2022 entfernt wird.<br />Sie wird ersetzt durch: <a data-beacon-article-sidebar=\"244\">Shows auf deiner Website einbetten</a></li>",
    "SelectionParameters": "Auswahlparameter",
    "Support": "Support",
    "TheEmailContainsAnOverview": "Die E-Mail enthält eine Übersicht aller für den Benutzer verfügbaren Feeds, einschließlich der eindeutigen Feed-URLs.",
    "UpTo1Feed": "Bis zu 1 Feed",
    "UpToNrFeeds": "Bis zu {0} Feeds",
    "XmlEventFeed": "Xml event feed",
    "XmlEventFeeds": "Xml event feeds",
    "XRecordsFoundInQueryWhichExceedTheMaximum": "{0} Datensätze in Anfrage gefunden, das Maximum ist überschritten. Aufgrund von Leistungseinschränkungen werden nur die ersten {2} Datensätze im Feed angezeigt.",
    "YouNeedToLogOn": "Du musst Dich bei <a href=\"http://www1.systemonesoftware.com\" target=\"_blank\">SystemOne</a> anmelden, um die zulässigen IP-Adressen für diesen Feed festzulegen."
  },
  "Festival": {
    "Festivals": "Festivals",
    "resxFestival": "Festival"
  },
  "File": {
    "AttachFiles": "Dateien anhängen",
    "ChooseFiles": "Dateien auswählen",
    "DeleteAll": "Alle löschen",
    "DeleteAllConfirmation": "Möchtest Du wirklich alle ausgewählten Dateien löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
    "Deleted": "gelöscht",
    "DidYouMeanToSelectFiles": "Ihr E-Mail-Text enthält einen Download-Link. Wollten Sie Dateien auswählen?",
    "Download": "Herunterladen",
    "DownloadAll": "Alle herunterladen",
    "DownloadLinkMissing": "Deiner E-Mail fehlt der Download-Link",
    "DropFilesHere": "Lege Dateien hier ab",
    "ErrorFileContainNotAllowedChars": "Der Dateiname enthält unzulässige Zeichen.",
    "ExpectAttachment": "Anlage erwarten",
    "Files": "Dateien",
    "FilesOverview": "Dateiübersicht",
    "inUse": "wird verwendet",
    "NewFile": "Neue Datei",
    "NoFilesAttached": "Keine Dateien beigefügt",
    "NoFilesUploaded": "Keine Dateien hochgeladen",
    "OnlineStorage": "Online-Speicher",
    "OnlineStorageInUse": "Online-Speicher wird verwendet",
    "resxFile": "Datei",
    "SavePdfToOnlineStorage": "PDF in Online-Speicher speichern",
    "SelectOneFileOption": "Wähle bei Anlagen mindestens eine Option aus",
    "Size": "Größe",
    "SystemWideFiles": "Systemweite Dateien",
    "UploadedAFile": "hat eine Datei hochgeladen",
    "UploadedBy": "Hochgeladen von",
    "UploadedFiles": "hat {0} Dateien hochgeladen",
    "UploadedOn": "Hochgeladen auf",
    "UploadingFiles": "Dateien werden hochgeladen"
  },
  "Financial": {
    "AgreedExchangeRate": "Vereinbarter Wechselkurs",
    "Amount": "Betrag",
    "Amounts": "Beträge",
    "ArtistFee": "Künstlerhonorar",
    "Average": "Durchschnitt: {0}",
    "BalanceImmediately": "Sofort begleichen",
    "BankWireFee": "Bankgebühr",
    "BelgianOgmCode": "OGM Code für Rechnungen in Belgien",
    "BookingFee": "Buchungsgebühr",
    "BookkeepingSoftware": "Buchhaltungssoftware",
    "Costs": "Kosten",
    "CostsPerUnit": "Selbstkostenpreis",
    "CostsSide": "Kostenseite",
    "CostsTax": "Kostensteuer",
    "CostsTotal": "Kosten gesamt",
    "Credit": "Gutschrift",
    "Currency": "Währung",
    "CurrentExchangeRate": "Aktueller Wechselkurs",
    "Debit": "Soll",
    "ExchangeRate": "Wechselkurs",
    "ExchangeRateDisclaimer": "SystemOne kann die Richtigkeit der angezeigten Wechselkurse nicht garantieren. Überprüfe die aktuellen Kurse, bevor Du Transaktionen durchführst, die von Wechselkursänderungen beeinflusst werden könnten. Die Kurse dienen nur zu Informationszwecken. Änderungen vorbehalten. Die Kurse für die tatsächlichen Transaktionen variieren.",
    "ExchangeRates": "Wechselkurse",
    "Finance": "Finanzabteilung",
    "FinancialDetails": "Finanzielle Informationen",
    "FinancialOffer": "Finanzielles Angebot",
    "HasBeenInvoiced": "In Rechnung gestellt",
    "InvoiceDetails": "Rechnungsdetails",
    "Invoices": "Rechnungen",
    "NoPreferredTradingCurrencyHasBeenSet": "Es wurde keine bevorzugte Handelswährung festgelegt.",
    "NotYetInvoiced": "Noch nicht in Rechnung gestellt",
    "OgmCode": "OGM Code (Belgien)",
    "Outstanding": "Ausstehend",
    "OutstandingAndOverDueDate": "Ausstehend und Zahlungsfrist abgelaufen",
    "OverDue": "Überfällig",
    "OverviewOfExchangeRates": "Übersicht Wechselkurse",
    "PreferredTradingCurrency": "Bevorzugte Handelswährung",
    "PriceExcludingTax": "Preis exkl. USt",
    "PriceExTax": "Preis exkl. USt",
    "PriceIncludingTax": "Preis inkl. USt",
    "PricePerUnit": "Einzelpreis",
    "Profit": "Gewinn",
    "Quantity": "Menge",
    "RecommendedPrice": "Empfohlener Preis",
    "Sales": "Verkauf",
    "SalesPerUnit": "Verkaufspreis",
    "SalesSide": "Ertragsseite",
    "SalesTax": "Umsatzsteuer",
    "SalesTotal": "Verkauf gesamt",
    "SalesTotalPerOwner": "Gesamtverkauf pro Besitzer",
    "Settled": "Abgewickelt",
    "Subtotal": "Zwischensumme",
    "TaxGroups": "Steuergruppen",
    "TaxPercentage": "USt {0}",
    "TaxRate": "USt-Tarif",
    "ToBeInvoiced": "Noch in Rechnung zu stellen",
    "Total": "Summe",
    "TotalCostsExTax": "Gesamtkosten exkl. Steuer",
    "TotalCostsIncTax": "Gesamtkosten inkl. Steuer",
    "TotalCostsTax": "Steuern Gesamtkosten",
    "TotalExTax": "Summe exkl. Steuer",
    "TotalIncTax": "Summe  inkl. Steuer",
    "TotalOutstanding": "Summe ausstehend",
    "TotalProfit": "Gesamtgewinn",
    "Totals": "Summen",
    "TotalSalesExTax": "Gesamtverkauf exkl. Steuer",
    "TotalSalesExTaxBase": "Umsatzsteuer exkl. Steuer in Basiswährung",
    "TotalSalesIncTax": "Gesamtverkauf inkl. Steuer",
    "TotalSalesIncTaxBase": "Umsatzsteuer inkl. Steuer in Basiswährung",
    "TotalSalesTax": "Umsatzsteuer gesamt",
    "TotalsByProductType": "Summen nach Produkttypen",
    "TotalSettled": "Gesamtbetrag abgewickelt",
    "VAT": "Mehrwertsteuer"
  },
  "FinancialRisks": {
    "Blocklist": "Sperrliste",
    "FullTrust": "Volles Vertrauen",
    "HighRisk": "Hohes Risiko",
    "LowRisk": "Geringes Risiko"
  },
  "Flight": {
    "AddSegment": "Segment hinzufügen",
    "Aircraft": "Flugzeug",
    "Airline": "Fluggesellschaft",
    "Airport": "Flughafen",
    "Airports": "Flughäfen",
    "Arrival": "Ankunft",
    "ArrivalCannotBeBeforeDeparture": "Ankunft kann nicht mehr als 36 Stunden vor Abflug sein. ",
    "AtLeast1SegmentShouldBe": "Es sollte mindestens 1 Segment hinzugefügt werden.",
    "BetweenFlights": "zwischen Flügen",
    "BookingCode": "Buchungsnummer",
    "By": "Von",
    "CommercialFlight": "Linienflug",
    "CompleteFlightBookingDetails": "Details zur Flugbuchung ausfüllen",
    "ConfirmationNumber": "Bestätigungsnummer",
    "Departure": "Abflug",
    "Distance": "Entfernung",
    "Duration": "Dauer",
    "EditFlightBookingDetails": "Details zur Flugbuchung ausfüllen",
    "EditFlightSegment": "Details zur Flugbuchung bearbeiten",
    "FlightBookingDetails": "Details zur Flugbuchung",
    "FlightLookup": "Flugsuche",
    "FlightNumber": "Flugnummer",
    "Flights": "Flüge",
    "FlightSegment": "Flugsegment",
    "FlightSegments": "Flugsegmente",
    "FlightsInThePastCannot": "Nach Flügen in der Vergangenheit kann nicht automatisch gesucht werden.",
    "FrequentFlyer": "Frequent Flyer",
    "Gate": "Flugsteig",
    "ManualInputOfFlightSegment": "Manuelle Eingabe von Flugsegmenten",
    "MaximumSegmentsError": "Es können maximal {0} Segmente hinzugefügt werden.",
    "NewFlight": "Neuer Flug",
    "NoMatchingFlightsFound": "Es konnten keine passenden Flüge in der Online-Datenbank gefunden werden. Ändere Deine Suchkriterien oder gebe den Flug manuell ein.",
    "PrivateJet": "Private jet",
    "Reservation": "Reservierung",
    "resxFlight": "Flug",
    "Seats": "Sitzplätze",
    "Terminal": "Terminal",
    "TravelAgency": "Reisebüro",
    "TravelClass": "Reiseklasse",
    "Traveller": "ReisendeR",
    "Travellers": "Reisende",
    "UponImportingThisItemTravellers": "Beim Importieren dieses Elements konnten die Reisenden nicht automatisch Kontakten in der Datenbank zugewiesen werden."
  },
  "FlightTravelClasses": {
    "BusinessClass": "Business Class",
    "Economy": "Economy",
    "FirstClass": "First Class",
    "PremiumEconomy": "Premium Economy"
  },
  "FreeField": {
    "AllFreeFieldsInThisCategory": "Alle freien Felder in dieser Kategorie werden beim Löschen in eine bestehende Kategorie verschoben.",
    "AssignedFreeFields": "Zugewiesene freie Felder",
    "AssignFreeField": "Freie Felder zuweisen",
    "Category": "Kategorie",
    "Checkbox": "Kontrollkästchen",
    "Choice": "Auswahl",
    "Choices": "Auswahl",
    "DefaultValue": "Standardwert",
    "DeleteFreeFieldConfirm": "Wenn Du dieses freie Feld löscht, werden alle Informationen, die Du für dieses Feld eingetragen hast, ebenfalls gelöscht. Möchtest Du fortfahren?",
    "DisplayFreeFields": "Freie Felder anzeigen",
    "DisplayPermissions": "Berechtigungen anzeigen",
    "DownloadAnOveriewOfMergeFieldCodes": "Laden Sie eine Übersicht über die Mergefield-Codes zur Verwendung in Vorlagen herunter.",
    "DropdownForm": "Dropdown-Formular",
    "EditCategoryFor": "Kategorie für {0} bearbeiten",
    "EditFreeField": "Freies Feld bearbeiten",
    "ExternalPermissionProfile": "Externes Berechtigungsprofil",
    "ExternalPermissionProfiles": "Externe Berechtigungsprofile",
    "FieldType": "Feldtyp",
    "FreeFieldCategories": "Kategorien für freies Feld",
    "FreeFieldCategoriesFor": "Kategorien für freies Feld für {0}",
    "FreeFieldCategory": "Kategorien für freies Feld",
    "FreeFieldPermissionProfile": "Berechtungsprofil für Freie Felder",
    "FreeFieldPermissionProfiles": "Berechtigungsprofile für Freie Felder",
    "FreeFields": "Freie Felder",
    "FreeFieldsFor": "Freie Felder für {0}",
    "FreeFieldsInThisCategory": "Freie Felder in dieser Kategorie",
    "HiddenFromItinerary": "Von Itinerary ausgeblendet",
    "HiddenFromMobileApp": "Von Mobil-App ausgeblendet",
    "HideFromItinerary": "Von Itinerary ausblenden",
    "HideFromMobileApp": "Von Mobil-App ausblenden",
    "InternalPermissionProfile": "Internes Berechtigungsprofil",
    "InternalPermissionProfiles": "Interne Berechtigungsprofile",
    "lblDate": "Datum",
    "lblDateTime": "Datum + Zeit",
    "LongText": "Text in mehreren Zeilen",
    "NewCategory": "Neue Kategorie",
    "NewCategoryFor": "Neue Kategorie für {0}",
    "NewChoice": "Neue Auswahl",
    "NewFreeField": "Neues freies Feld",
    "NoChoiceAvailable": "Keine Auswahl verfügbar",
    "NoFreeFieldsHaveBeenAssigned": "Es wurden keine freien Felder zugeordnet.",
    "NoProfileSelected": "Kein Profil ausgewählt",
    "NumberWithDecimals": "Nummer mit Dezimalstellen",
    "NumberWithoutDecimals": "Nummer ohne Dezimalstellen",
    "OpenByDefault": "Standardmäßig öffnen",
    "Percentage": "Prozentsatz",
    "PermissionProfile": "Berechtigungsprofil",
    "PermissionProfileNotSet": "Berechtigungsprofil nicht ausgewählt",
    "PermissionProfiles": "Berechtigungsprofile",
    "ReferenceCode": "Mergefield-Code",
    "ReferenceCodeDescription": "Referenzcode zur Verwendung in Vorlagen",
    "ReferenceCodesOverview": "Übersicht Mergefield-Codes",
    "resxFreeField": "Freies Feld",
    "SetTheirProfileTo": "Das Profil ändern auf: \"{0}\"",
    "ShortText": "Text auf 1 Zeile",
    "TextDisplayOnly": "Nur Text anzeigen",
    "ThisReferenceCodeHasAlreadyBeenUsedForAnotherFreeField": "Dieser Mergefield-Code wurde bereits für ein anderes Feld verwendet.",
    "UserHasNoAccessMessage": "Du verfügst nicht über ausreichend Zugriffsrechte, um diesen Wert zu ändern.",
    "WarningChangeDropdown": "Sei beim Ändern der Auswahl vorsichtig, wenn Du dieses freie Feld bereits verwendest. Eine zuvor gespeicherte Auswahl geht möglicherweise verloren.",
    "WarningWithUpdate": "Nachdem Du den Mergefield-Code geändert hast, müssen die Vorlagen manuell dort aktualisiert werden, wo der alte Code verwendet wurde.",
    "WhatShouldHappen": "Was soll mit zugeteilten BenutzerInnen passieren?",
    "YesNoNoChoice": "Ja, nein oder keine Auswahl."
  },
  "Gdpr": {
    "DataProcessingAgreement": "Vereinbarung zur Datenverarbeitung",
    "resxGdpr": "DSGVO"
  },
  "Genders": {
    "Female": "weiblich",
    "Male": "männlich",
    "Other": "Sonstiges"
  },
  "GeneralLabels": {
    "Abbreviation": "Abkürzung",
    "AddToDatabase": "Zur Datenbank hinzufügen",
    "All": "Alle",
    "Announcement": "Bekanntmachung",
    "Area": "Raum",
    "Artists": "KünstlerIn",
    "Ascending": "aufsteigend",
    "AssignedFunctionGroups": "Zugewiesene Funktionsgruppen",
    "AssignFunctionGroup": "Funktionsgruppe zuweisen",
    "AssignTo": "Zuweisen zu",
    "AtLeast1ItemShouldBeSelected": "Es muss mindestens 1 Element ausgewählt werden.",
    "AtLeastXItemsShouldBeSelected": "Es müssen mindestens {0} Elemente ausgewählt werden.",
    "Available": "Verfügbar",
    "BackupMadeOn": "Sicherung erfolgte am",
    "Browser": "Browser",
    "CallingCode": "Ländervorwahl",
    "Categories": "Kategorien",
    "ChangeLog": "Change log",
    "ChildOfItem": "Kind von {0}",
    "ChooseAColor": "Wählen Sie eine Farbe aus",
    "clear": "löschen",
    "ClearFilters": "Filtereinstellungen löschen",
    "ClearSelection": "Auswahl aufheben",
    "ClickHere": "Klicke hier",
    "Comment": "Kommentar",
    "CommentOn": "Kommentar zu",
    "Configure": "Konfigurieren",
    "ConfirmUpgrade": "Upgrade bestätigen",
    "ContactDetails": "Kontaktdetails",
    "DataBehindTheScreens": "Daten hinter den Bildschirmen",
    "Delete": "Löschen",
    "DeleteAllItemsInSelection": "Alle {0} in der Auswahl löschen.",
    "Descending": "absteigend",
    "Description": "Beschreibung",
    "Details": "Details",
    "Display": "Anzeigen",
    "DisplayMySelection": "Meine Auswahl anzeigen",
    "DragItemToSortList": "{0} ziehen, um Liste zu sortieren",
    "Email": "E-Mail",
    "EmailContent": "E-Mail-Inhalt",
    "EmailIsInvalid": "Die E-Mail-Adresse ist ungültig",
    "Emails": "E-Mails",
    "EnterValue": "Wert eingeben",
    "ErrorApplyingFilters": "Etwas ist schiefgegangen. Versuche es nochmal mit anderen Filtern",
    "ExistingRecord": "Vorhandener Datensatz",
    "ExtraInformation": "Zusätzliche Informationen",
    "Favorite": "Favorit",
    "FileSize": "Dateigröße",
    "Filter": "Filter",
    "FilterBy": "Nach {0} filtern",
    "FirstItem": "Erstes Element",
    "ForInstance": "zum Beispiel",
    "FunctionGroup": "Funktionsgrupppe",
    "FunctionGroups": "Funktionsgrupppen",
    "General": "Allgemein",
    "GeneralDetails": "Allgemeine Details",
    "GotoNextPage": "Nächste Seite",
    "GotoPage": "Gehe zu Seite {0}",
    "GotoPreviousPage": "Vorige Seite",
    "HasBeenArranged": "Wurde vereinbart",
    "HelpInfo": "Hilfeinfo",
    "Information": "Informationen",
    "InputMayNotContainTheseCharacters": "Folgende Zeichen dürfen nicht eingegeben werden: ({0})",
    "InternalCode": "Interner Code",
    "InternalTitle": "Interner Titel",
    "JustPasted": "Soeben eingefügt",
    "Keyword": "Suchbegriff",
    "Label": "Label",
    "LabelSubscript": "Labelbeschreibung",
    "LastCheck": "Letzte Überprüfung",
    "LastModified": "Zuletzt geändert",
    "LastSynchronisationWithDataProvider": "Letzte Synchronisierung mit Datenanbieter",
    "lblEdit": "{0} bearbeiten",
    "lblFalse": "Falsch",
    "lblNo": "Nein",
    "lblTrue": "Wahr",
    "lblYes": "Ja",
    "Link": "Verknüpfen",
    "Linked": "Verknüpft",
    "LinkedTo": "Verknüpft mit",
    "List": "Liste",
    "Logo": "Logo",
    "Mailinglist": "Mailingliste",
    "MaintainSubjects": "Betreffzeilen verwalten",
    "MakeSelection": "Auswählen",
    "MaximumLengthOfXCharactersIsExceededCurrentLengthIsY": "Maximale Länge von {0} Zeichen wurde überschritten. Aktuelle Länge beträgt {1} Zeichen.",
    "MinimumLengthOfXCharactersIsRequired": "Es ist eine Mindestlänge der Zeichen von {0} erforderlich.",
    "ModifySelection": "Auswahl ändern",
    "MoreInformationCanBeFoundInThisFaqArticle": "Weitere Informationen findest Du in diesem <a href=\"{0}\" target=\"_blank\">FAQ-Artikel</a>.",
    "MoveTo": "Verschieben nach",
    "MustBeChecked": "Dieses Kontrollkästchen muss aktiviert sein.",
    "MustBeUnchecked": "Dieses Kontrollkästchen darf nicht aktiviert sein.",
    "MySelection": "Meine Auswahl",
    "Name": "Name",
    "NextResx": "Nächste",
    "No": "Nein",
    "NoChoiceMade": "Keine Auswahl getroffen",
    "NoDataAvailable": "Keine Daten verfügbar.",
    "NoDigitsAllowed": "Keine Zahlen zulässig.",
    "NoItemsInYourSelection": "Keine Datensätze ausgewählt. Wähle zuerst Datensätze aus, indem Du das Kontrollkästchen am Anfang der Zeile aktivierst.",
    "NoOwnerAssigned": "Kein Besitzer zugewiesen",
    "NoPreselection": "Keine Auswahl",
    "NoResultsFound": "Keine Ergebnisse gefunden",
    "NotApplicable": "Nicht zutreffend",
    "NotAvailable": "Nicht verfügbar",
    "NotLinked": "Nicht verknüpft",
    "NotSpecified": "Nicht angegeben",
    "NotVisible": "Nicht sichtbar",
    "NrMb": "{0} Mb.",
    "Number": "Nummer",
    "NumberAndDate": "Zahlen und Datum",
    "NumberItemsDeletedPossibilyNotAllItemsHaveBeenDeleted": "{0} {1} gelöscht. Möglicherweise wurden nicht alle Elemente aus der Auswahl gelöscht. Du musst sie nacheinander löschen, da beim Löschen eine Benutzereingabe erforderlich ist.",
    "Numbers": "Nummern",
    "OnlyFavorites": "Nur Favoriten",
    "OnlyLettersAndNumbersAreAllowed": "Nur Buchstaben und Zahlen zulässig.",
    "OnlyLettersAndSpacesAreAllowed": "Nur Buchstaben und Leerzeichen zulässig.",
    "OnlyLettersAreAllowed": "Nur Buchstaben zulässig.",
    "OpenClosePanel": "Fenster öffnen/schließen",
    "Or": "Oder",
    "Other": "Sonstiges",
    "Others": "Andere",
    "OurLastTweet": "Unser letzter Tweet:",
    "Overview": "Überblick",
    "Overviews": "Übersichten",
    "Owner": "Eigentümer",
    "Page": "Seite",
    "PersonalSettings": "Persönliche Einstellungen",
    "PleaseMakeAChoice": "Bitte auswählen",
    "Position": "Position",
    "Previous": "Vorherige",
    "Privacy": "Datenschutz",
    "ReadMore": "Weitere Informationen",
    "ReceivedOn": "Erhalten am",
    "RecentActivity": "Letzte Aktivität",
    "RecentChanges": "Letzte Änderungen",
    "Recipients": "Empfänger",
    "RecordLabel": "Plattenlabel",
    "ReleaseDate": "Veröffentlichungsdatum",
    "Remaining": "{0} Mb. ({1}% frei)",
    "RepeatLetters": "Wiederhole den farbigen Text.",
    "ReportIssue": "Ein Problem melden",
    "Request": "Anfrage",
    "Requests": "Anfragen",
    "Reset": "Verwerfen",
    "resxColor": "Farbe",
    "resxDefault": "Standard",
    "resxNew": "Neu",
    "Search": "Suchen",
    "SearchByName": "Suchen nach Name",
    "Searching": "Suchen",
    "Section": "Bereich",
    "Sections": "Bereiche",
    "Security": "Sicherheit",
    "SelectEmailAddresses": "E-Mail-Adressen auswählen",
    "SelectEmailAddressesDesc": "Wähle E-Mail-Adressen aus, an die Du die Nachricht senden möchtest.",
    "SelectFromDatabase": "Aus Datenbank auswählen",
    "SelectionTool": "Auswahltool",
    "SendACopyToMyEmailAddress": "Eine Kopie an meine E-Mail-Adresse senden",
    "Settings": "Einstellungen",
    "SignIn": "Anmelden",
    "SignOut": "Abmelden",
    "SomethingUnexpectedHappened": "Es ist ein unerwarteter Fehler aufgetreten. Bitte versuche es später erneut.",
    "Source": "Quelle",
    "Statistics": "Statistik",
    "StatusTitle": "Statustitel",
    "StoreEmailInSystemOne": "E-Mail in SystemOne speichern",
    "Subject": "Betreff",
    "SubjectLine": "Betreffzeile",
    "SupportCenter": "Support-Center",
    "SystemConfiguration": "Systemkonfiguration",
    "SystemOneNews": "SystemOne Neuigkeiten",
    "SystemSettings": "Systemeinstellungen",
    "TaskManager": "Aufgabenmanager",
    "TechnicalDetails": "Technische Details",
    "TheChangesHaveBeenSaved": "Änderungen wurden gespeichert.",
    "TheLettersDidNotMatch": "Die Buchstaben stimmen nicht überein. Bitte versuche es erneut.",
    "ThereAreNoOptionsToModifySelection": "Es sind keine Optionen zum Ändern der Auswahl verfügbar, da Du nicht über ausreichende Berechtigungen für diesen Bereich verfügst.",
    "ThisRecordWillBeMergedWithTheExistingRecord": "Dieser Datensatz wird mit dem Ziel zusammengefügt. Klicke auf den farbigen Text, um den Zielwert zu überschreiben.",
    "ThisRecordWillBeSavedInTheDatabase": "Dieser Datensatz wird in der Datenbank gespeichert.",
    "Title": "Name",
    "Translations": "Übersetzungen",
    "Type": "Typ",
    "UniqueSystemOneKey": "Eindeutiger SystemOne-Schlüssel",
    "Unknown": "Unbekannt",
    "Unnecessary": "Unnötig",
    "Unspecified": "nicht spezifiziert",
    "Usage": "Verbrauch",
    "Visible": "Sichtbar",
    "VisibleInEventFeed": "In Event-API sichtbar",
    "VisibleToExternalUsers": "Für externe Benutzer sichtbar",
    "WebAddressIsInvalid": "Webadresse ungültig",
    "WebDevelopment": "Web Development",
    "Website": "Website",
    "WorksAt": "Arbeitet bei",
    "XNewItemsWereCreated": "1 neues Element wurde erstellt.",
    "Yes": "Ja",
    "YNewItemsWereCreated": "{0} neue Elemente wurden erstellt.",
    "YouCanOnlyDeleteNumberItemsAtATimeInAnAutomated": "Du kannst {0} {1} nur nacheinander löschen. Wähle weniger Elemente aus.",
    "YouCanPerformBatchProcessingOnYourSelection": "Du kannst eine Stapelverarbeitung für Deine Auswahl ausführen. Verwende diese Funktion mit Bedacht. {0} {1} in der Auswahl.",
    "YourDataIsSaved": "Deine Daten wurden gespeichert"
  },
  "Genre": {
    "Genres": "Genres",
    "resxGenre": "Genre "
  },
  "GroundTransport": {
    "AccordingToGoogle": "laut Google",
    "ArriveBy": "Ankunft bis",
    "CarType": "Autotyp",
    "CollectionPoint": "Sammelstelle",
    "DepartAt": "Abreise um",
    "Driver": "Fahrer",
    "DriverEmail": "E-Mail des Fahrers",
    "DriverMobile": "Mobil des Fahrers",
    "DriverName": "Name des Fahrers",
    "EditGroundTransport": "Fahrt bearbeiten",
    "FromTo": "Von / Nach",
    "GroundTransports": "Fahrten",
    "GroundTransportShort": "Fahrt",
    "NewGroundTransport": "Neue Fahrt",
    "Pickup": "Abholung",
    "resxGroundTransport": "Fahrt",
    "Vehicle": "Fahrzeug"
  },
  "PickupLocationType": {
    "Airport": "Flughafen",
    "BoatTerminal": "Fährterminal",
    "Home": "Zuhause",
    "Hotel": "Hotel",
    "Other": "Sonstiges",
    "Restaurant": "Restaurant",
    "TrainStation": "Bahnhof",
    "Venue": "Veranstaltungsort"
  },
  "Guestlist": {
    "AddGuestBtn": "Gäste hinzufügen",
    "BringsGuestsLbl": "Bringt Gäste mit",
    "ClosedStatus": "Geschlossen",
    "ClosingDate": "Liste geschlossen ab",
    "DaysBeforeShow": "{0} Tage vor Deadline",
    "DeleteGuestlist": "Gästeliste löschen",
    "DisplayGuestlist": "Gästeliste anzeigen",
    "EmailMandatoryLbl": "E-Mail obligatorisch",
    "ErrorLoadingGuestlist": "Fehler beim Laden der Gästeliste",
    "Guest": "Gast",
    "List": "Liste",
    "Lists": "Listen",
    "Mandatory": "obligatorisch",
    "MultiplePerNameLbl": "+1 erlaubt",
    "NoExistingGuestlist": "Keine Gästeliste aktiviert",
    "NoTypesExist": "Keine Liste hinzugefügt",
    "NoTypesExistTooltip": "Liste in Einstellungen hinzufügen",
    "OpenStatus": "Offen",
    "Passport": "Personalausweis",
    "PassportRequired": "Personalausweis erforderlich",
    "PhoneMandatoryLbl": "Telefon obligatorisch",
    "PlaceAddButton": "Neue Gästeliste hinzufügen",
    "PlaceCapacity": "{0} Plätze",
    "PlaceCapacityUsage": "{0} reservierte Plätze",
    "PlacesLeft": "{0} Plätze übrig",
    "PlacesTotal": "{0} Plätze insgesamt",
    "resxGuestlist": "Gästeliste",
    "SearchByGuestName": "Nach Gast suchen",
    "UsedSpots": "{0}/{1} Plätze"
  },
  "GuestlistErrors": {
    "AllGuestsMustHaveName": "Gästenamen obligatorisch . +1 nicht erlaubt",
    "CannotReduceAvailablePlaces": "Anzahl Plätze kann nicht niedriger als Anzahl der Gäste sein",
    "CustomNameEmpty": "Gästelistename nicht angegeben",
    "EmailRequired": "Emailadresse obligatorisch",
    "FirstNameEmpty": "Vorname ausfüllen",
    "GuestExists": "Gästeliste bereits erstellt",
    "GuestlistClosed": "Gästeliste geschlossen, keine Änderung möglich",
    "GuestlistExists": "Show hat bereits eine Gästeliste",
    "GuestlistPlaceExists": "Gästelisten mit diesem Namen existiert bereits",
    "HasAnonymousGuests": "Manche Gäste haben ein +1. Fehlende Gästenamen hinzufügen.",
    "LastNameEmpty": "Nachname ausfüllen",
    "ListContainsGuests": "Gästeliste nicht leer, löschen nicht möglich",
    "ListDoesntExist": "Gästeliste existiert nicht",
    "NotAllGuestsHaveEmail": "Gäste ohne Emailadresse. Emailadresse hinzufügen.",
    "NotAllGuestsHavePhone": "Gäste ohne Telefonnummer. Telefonnummer hinzufügen.",
    "PhoneRequired": "Telefonnummer ist obligatorisch",
    "TooManyGuests": "Gästeliste ist voll"
  },
  "GuestlistExceptions": {
    "GuestDoesntExist": "Gast existiert nicht mehr",
    "GuestlistAlreadyExists": "Es existiert bereits eine Gästeliste. Vielleicht wurde sie soeben erstellt?",
    "GuestlistDoesntExist": "Gastliste existiert nicht mehr",
    "GuestlistPlaceDoesntExist": "Liste existiert nicht mehr",
    "NoEditingPermission": "Du bist nicht berechtigt, diese Änderungen vorzunehmen.",
    "ShowDoesntExist": "Show existiert nicht mehr"
  },
  "HttpError": {
    "Error403Description": "Sie haben nicht die richtigen Berechtigungen, um diese Informationen anzuzeigen. Versuchen Sie, sich abzumelden und erneut anzumelden. Wenn dies das Problem nicht löst, wenden Sie sich an Ihren Systemadministrator.",
    "Error403Title": "Zugriff verweigert",
    "Error404Description": "Die Seite konnte nicht gefunden werden. Möglicherweise ist sie umgezogen. Sie sollten Ihre Lesezeichen aktualisieren.",
    "Error404Title": "Seite nicht gefunden",
    "Error500Description": "Schließen Sie den Browser und wiederholen Sie den Vorgang in 5 Minuten. Wenn das Problem weiterhin besteht, wenden Sie sich an SystemOne.",
    "Error500Title": "Programmfehler"
  },
  "Invitation": {
    "AcceptedBySomeoneElse": "Von jemand anderem angenommen",
    "AcceptedBySomeoneElseMessage": "Diese Einladung wurde von einem anderen Konto ({0}) angenommen.",
    "AcceptedNotAuthenticatedMessage": "Diese Einladung wurde von {0} angenommen.",
    "HowToGetInvited": "So wirst du eingeladen",
    "InvitationAccepted": "Einladung angenommen",
    "InvitationNotFound": "Einladung nicht gefunden",
    "InvitationNotFoundMessage": "Diese Einladung wurde gefunden, das könnte bedeuten, dass die Einladung gelöscht wurde oder dass die URL falsch ist. Bitte wende dich an die Person, von der du die Einladung erhalten hast.",
    "InvitationNotYetAccepted": "Einladung noch nicht angenommen",
    "ThatsMe": "Das bin ich",
    "ThatsNotMe": "Das bin ich nicht. Jemand hat mir diesen Link weitergeleitet.",
    "ThisInvitationHasBeenAcceptedBy": "Diese Einladung wurde akzeptiert von {0}.",
    "WriteAMessageThatWillBeDisplayed": "Schreibe eine Nachricht, die in der Einladungsmail angezeigt wird."
  },
  "InvitationTemplate": {
    "BerlinGermany": "Berlin, Deutschland",
    "Concerning": "Bezüglich",
    "PlainTextLinkDescription": "Folgen Sie dem Link:",
    "SystemOneIsMadeBy": "SystemOne wurde entwickelt von",
    "WhatIsThisAboutContentPart1": "Jemand, der SystemOne verwendet, hat dir eine Einladung geschickt.",
    "WhatIsThisAboutContentPart2": "ist eine Plattform, auf der Künstlervermittlungsagenturen Konzerte und Tourneen im Namen der Künstler, die sie vertreten, verwalten können.",
    "WhatIsThisAboutHeader": "Worum geht es?"
  },
  "InvitationTemplateForAdvancing": {
    "ButtonText": "Informationen zur Verfügung stellen",
    "InvitationText": "hat Sie eingeladen, Informationen für eine Show zu vervollständigen.",
    "SubjectText": "Sie sind eingeladen, Informationen zu vervollständigen für",
    "WhatIsThisAboutReason": "Jemand hat Sie eingeladen, beim Advancing einer Show zu helfen."
  },
  "Invoice": {
    "DetailsOnInvoice": "Rechnungsdetails",
    "EditInvoice": "Rechnung bearbeiten",
    "Invoices": "Rechnungen",
    "NewInvoice": "Neue Rechnung",
    "xInvoice": "Rechnung"
  },
  "InvoiceExport": {
    "DebtorRecords": "Debitorenaufzeichnungen",
    "Exported": "Exportiert",
    "ExportInvoiceData": "Rechnungsdaten exportieren",
    "Lock": "Sperren",
    "Locked": "Gesperrt",
    "LockInvoices": "Rechnungen sperren",
    "resxInvoiceExport": "Rechnungsexport",
    "SalesRecords": "Umsatzaufzeichnungen",
    "Unlock": "Entsperren"
  },
  "Language": {
    "DefaultLanguage": "Standardsprache",
    "FilterByLanguage": "Nach Sprache filtern",
    "InterfaceLanguage": "Sprache der Benutzeroberfläche",
    "resxLanguage": "Sprache",
    "SelectLanguage": "Sprache auswählen"
  },
  "LogonResponseMessages": {
    "NoApplicationKeyInvalid": "Ungültiger Anwendungsschlüssel",
    "NoClientAccountSuspended": "Kundenkonto durch SystemOne gesperrt",
    "NoFaultyUserConfiguration": "Problem mit Konfiguration des Benutzerkontos. Kontaktieren Sie den Systemadministrator.",
    "NoIncorrectFormatting": "Benutzername oder Kennwort falsch.",
    "NoMatchInDb": "Benutzername oder Kennwort falsch.",
    "NoRemoteAddressBlacklist": "IP-Adresse auf Schwarzer Liste",
    "NoUnknown": "Unbekannter Fehler",
    "NoUserAccessDisabled": "Konto durch Systemadministrator deaktiviert.",
    "YesAccessGranted": "Zugriff erlaubt",
    "YesGoogleLogin": "Zugriff erlaubt"
  },
  "Map": {
    "AddressLookupPending": "Nachschlagen der Adresse steht aus",
    "CannotFindAddressOnMap": "Adresse konnte nicht auf der Karte gefunden werden",
    "EventMap": "Veranstaltung auf der Karte",
    "NearbyAirports": "Flughäfen in der Nähe",
    "NotEnoughDataToFindAddressOnMap": "Nicht ausreichende Information um Adresse auf Karte zu finden",
    "resxMap": "Karte"
  },
  "MaritalStatuses": {
    "Divorced": "Geschieden",
    "Married": "Verheiratet",
    "NeverMarried": "Nie verheiratet",
    "Widowed": "Verwitwet"
  },
  "Message": {
    "AddMySignature": "Meine Signatur hinzufügen",
    "Appointment": "Termin",
    "AssignedTo": "Zugeordnet zu",
    "AtLeast1EmailAddressAndAtMaximum": "Gebe mindestens 1 E-Mail-Adresse und höchstens {0} E-Mail-Adressen an.",
    "AtMaximumEmailAdresses": "Gebe höchstens {0} E-Mail-Adressen an.",
    "Check": "Überprüfen",
    "ConvertToNote": "In Notiz konvertieren",
    "Deadline": "Termin",
    "EditNote": "Notiz bearbeiten",
    "EmailSent": "E-Mail gesendet",
    "EmailSentFailedDialogText": "Aufgrund eines technischen Fehlers konnte die E-Mail nicht gesendet werden. Bitte wende Dich an SystemOne.",
    "EmailSentSuccessDialogText": "E-Mail wurde gesendet.",
    "Expired": "Abgelaufen",
    "Future": "Zukünftig",
    "lblBcc": "Bcc",
    "lblCC": "Cc",
    "lblTo": "An",
    "Letter": "Brief",
    "MaximumLengthOfCharactersExceeded": "Die maximale Länge von {0} Zeichen wurde überschritten.",
    "Meeting": "Meeting",
    "newMessage": "Neue Mitteilung",
    "newMessages": "Neue Mitteilungen",
    "NoSentEmails": "Es wurden keine E-Mails von diesem Element gesendet.",
    "NoSentSms": "Von diesem Element wurden keine SMS gesendet.",
    "NoSubjectHasBeenEnteredForThisEmail": "Für diese E-Mail wurde kein Betreff angegeben.",
    "PhoneCall": "Telefonanruf",
    "RelatedTo": "Verknüpft mit",
    "Reminder": "Erinnerung",
    "ReminderByEmail": "Erinnerung per E-Mail",
    "ReminderInPopup": "Erinnerung in Pop-Up-Fenster",
    "SentEmail": "Gesendete E-Mails",
    "Subject": "Betreff",
    "ThisMessageIsEmpty": "Diese Nachricht ist leer.",
    "ToggleReminderInSystemOnePopup": "Pop-Up-Funktion ein- oder ausschalten",
    "ToggleReminderPerEmail": "E-Mail-Erinnerung ein- oder ausschalten",
    "TurnReminderInPopupOff": "Erinnerung in Pop-Up-Fenster deaktivieren"
  },
  "MessageTemplate": {
    "NewPersonalMessage": "Neue persönliche Nachricht",
    "NewPersonalMessageFor": "Neue persönliche Nachricht für {0}",
    "NoPersonalMessageSet": "Es wurde keine persönliche Nachricht hinterlegt",
    "NoTranslationsAvailable": "Keine Übersetzungen verfügbar",
    "PersonalMessage": "Persönliche Nachricht",
    "PersonalMessages": "Persönliche Nachrichten"
  },
  "MoreMenu": {
    "AllAirports": "Alle Flughäfen in der Anwendung.",
    "ChangeLockForShow": "Ändere die Sperre für eine Auswahl von Shows auf einmal.",
    "ConfigureWebform": "Formulare konfigurieren, um sie auf Ihrer Website zu platzieren.",
    "FilesThatAreAvailable": "Dateien, die in der gesamten Anwendung verfügbar sind.",
    "ListAllCountries": "Liste aller Länder, die in der Anwendung verfügbar sind.",
    "ListAllCultures": "Liste aller Kulturen, die in der Anwendung verfügbar sind.",
    "ListAllExchangeRates": "Liste aller Wechselkurse, die in der Anwendung verfügbar sind.",
    "ViewAllUploaded": "Alle hochgeladenen Dateien anzeigen.",
    "ViewLogOfChanges": "Protokoll der wichtigen Änderungen anzeigen, die Benutzer vorgenommen haben.",
    "WidgetWithShows": "Ein Widget mit Shows für Ihre Website."
  },
  "MySelection": {
    "XAreLocked": "{0} {1} sind gesperrt und nicht betroffen.",
    "XIsLocked": "1 {0} ist gesperrt und nicht betroffen.",
    "YouAreAboutToChangeX": "Du bist dabei, {0} in Ihrer Auswahl zu ändern.",
    "YourSelectionIsEmpty": "Deine Auswahl enthält keine Elemente."
  },
  "Note": {
    "NewNote": "Neue Notiz",
    "NoNotes": "Zu diesem Element wurden keine Notizen vorgenommen.",
    "Notes": "Notizen"
  },
  "NotificationMessages": {
    "changedStatusTo": "hat den Status geändert in",
    "changedTheShowStatusTo": "hat den Showstatus geändert in",
    "changedTheStatusForAShow": "hat den Status für eine Show geändert.",
    "declinedAdvancingInput": "hat die Advancing-Eingabe abgelehnt.",
    "madeABookingRequest": "hat eine Buchungsanfrage gestellt.",
    "providedInformationThroughAdvancing": "hat Informationen über Advancing bereitgestellt.",
    "uploadedAFileInAdvancing": "hat eine Datei in Advancing hochgeladen.",
    "wroteAMessageInAdvancing": "hat eine Nachricht in Advancing geschrieben."
  },
  "Notifications": {
    "AllNotifications": "Alle Benachrichtigungen",
    "MarkAllAsRead": "Alle als gelesen markieren",
    "MarkAsRead": "Als gelesen markieren",
    "MarkAsUnread": "Als ungelesen markieren",
    "resxNotifications": "Benachrichtigungen",
    "SeeAllNotifications": "Alle Benachrichtigungen anzeigen",
    "ThereAreNoNewNotifications": "Es gibt keine neuen Benachrichtigungen",
    "ThisIsATestNotification": "Dies ist eine Test-Benachrichtigung",
    "ThisNotificationIsHidden": "Diese Benachrichtigung wurde ausgeblendet."
  },
  "NotificationSettings": {
    "AFileIsAdded": "Eine Datei wurde hinzugefügt",
    "AMessageIsSent": "Eine Nachricht wurde gesendet",
    "ANewBookingRequestIsReceived": "Eine neue Buchungsanfrage ist eingegangen",
    "ChangeNotifications": "Ändern, welche Benachrichtigungen Du erhalten möchtest.",
    "ForAllArtists": "Alle Künstler",
    "InputIsDeclined": "Eingabe wurde abgelehnt",
    "Mobile": "Mobil",
    "MyTasksAreDue": "Meine Aufgaben sind fällig",
    "NewInformationIsProvided": "Neue Informationen wurden bereitgestellt",
    "NotifyMeAbout": "Mich benachrichtigen über",
    "NotifyMeWhen": "Mich benachrichtigen, wenn…",
    "OnlyASelectionOfArtists": "Auswahl von Künstlern",
    "ReceiveNotifications": "Benachrichtigungen erhalten",
    "resxNotificationSettings": "Einstellungen für Benachrichtigungen",
    "TheStatusIsChanged": "Der Status wurde geändert",
    "TypesOfNotifications": "Benachrichtigungsarten",
    "When": "wenn…"
  },
  "OnlineInvoice": {
    "DocumentLanguage": "Document language",
    "ViewInvoice": "View invoice"
  },
  "OptionsPanel": {
    "BackTo": "Zurück zu {0}",
    "Copy": "Kopieren",
    "CopyItem": "{0} kopieren",
    "Delete": "Entfernen",
    "DeleteItem": "{0} entfernen",
    "Edit": "Bearbeiten",
    "EditItem": "{0} bearbeiten",
    "Merge": "Zusammenfügen",
    "NewEmail": "Neue E-Mail",
    "NewNote": "Neue Notiz",
    "NewNoteWithItem": "Neue Notiz für {0} schreiben",
    "NewTask": "Neue Aufgabe",
    "NewTaskWithItem": "Neue Aufgabe für {0} erstellen",
    "NextItem": "Nächstes Element",
    "Options": "Optionen",
    "PreviousItem": "Vorheriges Element",
    "ReturnToListView": "Zurück zur Listenansicht"
  },
  "Payment": {
    "Amount": "Betrag",
    "AssignedToInvoice": "Zugeordnet zu Rechnung",
    "BalanceToCreditNumber": "Ausgleichen mit Gutschrift {0}",
    "BalanceToInvoiceNumber": "Ausgleichen mit Rechnung {0}",
    "ChangePaymentMethod": "Zahlungsmethode ändern",
    "EditPayment": "Zahlungen bearbeiten",
    "Expires": "Läuft ab",
    "FilterByPaymentMethod": "Nach Zahlungsmethode filtern",
    "ModifyPayments": "Zahlung bearbeiten",
    "NewPayment": "Neue Zahlung",
    "Paid": "Bezahlt",
    "PaymentDate": "Zahltag",
    "PaymentDetails": "Zahlung Detailansicht",
    "PaymentMethod": "Zahlungsmethode",
    "Payments": "Zahlungen",
    "PaymentsOverview": "Zahlungsübersicht",
    "PaymentStatement": "Zahlungsübersicht",
    "ReceivedFrom": "Erhalten von",
    "ReceivedOn": "Erhalten am",
    "resxPayment": "Zahlung",
    "RetrieveAList": "Eine Liste der Zahlungen herunterladen",
    "WithinPaymentTerms": "Innerhalb der Zahlungsbestimmungen"
  },
  "PaymentMethods": {
    "BalanceForPaymentSurplusDeficit": "Kontostand für Zahlungsüberschuss/-defizit",
    "BalanceToCredit": "Ausgleichen mit Gutschrift",
    "BalanceToInvoice": "Ausgleichen mit Rechnung",
    "BankTransfer": "Banküberweisung",
    "Card": "Karte",
    "Cash": "Barzahlung",
    "CashiersCheck": "Bankscheck",
    "CompanyCheck": "Firmenscheck",
    "DirectDeposit": "Direkt Einzahlung",
    "Internal": "Intern",
    "MoneyOrder": "Zahlungsanweisung",
    "PayPal": "PayPal",
    "SepaDebit": "SEPA-Lastschrift",
    "Stripe": "Stripe",
    "Wise": "Wise",
    "WriteOff": "Abschreibung"
  },
  "PaymentTermDueTexts": {
    "ToBePaidBeforeAnnouncement": "fällig vor Bekanntgabe",
    "ToBePaidUntil": "fällig bis",
    "ToBePaidUntilTheDayOfTheShow": "fällig bis zum Showtag",
    "ToBePaidUtilTheFirstWorkdayAfterShow": "fällig bis zum ersten Werktag nach Show"
  },
  "PaymentTermOptions": {
    "BeforeAnnouncement": "Vor Bekanntgabe",
    "FirstWorkdayAfter": "Erster Werktag nach Show",
    "Month1Before": "1 Monat vorher",
    "Month2Before": "2 Monate vorher",
    "Month3Before": "3 Monate vorher",
    "Month4Before": "4 Monate vorher",
    "Month5Before": "5 Monate vorher",
    "Month6Before": "6 Monate vorher",
    "SameDay": "Am Showtag",
    "SpecificDate": "Spezielles Datum",
    "Week1Before": "1 Woche vorher",
    "Week2Before": "2 Wochen vorher",
    "Week3Before": "3 Wochen vorher"
  },
  "PersonalSettings": {
    "ChangeName": "Name ändern",
    "ChangePassword": "Passwort ändern",
    "ChangeSecretQuestion": "Geheime Frage ändern",
    "ColorSetting": "Farbeinstellung",
    "Connect": "Verbinden",
    "ConnectedToGoogle": "Mit Google verbunden",
    "ConnectToGoogle": "Mit Google verbinden",
    "ConnectYourAccount": "Stelle eine Verbindung mit Deinem SystemOne-Konto her, um die Anmeldung zu erleichtern.",
    "CsvSeparator": "CSV-Trennzeichen",
    "CurrentPassword": "Aktuelles Passwort",
    "DarkMode": "Dunkler Modus",
    "DefaultAttn": "Standard z. H. in Adresse",
    "DefaultRecipientsInBccField": "Standardempfänger im BCC-Feld",
    "DefaultSalutation": "Standardanrede",
    "DefaultsPreferences": "Präferenzen für neue Elemente",
    "DefaultValues": "Standardwerte",
    "EmailSettings": "E-Mail-Einstellungen",
    "FeatureConfiguration": "Feature Konfiguration",
    "Format": "Format",
    "HtmlEmail": "E-Mail mit HTML-Layout (Empfohlen)",
    "LanguageAndRegion": "Sprache & Land",
    "MinutesBetweenReminderPopup": "Zwischen Pop-up-Fenster für Erinnerungen",
    "NewPassword": "Neues Passwort",
    "Password": "Passwort",
    "PasswordMustBeAtLeast": "Das Passwort muss mindestens 10 Zeichen lang sein und sowohl Buchstaben als auch Zahlen enthalten.",
    "PasswordsDontMatch": "Die eingegebenen Passwörter stimmen nicht überein.",
    "PlainTextEmail": "E-Mail ohne Layout / Klartext",
    "Preferences": "Präferenzen",
    "PreselectBookkeeping": "Voreingestellte Buchhaltung",
    "PreselectedCountry": "Voreingestelltes Land",
    "PreselectedCurrency": "Voreingestellte Währung",
    "resxPersonalSettings": "Persönliche Einstellungen",
    "RetypePassword": "Passwort wiederholen",
    "ScrollMenuWithPage": "Menü mit Seite durchblättern",
    "SecretAnswer": "Geheime Antwort",
    "SecretAnswerCannotBeEmpty": "Beantworte die geheime Frage. Diese Frage wird gestellt, falls du dein Passwort vergisst.",
    "SecretQuestion": "Geheime Frage",
    "Sender": "Absender",
    "Styling": "Design",
    "SystemOneFacebookPage": "SystemOne Facebook-Seite",
    "SystemOneTutorials": "SystemOne Tutorials",
    "UploadYourPhoto": "Laden Sie Ihr Foto hoch",
    "UserPreferences": "Nutzerpräferenzen"
  },
  "Planning": {
    "AddAttendeesToAllShowsOfArtistBand": "Teilnehmer für alle Shows der Band/des Künstlers hinzufügen",
    "Attendee": "Teilnehmer",
    "Attendees": "Teilnehmer",
    "Availability": "Verfügbarkeit",
    "AvailabilityCheck": "Verfügbarkeitsüberprüfung",
    "Available": "Verfügbar: {0}",
    "ChangeOwner": "Eigentümer ändern",
    "ChangePublishStatus": "Publikation ändern",
    "ChangeTheOwnerOfAllItemsInTheSelection": "Eigentümer aller {0} in der Auswahl ändern",
    "ChangeThePublishStatusOfAllItemsInTheSelection": "Publikationsstatus aller {0} in der Auswahl ändern",
    "Code": "Code",
    "ConsiderIncluding": "Berücksichtigen einschließlich",
    "DeleteBatchText": "Du bist im Begriff, {0} in Deiner Auswahl zu löschen. Bitte beachte Folgendes:<ul><li>Das Element wird endgültig aus der Datenbank gelöscht.</li><li>Aufgaben, Notizen und E-Mails werden gelöscht.</li><li>Alle {0}, die in anderen Bereichen des Systems verwendet werden, werden nicht gelöscht. Du musst sie manuell löschen.</li></ul>",
    "ExportAll": "Alles exportieren",
    "ExportAllDesc": "Alle Daten aus der Datenbank. (nur für Benutzer mit Systemadministratorrechten)",
    "ExtendedExport": "Erweiterter Export",
    "ExtendedExportDesc": "Mit erweiterten Firmendaten",
    "ExternalInput": "Externe Eingabe",
    "FinancialStatement": "Finanzaufstellung",
    "FinancialStatementDesc": "Finanzaufstellung",
    "FirstLinkedAppointment": "Erster verknüpfter Termin",
    "InputFromWebform": "Buchungsanfrage",
    "InsertedBy": "Eingefügt von",
    "Itinerary": "Itinerary",
    "LinkedCompaniesAndContactPersons": "Verknüpfte Firmen & Kontaktpersonen",
    "Location": "Veranstaltungsort",
    "Logistics": "Logistik",
    "More": "Mehr",
    "NoPublicationToWeb": "Nicht im Web veröffentlichen",
    "NotVisibleToExternalUsers": "Nicht sichtbar für externe Benutzer",
    "NotYetChecked": "Noch nicht überprüft",
    "Once": "Einmal",
    "OneOfTheCompaniesHasOtherItemsOnThisDate": "Eine der Firmen hat andere {0} für dieses Datum:",
    "PublicationToWeb": "Veröffentlichung im Web",
    "Repeat": "Wiederholen",
    "RepeatPeriod": "Wiederholungsintervall",
    "Repetitions": "Wiederholungen",
    "resxPlanning": "Planung",
    "ShortExport": "Verkürzter Export",
    "ShortExportDesc": "Ohne erweiterte Firmendaten",
    "TheOwnerIsChangedForNumberItems": "Der Eigentümer wurde für {0} geändert.",
    "ThePublishStatusIsChangedForNumberItems": "Publikation wurde für {0} geändert.",
    "TitleName": "Titel / Name",
    "Type": "Typ",
    "VisibleToExternalUsers": "Sichtbar für externe Benutzer",
    "VisibleToInternalAndExternalUsers": "Sichtbar für interne Benutzer & externe Benutzer",
    "VisibleToInternalExternalAndEventFeed": "Sichtbar für interne Benutzer, externe Benutzer & in event API",
    "VisibleToInternalUsers": "Sichtbar für interne Benutzer",
    "YouCantDeleteItemsThatHaveBeenChecked": "Du kannst keine {0} löschen, die von einem internen Benutzer überprüft wurden.",
    "YouCantEditItemsThatHaveBeenChecked": "Du kannst keine {0} bearbeiten, die von einem internen Benutzer überprüft wurden."
  },
  "PlanningMap": {
    "TooManyItemsInSelectionError": "There are {0} items in your selection, while maximum {1} items can be displayed on the map. Please apply different filters."
  },
  "Preposition": {
    "For": "Für"
  },
  "Product": {
    "EditProduct": "Produkt bearbeiten",
    "MoveLinkedProducts": "Verknüpftes Produkt verschieben",
    "NewProduct": "Neues Produkt",
    "Products": "Produkte",
    "ProductsThatLinkToThisTemplate": "Mit dieser Vorlage verknüpfte Produkte",
    "xProduct": "Produkt"
  },
  "ProductGroup": {
    "AllProductsWithThisProductGroup": "Alle Produkte und Produktvorlagen in dieser Produktgruppe werden beim Löschen in eine andere Gruppe verschoben.",
    "EditProductGroup": "Produktgruppe bearbeiten",
    "FilterByProductGroup": "Nach Produktgruppe filtern",
    "InGroup": "In Gruppe",
    "Ledger": "Register",
    "LedgerNumber": "Registernummer",
    "NewProductGroup": "Neue Produktgruppe",
    "ProductGroups": "Produktgruppen",
    "ProductsInGroup": "Produkte in dieser Gruppe",
    "resxProductGroup": "Produktgruppe",
    "SelectAnotherProductGroup": "Wählen Sie eine andere Produktgruppe als die aus, die Sie löschen möchten."
  },
  "ProductTemplate": {
    "DescriptionForTemplates": "Beschreibung für Vorlagen",
    "EditProductTemplate": "Produktvorlage bearbeiten",
    "InternalName": "Interner Name",
    "NewProductTemplate": "Neue Produktvorlage",
    "ProductCodeForOwnUse": "Produktcode für internen Gebrauch",
    "ProductNamePlusNote": "Produktname + evtl. Notiz",
    "ProductTemplates": "Produktvorlagen",
    "ProductType": "Produkt-Typ",
    "resxProductTemplate": "Produktvorlage",
    "Unit": "Einheit"
  },
  "ProductTypes": {
    "Accommodation": "Unterkunft",
    "ArtistFee": "Künstlergage",
    "BankFee": "Bank charge",
    "BookingFee": "Buchungsgebühr",
    "BuyoutAccommodation": "Buyout Unterkunft",
    "BuyoutCatering": "Buyout Catering",
    "BuyoutOther": "Buyout Sonstiges",
    "BuyoutTechnical": "Buyout Technik",
    "BuyoutTravel": "Buyout Flüge & sonstige Transportleistungen",
    "Catering": "Gastronomie",
    "Copyright": "Urheberrecht",
    "Custom1": "Benutzerdefiniert 1",
    "Custom2": "Benutzerdefiniert 2",
    "Custom3": "Benutzerdefiniert 3",
    "Insurance": "Versicherung",
    "ManagementFee": "Managementgebühr",
    "Marketing": "Marketing",
    "Production": "Produktion",
    "Services": "Dienstleistungen",
    "Ticketing": "Kartenverkauf",
    "Travel": "Flüge & andere Transporte",
    "Undefined": "Nicht spezifiziert",
    "Visa": "Visum",
    "Wht": "Abgeltungssteuer (WHT)"
  },
  "Production": {
    "EditProduction": "Produktion bearbeiten",
    "NewProduction": "Neue Produktion",
    "Productions": "Produktion",
    "resxProduction": "Produktion",
    "SelectionOfProductions": "Auswahl von Produktionen"
  },
  "Promoter": {
    "CommentOnFinancialRisk": "Kommentar zu finanzielles Risiko",
    "FinancialRisk": "Finanzielles Risiko",
    "NewPromoter": "Neuer Veranstalter",
    "Promoters": "Veranstalter",
    "resxPromoter": "Veranstalter"
  },
  "RecordInformation": {
    "by": "von",
    "CreatedBy": "Hergestellt von",
    "CreatedOn": "Erstellt am",
    "ImageOf": "Foto von {0}",
    "LastModifiedBy": "Zuletzt geändert von",
    "LastModifiedOn": "Zuletzt geändert am",
    "resxRecordInformation": "Informationen zu Angaben"
  },
  "Region": {
    "Regions": "Regionen",
    "resxRegion": "Region"
  },
  "RegionNames": {
    "Africa": "Afrika",
    "Asia": "Asien",
    "CentralAmerica": "Mittelamerika",
    "Europe": "Europa",
    "MiddleEast": "Naher und Mittlerer Osten",
    "NorthAmerica": "Nordamerika",
    "Oceania": "Ozeanien",
    "SouthAmerica": "Südamerika"
  },
  "Reminder": {
    "Reminders": "Erinnerungen",
    "xReminder": "Erinnerung"
  },
  "Restaurant": {
    "Restaurants": "Restaurants",
    "resxRestaurant": "Restaurant"
  },
  "RetrievePassword": {
    "IncorrectUsername": "Der Benutzername ist falsch.",
    "NoSecretAnswerHasBeenEntered": "Es wurde keine geheime Antwort für Deine persönlichen Einstellungen eingegeben. Daher kannst Du Dein Passwort hier nicht abrufen.<br/>Wende Dich an Deinen Systemadministrator, um Ihr Kennwort zurückzusetzen.",
    "NoSecretQuestionHasBeenEntered": "No secret question has been entered with your personal settings, so you cannot retrieve your password here.<br/>Ask your system administrator to reset your password.",
    "ThisIsNotTheCorrectAnswer": "Das ist nicht die richtige Antwort auf die geheime Frage.",
    "YourPasswordHasBeenSent": "Dein Passwort wurde an Deine E-Mail-Adresse {0} gesendet"
  },
  "SecretQuestion": {
    "WhatIsTheBrandOfYourFirstCar": "Welche Marke hatte Dein erstes Fahrzeug?",
    "WhatIsTheNameOfYourFavoritePet": "Wie heißt Dein Lieblingshaustier?",
    "WhatIsTheNameOfYourHighschool": "Wie lautet der Name Deiner Schule?",
    "WhatIsYourMothersMaidenName": "Wie lautet der Geburtsname Deiner Mutter?",
    "WhatIsYourPlaceOfBirth": "Wie lautet der Name Deines Geburtsorts?"
  },
  "SelectionManager": {
    "AddPreset": "Voreinstellung hinzufügen",
    "AllTasksFromTodayOn": "Alle Aufgaben ab heute",
    "AllTasksUpAndIncludingToday": "Alle Aufgaben ab und einschließlich heute",
    "Apply": "Anwendung",
    "AssignedTo": "Zugeordnet zu",
    "AvailableInSelectionTools": "In den Auswahl-Tools verfügbar",
    "BackToFilters": "Zurück zu den Filtern",
    "ByUsing": "durch Verwendung von",
    "CityStateProvinceRegion": "Ort/Staat/Bundesland/Region",
    "ClearFilters": "Filter aufheben",
    "ContactName": "Kontaktname",
    "Country": "Land",
    "CreateNewSelectionSet": "Neue Auswahl erstellen",
    "DealName": "Name der Vereinbarung",
    "EditExistingSelectionSet": "Bestehende Auswahl bearbeiten",
    "Email": "E-Mail",
    "ExportSelection": "Auswahl exportieren",
    "Filter": "Filter {0}",
    "FilterByItem": "Nach {0} filtern",
    "FilterPresets": "Filter Voreinstellungen",
    "Group": "Gruppe",
    "NoFilter": "Kein Filter",
    "NoFiltersApplied": "Kein Filter angewendet",
    "PaymentStatus": "Bezahlstatus",
    "Position": "Position",
    "Presets": "Voreinstellungen",
    "Publication": "Veröffentlichung",
    "ResetFilters": "Filter zurücksetzen",
    "SelectionSetTitle": "Titel der Auswahl",
    "SetAsFavorite": "Als Favorit festlegen",
    "SortBy": "Ordnen"
  },
  "Service": {
    "EditService": "Dienst bearbeiten",
    "Includes": "Enthält",
    "NewService": "Neuer Dienst",
    "resxService": "Dienst",
    "ServiceDetails": "Details zum Dienst",
    "Services": "Dienste",
    "ThisServiceIsUsedByASubscription": "Dieser Dienst wird von einem Abonnement verwendet"
  },
  "Show": {
    "DateOfEvent": "Veranstaltungsdatum",
    "EditShow": "Show bearbeiten",
    "EventDetails": "Veranstaltungsdetails",
    "EventWebsite": "Website der Veranstaltung",
    "LockShows": "Shows sperren",
    "NameOfEvent": "Name der Veranstaltung",
    "NewShow": "Neue Show",
    "resxShow": "Show",
    "Shows": "Shows",
    "TimeOfPerformance": "Uhrzeit Auftritt"
  },
  "Status": {
    "AllItemsWithThisStatus": "Alle Elemente mit diesem Status werden beim Löschen in einen bestehenden Status verschoben.",
    "Blocked": "Blockiert",
    "Cancelled": "Abgesagt",
    "CannotDeleteLast": "Der letzte Status kann nicht gelöscht werden",
    "ChangeStatus": "Status ändern",
    "ChangeTheStatusOfAllItemsInTheSelection": "Ändere den Status aller {0} in der Auswahl.",
    "CheckedByDefault": "Standardmäßig überprüft",
    "CheckedByDefaultInSelectiontool": "Standardmäßig im Auswahltool überprüft",
    "Confirmed": "Bestätigt",
    "DefaultStatus": "Standardstatus",
    "Definitive": "Definitiv",
    "Discarded": "Verworfen",
    "DisplayCancelled": "Absagen anzeigen",
    "DisplayConfirmed": "Bestätigte anzeigen",
    "DisplayOptions": "Anzeige Optionen",
    "EditStatus": "Status bearbeiten",
    "EditStatusFor": "Status von {0}",
    "FirstReminder": "1. Mahnung",
    "IncludeCancelled": "Einschließlich Abgesagter",
    "IncludeConfirmed": "Bestätigte einschließen",
    "IncludeOptions": "Einschließlich Optionen",
    "LastReminder": "Letzte Mahnung",
    "LblOption": "Option",
    "NewStatus": "Neuer Status",
    "NewStatusFor": "Neuer Status von {0}",
    "NoAction": "Keine Aktion",
    "NotCollectable": "Nicht eintreibbar",
    "NotConfirmed": "Nicht bestätigt",
    "Options": "Optionen",
    "Pending": "Ausstehend",
    "Promotional": "Promo",
    "resxStatus": "Status",
    "SecondReminder": "2. Mahnung",
    "SelectAnotherStatus": "Wähle einen anderen Status als den aus, den Du löschen möchtest.",
    "SelectStatus": "Status auswählen",
    "Statuses": "Status",
    "StatusesFor": "Status von {0}",
    "StatusFor": "Status von {0}",
    "TheStatusIsChangedForNumberItems": "Der Status für {0} wird geändert.",
    "ToDebtCollection": "Zum Inkasso",
    "TreatAs": "Behandeln als",
    "WithoutStatus": "Ohne Status",
    "WithStatus": "Mit Status"
  },
  "Subscription": {
    "AccountClosesOnX": "Konto schließt am",
    "AddPeriodicalRaise": "Regelmäßige Erhöhungen hinzufügen",
    "AllInUse": "Alle in Benutzung",
    "BillingCycle": "Abrechnungszeitraum",
    "BillingDetails": "Abrechnungsdetails",
    "Cancelled": "Gekündigt",
    "ChangesToXOn": "➔ {0} am",
    "ContactUsToActivateOrExtendTrial": "Kontaktiere uns um dein Konto zu aktivieren oder dein Testkonto zu verlängern.",
    "ContactUsToChangeBillingCycle": "Kontaktiere uns um deine Zahlweise zu ändern.",
    "ContactUsToChangeToReadOnly": "Kontakte uns um zu einem Read-Only-Konto zu wechlsen und keine Daten zu verlieren. ",
    "Discount": "Ersparnis",
    "DontCancel": "Nicht kündigen",
    "DurationOfSubscription": "Dauer des Abonnements",
    "EditSubscription": "Abonnement bearbeiten",
    "EndUserLicenceTerms": "Endnutzerlizenzbedingungen",
    "For1InternalUser": "Für 1 Internen BenutzerIn",
    "ForXInternalUsers": "Für {0} Interne BenutzerInnen",
    "IncludesPeriodicalRaise": "Enthält regelmäßige Erhöhungen",
    "NewSubscription": "Neues Abonnement",
    "ReadOnly": "Read-Only Abonnement",
    "Renewal": "Verlängerung",
    "resxSubscription": "Abonnement",
    "SaveByPayingPerYear": "Spare 10% bei jährlicher Zahlweise",
    "SomethingWentWrong": "Etwas ist mit Deinen Lizenzen schiefgegangen. Kontaktiere bitte den SystemOne Support. ",
    "Subscribe": "Abonnieren",
    "SubscriptionHasExpired": "Das Abonnement ist abgelaufen",
    "SubscriptionNotYetStarted": "Das Abonnement hat noch nicht begonnen",
    "SubscriptionRenewal": "Abonnement erneuern",
    "Subscriptions": "Abonnements",
    "Switch": "Steige um",
    "SwitchesToFreeOnX": "Konto ändert sich in ein kostenloses Abonnement.",
    "SwitchesToPaidOnX": "Konto ändert sich in ein bezahltes Abonnement.",
    "SwitchesToPausedOnX": "Abonnement wird pausiert am {0}.",
    "SwitchesToReadOnlyOnX": "Konto ändert sich zu einem Read-Only-Abonnement",
    "SwitchToAnnualPayment": "Zu jährlicher Zahlungsweise wechseln",
    "Terms": "Bedingungen",
    "ThisAccountIsForNonCommercialPurposes": "Dieses Konto ist für nicht-gewerbliche Zwecke.",
    "TotalOfActiveSubscriptions": "Gesamtanzahl aktiver Abonnements",
    "TrailEndsOnX": "Testzeitraum endet am {0}",
    "Trial": "Trial",
    "WillExpireWithInNrWeeks": "Läuft innerhalb von {0} Wochen ab",
    "XInUse": "{0} in Benutzung",
    "XUnused": "{0} nicht in Benutzung",
    "YouCanReactivateAnyMoment": "Du kannst dein Abonnement jederzeit reaktivieren. Kontaktiere uns. "
  },
  "Support": {
    "RelatedArticles": "Verwandte Artikel",
    "SupportArticles": "Support-Artikel"
  },
  "Tag": {
    "AddTags": "Tags hinzufügen",
    "AlsoCopyReferences": "Referenzen auch auf Elemente mit Tag kopieren",
    "AssignedTags": "Zugewiesene Tags",
    "AtLeast1TagShouldBeSelected": "Wähle mind. 1 Tag aus.",
    "BelongsToFunctionGroup": "Gehört zur Funktionsgruppe",
    "DisplayInList": "In Liste anzeigen",
    "DontMove": "Keine Referenzen in einen bestehende Tag verschieben",
    "EditTag": "Tag bearbeiten",
    "EditTagFor": "Tag bearbeiten für {0}",
    "MoveTo": "Verschiebe Referenzen zu",
    "NewTag": "Neuer Tag",
    "NewTagFor": "Neuer Tag für {0}",
    "ReassignTagsForAllItemsInTheSelection": "Tags für alle {0} in der Auswahl neu zuweisen. Alle vorherigen Einstellungen der Tags gehen verloren.",
    "ReplaceTags": "Tag ersetzen",
    "resxTag": "Tag",
    "SelectAnotherTag": "Wählen einen anderen Tag aus, als den, den Du löschen möchtest.",
    "TagFor": "Tag für {0}",
    "Tags": "Tags",
    "TagsFor": "Tags für {0}",
    "TagsHaveBeenAddedForNumberItems": "Tags wurden hinzugefügt für {0}.",
    "TagsHaveBeenReassignedForNumberItems": "Tags wurden für {0} neu zugewiesen.",
    "UponDeletingYou": "Du kannst beim Löschen alle Referenzen in einen bestehenden Tag verschieben.",
    "WithoutTag": "Ohne Tag",
    "WithTag": "Mit Tag"
  },
  "Task": {
    "AllTasks": "Alle Aufgaben",
    "ConvertToTask": "In Aufgabe konvertieren",
    "EditTask": "Aufgabe bearbeiten",
    "isOverDueFor": "ist überfällig seit",
    "NewTask": "Neue Aufgabe",
    "NoTasks": "Für dieses Element wurden keine Aufgaben geplant.",
    "OnlyExpiredTasks": "Nur abgelaufene Aufgaben",
    "OnlyFutureTasks": "Nur zukünftige Aufgaben",
    "resxTask": "Aufgabe",
    "Tasks": "Aufgaben",
    "TheTask": "Die Aufgabe"
  },
  "Template": {
    "AddColumn": "Spalte hinzufügen",
    "Backup": "Sicherung",
    "Column": "Spalte",
    "Columns": "Spalten",
    "Content": "Inhalt",
    "CSVFile": "Dateien mit durch Komma getrennten Werten (.csv)",
    "CurrentFile": "Aktuelle Datei",
    "DocumentTemplateForListView": "Word-Vorlage in Listenansicht",
    "DocumentTemplatesForListView": "Word-Vorlagen in Listenansicht",
    "DocXFile": "Word-Datei für Version 2010 und höher (.docx)",
    "EditDocumentTemplateForListView": "Word-Vorlage in Listenansicht bearbeiten",
    "EditEmailTemplate": "E-Mail-Vorlage bearbeiten",
    "EditEmailTemplates": "E-Mail-Vorlagen bearbeiten",
    "EditICalendarTemplate": "ICalendar-Vorlage bearbeiten",
    "EmailTemplate": "E-Mail-Vorlage",
    "EmailTemplates": "E-Mail-Vorlagen",
    "ExcelFile": "Excel-Datei  für Version 2007 und höher (.xlsx)",
    "Export": "Exportieren",
    "GeneralExport": "Allgemeiner Export",
    "GenerateDocumentFromTemplate": "Dokument aus Vorlage generieren",
    "HtmlFile": "Html-Datei",
    "ICalendarTemplate": "ICalendar-Vorlage",
    "ICalendarTemplates": "ICalendar-Vorlagen",
    "MakeSureThatMicrosoftWordIsClosed": "Stelle sicher, dass Microsoft Word geschlossen wird, bevor du die Datei hochlädst.",
    "MergeFieldsForSection": "Kombinationsfelder für den Bereich {0}",
    "NewDocumentTemplateForListView": "Neue Word-Vorlage in Listenansicht",
    "NewEmailTemplate": "Neue E-Mail-Vorlage",
    "NewEmailTemplateFor": "Neue E-Mail-Vorlage für {0}",
    "NewICalendarTemplate": "Neue ICalendar-Vorlage",
    "NoAuthorisationForDetailExport": "Du verfügst über keine Berechtigung, den Detailexport in diesem Bereich zu verwenden. Wende Dich an Deinen Systemadministrator, um Deine Kontoeinstellungen zu ändern.",
    "NoAuthorisationForListExport": "Du verfügst über keine Berechtigung, den Listenansichtexport in diesem Bereich zu verwenden. Wende Dich an Deinen Systemadministrator, um Ihre Kontoeinstellungen zu ändern",
    "NoDetailExportAvailable": "Es wurde noch keine Exportvorlage erstellt. Wende Dich an den SystemOne-Support, um weitere Informationen zu erhalten.",
    "NoEmailTemplatesAreAvailableWithLink": "Für diesen Bereich sind keine E-Mail-Vorlagen verfügbar. <a href=\"{0}\">Klicke hier,</a> um zum Modul für die Bearbeitung der E-Mail-Vorlagen zu gehen.",
    "NoFileHasBeenUploaded": "Es wurde keine Datei hochgeladen.",
    "NoTemplatesAvailable": "Keine Vorlagen verfügbar",
    "OnlyUploadDocXFiles": "Lade nur .DOCX-Dateien, die von Microsoft Word 2010 oder höher erstellt wurden, hoch.",
    "PdfFile": "PDF-Datei (.pdf)",
    "PleaseAddAColumn": "Bitte füge eine Spalte hinzu",
    "PleaseWaitForExportToFinish": "Bitte warte, bis der Export beendet wurde.",
    "resxTemplate": "Vorlage",
    "SelectColumns": "Spalten auswählen",
    "SelectColumnsToExport": "Spalten zum Exportieren auswählen",
    "SelectTemplate": "Vorlage auswählen",
    "TemplateFile": "Vorlagendatei",
    "TemplateSettings": "Vorlageneinstellungen",
    "TheFileSizeOfTheUploadedFile": "Die Dateigröße der hochgeladenen Datei ({0} Kb.) überschreitet die maximale Größe von {1} Kb.",
    "TheMIMETypeOfThefileYouAreTryingToUpload": "Der MIME-Typ der Datei, die Du hochladen möchtest, lautet: {0}.",
    "ThereIsAMaximumToTheNumberOfItemsThatCan": "Es gibt eine maximale Anzahl von Elementen, die exportiert werden können. In der aktuellen Auswahl befinden sich {1} Elemente, von denen die ersten {0} im Export angezeigt werden.",
    "TheUploadedDocumentDidNotPassTheValidationTest": "Das hochgeladene Dokument hat die Überprüfung nicht bestanden. Möglicherweise wurden Probleme in den Dateidaten gefunden. Dies könnte zu unerwarteten Problemen beim Exportieren des Dokuments führen.",
    "TxtFile": "Klartext-Datei (.txt)",
    "UploadNewFile": "Neue Datei hochladen",
    "UseDefaultTemplate": "Standardvorlage verwenden",
    "VATSalesTaxExport": "USt-Export",
    "XMLFile": "Extensible Markup Language file (.xml)",
    "YouCanCreateYourOwnTemplates": "Du kannst deine eigenen Vorlagen erstellen"
  },
  "TestResources": {
    "TestOne": "Test Eins",
    "TestTwo": "Test zwei"
  },
  "Ticket": {
    "NotYetOnPresale": "VVK noch nicht gestartet",
    "OnPresale": "VVK gestartet",
    "PresaleSite": "VVK-Webseite",
    "SoldOut": "Ausverkauft",
    "TicketLink": "Ticket-Link",
    "TicketPrice": "Eintrittspreis",
    "Tickets": "Tickets"
  },
  "TicketTypes": {
    "Door": "Abendkasse",
    "Presale": "VVK",
    "Reduced": "Reduziert"
  },
  "Timetable": {
    "AddElement": "Element hinzufügen",
    "EditElement": "Element bearbeiten",
    "FromOtherShow": "Von anderer Show",
    "xTimetable": "Zeitplan"
  },
  "TimetableEvent": {
    "Arrival": "Ankunft",
    "BackstageCurfew": "Backstage Curfew",
    "Breakfast": "Frühstück",
    "BusCall": "Bus Call",
    "Changeover": "Changeover",
    "ChangeoverAndLinecheck": "Changeover & Linecheck",
    "CrewCall": "Crew Call",
    "Curfew": "Curfew",
    "Dinner": "Abendessen",
    "DoorsClose": "Ende",
    "DoorsOpen": "Einlass",
    "GetIn": "Get In",
    "GetInAndBreakfast": "Get In & Frühstück",
    "GetInAndDinner": "Get In & Abendessen",
    "GetInAndLoadIn": "Get In & Load In",
    "GetInAndLunch": "Get In & Mittagessen",
    "GetInAndSetUp": "Get In & Set Up",
    "GetInArtist": "Get In Künstler",
    "GetInBand": "Get In Band",
    "GetInCatering": "Get In Catering",
    "GetInCrew": "Get In Crew",
    "GetInLight": "Get In Licht",
    "GetInSound": "Get In Sound",
    "GetInSoundAndLight": "Get In Sound & Licht",
    "GetInSupport": "Get In Support",
    "Interview": "Interview",
    "LineCheck": "Linecheck",
    "LoadIn": "Load In",
    "LoadInAndSetUp": "Load In & Set Up",
    "LoadOut": "Load out",
    "Lunch": "Mittagessen",
    "MeetAndGreet": "Meet & Greet",
    "OffStage": "Off Stage",
    "OnStage": "On Stage",
    "Performance": "Performance",
    "PickUp": "Pick Up",
    "ProgrammingLight": "Lichtprogrammierung",
    "Rehearsal": "Probe",
    "SecurityMeeting": "Security Meeting",
    "SetUp": "Set Up",
    "SetUpAndSoundcheck": "Set Up & Soundcheck",
    "SetUpLight": "Set Up Licht",
    "SetUpSound": "Set Up Sound",
    "SetUpSoundAndLight": "Set Up Sound & Licht",
    "Showtime": "Showtime",
    "Soundcheck": "Soundcheck",
    "SoundcheckSupport": "Soundcheck Support",
    "StageClear": "Stage Clear",
    "StageCurfew": "Stage Curfew",
    "StageTime": "Stagetime"
  },
  "Train": {
    "Carriage": "Wagen",
    "From": "Von",
    "NewTrainJourney": "Neue Zugreise",
    "Platform": "Bahnsteig",
    "resxTrain": "Zug",
    "Station": "Bahnhof",
    "To": "Nach",
    "TrainNumber": "Zugnummer",
    "Trains": "Züge"
  },
  "TrainTravelClasses": {
    "FirstClass": "Erste Klasse",
    "SecondClass": "Zweite Klasse",
    "ThirdClass": "Dritte Klasse"
  },
  "SoUser": {
    "Access": "Zugriff",
    "AccessDetailView": "Auf Detailansicht zugreifen",
    "AccessEmails": "Zugriff auf E-Mails",
    "AccessFiles": "Zugriff auf Dateien",
    "AccessGuestlist": "Zugriff auf Gästeliste",
    "AccessListView": "Auf Listenansicht zugreifen",
    "AccessNotes": "Zugriff auf Notizen",
    "AccessStatsAndTotals": "Auf Statistiken und Summen zugreifen",
    "AccessTasks": "Zugriff auf Aufgaben",
    "AccessToArtistData": "Zugriff auf Künstlerdaten",
    "AccessToBookKeepings": "Auf Buchführungen zugreifen",
    "AccessToPayments": "Auf Zahlungen zugreifen",
    "AccessToTaskManager": "Auf Taskmanager zugreifen",
    "AccessToTemplateSettings": "Auf Vorlageneinstellungen zugreifen",
    "AccountDeactivated": "Konto deaktiviert",
    "AccountDetails": "Details zum Benutzerkonto",
    "AccountDiagnose": "Kontodiagnose",
    "AccountIsBlocked": "Konto wurde gesperrt",
    "AccountType": "Kontotyp",
    "Active": "Aktiv",
    "AddNewShows": "Neue Shows hinzufügen",
    "AddProblemEmail": "Ein neuer BenutzerIn wurde erfolgreich erstellt. Jedoch trat beim Senden der E-Mail mit den Anmeldedaten ein Fehler auf. Bitte wende Dich umgehend an SystemOne!",
    "AddSuccess": "Ein neuer BenutzerIn wurde erfolgreich erstellt. Es wurde eine E-Mail mit den Anmeldedetails an diesen BenutzerIn gesendet. Falls der Benutzer diese E-Mail nicht umgehend erhalten hat, bitte ihn/sie, den Spamordner des Posteingangs zu überprüfen.\r\n\r\nIm nächsten Schritt werden die Zugriffsrechte für diesen Benutzer festgelegt.",
    "AMaximumOfCompanies": "Diesem BenutzerIn können maximal {0} Firmen zugewiesen werden.",
    "AmountsSettings": "Betrageinstellungen",
    "AmountsVisible": "Beträge sichtbar",
    "AssignedCompanies": "Zugewiesene Firmen",
    "AssignedContacts": "Zugewiesene Kontakte",
    "AssignedUsers": "Zugewiesene BenutzerIn",
    "AssignUsers": "BenutzerIn zuweisen",
    "AtLeast1ArtistShould": "Mindestens 1 KünstlerIn muss zugeordnet sein.",
    "AtLeast1BookKeeping": "Dieser BenutzerIn sollte auf mindestens 1 Buchführung zugreifen können.",
    "AtLeast1ContactShould": "Dieser BenutzerIn muss mindestens 1 Kontakt zugewiesen sein.",
    "CurIdentityName": "Aktueller Benutzername",
    "DeactivateAccount": "Konto deaktivieren",
    "Deactivated": "Deaktiviert",
    "DefaultAttn": "z.Hd:",
    "DefaultPublication": "Standardpublikation",
    "DefaultSalutation": "Sehr geehrte Damen und Herren",
    "DefaultSignature": "Mit freundlichen Grüßen,<br />\r\n<br />\r\n<b>{0}</b><br />\r\n{1}<br />\r\n<br />\r\np. +00(0)00 000 00 00<br />\r\nw. www.IhreWebsite.com",
    "DontTransfer": "Nicht übertragen",
    "EmailOfLoggedOnUser": "Email von eingeloggtem BenutzerIn",
    "Export": "Exportieren",
    "ExportDesc": "Einschränkung auf die Verwendung von Exportvorlagen.",
    "ExportFromDetailView": "Aus Detailansicht exportieren",
    "ExportFromListView": "Aus Listenansicht exportieren",
    "ExportInPlanning": "Exportieren in Planung",
    "ExportSettings": "Einstellungen exportieren",
    "ExtraInternalUsersCost": "Extra Kosten für interne BenutzerIn {0} {1}.",
    "FirstNameOfLoggedOnUser": "Vorname des angemeldeten Benutzers",
    "FromListViewAndDetailView": "Von Liste und Detailansicht",
    "FullNameOfLoggedOnUser": "Vollständiger Name des angemeldeten Benutzers",
    "InBookkeeping": "In Buchführung",
    "Insert": "Einfügen",
    "InsertionOfLoggedOnUser": "Zweiter Vorname des angemeldeten Benutzers",
    "InsertNotes": "Notizen einfügen",
    "InvitationPending": "Einladung ausstehend",
    "LastActivity": "Letzte Aktivität",
    "LastLogin": "Letzte Anmeldung",
    "LastNameOfLoggedOnUser": "Nachname des angemeldeten Benutzers",
    "LimitedAccess": "Eingeschränkter Zugriff",
    "ListExport": "Listen exportieren",
    "MemberOf": "Mitglied von",
    "Modify": "Ändern",
    "MoveOwnership": "Als Eigentum kennzeichnen",
    "MoveTasks": "Aufgaben verschieben",
    "NeverLoggedOn": "Noch nie angemeldet",
    "NewUsername": "Neuer Benutzername",
    "NoAccess": "Kein Zugriff",
    "NoAccessToArtists": "Kein Zugriff auf KünstlerInnen",
    "NoAmounts": "Keine Beträge",
    "NoCompaniesHaveBeenAssigned": "Dieser BenutzerIn wurden keine Firmen zugewiesen. Daher werden diesem BenutzerIn keine Daten angezeigt, wenn dieser angemeldet ist.",
    "NoUsersHaveBeenAssigned": "Keine BenutzerIn zugewiesen",
    "OnlyCosts": "Nur Kosten",
    "OnlyFromDetailView": "Nur aus Detailansicht",
    "OnlyOnlineUsers": "Nur Online-BenutzerIn",
    "OnlySales": "Nur Verkauf",
    "OnlyVisibleToAdminOneUsers": "Nur für Admin One-Benutzer sichtbar",
    "Permissions": "Erlaubnis",
    "PersonalDetails": "Persönliche Informationen",
    "ReactivateAccount": "Konto reaktivieren",
    "ReadPromoterDetails": "Lesezugriff auf Veranstalterdaten",
    "ReadTags": "Tags lesen",
    "RemoveAssignedCompany": "Zugewiesene Firma entfernen",
    "RemoveAssignedCompanyDesc": "Möchten Du die Zuweisung dieser Firma wirklich entfernen? BenutzerIn kann dadurch nicht mehr auf die mit dieser Firma verknüpften Daten zugreifen.",
    "ResetPassword": "Passwort zurücksetzen",
    "SelectUser": "Benutzer auswählen",
    "SendEmails": "E-Mails versenden",
    "ShowCosts": "Kosten anzeigen",
    "ShowSales": "Umsatz anzeigen",
    "ShowX": "{0} anzeigen",
    "Signature": "Signatur",
    "SignatureOfLoggedOnUser": "Signatur der angemeldeten BenutzerIn",
    "SorryThisUsernameHasAlreadyBeenTaken": "Dieser Benutzername ist leider schon vergeben.",
    "SpecialSettings": "Besondere Einstellungen",
    "TheCompanyIsNotAssignedToThisUser": "Die Firma <i>{0}</i> wurde diesem BenutzerIn nicht zugewiesen. Ändern Sie die zugewiesenen Firmen in der Detailansicht dieses Benutzerkontos.",
    "ThePasswordForHasBeenReset": "Das Passwort für <b>{0}</b> wurde zurückgesetzt.<br />Eine E-Mail mit den neuen Anmeldeinformationen wurde gesendet an: <b>{1}</b>.",
    "ThePasswordForHasBeenResetButThereWasAProblem": "Das Kennwort für <b>{0}</b> wurde zurückgesetzt. Jedoch trat beim Senden der E-Mail mit den Anmeldedaten ein Fehler auf. Bitte wende Dich umgehend an SystemOne.",
    "TheProvidedPasswordIsIncorrect": "Das angegebene Kennwort ist falsch.",
    "ThisUserHasAccessToAllArtists": "Dieser BenutzerIn hat Zugriff auf alle KünstlerInnen",
    "ToAnotherUser": "Zu einer anderen BenutzerIn",
    "TransferToOtherUser": "Übertragen auf andere BenutzerIn",
    "UpTo1User": "Bis zu 1 BenutzerIn",
    "UpToNrUsers": "Bis zu {0} Benutzer",
    "User": "BenutzerIn",
    "UserHasAccess": "BenutzerIn hat Zugriff",
    "UserHasNoAccess": "BenutzerIn hat keinen Zugriff",
    "Username": "Benutzername",
    "Users": "Benutzer",
    "UsersThatCanAccess": "BenutzerIn, die zugreifen können auf",
    "ViewAll": "Detail-/Listenansicht anzeigen",
    "ViewInsert": "Detail-/Listenansicht anzeigen & einfügen",
    "ViewList": "Listenansicht anzeigen",
    "ViewModifyInsert": "Detail-/Listenansicht anzeigen, ändern & einfügen",
    "ViewModifyInsertDelete": "Detail-/Listenansicht anzeigen, ändern, einfügen & löschen",
    "WhenYouUpgradeNowYourWillBe": "Wenn Du upgradest, werden dir bis zur nächsten Verlängerung {0} {1} in Rechnung gestellt.",
    "WillBeAutomaticallyGenerated": "Wird automatisch erstellt und per E-Mail gesendet",
    "YouCantMakeThisChangeToYourOwnAccount": "Du kannst diese Änderung nicht am eigenen Konto vornehmen",
    "YouHaveNoAccessToTheSystemSettings": "Du hast keinen Zugriff auf die Systemeinstellungen.",
    "YouHaveNoAccessToTheTemplateSettings": "Du hast keinen Zugriff auf die Vorlageneinstellungen.",
    "YourSubscriptionRuns": "Dein Abonnement läuft von {0} bis {1}. Wenn Du einen BenutzerIn erstellen möchtest, musst Du Deine Lizenzen erweitern.",
    "YourSystemAdministratorShould": "DeinE SystemadministratorIn muss Deinem Benutzerkonto „Verknüpfte Firmen“ zuweisen, damit Du die Daten einsehen kannst."
  },
  "UserType": {
    "DeleteDeveloper": "Developer löschen",
    "DeleteExternalUser": "Externe Benutzer löschen",
    "DeleteInternalUser": "Interne Benutzer löschen",
    "Developer": "Developer",
    "Developers": "Entwickler",
    "EditDeveloper": "Entwickler bearbeiten",
    "EditExternalUser": "Externe Benutzer bearbeiten",
    "EditInternalUser": "Interne Benutzer bearbeiten",
    "External": "Extern",
    "ExternalUser": "Externer Benutzer",
    "ExternalUsers": "Externe Benutzer",
    "Internal": "Intern",
    "InternalUser": "Interner Benutzer",
    "InternalUsers": "Interne Benutzer",
    "NewDeveloper": "Neue Entwickler",
    "NewExternalUser": "Neue Benutzer",
    "NewInternalUser": "Neue Benutzer",
    "SelfRegisteredUser": "Selbst registriert",
    "SelfRegisteredUsers": "Selbst registriert"
  },
  "UserAction": {
    "RecentUserActions": "Letzte Benutzeraktivität"
  },
  "VatApplicableLabels": {
    "NoBookkeepingOutsideEU": "Nein, Buchführung ist außerhalb der EU registriert",
    "NoBuyerOutsideEU": "Nein, Adressat befindet sich außerhalb der EU",
    "NoEUBuyerHasValidVATNumber": "Nein, Adressat verfügt über gültige USt.-Id.Nr.",
    "YesEUBuyerHasNoValidVATNumber": "Ja, Adressat verfügt nicht über gültige USt.-Id.Nr.",
    "YesEUBuyerInOwnCountry": "Ja, Adressat aus gleichem Land"
  },
  "VatNumber": {
    "ChargeVAT": "Umsatzsteuer berechnen",
    "CheckAgain": "Erneut überprüfen",
    "ClearVATNumberAndPlaceInANote": "Nummer der Umsatzsteuer löschen und als Notiz speichern",
    "IncorrectFormat": "Falsche Formatierung",
    "InEU": "In der EU",
    "LookupInDatabase": "In VIES Datenbank nachschauen",
    "ManuallyApproved": "Manuell überprüft",
    "ModifyVATNumber": "Umsatzsteuernummer ändern",
    "NoVATRatesHaveBeenRegistered": "Für dieses Land wurden noch keine USt.-Sätze eingegeben. Wenden Sie sich für mehr Informationen an SystemOne.",
    "RateType": "Art des Steuersatzes",
    "RemoveVATNumber": "Umsatzsteuernummer entfernen",
    "resxVATNumber": "USt.-Id.-Nr.",
    "VAT": "USt",
    "VATExcluded": "Exkl. USt",
    "VATIncluded": "Inkl. USt",
    "VATNumberRemoved": "Umsatzsteuernummer entfernt",
    "VATNumbers": "Umsatzsteuernummer",
    "VATNumbersWithProblems": "Fehlerhafte Umsatzsteuernummer",
    "VATRates": "USt.-Satz",
    "VisitEuropeanCommission": "Bei Seite der Europäischen Kommision VIES nachschauen"
  },
  "VatNumberValidationLabels": {
    "FormattingError": "USt.IdNr ist inkorrekt formatiert",
    "FoundInEuDB": "In VIES-Datenbank gefunden",
    "LookupPending": "Abfrage in VIES-Datenbank bevorstehend",
    "NoData": "Keine Umsatzsteuer-ID registriert",
    "NotFoundInEuDb": "Nicht in VIES-Datenbank gefunden",
    "OutsideEU": "Unternehmen außerhalb der EU",
    "WebServiceError": "Abfrage in VIES-Datenbank bevorstehend [E]"
  },
  "Venue": {
    "AgeRestrictions": "Altersbeschränkungen",
    "AllAges": "Alle Altersgruppen",
    "Capacity": "Kapazität",
    "Category": "Kategorie",
    "Close": "schließen",
    "DeepL": "tief",
    "Depth": "Tiefe",
    "Doors": "Türen",
    "DressingRooms": "Umkleideräume",
    "GearAccess": "Ladeweg",
    "Lighting": "Beleuchtung",
    "MeterAbr": "M",
    "NewVenue": "Neue Veranstaltungsorte",
    "NoiseRestrictions": "Lärmbeschränkungen",
    "Open": "offen",
    "Parking": "Parkplatz",
    "Power": "Stromversorgung",
    "resxVenue": "Veranstaltungsort",
    "Stage": "Bühne",
    "StageDepth": "Bühnentiefe",
    "StageToCeiling": "Bühne bis Decke",
    "StageToFloor": "Bühne bis Boden",
    "StageWidth": "Bühnenbreite",
    "ToCeiling": "Bis Decke",
    "ToCeilingL": "bis Decke",
    "ToFloor": "Bis Boden",
    "ToFloorL": "bis Boden",
    "VehicleAccess": "Fahrzeugzufahrt",
    "Venues": "Veranstaltungsorte",
    "WideL": "breit",
    "Width": "Breite",
    "xYearsAndOver": "ab {0} Jahren"
  },
  "VenueCategories": {
    "BanquetHall": "Festsaal",
    "Boat": "Schiff",
    "Business": "Business",
    "Church": "Church",
    "ConcertHall": "Konzertsaal",
    "ConferenceCenter": "Konferenzzentrum",
    "Festival": "Festival",
    "Hotel": "Hotel",
    "IndoorArena": "Indoor-Arena",
    "Library": "Library",
    "LiveMusicBar": "Live music bar",
    "LivingRoom": "Living room",
    "Nightclub": "Club / Diskothek",
    "Other": "Sonstiges",
    "Outdoor": "Open Air",
    "Pub": "Bar",
    "Restaurant": "Restaurant",
    "School": "Schule",
    "SportsStadium": "Sportstadion",
    "Tent": "Zelt",
    "Theatre": "Theater"
  },
  "Webform": {
    "AfterSubmitting": "Nach dem Senden",
    "ChooseFromDatabase": "Aus Datenbank auswählen",
    "CssStyleSheet": "CSS-Stylesheet",
    "CustomizeStyling": "Styling anpassen",
    "DefaultUser": "Standardbenutzer",
    "DescriptionSmallLabel": "Beschreibender Untertext",
    "DisplayFlags": "Flaggen anzeigen",
    "DisplayInputFieldForComment": "Eingabefeld für Kommentar anzeigen",
    "EstimatedTimeOfPerformance": "Auftrittszeit",
    "FormElementIsHidden": "Das Formularelement ist ausgeblendet.",
    "FormElementIsVisibleInputOptional": "Formularelement ist sichtbar, Eingabe ist optional.",
    "FormElementIsVisibleInputRequired": "Formularelement ist sichtbar, Eingabe ist erforderlich.",
    "FormElements": "Formularelemente",
    "FormMode": "Formularmodus",
    "FreeTextField": "Freies Textfeld",
    "FullHtmlFrame": "Vollständiger HTML-Frame",
    "Hidden": "Ausgeblendet",
    "IFrameCode": "IFrame Code",
    "IfTheContactPersonIsntValidatedItWillAlso": "Wenn die Kontaktperson nicht überprüft wurde, wird sie ebenfalls gelöscht.",
    "IfThisCompanyDoesntExists": "Wenn diese Firma nicht in der Datenbank existiert, kannst Du die Informationen überprüfen und freigeben.",
    "IfThisContactPersonDoesntExists": "Wenn diese Kontaktperson nicht in der Datenbank vorhanden ist, kannst Du die Informationen überprüfen und genehmigen.",
    "InIFrame": "In Iframe",
    "InputCheck": "Überprüfung der Eingabe",
    "ManualInput": "Manuelle Eingabe",
    "MessageAfterSubmitting": "Nachricht nach dem Senden",
    "PreviewForm": "Preview form",
    "RemoveInformationIfThisCompanyShouldnt": "Informationen löschen, wenn diese Firma nicht in der Datenbank gespeichert werden soll.",
    "RemoveInformationIfThisContactPersonShouldnt": "Informationen löschen, wenn diese Kontaktperson nicht in der Datenbank gespeichert werden soll.",
    "ReportTechnicalIssuesTo": "Melden technische Probleme an: {0}.",
    "resxWebform": "Formular für Buchungsanfrage",
    "SendNotificationEmailToDefaultUser": "Benachrichtigungs-E-Mail an Standardbenutzer senden",
    "ThisFormIsLinkedTo": "Dieses Formular ist mit {0} verknüpft.",
    "ThisFormIsMaintainedBy": "Dieses Formular wird von {0} gepflegt",
    "TitleSmallLabel": "Wird als Kopfzeile angezeigt",
    "ValidationOfInputThroughWebform": "Überprüfung der im Buchungsanfrageformular eingegebenen Daten",
    "WebformHelpText": "Diese Informationen wurden über ein Buchungsanfrageformular eingegeben. Bevor Du zur Detailansicht gehen kannst, müssen die eingegebenen Daten überprüft werden.",
    "Webforms": "Buchungsanfrageformulare",
    "YouHaveInsufficientAccessToCompanySection": "Sie verfügen nicht über ausreichend Zugriff auf den Firmenbereich. Wenden Dich an Deinen Systemadministrator, um Berechtigungen zum Einfügen, Aktualisieren und Löschen Deines Kontos zu erhalten.",
    "YouHaveInsufficientAccessToContactSection": "Du verfügst nicht über ausreichend Zugriff auf den Kontaktbereich. Wenden Dich an Deinen Systemadministrator, um Berechtigungen zum Einfügen, Aktualisieren und Löschen Deines Kontos zu erhalten."
  },
  "WebformEditionDefaults": {
    "Artist_EventOfferDescription": "Angebotener Betrag enthält keine Agenturgebühren, Reisekosten, Unterbringung, Verpflegung und Steuern.",
    "Artist_MainDescription": "Bitte gebe folgende Informationen ein, um eine Künstleranfrage zu starten.",
    "Artist_MainDescriptionSuccess": "Vielen Dank, wir haben Ihre Anfrage erhalten. Wir werden uns umgehend mit Ihnen in Verbindung setzen.",
    "Artist_MainTitle": "Buchungsanfrage"
  },
  "WordTemplate": {
    "EditWordTemplate": "Word-Vorlage bearbeiten",
    "NewWordTemplate": "Neue Word-Vorlage",
    "NewWordTemplateFor": "Neue Word Template für {0}",
    "resxWordTemplate": "Word-Vorlage",
    "WordTemplates": "Word-Vorlagen"
  }
};

